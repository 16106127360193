//LIBRARIES
import React, { useState, useEffect } from "react";
import PasswordPresentational from "./presentational";

const minimumValidScore = 25;
const minimumLength = 8;

function Password(props) {
  const { valid, setInput, input, label, ...other } = props;
  const [state, setState] = useState({
    bottomLabel: "",
    score: 0,
    strength:{
      bottomLabel: "",
      progressBar: 0,
      keyword: '',
    }
  });

  useEffect(() => {
    if(!input && valid === null)
    setState({
      bottomLabel: "",
      score: 0,
      strength:{
        bottomLabel: "",
        progressBar: 0,
        keyword: '',
      }
    });
  }, [input, valid])


  function handleOnChange(event) {
    let [validPassword, score] = validatePassword(event.target.value);
    let [bottomLabel,progressBar,keyword] = getPasswordStrength(event.target.value,score);
    setInput({ value: event.target.value, valid: validPassword });

    setState({
      ...state,
      strength: {bottomLabel: bottomLabel, progressBar: progressBar, keyword:keyword},
    });
    
  }

  return (
    <PasswordPresentational
      label={label} 
      isEmpty={input.length === 0}
      valid={valid}
      value={input}
      handleOnChange={handleOnChange}
      strength={state.strength}
      {...other}
    />
  );
}

export default Password;

function scorePassword(pass) {
  var score = 0;
  if (!pass) return score;

  // award every unique letter until 5 repetitions
  var letters = {};
  for (var i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;
  return parseInt(score);
}


function getPasswordStrength(pass, score) {

  if (pass.length < minimumLength) {
    return ["*Passwords must be at least " + minimumLength + " characters long.",0,""];
  } 
  
  else {
    switch(true){
    case (score < minimumValidScore):
      return ["*That password is too easy to guess.",25,""];
    case (score < 50):
      return ["That password is good, but you can make it even better.",50,"Weak"];
    case (score < 75):
      return ["That password is good, but you can make it even better.",75,"Fair"];
    case (score >= 75):
      return ["Great password! Only use it here to help protect your account.",100,"Strong"];
    default:
      return ["0"];
    }
  }
}

function validatePassword(pass) {
  let score = scorePassword(pass);
  let length = pass.length;
  if (length >= minimumLength && score >= minimumValidScore)
    return [true, score];
  return [false, score];
}
