//LIBRARIES
import React, {useState} from "react";
import {InputBase} from "components/UI";
import { 
  StyledStrengthLabel as StrengthLabel,
  StyledProgressBarContainer as ProgressBarContainer,
  StyledStrengthContainer as StrengthContainer,
  StyledProgress as Progress,
  StyledIconButton as IconButton,
  StyledIcon as Icon,
  StyledIconContainer as IconContainer,
} from "./style";


import {GrowIn} from 'components/UI/Animations'

function PasswordPresentational(props) {
  const { valid, handleOnChange, strength, isEmpty, label, ...other } = props;
  const [visible, setVisible] = useState(false);

  function changeVisibility(){
    setVisible(!visible);
  }

  let buttonComponent = <IconButton onClick={changeVisibility} icon={!visible ? "eye" : "eye-slash"} tabIndex="-1"/>
  // <IconButton icon={"paper"}/>
  return (
    <InputBase
      topRightLabelComponent={<TopRightLabelComponent valid={valid} isEmpty={isEmpty} strength={strength}/>}
      valid={valid}
      type={visible ? "text" : "password"}
      label={label} 
      buttonComponent={buttonComponent}
      handleOnChange={handleOnChange}
      bottomLabel={strength.bottomLabel}
      {...other}
    />
  );
}

export default PasswordPresentational;


function ProgressBar({percentage, ...props }) {
  return (
    <ProgressBarContainer {...props}>
            <Progress percentage={percentage}/>
    </ProgressBarContainer>
  );
}

function TopRightLabelComponent({valid,isEmpty, strength, ...props }) {

    return (
      <StrengthContainer valid={valid} strength={strength}{...props}>
            <IconInLabel valid={valid} strength={strength} />
            <StrengthLabel>{strength.keyword}</StrengthLabel>
            {!isEmpty && <ProgressBar percentage={strength.progressBar}/>}
      </StrengthContainer>
    );
  }
  
  
  function IconInLabel(props) {
    const {valid, strength} = props;
    if (valid!==null)
      return (
          (valid) ?
            <GrowIn>
              <IconContainer >
                <Icon valid={valid ? 1 : 0} name={strength.progressBar>75 ? "solid circle-check" : "solid alert"}/>
              </IconContainer>
            </GrowIn>
            :
            <GrowIn>
              <IconContainer >
                <Icon valid={valid ? 1 : 0} name={"solid circle-minus"}/>
              </IconContainer>
            </GrowIn>
          );
      else return <></>
  }
  
  

