//LIBRARIES
import React, { useRef, useState, useEffect } from "react";
//STYLEDCOMPONENTS
import {
  StyledContainer as Container,
  StyledBreadcrumbs as BreadcrumbsBase,
  StyledHomeTitle as HomeTitle,
  StyledSkeleton as Skeleton
} from "./style";
import { useSelector } from "react-redux";
import { Link } from "@mui/material";
import { Typography } from "components/UI";
import { Link as RouterLink } from "react-router-dom";

const LinkRouter = (props) => <Link underline="hover" {...props} component={RouterLink} />;

function Breadcrumbs(props) {
  const breadcrumbs = useSelector((store) => store.breadcrumbs);
  const characterWidthPixel = 10;
  let breadcrumbsCharacters = 0;
  let maxItems = 0;
  const [dimensions, setDimensions] = useState(null);
  const ref = useRef(null);
  const inAccount = window.location.pathname === '/account'


  useEffect(() => {
    if (!dimensions)
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    function handleResize(event) {
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  if (breadcrumbs.loaded && dimensions !== null) {
    breadcrumbs.payload.forEach((breadcrumb, i) => {
      breadcrumbsCharacters = breadcrumbsCharacters + breadcrumb.title.length;
      let breadcrumbsWidth =
        breadcrumbsCharacters * characterWidthPixel + (i + 1) * 4;
      if (breadcrumbsWidth > dimensions.w) maxItems = i;
    });
  }

  return (
    <Container ref={ref}>
      {!inAccount &&
        (breadcrumbs.loaded ?
          (breadcrumbs.payload.length !== 1 ? (
            <BreadcrumbsBase
              maxItems={maxItems !== 0 ? maxItems : undefined}
              aria-label="breadcrumb"
            >
              {breadcrumbs.payload.map((path, index) => {
                const last = index === breadcrumbs.payload.length - 1;
                let to = `/${path.base}/${path.current}`;

                if (to === "//") to = "/library";

                return last ? (
                  <Typography color="textPrimary" key={to}>
                    {path.title}
                  </Typography>
                ) : (
                  <LinkRouter color="inherit" to={to} key={to}>
                    {path.title}
                  </LinkRouter>
                );
              })}
            </BreadcrumbsBase>
          ) : (
            <HomeTitle>
              {/* <TheosLogo/>
                <Logo size={45} /> */}
              <Typography size="xl" color="primary" shadow={false}>
                Library
              </Typography>
            </HomeTitle>
          ))
          :
          <Skeleton />
        )}
    </Container>
  );
}

export default Breadcrumbs;
