import * as Types from "./types";


const initialState = {
    loaded:false,
    loading:false,
    loadError:false,
    payload:[],
    error:''
}


function ProjectsReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECTS_INIT:
            return initialState
            
        case Types.PROJECTS_REQUEST:
            return {
                loaded:false,
                loading:true,
                loadError:false,
                payload:[],
                error:''
            }
        case Types.PROJECTS_SUCCESS:
            return {
                loaded:true,
                loading:false,
                loadError:false,
                payload:action.payload.projects,
                error:''
            }
        case Types.PROJECTS_FAILURE:
            return {
                loaded:true,
                loading:false,
                loadError:true,
                payload:[],
                error:action.payload.error
            }
        default: return state
    }
}

export default ProjectsReducer;