import * as Types from './types';


const initialState = {
    loaded:false,
    payload:{},
    error:'',
}


function AlgorithmTrainedReducer(state=initialState, action){
    switch(action.type){
        case Types.ALGORITHM_TRAINED_INIT:
            return initialState
            
        case Types.ALGORITHM_TRAINED_REQUEST:
            return {
                ...state,
                loaded:false
            }
        case Types.ALGORITHM_TRAINED_SUCCESS:
            return {
                loaded:true,
                payload:action.payload.algorithm,
                error:''
            }
        case Types.ALGORITHM_TRAINED_FAILURE:
            return {
                loaded:false,
                payload:{},
                error:action.payload.error
        }
        default: return state
    }
}

export default AlgorithmTrainedReducer;