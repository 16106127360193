import styled from "styled-components";

const borderRadius = 15;
const borderWidth = 1;
const minHeight = 10;

export const Border = styled.div`
  /* background: ${(props) =>
    props.theme.colors.autocomplete.border.linearGradient}; */
  height: ${(props) => props.height ? props.height : `${minHeight}px`};
  display: flex;
  padding: ${borderWidth}px;
  border-radius: ${borderRadius + borderWidth}px;
  transition: box-shadow 0.2s ease-in-out;
      box-shadow:  ${(props) =>
    `inset -${props.theme.effects.neumo.spread/2}px 
      -${props.theme.effects.neumo.spread/2}px 
      ${props.theme.effects.neumo.blurLength}px 0 
      ${props.theme.colors.boxShadow.neumo.light}, 
      inset  ${props.theme.effects.neumo.spread/2}px 
      ${props.theme.effects.neumo.spread/2}px 
      ${props.theme.effects.neumo.blurLength}px 0 
      ${props.theme.colors.boxShadow.neumo.contrast},
      -${props.theme.effects.neumo.spread/2}px 
      -${props.theme.effects.neumo.spread/2}px 
      ${props.theme.effects.neumo.blurLength}px 0 
      ${props.theme.colors.boxShadow.neumo.light},
       ${props.theme.effects.neumo.spread/2}px 
      ${props.theme.effects.neumo.spread/2}px 
      ${props.theme.effects.neumo.blurLength}px -3px
      ${props.theme.colors.boxShadow.neumo.contrast}
      `};
`;

export const ProgressDiv = styled.div`
  border-radius: ${borderRadius}px;
  background-image: ${(props) => props.theme.colors.progressBar};
  box-shadow: -2px -2px 5px 0 ${(props) => props.theme.colors.boxShadow.neumo.light},
    3px 3px 7px 0px ${(props) => props.theme.colors.boxShadow.green.light},
    inset 1px -1px 1px 0 ${(props) => props.theme.colors.boxShadow.green.light},
    inset -0.5px 0.5px 2px -1px
      ${(props) => props.theme.colors.boxShadow.green.contrast};
  width: ${(props) => props.percentage}%;
  min-width: ${(borderRadius - borderWidth) * 1.5}px;
  height: 100%;
`;


