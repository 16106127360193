import * as Types from "./types";
import update from 'immutability-helper';

const initialState = {
    loaded:false,
    loading:false,
    id:undefined,
    key:undefined,
    name:undefined,
    pathName:undefined,
    status:undefined,
    changingExperimentMachine:false,
    experimentMachineChanged:false,
    changingExperimentInitialWeight:false,
    experimentInitialWeightChanged:false,
    removingExperimentInitialWeight:false,
    experimentInitialWeightRemoved:false,
    algorithm:{},
    dataset:{},
    loadingExperiment:false,
    experimentLoaded:false,
    selectedExperimentId:undefined,
    experimentSetup:[],
    experimentHyperparameters:[],
    experiment:{},
    experiments:[],
    selectedMetricPathName:'',
    subfield:'',
    error:''
}

function TrainingSessionReducer(state=initialState, action){
    switch(action.type){
        case Types.TRAINING_SESSION_INIT:
            return initialState;

        case Types.TRAINING_SESSION_REQUEST:
            return {
                ...initialState,
                loaded:false,
                loading:true,
                error:''
            }
        
        case Types.TRAINING_SESSION_REQUEST_SUCCESS:
            return {
                ...state,
                loaded:true,
                loading:false,
                id:action.payload.id,
                key:action.payload.key,
                name:action.payload.name,
                pathName:action.payload.pathName,
                status:action.payload.status,
                algorithm:action.payload.algorithm,
                dataset:action.payload.dataset,
                experimentSetup:[],
                experimentHyperparameters:[],
                experiment:action.payload.experiment,
                experiments:action.payload.experiments,
                subfield:action.payload.subfield,
                error:''
            }

        case Types.TRAINING_SESSION_REQUEST_FAILURE:
            return {
                ...state,
                loaded:true,
                loading:false,
                error:action.payload.error
            }

        case Types.TRAINING_SESSION_SELECTED_EXPERIMENT_SET:
            return {
                ...state,
                selectedExperimentId:action.payload.experimentId
            }

        case Types.TRAINING_SESSION_SELECTED_EXPERIMENT_METRIC_SET:
            return {
                ...state,
                selectedMetricPathName:action.payload.metricPathName
            }

        case Types.TRAINING_SESSION_EXPERIMENT_ADD:
            return update(state, {
                experiment:{$set: action.payload.experiment},
                experiments:{$push: [action.payload.experiment]}
            })

        case Types.TRAINING_SESSION_EXPERIMENT_LOAD:
            return {
                ...state,
                loadingExperiment:true,
                experimentLoaded:false,
            }

        case Types.TRAINING_SESSION_EXPERIMENT_LOAD_SUCCESS:
            return {
                ...state,
                loadingExperiment:false,
                experimentLoaded:true,
                experiment:action.payload.experiment,
                selectedExperimentId:action.payload.experiment.id
            }

        case Types.TRAINING_SESSION_EXPERIMENT_LOAD_FAILURE:
            return {
                ...state,
                loadingExperiment:false,
                experimentLoaded:true,
                error:action.payload.error
            }

        case Types.TRAINING_SESSION_EXPERIMENT_SETUP_SET:
            return update(state, {
                experiment:{
                    setup:{$set: action.payload.setup}
                },
                experimentSetup:{$set: action.payload.setup}
            })
        
        case Types.TRAINING_SESSION_EXPERIMENT_HYPERPARAMETERS_SET:
            return {
                ...state,
                experimentHyperparameters:action.payload.hyperparameters
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_INIT:
            return {
                ...state,
                changingExperimentMachine:false,
                experimentMachineChanged:false
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE:
            return {
                ...state,
                changingExperimentMachine:true,
                experimentMachineChanged:false
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_SUCCESS:
            return update(state, {
                changingExperimentMachine:{$set:false},
                experimentMachineChanged:{$set:true},
                experiment:{
                   machine:{$set: action.payload.machine}
                }
            })
        
        case Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_FAILURE:
            return {
                ...state,
                changingExperimentMachine:false,
                experimentMachineChanged:false,
                error:action.payload.error
            }

        case Types.TRAINING_SESSION_EXPERIMENT_MACHINE_STATUS_SET:
            return update(state, {
                experiment:{
                   machine:{
                       connected:{$set: action.payload.connected}
                   }
                }
            })
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_INIT:
            return {
                ...state,
                changingExperimentInitialWeight:false,
                experimentInitialWeightChanged:false
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE:
            return {
                ...state,
                changingExperimentInitialWeight:true,
                experimentInitialWeightChanged:false
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_SUCCESS:
            return update(state, {
                changingExperimentInitialWeight:{$set:false},
                experimentInitialWeightChanged:{$set:true},
                experiment:{
                    initial_weight:{$set: action.payload.initial_weight}
                }
            })
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_FAILURE:
            return {
                ...state,
                changingExperimentInitialWeight:false,
                experimentInitialWeightChanged:false,
                error:action.payload.error
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_INIT:
            return {
                ...state,
                removingExperimentInitialWeight:false,
                experimentInitialWeightRemoved:false
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE:
            return {
                ...state,
                removingExperimentInitialWeight:true,
                experimentInitialWeightRemoved:false,
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_SUCCESS:
            return update(state, {
                removingExperimentInitialWeight:{$set:false},
                experimentInitialWeightRemoved:{$set:true},
                experiment:{
                    initial_weight:{$set: undefined}
                }
            })
        
        case Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_FAILURE:
            return {
                ...state,
                removingExperimentInitialWeight:false,
                experimentInitialWeightRemoved:false,
                error:action.payload.error
            }

        case Types.TRAINING_SESSION_EXPERIMENT_STATUS_SET:
            return update(state, {
                experiment:{
                   status:{$set: action.payload.status},
                   status_message:{$set: action.payload.status_message}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_START_REQUEST:
            return update(state, {
                experiment:{
                    status:{$set: 'STARTING'},
                    status_message:{$set: ''}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_START_SUCCESS:
            return update(state, {
                experiment:{
                    status:{$set: 'STARTED'},
                    status_message:{$set: ''}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_START_FAILURE:
            return update(state, {
                experiment:{
                    status:{$set: 'ERROR'},
                    status_message:{$set: action.payload.error}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_STOP_REQUEST:
            return update(state, {
                experiment:{
                    status:{$set: 'STOPPING'},
                    status_message:{$set: ''}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_STOP_SUCCESS:
            return update(state, {
                experiment:{
                    status:{$set: 'STOPPED'},
                    status_message:{$set: ''}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_STOP_FAILURE:
            return update(state, {
                experiment:{
                    status:{$set: 'ERROR'},
                    status_message:{$set: action.payload.error}
                }
            })

        case Types.TRAINING_SESSION_EXPERIMENT_ERROR_SET:
            return update(state, {
                experiment:{
                    status:{$set: 'ERROR'},
                    status_message:{$set: action.payload.error}
                }
            })

        default: return state
    }
}

export default TrainingSessionReducer;