import React, { useEffect, useState } from "react";

import { Divider, Checkbox, Text } from "components/UI";

//import {amex} from "assets/svgs/complex/credit-cards"
//import amex from "assets/svgs/complex/credit-cards/amex.svg"
import cards from "./cards.json";
import * as c from "assets/svgs/complex/credit-cards";
import {detectCardBrand} from "utils"

//STYLEDCOMPONENTS
import * as S from "./style";

const cardsIcons = {
  amex: c.amex,
  diners: c.diners,
  discover: c.diners,
  elo: c.elo,
  jcb: c.jcb,
  mastercard: c.mastercard,
  unionpay: c.unionpay,
  visa: c.visa,
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function getOrdinalSuffix(i) {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return "st";
  }
  if (j == 2 && k != 12) {
      return "nd";
  }
  if (j == 3 && k != 13) {
      return "rd";
  }
  return "th";
}

function getDateFromString(dateString){
  const dateSplit = dateString.split('-');
  return new Date(
    dateSplit[0],
    dateSplit[1] - 1,
    dateSplit[2]
  );
}

function Review(props) {
  const { plan, period, currentPlan, onClickOutside, setPrimaryButtonDisabled, ...others } = props;
  const [cardIdSelected, setCardIdSelected] = useState(0);
  const [termsChecked, setTermsChecked] = useState(true);
  let price = period === 'monthly' ? plan.price.month : plan.price.year;
  let interval = period === 'monthly' ? 'month' : 'year';
  const today = (currentPlan && currentPlan.path_name !== 'free' && currentPlan.period.toLowerCase() === period)? new Date(Date.parse(currentPlan.start_date)):new Date();
  console.log('TODAY', today);
  const day = today.getDate();
  let daySuffix = getOrdinalSuffix(day);
  let renewDate = period === 'monthly'? `${day}${daySuffix}`:`${day}${daySuffix} of ${monthNames[today.getMonth()]}`;
  let cardSelectItems = [];
  
  useEffect(() => {
    if(currentPlan){
      currentPlan.payment_methods.forEach(card => {
        if(card.default){
          setCardIdSelected(card.id);
        }
      });
    }
  }, [currentPlan]);

  const handleSelectCardChange = (event) => {
    setCardIdSelected(event.target.value);
  };

  const handleCheckbox = (event) => {
    // setTermsChecked(event.target.checked);
    // setPrimaryButtonDisabled(!event.target.checked);
  };

  if(currentPlan){
    cardSelectItems = currentPlan.payment_methods.map((card, index) => (
      <S.SelectItem key={index} value={card.id}>
        <div className="option-wrapper">
          <div className="payment-source-option">
          <img
              className="icon"
              src={cardsIcons[card.brand]}
            />
            <div className="label">
              Card ending in {card.last4}
            </div>
          </div>
        </div>
      </S.SelectItem>
    ));
  }

  return (
    <S.Container>
      <S.Section>
        <S.Invoice>
          <S.Title className="header">Theos {plan.name} subscription</S.Title>
          <Divider />
          <div className="total-row">
            <S.Title className="total-label">
              Total (USD)
            </S.Title>
            <S.Title className="total-amount">${price} / {interval}</S.Title>
          </div>
          <div className="fine-print">
            Your subscription will renew for <strong>${price}</strong>{" "}
            the&nbsp;
            <strong>{renewDate}</strong> {period === 'monthly'? 'day of every month.':'of every year.'}
          </div>
        </S.Invoice>
      </S.Section>
      {cardSelectItems.length > 0 && <S.Section>
        <S.Subtitle>Payment method</S.Subtitle>
        <S.Select
          onChange={handleSelectCardChange}
          defaultValue={"abud"}
          value={cardIdSelected}
        >
         {cardSelectItems}
        </S.Select>
      </S.Section>}
      <S.Section>
        <S.Subtitle>Legal</S.Subtitle>
        <S.CheckboxWrapper>
          <Checkbox onChange={handleCheckbox}  checked={termsChecked}/>
          <Text className="label">
              I agree to{" "}
              <S.Anchor
                class="anchor"
                href="https://theos.ai/site/terms"
                rel="noreferrer noopener"
                target="_blank"
              >
                Theos Terms of Service
              </S.Anchor>{" "}
              and{" "}
              <S.Anchor
                class="anchor"
                href="https://theos.ai/site/terms"
                rel="noreferrer noopener"
                target="_blank"
              >
                Paid Services Terms
              </S.Anchor>
   
          </Text>
        </S.CheckboxWrapper>
      </S.Section>
    </S.Container>
  );
}

export default Review;
