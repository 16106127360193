import styled from "styled-components";
import Icon from "icons";

export const StyledButton = styled.button.attrs((props) => ({
    disableRipple: true
}))`
background-color: rgba(0,0,0,0);
color: ${props=>(props.disabled ? "#D6D5D6" : props.color ? props.color =="secondary" ? props.theme.colors.text.medium : props.color : props.theme.colors.colorful.primary)};
height: 40px;
width: 40px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50px;
transition: background-color 300ms;
${props=>(props.disabled && "pointer-events: none")};

:hover {
    background-color: ${props=>(!props.disabled && "rgba(0,0,0,0.05)")}; 
}
`;
export const StyledIcon = styled(Icon).attrs((props) => ({
    size: props.size ? props.size : "50%",
    color: props.disabled ? "rgba(255,255,255,1)" : undefined
}))`


`;

