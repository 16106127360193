import React from "react";
import Lottie from "react-lottie";
import RingData from "assets/lotties/ring.json";

//STYLEDCOMPONENTS
import * as S from "./style";


function Ring(props) {
  const { ...others } = props;

  const ringOptions = {
    loop: true,
    autoplay: true,
    animationData: RingData,
    rendererSettings: {
      preserveAspectRatio: "xMinYMid slice",
    },
  };

  return (
    <S.Container {...others}>
      <S.LottieWrapper >
        <Lottie
          //style={{transform: "scale(50%,-50%) translate(0, -100%)"}}
         
          isClickToPauseDisabled={true}
          options={ringOptions}
          height={"100%"}
          speed={0.75}
          width={"auto"}
        />
      </S.LottieWrapper>
    </S.Container>

  );
}

export default Ring;
