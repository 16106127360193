import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from "styled-components";



export const StyledTab = styled(Tab)`
      height: 35px;
      min-width: 100px;
      margin-right:5px;
      padding: 8px 16px;
      border-radius: 10px 10px 0px 0px;   
      display: flex;
      color: ${props=>(props.selected ? props.theme.colors.colorful.secondary : props.theme.colors.text.light)};
      font-weight: ${(props) => props.theme.fonts.weight.medium};
      font-size: ${(props) => props.theme.fonts.body.m.size};
      cursor: pointer;
      justify-content: center;
      align-items: center;
      border-bottom: ${props=>(props.selected ? "1px solid"+props.theme.colors.colorful.secondary : props.theme.colors.divider)};
      box-shadow: ${props=>(props.selected && "6px 6px 10px rgba(0,0,0,0.06), inset -2px -2px 3px rgba(0,0,0,0.03), -6px -6px 10px rgba(255,255,255,0.6), inset 2px 2px 3px rgba(255,255,255,0.6), inset 0px -10px 15px -15px"+props.theme.colors.colorful.secondary)};
      transition: box-shadow 600ms ease-in, border-bottom 600ms ease-in;

  /* .react-tabs__tab--disabled {
    background-color: blue; 
    color: red;  
  } */

  &:before {
    transition: border-bottom 600ms ease-in;
  }
  &:after {
    transition: border-bottom 600ms ease-in;
  }

  &:hover {
    box-shadow: ${props=>(!props.selected && "3px 3px 5px rgba(0,0,0,0.05),  -3px -3px 5px rgba(255,255,255,0.6)")};
    transition:   box-shadow 200ms ease-in;  
  }

`;

export const StyledTabs = styled(Tabs).attrs((props) => ({
  selectedTabPanelClassName:"selected--panel"
  //loading: "lazy",
}))`
  .react-tabs__tab--disabled {
    cursor: not-allowed;
    box-shadow: none;

    &:hover {
    box-shadow: none;
  }
  }
`;




export const StyledTabList = styled(TabList)`
  && {    
    display: flex;
    
    border-bottom: solid 1px ${props=>(props.theme.colors.divider)};
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  && {    

    &.selected--panel {
      opacity: 1;
      /* filter: blur(0px); */
      animation: fade-and-slide-in 500ms ease-out;
    }
  

    @keyframes fade-and-slide-in {
    from {
  
    /* filter: blur(16px); */
    /* transform: translateX(-10%); */
    }
    to {

    /* filter: blur(0px); */
    /* transform: translateX(0%); */
    }

  }
`;
