import styled from "styled-components";


export const StyledContainer = styled.div`
   height: ${props => props.height}px;
   width:100%;
   display: flex;
   align-items: center;
`;

export const StyledBorder = styled.hr`
    /* border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: ${props=>(props.theme.colors.border.default)}; */
    width:100%;
    color: ${props=>(props.theme.colors.border.default)};
 
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color:  ${props=>(props.theme.colors.border.default)};
`;
