//LIBRARIES
import React from "react";
import Button from "./";
import {useKeyPressed} from "utils"

function PrimaryActionButton(props) {
  const { onClick, children, ...others } = props;

  useKeyPressed("Enter", () => {
    onClick();
  });

  return <Button onClick={onClick} color="primary" variant="contained" {...others}>{children}</Button>;
}

export default PrimaryActionButton;
