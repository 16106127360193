import {
    SUBFIELD_LEADERBOARD_REQUEST,
    SUBFIELD_LEADERBOARD_SUCCESS,
    SUBFIELD_LEADERBOARD_FAILURE,
} from './types'



const initialState = {
    loaded:false,
    payload:{},
    error:''
}


function SubfieldLeaderboardReducer(state=initialState, action){
    switch(action.type){
        case SUBFIELD_LEADERBOARD_REQUEST:
            return {
                ...state,
                loaded:false
            }
        case SUBFIELD_LEADERBOARD_SUCCESS:
            return {
                loaded:true,
                payload:action.payload.leaderboard,
                error:''
            }
        case SUBFIELD_LEADERBOARD_FAILURE:
            return {
                loaded:false,
                payload:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default SubfieldLeaderboardReducer