//LIBRARIES
import React, {useCallback, useState} from 'react';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledContainerBorder as ContainerBorder,
} from "./style";

  
function BorderedDiv(props) {
   const { children, borderColor, borderRadius, position, width, height, margin, ...other } = props;
   const [dimensions, setDimensions] = useState(null);

   const div = useCallback(node => {
     if (node !== null) {
      setDimensions({h:node.getBoundingClientRect().height,w:node.getBoundingClientRect().width});
     }
   }, []);

  return (
    <ContainerBorder ref={div} borderRadius={borderRadius} position={position} margin={margin} width={width} height={height} angle={dimensions && Math.atan(dimensions.h/dimensions.w)/Math.PI*180+90} borderColor={borderColor}>
      <Container borderRadius={borderRadius} {...other} >{children}</Container>
    </ContainerBorder>
  );
}
 
export default BorderedDiv;