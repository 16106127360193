import styled from "styled-components";

const spread = 9;
const blurLength = 15;
const borderWidth = 1;
const borderRadius = 13;

export const StyledContainerBorder = styled.div`
background-color: ${(props) =>
    props.borderColor === undefined ? props.theme.colors.bg.default : props.borderColor};
  border-radius: ${(props) =>
    props.borderRadius === undefined
      ? borderRadius + "px"
      : props.borderRadius + "px"};

  /* background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(200, 200, 200, 0.4) 100%
  ); */

  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: ${(props) => props.position};
  margin: ${(props) => props.margin};

  // background: ${(props) => props.angle ? `linear-gradient(${props.angle}deg, ${props.theme.colors.border.left} 20%, ${props.theme.colors.border.right} 100%)` : `linear-gradient(135deg, ${props.theme.colors.border.left} 50%, ${props.theme.colors.border.right} 100%)`};

  box-shadow: ${spread}px ${spread}px ${blurLength}px rgba(0, 0, 0, 0.1),
    -${spread}px -${spread}px ${blurLength}px rgba(255, 255, 255, 0.7);
    
  padding: ${borderWidth}px !important;


`;

export const StyledContainer = styled.div`
  height:100%;
  width:100%;
  background-color: ${(props) => props.theme.colors.bg.default};
  border-radius: ${(props) =>
    props.borderRadius === undefined
      ? borderRadius - borderWidth + "px"
      : props.borderRadius - borderWidth + "px"};


`;
