export const ACCEPT_AUTOLABELER_LABELS = 'ACCEPT_AUTOLABELER_LABELS';
export const ADD_LABEL = 'ADD_LABEL';
export const ADD_UNDO = 'ADD_UNDO';
export const BRIGHTNESS_SET = 'BRIGHTNESS_SET';
export const BRING_TO_FRONT_LABEL = 'BRING_TO_FRONT_LABEL';
export const CHANGE_LABEL_CLASSNAME = 'CHANGE_LABEL_CLASSNAME';
export const COPY_LABEL = 'COPY_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const EXAMPLE_DELETE = 'EXAMPLE_DELETE';
export const EXAMPLE_DELETE_FAILURE = 'EXAMPLE_DELETE_FAILURE';
export const EXAMPLE_DELETE_SUCCESS = 'EXAMPLE_DELETE_SUCCESS';
export const EXAMPLE_FETCH_END = 'EXAMPLE_FETCH_END';
export const EXAMPLE_FETCH_START = 'EXAMPLE_FETCH_START';
export const EXAMPLE_INIT = 'EXAMPLE_INIT';
export const EXAMPLE_LOAD = 'EXAMPLE_LOAD';
export const EXAMPLE_LOAD_END = 'EXAMPLE_LOAD_END';
export const EXAMPLE_REQUEST = 'EXAMPLE_REQUEST';
export const EXAMPLE_REQUEST_FAILURE= 'EXAMPLE_REQUEST_FAILURE';
export const EXAMPLE_REQUEST_SUCCESS = 'EXAMPLE_REQUEST_SUCCESS';
export const EXAMPLE_SKIP = 'EXAMPLE_SKIP';
export const EXAMPLE_SKIP_FAILURE = 'EXAMPLE_SKIP_FAILURE';
export const EXAMPLE_SKIP_SUCCESS = 'EXAMPLE_SKIP_SUCCESS';
export const EXAMPLE_SUBMIT = 'EXAMPLE_SUBMIT';
export const EXAMPLE_SUBMIT_FAILURE = 'EXAMPLE_SUBMIT_FAILURE';
export const EXAMPLE_SUBMIT_SUCCESS = 'EXAMPLE_SUBMIT_SUCCESS';
export const EXAMPLE_UNSAVED_CHANGES_SET = 'EXAMPLE_UNSAVED_CHANGES_SET';
export const LABEL_CLASSNAME_CHANGE_END = 'LABEL_CLASSNAME_CHANGE_END';
export const LABEL_CLASSNAME_CHANGE_START = 'LABEL_CLASSNAME_CHANGE_START';
export const NEXT_EXAMPLE_REQUEST = 'NEXT_EXAMPLE_REQUEST';
export const NEXT_EXAMPLE_REQUEST_SUCCESS = 'NEXT_EXAMPLE_REQUEST_SUCCESS';
export const PASTE_LABEL = 'PASTE_LABEL';
export const PASTE_PREVIOUS_LABELS = 'PASTE_PREVIOUS_LABELS';
export const PREVIOUS_EXAMPLE_REQUEST = 'PREVIOUS_EXAMPLE_REQUEST';
export const PREVIOUS_EXAMPLE_REQUEST_SUCCESS = 'PREVIOUS_EXAMPLE_REQUEST_SUCCESS';
export const REDO = 'REDO';
export const REJECT_AUTOLABELER_LABELS = 'REJECT_AUTOLABELER_LABELS';
export const REPLACE_LABEL_BY_ID = 'REPLACE_LABEL_BY_ID';
export const REPLACE_LAST_LABEL = 'REPLACE_LAST_LABEL';
export const SEND_TO_BACK_LABEL = 'SEND_TO_BACK_LABEL';
export const SET_CLASSNAMES = 'SET_CLASSNAMES';
export const SET_IMAGE_SIZE = 'SET_IMAGE_SIZE';
export const SET_LABELS = 'SET_LABELS';
export const SET_VISION_LLM_LABEL = 'SET_VISION_LLM_LABEL';
export const SET_VISION_LLM_INSTRUCTIONS = 'SET_VISION_LLM_INSTRUCTIONS';
export const SET_LABELS_IMAGE = 'SET_LABELS_IMAGE';
export const SET_LABELS_VISIBLE = 'SET_LABELS_VISIBLE';
export const SET_LABEL_TEXT = 'SET_LABEL_TEXT';
export const SET_LABEL_VISIBLE = 'SET_LABEL_VISIBLE';
export const SET_SCALE = 'SET_SCALE';
export const SET_SELECTED_ID = 'SET_SELECTED_ID';

export const SET_NEW_LABEL = 'SET_NEW_LABEL';

export const SET_KEYPOINT_VISIBLE = 'SET_KEYPOINT_VISIBLE';
export const RESTORE_KEYPOINTS_VISIBLE = 'RESTORE_KEYPOINTS_VISIBLE';


export const UNDO = 'UNDO';