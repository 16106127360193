import styled from "styled-components";
import { Div, Thumbnail, ScrollableDiv } from "components/UI";

const borderRadius = 10;
const thumbnailHeight = 9;
const ttime = 200;
const tfunction = "ease-in";

export const StyledContainer = styled.div`
display: flex;
flex-direction: column;
`;

export const StyledFlexboxVertical = styled.div`
display: flex;
flex-direction: column;
`;

export const StyledFlexboxHorizontal = styled.div`
display: flex;
padding-top: 10px;
height: ${thumbnailHeight*4}em;
`;

export const StyledCategoryContainer = styled(Div).attrs((props) => ({
  variant: "sunk",
}))`


    border: solid 1px ${props=>(props.theme.colors.border.default)};
    border-radius: ${borderRadius}px;
    color: ${(props) => props.theme.colors.text.default};

    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;

    max-width: ${thumbnailHeight*2*16/9+0.5}em;
    min-width: ${thumbnailHeight*2*16/9+0.5}em;
    justify-content: left;
    align-items: center;

    scrollbar-width: thin !important;
    margin-left: 10px;
    
    &::-webkit-scrollbar {
      width: 20px; 
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      border: 8px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 20px;
      background-color: lightgrey;
      -webkit-box-shadow: inset 0 0 0 1px lightgrey;
    }

    @media (max-width: ${props => (props.theme.breakpoints.desktop.min)}px) {
      display: none;
    }
`;

export const StyledCategoryTitle = styled.div`
    display:flex;
    width:100%;
    padding-left: 5px;
    text-align: left;
    color: ${(props) => props.theme.colors.text.light};
    font-weight: ${(props) => props.theme.fonts.weight.medium};
    font-size: ${(props) => props.theme.fonts.title.m.size};
`;



export const StyledCategorysList = styled(ScrollableDiv)`
    border: solid 1px ${props=>(props.theme.colors.border.default)};
    overflow-y: auto;
    flex-grow:1;
    width:150px;
    padding-top:5px;
    border-radius: ${borderRadius}px;
    display: flex;
    flex-direction: column;
`;




export const StyledCategoryButton = styled.button.attrs((props) => ({
}))`
  margin: 5px 10px;
  color: ${(props) => props.theme.colors.text.classes};
  border-radius: 5px;
  padding:5px 5px;
  min-width: 80px;
  font-size:13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  min-height: 30px;
  background-color: ${props => (props.theme.colors.button.default)};
  transition: ${ttime}ms ${tfunction};

  @media (min-width: ${props => (props.theme.breakpoints.desktop.min)}px) {
    &:hover {
      box-shadow: -${props => (props.theme.effects.neumo.spread)/2}px -${props => (props.theme.effects.neumo.spread)/2}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                  ${props => (props.theme.effects.neumo.spread)/2}px ${props => (props.theme.effects.neumo.spread)/2}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
                  animation: 2000ms ease-in;

    }

    &:active {
        box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                    ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                    inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                    inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
        > * {
            transform:scale(0.95);
        }
    }

    &:focus {
        box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                    ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                    inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                    inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
        > * {
            transform:scale(0.95);
        }
    }
  }
`;


export const StyledSkeletonWrapper = styled.div`
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    width: 50%;
    height: 100%;

`;

export const StyledThumbnail = styled(Thumbnail)`
  &:hover {
    border: 2px solid  ${(props) => props.theme.colors.colorful.primary};
    box-shadow: 0 0 5px 0px  ${(props) => props.theme.colors.colorful.primary};
    transition: box-shadow ${ttime}ms ${tfunction};
  }
`;

