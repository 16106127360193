//LIBRARIES
import React, { useState } from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

import { Typography } from "components/UI";

function Success(props) { 
   const {title, children, type } = props;
  const [fullscreen, setFullscreen] = useState(true);

  function toggleFullscreen() {
    setFullscreen(!fullscreen);
  }

  return (
    fullscreen && (
      <S.Modal onClickOutside={toggleFullscreen}>
        <S.Bubble type={type} size="l">
          <S.Icon type={type} />
        </S.Bubble>
        <Typography size="l">{title}</Typography>
        <S.Message>{children}</S.Message>
        {/* <Button onClick={toggleFullscreen}>Try Again</Button> */}
        <S.LinkButton type={type} onClick={toggleFullscreen}>Done</S.LinkButton>
        <S.BlurriedBubble type={type} />
      </S.Modal>
    )
  );
}

export default Success;
