import styled from "styled-components";
import {
  Fullscreen,
  Typography,
  Text,
  Button as BaseButton,
  Stepper as BaseStepper,
  Radio as BaseRadio,
} from "components/UI";
import { FormControlLabel as BaseFormControlLabel } from "@mui/material";
// import {Radio as BaseRadio} from '@mui/material';
import BaseIcon from "icons";

//rgb(47,131,189)

export const Container = styled.div`
  padding: 16px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  color: ${(props) => !props.selected && props.theme.colors.text.medium};
  transition: color 300ms;
  .plan_option {
    display: flex;
    .discount {
      background-color: blue;
      border-radius: 16px;
      font-size: 12px;
      height: min-content;
      /* font-weight:  ${(props) => props.theme.fonts.weight.bold}; */
      padding: 2px 8px;
      text-transform: uppercase;
      color: #fff;
      margin-left: 8px;
      vertical-align: middle;
      background-color: ${(props) => props.theme.colors.button.success.left};
      box-shadow: 0px 0px 10px -2px ${(props) => props.theme.colors.button.success.left};
    }
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  font-size: ${(props) => props.theme.fonts.title.s.size};
  color: ${(props) => props.theme.colors.text.medium};
  margin-top:15px;

  .amount {
    
    display: flex;
    align-items: flex-end;

    .price {
      font-size: 24px;
      color: ${(props) => props.theme.colors.text.default};
      font-weight: ${(props) => props.theme.fonts.weight.bold};
      margin-bottom: -3px;
    }
  }
`;

export const WarnContainer = styled.div`
display: flex;
margin-top: 16px;
.message {
  margin-left: 16px;
  font-size: ${(props) => props.theme.fonts.body.s.size};
}
`;


export const Radio = styled(BaseRadio).attrs((props) => ({}))``;

export const Icon = styled(BaseIcon).attrs((props) => ({
  size: "32px"
}))`

color: ${(props) => props.theme.colors.text.alert};
`;

export const FormControlLabel = styled(BaseFormControlLabel).attrs(
  (props) => ({})
)`
  &.MuiFormControlLabel-root {
    margin-right: 0px !important;
  }

  & .MuiFormControlLabel-label {
    width: 100%;

    /* background-color: blue; */
    font-size: ${(props) => props.theme.fonts.body.m.size};
  }
`;

export const Title = styled(Typography).attrs((props) => ({
  size: "s",
}))``;

export const Description = styled(Text).attrs((props) => ({}))`
  margin-top: 16px;

  .link {
    color: ${(props) => props.theme.colors.text.purple};
  }
`;
