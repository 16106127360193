//LIBRARIES
import React, { useEffect } from "react";
import * as S from "./style"
import { PrincipalMedia } from "components/UI";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Image from "./Image";


function FullscreenMedia(props) {
  const {
    media,
    onClickOutside,
    onClickNext,
    onClickPrevious,
    index,
    mediasQuantity,

  } = props;

  function handleKeypress(e) {
    var code = e.which || e.keyCode;
    if (code + "" === "37") {
      onClickPrevious();
    } else if (code + "" === "39") {
      onClickNext();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.removeEventListener("keydown", handleKeypress);
    };
  });


  return (
    <S.FullscreenContainer
      onKeyDown={handleKeypress}
      onClickOutside={onClickOutside}
    >
      {mediasQuantity !== undefined && (
        <S.Quantity>{index + " / " + mediasQuantity}</S.Quantity>
      )}
      {onClickPrevious !== undefined && (
        <S.PreviousButton onClick={onClickPrevious}>
          <ChevronLeftIcon />
        </S.PreviousButton>
      )}
      {onClickNext !== undefined && (
        <S.NextButton onClick={onClickNext}>
          <ChevronRightIcon />
        </S.NextButton>
      )}

      {!media.video ? (
        <Image media={media} onClickOutside={onClickOutside} />
      ) : (
        <S.VideoContainer>
          <S.Container>
            <PrincipalMedia selectedMedia={media} />
          </S.Container>
        </S.VideoContainer>
      )}
    </S.FullscreenContainer>
  );
}

export default FullscreenMedia;
