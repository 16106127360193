import styled from "styled-components";
import { IconButton } from "components/UI";
import Icon from "icons";

export const StyledIconButton = styled(IconButton)`
  
height: 100%;
width: 40px;
color: ${(props) => props.theme.colors.text.light};
border-radius: 0px 4px 4px 0px;
background-color:  ${(props) => !props.focused ?  `rgba(0, 0, 0, 0.025)`:`rgba(255, 255, 255, 0.5)` };
border-bottom:  ${(props) => !props.focused ?  `2px solid white` : `2px solid transparent`};
transition: background-color 0.3s ease-in-out;

:hover {
  background-color: ${props=>(!props.disabled && !props.focused ? "rgba(0,0,0,0.05)": "rgba(0,0,0,0.01)")}; 
}


`;

export const StyledIcon = styled(Icon).attrs((props) => ({
  size: '100%'
  }))`


color: ${(props) => props.valid ? props.theme.colors.text.success : props.theme.colors.text.error};
   ${(props) => {
    switch (props.name) {
      case "solid circle-minus":
        return `color: ${props.theme.colors.text.error};`;
      case "solid circle-check":
        return `color: ${props.theme.colors.text.success};`;
      case "solid alert":
        return `color: ${props.theme.colors.text.alert};`;
      default:
        return `color: ${props.theme.colors.text.error};`;
    }
  }}

  /* filter: ${(props) => props.valid ? `drop-shadow(0px 0px 3px ${props.theme.colors.text.success})` : `drop-shadow(0px 0px 3px ${props.theme.colors.text.error})`}; */

  filter: drop-shadow(-2px -2px 1px white) drop-shadow(2px 2px 1px rgba(0,0,0,0.1));
`;
  

export const StyledIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: -3px -3px 4px 0px white, 2px 2px 2px 0px rgba(0,0,0,0.05); */
  border-radius: 20px;
  height: 18px;
  width: 18px;
`;

export const StyledStrengthLabel = styled.label`
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;

export const StyledProgressBarContainer = styled.div`
  border-radius: 20px;
  overflow:hidden;
  width: 60px;
  height: 10px;
  background-color: ${(props) => props.theme.colors.text.light};
`;

export const StyledProgress = styled.div`
  width: ${(props) => props.percentage}%;
  height: 100%;
  background-color: red;

  ${(props) => {
    switch (true) {
      case props.percentage <= 30:
        return `background-color: ${props.theme.colors.text.error};`;
      case props.percentage <= 75:
        return `background-color: ${props.theme.colors.text.alert};`;
      case props.percentage > 75:
        return `background-color: ${props.theme.colors.text.success};`;
      default:
        return `background-color: ${props.theme.colors.text.error};`;
    }
  }}
`;

export const StyledStrengthContainer = styled.div`

${(props) => {
  if (!props.valid) {
    return `color: ${props.theme.colors.text.error};`;
  } else {
    if (props.strength.progressBar > 75)
      return `color: ${props.theme.colors.text.success};`;
    else return `color: ${props.theme.colors.text.alert};`;
  }
}}

> * {
  margin: 0px 3px;
}

flex-grow: 1;
display: flex;
justify-content: flex-end;
align-items: center;
height: 100%;

`;
