export const TRAINING_SESSION_INIT = 'TRAINING_SESSION_INIT';
export const TRAINING_SESSION_REQUEST = 'TRAINING_SESSION_REQUEST';
export const TRAINING_SESSION_REQUEST_SUCCESS = 'TRAINING_SESSION_REQUEST_SUCCESS';
export const TRAINING_SESSION_REQUEST_FAILURE = 'TRAINING_SESSION_REQUEST_FAILURE';
export const TRAINING_SESSION_SELECTED_EXPERIMENT_SET = 'TRAINING_SESSION_SELECTED_EXPERIMENT_SET';
export const TRAINING_SESSION_SELECTED_EXPERIMENT_METRIC_SET = 'TRAINING_SESSION_SELECTED_EXPERIMENT_METRIC_SET';
export const TRAINING_SESSION_EXPERIMENT_ADD = 'TRAINING_SESSION_EXPERIMENT_ADD';
export const TRAINING_SESSION_EXPERIMENT_LOAD = 'TRAINING_SESSION_EXPERIMENT_LOAD';
export const TRAINING_SESSION_EXPERIMENT_LOAD_SUCCESS = 'TRAINING_SESSION_EXPERIMENT_LOAD_SUCCESS';
export const TRAINING_SESSION_EXPERIMENT_LOAD_FAILURE = 'TRAINING_SESSION_EXPERIMENT_LOAD_FAILURE';
export const TRAINING_SESSION_EXPERIMENT_SETUP_SET = 'TRAINING_SESSION_EXPERIMENT_SETUP_SET';
export const TRAINING_SESSION_EXPERIMENT_STATUS_SET = 'TRAINING_SESSION_EXPERIMENT_STATUS_SET';
export const TRAINING_SESSION_EXPERIMENT_HYPERPARAMETERS_SET = 'TRAINING_SESSION_EXPERIMENT_HYPERPARAMETERS_SET';
export const TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_INIT = 'TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_INIT';
export const TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE = 'TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE';
export const TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_SUCCESS = 'TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_SUCCESS';
export const TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_FAILURE = 'TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_FAILURE';
export const TRAINING_SESSION_EXPERIMENT_MACHINE_STATUS_SET = 'TRAINING_SESSION_EXPERIMENT_MACHINE_STATUS_SET';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_INIT = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_INIT';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_SUCCESS = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_SUCCESS';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_FAILURE = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_FAILURE';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_INIT = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_INIT';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_SUCCESS = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_SUCCESS';
export const TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_FAILURE = 'TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_FAILURE';
export const TRAINING_SESSION_EXPERIMENT_START_REQUEST = 'TRAINING_SESSION_EXPERIMENT_START_REQUEST';
export const TRAINING_SESSION_EXPERIMENT_START_SUCCESS = 'TRAINING_SESSION_EXPERIMENT_START_SUCCESS';
export const TRAINING_SESSION_EXPERIMENT_START_FAILURE = 'TRAINING_SESSION_EXPERIMENT_START_FAILURE';
export const TRAINING_SESSION_EXPERIMENT_STOP_REQUEST = 'TRAINING_SESSION_EXPERIMENT_STOP_REQUEST';
export const TRAINING_SESSION_EXPERIMENT_STOP_SUCCESS = 'TRAINING_SESSION_EXPERIMENT_STOP_SUCCESS';
export const TRAINING_SESSION_EXPERIMENT_STOP_FAILURE = 'TRAINING_SESSION_EXPERIMENT_STOP_FAILURE';
export const TRAINING_SESSION_EXPERIMENT_ERROR_SET = 'TRAINING_SESSION_EXPERIMENT_ERROR_SET';