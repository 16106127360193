import * as Style from "./types";


const initialState = {
    emailSent: false,
    loading: false,
    error:''
}


function PasswordResetReducer(state=initialState, action){
    switch(action.type){
        case Style.FORGOT_PASSWORD_INIT:
            return initialState
            
        case Style.FORGOT_PASSWORD_REQUEST:
            return {
                emailSent:false,
                loading:true,
                error:''
            }

        case Style.FORGOT_PASSWORD_SUCCESS:
            return {
                emailSent:true,
                loading:false,
                error:''
            }
        
        case Style.FORGOT_PASSWORD_FAILURE:
            return {
                emailSent:false,
                loading:false,
                error:action.payload.error
            }

        default: return state
    }
}

export default PasswordResetReducer;