import React from "react";
//STYLEDCOMPONENTS
import * as S from "./style";
import { Loader } from "components/UI";

function Loading(props) {
  return (
    <S.Container>
      <Loader />
    </S.Container>
  );
}

export default Loading;