//LIBRARIES
import React from "react";
import { useLocation } from "react-router-dom";
//STYLEDCOMPONENTS
import * as S from "./style" 
import { SideMenuRoutes as Routes } from "routes";
import MenuButton from "./MenuButton";
import Account from "./Account";
import Projects from "./Projects";
import { Link as BaseLink} from "react-router-dom";
import { useSelector } from "react-redux";


function SideMenu(props) {
  const { open } = props;
  const location = useLocation();
  const projectLoaded = useSelector((state) => state.project.main.loaded);
  const role = useSelector((state) => state.project.main.payload.role);
  const user = useSelector((store) => store.user);
  let selectedMenu = 0;

  Routes.forEach((route, index) => {
    if (route.path != "/") {
      if (location.pathname.includes(route.path)) {
        selectedMenu = index;
      }
    }
  });

  return (
    <>
      <S.Container open={open}>
        <Account />
        <Projects/>
        <S.ListContainer>
          {Routes.map((route, index) => {
            if (!route.projectRequired || (projectLoaded && ((route.adminRequired && user.profile.payload.is_admin) || ((!route.adminRequired && !route.checkPermission) || (role.permissions && role.permissions[route.actionName]))))) {
              return (
                <BaseLink
                  to={route.path}
                  style={{ textDecoration: "none" }}
                  id={index}
                  key={index}
                  onClick={(e) => {
                      if(location.pathname === route.path){
                        e.preventDefault();
                      }
                    }
                  }
                >
                  <MenuButton
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    open={true}
                    id={index}
                    design="secondary"
                    icon={route.icon}
                    selected={index === selectedMenu ? true : false}
                  >
                    {route.menuItemName}
                  </MenuButton>
                </BaseLink>
              );
            }
          })}
        </S.ListContainer>
      </S.Container>
    </>
  );
}

export default SideMenu;
