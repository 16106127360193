//LIBRARIES
import React, { useEffect, useRef } from 'react';

//STYLEDCOMPONENTS
import Password from './Password.js'
import Username from "./Username.js";
import Text from "./Text.js";
import Email from "./Email.js";


function Input(props) {
  const { children, name, type, placeholder, label, id, onChange, input, password, showValidIcon, focus, ...other } = props;
  const inputRef = useRef();
  
  console.log(input)
  
  useEffect(() => {
    if(inputRef.current !== undefined && focus){
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const setInput = input => {
    onChange(id, input);
  };


 
  switch (type) {
    case 'username':
      return <Username innerRef={inputRef} input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
    case 'email':
        return <Email innerRef={inputRef} input={input.value} setInput={setInput}  showValidIcon={showValidIcon} valid={input.valid} label={label} {...other}/>;
    case 'password':
        return <Password innerRef={inputRef} input={input.value} setInput={setInput}  valid={input.valid} label={label} focus={focus} {...other}/>;
    default:
      return <Text innerRef={inputRef} input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
  }
}

export default Input;