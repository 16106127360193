//LIBRARIES
import React from "react";
//STYLEDCOMPONENTS
import {
  StyledCodeBlock as CodeBlock,
  StyledContainer as Container,
} from "./style";

export default function Code(props) {
  const { code, language, showLineNumbers, ...others } = props;

  return (
    <Container {...others}>
      <CodeBlock
      showLineNumbers={showLineNumbers}
      lineNumberStyle  =  {{opacity: 0.4}}
      language={language}
      >
        {code}
      </CodeBlock>
    </Container>
  );
}
