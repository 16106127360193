import styled from "styled-components";
import {PrimaryActionButton, Loader, Text, InputSignUp} from "components/UI"
import Icon from 'icons';

export const StyledContainer = styled.div`
    display: flex;
    height: min-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding: 30px;

    align-items: flex-end;

    & >:not(:first-child):not(:last-child) {
        margin: 5px 0px;
    }
    & >:first-child {
        margin-bottom:10px;
    }
    & >:last-child {
        margin-top: 10px;
    }
`;

export const StyledIcon = styled(Icon).attrs((props) => ({
size:"20px",
}))`
`;

export const StyledText= styled(Text)`
   text-align: left;
`;

export const StyledSpan = styled.span`
   text-align: left;
   font-weight: ${props=>(props.theme.fonts.weight.bold)};
`;
     

export const StyledInput = styled(InputSignUp).attrs((props) => ({
}))`
    width: 100%;
`;

export const StyledLoader = styled(Loader).attrs((props) => ({
}))`
    align-self: center;
`;
export const StyledPrimaryButton = styled(PrimaryActionButton).attrs((props) => ({
        color:"primary",
        variant:"contained",
        //size:"small"
    }))`
`;