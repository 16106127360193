import styled , {keyframes} from "styled-components";

const logoSize = 200;

const bubble1Size = 80;
const bubble2Size = 60;
const bubble3Size = 30;
const bubble4Size = 60;

const purple = "#AB5AEA";
const lightBlue = "#90E0FF";
const yellow = "#FCC55C";
const pink = "#F20E79";
const red = "#FB3A46";


export const StyledContainer = styled.span`
display: flex;
position: relative;
justify-content: center;
align-items: center;
margin-left: 10px;
height: ${props=>(props.size ? props.size+"px" : logoSize+"px")};
width: ${props=>(props.size ? props.size+"px" : logoSize+"px")};
`;

export const StyledBlurContainer = styled.div`
position: absolute;
height: 100%;
width: 100%;
filter: blur(15px);
opacity: 0.8;
`;

const transform = keyframes`
    0%,100% { transform: translate(-30%, 0%); } 
    14% { transform: translate(-60%, 10%); } 
    28% { transform: translate(50%, 20%); } 
    42% { transform: translate(80%, 0%); } 
    56% { transform: translate(0%, -10%);} 
    70% { transform: translate(-90%, 10%); } 
    84% { transform: translate(-30%, 0%);} 
`;

const rotate = keyframes`
   from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const borderTransform = keyframes`
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
    14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
    28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
    42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
    56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
    70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
    84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
`;

// const blur = keyframes`
//     0%,100% { filter: blur(${0.08*blurRatio}px);} 
//     14% { filter: blur(${0.15*blurRatio}px);} 
//     28% { filter: blur(${0.05*blurRatio}px);} 
//     42% { filter: blur(${0.17*blurRatio}px);  } 
//     56% { filter: blur(${0.07*blurRatio}px);  } 
//     70% { filter: blur(${0.13*blurRatio}px);  } 
//     84% { filter: blur(${0.06*blurRatio}px);  } 
// `;

    /* background:
    linear-gradient(45deg, ${blue} 0%, ${purple} 100%),
    linear-gradient(135deg, ${lightBlue} 0%, ${yellow} 100%),
    linear-gradient(225deg, ${pink} 0%, ${red} 100%),
    linear-gradient(315deg, ${lightBlue} 0%, ${purple} 100%); */


export const StyledBubble1 = styled.div`
    position:absolute;
    filter: blur(7px);
    background:
    linear-gradient(315deg, ${lightBlue} 0%, ${purple} 100%);
    width: ${bubble1Size}%;
    height: ${bubble1Size}%;
    animation: ${borderTransform} 6s linear infinite, ${transform} 6s linear infinite;
`;

export const StyledBubble2 = styled.div`
    position:absolute;
    filter: blur(7px);
    bottom:-10%;
    background:
    linear-gradient(225deg, ${pink} 0%, ${red} 100%);
    width: ${bubble2Size}%;
    height: ${bubble2Size}%;
    animation: ${borderTransform} 5s linear infinite, ${transform} 6s linear infinite;
`;

export const StyledBubble3 = styled.div`
    position:absolute;
    filter: blur(7px);
    top:0;
    left:0;
    background:${lightBlue};
    width: ${bubble3Size}%;
    height: ${bubble3Size}%;
    animation: ${borderTransform} 10s linear infinite, ${transform} 6s linear infinite;
`;

export const StyledBubble4 = styled.div`
    position:absolute;
    filter: blur(7px);
    bottom:-40%;
    right:-30%;
    background:${lightBlue};
    width: ${bubble4Size}%;
    height: ${bubble4Size*1.5}%;
    animation: ${borderTransform} 3s linear infinite, ${transform} 6s linear infinite;
`;


export const StyledBase = styled.div`
overflow:hidden;
position:relative;
/* border: 2px solid white; */
background: linear-gradient(90deg, ${purple} 0%, ${yellow} 100%);
width: 80%;
height:80%;
border-radius:50%;
transform: translateZ(0);
/* animation: ${rotate} 12s linear infinite; */
/* animation: ${borderTransform} 6s linear infinite; */
`;

export const StyledShadow = styled.div`
position:absolute;
border-radius:50%;
/* box-shadow: inset 0px 0px 20px 15px white; */
box-shadow: inset 0px 0px 10px 5px white; 
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%) translateZ(0);
/* animation: ${borderTransform} 6s linear infinite; */
width: 105%;
height: 105%;
`;
