import styled from "styled-components";
import { Skeleton } from "components/UI";
import Icon from "icons";

const borderRadius = 10;
const aspectRatio = 16 / 9;
const thumbnailHeight = 8;

export const StyledThumbnailImage = styled.img.attrs((props) => ({
  loading: "lazy",
}))`
  position: absolute;
  height: 100%;
`;

export const StyledThumbnailImageBlurried = styled.img.attrs((props) => ({
  loading: "lazy",
}))`
  position: absolute;
  //filter: blur(8px);
  height: 150%;
  width: 150%;
`;

export const StyledThumbnailContainer = styled.div`
  display: inline-block;
  height:  ${(props) => props.height};
  width: ${(props) => props.width ? props.width : `calc(${props.height} * ${props.aspectRatio})`};
  padding: 5px;
`;

export const StyledThumbnailContainerFlexbox = styled.div`
  cursor: pointer;
  z-index: 0;
  position: relative;
  display: flex;
  overflow: hidden;
  border-radius: ${borderRadius}px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;


export const StyledIcon = styled(Icon).attrs((props) => ({
  size: "1.75em",
  name: "play",
}))`
  opacity: 0.8;
  color: white;
  filter: drop-shadow(-2px -2px 3px rgba(0, 0, 0, 0.3))
    drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.3));
`;

export const StyledBlackContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.text.light};
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
`;

export const StyledOnClickDiv = styled.div`
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const StyledThumbnailSkeleton = styled(Skeleton)`
  position: absolute;
  width: 100%;
  height: 100%;
`;
