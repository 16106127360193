//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'components/UI'

//STYLEDCOMPONENTS
import { 
    StyledPropertieItemContent as PropertieItemContent,
    StyledPropertieItemTitle as PropertieItemTitle,
    StyledFlexboxVertical as FlexboxVertical,
} from "./style";


function Property(props) {
  const {loading, children, item, size } = props;

  return (
    !loading ?
    <FlexboxVertical>
    <PropertieItemContent size={size}>{children}</PropertieItemContent>
    <PropertieItemTitle size={size}>{item}</PropertieItemTitle>
  </FlexboxVertical>
   :
   <FlexboxVertical>
      <Typography size={size} loading/> 
      <Typography size={size} loading/> 
   </FlexboxVertical>

  );
}
 
Property.propTypes = {
  item: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.string,
};

export default Property;

