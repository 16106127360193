import * as Types from "./types";


const initialState = {
    loading:false,
    loaded:false,
    all:[],
    error:''
}


function FieldsReducer(state=initialState, action){
    switch(action.type){
        case Types.FIELDS_REQUEST:
            return {
                loading:true,
                loaded:false,
                all:[],
                error:''
            }
        case Types.FIELDS_SUCCESS:
            return {
                loading:false,
                loaded:true,
                all:action.payload.fields,
                error:''
            }
        case Types.FIELDS_FAILURE:
            return {
                loading:false,
                loaded:false,
                all:[],
                error:action.payload.error
            }
        default: return state
    }
}

export default FieldsReducer