import { combineReducers } from 'redux';
import MachineReducer from 'components/Project/Machines/Machine/state/reducer';
import MachinesListReducer from 'components/Project/Machines/MachineList/state/reducer';
import MachineDeleteReducer from 'components/Project/Machines/Machine/state/Delete/reducer';

const ProjectMachinesReducer = combineReducers({
    main: MachineReducer,
    delete: MachineDeleteReducer,
    list: MachinesListReducer
});

export default ProjectMachinesReducer;