import { combineReducers } from 'redux';
import ProjectAlgorithmsReducer from './Algorithms/reducer';
import ProjectAlgorithmReducer from './Algorithm/reducer';
import MainProjectReducer from 'components/Project/state/reducer';
import CreateProjectReducer from 'components/Project/state/Create/reducer';
import TrainingReducer from './Training/reducer';
import ProjectMachinesReducer from './Machines/reducer';
import ProjectSettingsReducer from './Settings/reducer';
import DatasetReducer from './Dataset/reducer';
import ImageDatasetsReducer from 'components/Project/Datasets/state/reducer';
import DeploymentsReducer from 'components/Project/Deployments/DeploymentsList/state/reducer';
import DeploymentReducer from './Deployment/reducer';
import ProjectPlanReducer from 'components/Project/Settings/Billing/state/reducer';
import ProjectRolesReducer from 'components/Project/Settings/Roles/state/reducer';
import ProjectActionsCategoriesReducer from 'components/Project/Settings/Roles/state/ActionsCategories/reducer';
import ProjectRolePermissionsReducer from 'components/Project/Settings/Roles/EditRole/Role/Permissions/state/reducer';
import ProjectMembersReducer from 'components/Project/Settings/Members/state/reducer';
import ProjectTokensReducer from 'components/Project/Settings/Tokens/state/reducer';

const ProjectReducer = combineReducers({
    main: MainProjectReducer,
    create: CreateProjectReducer,
    machines: ProjectMachinesReducer,
    algorithms: ProjectAlgorithmsReducer,
    algorithm: ProjectAlgorithmReducer,
    training: TrainingReducer,
    settings: ProjectSettingsReducer,
    dataset: DatasetReducer,
    datasets: ImageDatasetsReducer,
    deployments: DeploymentsReducer,
    deployment: DeploymentReducer,
    plan: ProjectPlanReducer,
    roles: ProjectRolesReducer,
    actionsCategories: ProjectActionsCategoriesReducer,
    rolePermissions: ProjectRolePermissionsReducer,
    members: ProjectMembersReducer,
    tokens: ProjectTokensReducer
});

export default ProjectReducer;