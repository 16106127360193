//LIBRARIES
import React, { useState } from 'react';
//STYLEDCOMPONENTS
import {
  StyledContainer as Container,
  StyledCenterContainer as CenterContainer,
  StyledSignInButton as SignInButton,
  StyledLogo as Logo,
  StyledLogoContainer as LogoContainer
} from "./style";
import { useDispatch } from "react-redux";
import { initAuth } from 'components/User/Auth/state/actions';
import Auth from 'components/User/Auth'


function AppBar(props) {
  const { open } = props;
  const [auth, setAuth] = useState({ fullscreen: false, defaultIndex: 0 });
  const dispatch = useDispatch();

  function toggleSignIn() {
    dispatch(initAuth());
    setAuth({ fullscreen: !auth.fullscreen, defaultIndex: 0 });
  }

  function toggleAuthFullscreen() {
    setAuth({ ...auth, fullscreen: !auth.fullscreen });
  }

  return (
    <Container>
      {auth.fullscreen && <Auth onlySignIn defaultIndex={auth.defaultIndex} toggleFullscreen={toggleAuthFullscreen} />}
      <CenterContainer open={open}>
      <LogoContainer to="/" aria-label="homepage">
        <Logo/>
      </LogoContainer>
        <div>
          <SignInButton onClick={toggleSignIn}>Log In</SignInButton>
        </div>
      </CenterContainer>
    </Container>
  );
}

export default AppBar;