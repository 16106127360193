import * as Types from './types';


const initialState = {
    os:"",
}


function AlgorithmUsageReducer(state=initialState, action){
    switch(action.type){
        case Types.ALGORITHM_USAGE_SET_OS:
            return {
                ...state,
                os:action.payload.os,
            }
        default: return state
    }
}

export default AlgorithmUsageReducer;