import * as Types from './types';


const initialState = {
    loaded:false,
    loading:false,
    deleted:false,
    error:''
}


function MachineDeleteReducer(state=initialState, action){
    switch(action.type){
        case Types.MACHINE_DELETE_REQUEST:
            return {
                loaded:false,
                loading:true,
                deleted:false,
                error:''
            }
        
        case Types.MACHINE_DELETE_REQUEST_SUCCESS:
            return {
                loaded:true,
                loading:false,
                deleted:true,
                error:''
            }

        case Types.MACHINE_DELETE_REQUEST_FAILURE:
            return {
                loaded:true,
                loading:false,
                deleted:false,
                error:action.payload.error
            }
        default: return state
    }
}

export default MachineDeleteReducer;