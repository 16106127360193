import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.bg.default};
`;