import { ReactComponent as IconAlert } from './alert.svg';
import { ReactComponent as IconCircleCheck } from './circle-check.svg';
import { ReactComponent as IconCircleMinus } from './circle-minus.svg';
import { ReactComponent as IconPlusCircleSolid} from './plus-circle-solid.svg';
import { ReactComponent as IconSolidShieldRole} from './solid_shield-role.svg';
import { ReactComponent as IconSolidUser} from './user.svg';
import { ReactComponent as IconSolidUsers} from './users.svg';
import { ReactComponent as IconSolidPencil} from './pencil.svg';
import { ReactComponent as IconSolidXmark} from './xmark.svg';
import { ReactComponent as IconSolidPaint} from './paint.svg';
import { ReactComponent as IconSolidPencilMinus} from './pencil-minus.svg';
import { ReactComponent as IconSolidPencilPlus} from './pencil-plus.svg';
import { ReactComponent as IconSolidStar } from './star.svg';


export default {
    "solid alert":IconAlert,
    "solid solid circle-check":IconCircleCheck,
    "solid solid circle-minus":IconCircleMinus,
    "solid solid plus-circle-solid": IconPlusCircleSolid,
    "solid shield-role": IconSolidShieldRole,
    "solid user": IconSolidUser,
    "solid users": IconSolidUsers,
    "solid pencil": IconSolidPencil,
    "solid xmark": IconSolidXmark,
    "solid paint": IconSolidPaint,
    "solid pencil-minus": IconSolidPencilMinus,
    "solid pencil-plus": IconSolidPencilPlus,
    "solid star": IconSolidStar
};


