import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { Button } from 'components/UI';
import  BaseIcon from "icons";

const spread = 2;
const blurLength = 12;


export const StyledContainer = styled.div`
    && {
        display: flex;
        justify-content:center;
        align-items: center;
        min-width: ${props => (props.theme.breakpoints.phone.min)}px;
        width:100%;
        height: ${props => (props.theme.breakpoints.components.appbar)}px;
        box-shadow: ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
    }
`;


export const StyledCenterContainer = styled.div`
    && {
        max-width: 1200px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-left:20px;
        margin-right:20px;
        flex-grow:1;
    }
`;



export const StyledIconButton = styled(IconButton).attrs((props) => ({
    edge:"start",
}))`
    height: ${props => (props.theme.breakpoints.components.appbar)}px;
    width:  ${props => (props.theme.breakpoints.components.appbar)}px;
    color: ${props=>(props.theme.colors.text.default)};
    margin-left: ${props => (props.theme.breakpoints.components.appbar*.1)}px;
    margin-right: 15px;
    padding: ${props => (props.theme.breakpoints.components.appbar*.25)}px;
    display: ${props=>(props.open && "none")};

    /* @media (min-width: ${props => (props.theme.breakpoints.components.contentMaxWithMenuIcon)}px) {
        position: absolute;
        left:0;
    } */

`;


export const StyledSignInButton = styled(Button).attrs((props) => ({
    color:"secondary",
    variant:"contained",
    size:"small"
}))`
`;

export const StyledSignUpButton = styled(Button).attrs((props) => ({
    color:"primary",
    variant:"contained",
    size:"small"
}))
`
   margin-left: 10px;
`;


export const StyledLogo = styled(BaseIcon).attrs({
    name: "theos"
   })`
   && {
     width:80px; 
   }
   `;

   export const StyledLogoContainer = styled(Link)``;