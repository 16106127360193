import * as Types from "./types";
import { api } from 'state/api';
import { connect, disconnect } from 'state/Websocket/actions';
import { displayError } from 'utils';

function projectInit(){
    return {
        type:Types.PROJECT_INIT
    }
}

function projectRequest(){
    return {
        type:Types.PROJECT_REQUEST
    }
}

function projectRequestSuccess(project){
    return {
        type:Types.PROJECT_REQUEST_SUCCESS,
        payload: {
            project
        }
    }
}

function projectRequestFailure(error){
    return {
        type:Types.PROJECT_REQUEST_FAILURE,
        payload: {
            error:error
        }
    }
}

function projectPlanSet(plan_path_name){
    return {
        type:Types.PROJECT_PLAN_SET,
        payload:{
            plan_path_name
        }
    }
}

function projectRoleSet(role){
    return {
        type:Types.PROJECT_ROLE_SET,
        payload:{
            role
        }
    }
}

export function initProject(){
    return (dispatch) => {
        dispatch(projectInit());
    }
}

export function fetchProject(projectId){
    return (dispatch) => {
        dispatch(projectRequest());
        api()
            .get(`/projects/${projectId}/`)
            .then(response => {
                const project = response.data;
                console.log("FETCH PROJECT:", project);
                dispatch(projectRequestSuccess(project));
                dispatch(disconnect());
                dispatch(connect());
            })
            .catch(error => {
                dispatch(projectRequestFailure(error.message));
                displayError(error);
            })
    }
}

export function fetchProjectByKey(projectKey){
    return async (dispatch) => {
        // dispatch(projectRequest());
        try{
            const response = await api().get(`/projects/${projectKey}/`);
            const project = response.data;
            console.log("FETCH PROJECT:", project);
            dispatch(projectRequestSuccess(project));
            dispatch(disconnect());
            dispatch(connect());
            return true;
        }catch(error){
            // dispatch(projectRequestFailure(error.message));
            displayError(error);
            return false;
        }
    }
}

export function setProjectPlan(plan_path_name){
    return (dispatch) => {
        dispatch(projectPlanSet(plan_path_name));
    }
}