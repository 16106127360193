import * as Types from "./types";
import { api } from 'state/api';
import { toast } from 'react-toastify';
import { displayError } from 'utils';


function passwordResetInit(){
    return {
        type: Types.PASSWORD_RESET_INIT
    }
}

function passwordResetRequest(){
    return {
        type: Types.PASSWORD_RESET_REQUEST
    }
}

function passwordResetSuccess(){
    return {
        type: Types.PASSWORD_RESET_SUCCESS
    }
}

function passwordResetFailure(error){
    return {
        type: Types.PASSWORD_RESET_FAILURE,
        payload: {
            error,
        }
    }
}


export function initPasswordReset(){
    return (dispatch) => {
        dispatch(passwordResetInit())
    }
}

export function confirmPasswordReset(new_password1, new_password2, uid, token, onSuccess){
    return (dispatch) => {
        dispatch(passwordResetRequest())
        api()
        .post('/auth/password/reset/confirm/', {new_password1, new_password2, uid, token})
        .then(response => {
            dispatch(passwordResetSuccess());
            toast.success('password changed successfully');
            onSuccess();
        })
        .catch(error => {
            console.log(error.response.data);
            dispatch(passwordResetFailure(error.response.data));
            displayError(error);
        })
    }
}