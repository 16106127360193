//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledBorder as Border,
} from "./style";

  
function Divider(props) {
  const {height, ...other } = props;
  return (
    <Container  height={height} {...other }>
        <Border />
    </Container>
  );
}
export default Divider;