import styled from "styled-components";

export const StyledContainer = styled.div`
    margin-top:35px;
    display: flex;
    justify-content: center;

    /* justify-content: left;
    padding-left: 16px; */

    /* @media (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
        justify-content: center;
        padding-left: 0px;
    } */

    
`;