import * as Types from "./types";

const initialState = {
    loading:false,
    loaded:false,
    payload:{},
    error:''
}

function ProjectRolePermissionsReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_ROLE_PERMISSIONS_INIT:
            return initialState;

        case Types.PROJECT_ROLE_PERMISSIONS_REQUEST:
            return {
                loading:true,
                loaded:false,
                payload:{},
                error:''
            }

        case Types.PROJECT_ROLE_PERMISSIONS_REQUEST_SUCCESS:
            return {
                loading:false,
                loaded:true,
                payload:action.payload.permissions,
                error:''
            }

        case Types.PROJECT_ROLE_PERMISSIONS_REQUEST_FAILURE:
            return {
                loading:false,
                loaded:false,
                payload:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default ProjectRolePermissionsReducer;