import * as Types from './types';
import { api } from 'state/api';
import { sleep, displayError } from 'utils';

function trainingExperimentChartsSet(metrics){
    return {
        type:Types.TRAINING_EXPERIMENT_CHARTS_SET,
        payload: {
            metrics
        }
    }
}

function trainingExperimentChartRequest(metricPathName){
    return {
        type:Types.TRAINING_EXPERIMENT_CHART_REQUEST,
        payload:{
            metricPathName
        }
    }
}

function trainingExperimentChartSuccess(metricPathName, data, x){
    return {
        type:Types.TRAINING_EXPERIMENT_CHART_SUCCESS,
        payload: {
            metricPathName,
            data,
            x
        }
    }
}

function trainingExperimentChartFailure(metricPathName, error){
    return {
        type:Types.TRAINING_EXPERIMENT_CHART_FAILURE,
        payload: {
            metricPathName,
            error
        }
    }
}

function trainingExperimentChartLoaded(metricPathName){
    return {
        type:Types.TRAINING_EXPERIMENT_CHART_LOADED,
        payload: {
            metricPathName
        }
    }
}

function trainingExperimentChartsLoaded(){
    return {
        type:Types.TRAINING_EXPERIMENT_CHARTS_LOADED
    }
}

function trainingExperimentChartMetricPointAdd(metricPathName, point, x){
    return {
        type:Types.TRAINING_EXPERIMENT_CHARTS_METRIC_POINT_ADD,
        payload: {
            metricPathName,
            point,
            x
        }
    }
}

export function setTrainingExperimentCharts(projectId, trainingSessionKey, experimentId, metrics){
    return async (dispatch, getState) => {
        const experimentStatus = getState().project.training.session.main.experiment.status;
        let parsedMetrics = {};

        metrics.forEach((metric) => {
            parsedMetrics[metric.path_name] = {
                id:metric.id,
                name:metric.name, 
                pathName:metric.path_name, 
                xAxis:metric.x_axis, 
                objective:metric.objective, 
                loading:false, 
                loaded:false,
                data:[],
                x: {
                    name:'',
                    value:0,
                    total:0
                }
            };
        });

        dispatch(trainingExperimentChartsSet(parsedMetrics));
        if(experimentStatus !== 'IDLE' && experimentStatus !== 'ERROR'){
            const latestMetricId = metrics[metrics.length - 1].id;
            for(let i=0;i<metrics.length;i++){
                const selectedExperimentId = getState().project.training.session.main.selectedExperimentId;
                if(selectedExperimentId !== undefined && selectedExperimentId !== experimentId){
                    return;
                }
                const metric = metrics[i];
                let isLast = metric.id === latestMetricId;
                dispatch(fetchExperimentMetricData(projectId, trainingSessionKey, experimentId, metric.path_name, metric.id, isLast));
                await sleep(1000);
            }
        }else{
            for(let i=0;i<metrics.length;i++){
                const selectedExperimentId = getState().project.training.session.main.selectedExperimentId;
                if(selectedExperimentId !== undefined && selectedExperimentId !== experimentId){
                    return;
                }
                const metric = metrics[i];
                await dispatch(trainingExperimentChartLoaded(metric.path_name));
            }
            dispatch(trainingExperimentChartsLoaded());
        }
    }
}

function compareExperimentMetrics(a, b) {
    if ( a.x < b.x ){
      return -1;
    }
    if ( a.x > b.x ){
      return 1;
    }
    return 0;
}

export function fetchExperimentMetricData(projectId, trainingSessionKey, experimentId, metricPathName, metricId, isLast){
    return async (dispatch) => {
        dispatch(trainingExperimentChartRequest(metricPathName));
        try{
            const response = await api().get(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experimentId}/metrics/${metricId}/data/`);
            const data = response.data.data;
            const x = response.data.x;

            data.sort(compareExperimentMetrics);
            dispatch(trainingExperimentChartSuccess(metricPathName, data, x));

            if(isLast){
                dispatch(trainingExperimentChartsLoaded());
            }
        }catch(error){
            console.log('CHART ERROR:', error);
            dispatch(trainingExperimentChartFailure(metricPathName, error.response.data.error));
            displayError(error);
        }
    }
}

export function addExperimentMetricPoint(metricPathName, point, x){
    return (dispatch, getState) => {
        const metrics = getState().project.training.session.experiment.metrics.payload;
        if(metricPathName in metrics){
            dispatch(trainingExperimentChartMetricPointAdd(metricPathName, point, x));
        }else{
            console.log('METRIC NOT IN PAYLOAD:', metricPathName);
        }
    }
}