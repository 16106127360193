import {
    Alert,
    AlertDescription,
  } from "components/shadcnui/alert"

  import { AlertCircle } from "lucide-react"
  
  export function AlertDestructive({ error }: { error: string }) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        {/* <AlertTitle>Error</AlertTitle> */}
        <AlertDescription>
          {error}
        </AlertDescription>
      </Alert>
    )
  }