import React from "react";
import { round } from "utils";
//STYLEDCOMPONENTS
import * as S from "./style";

function ComplexProgressBar(props) {
  const {
    title,
    percentage,
    bottomRightInfo,
    bottomLeftInfo,
    showPercetage,
  } = props;

  return (
    <S.Container>
      {title && <S.Title size="s" weight="thin">{title}</S.Title>}
      <S.ProgressBarContainer>
        <S.ProgressBar percentage={percentage}/>
        {showPercetage && <S.Percentage>{round(percentage, 0)}%</S.Percentage>}
      </S.ProgressBarContainer>
      <S.BottomContainer>
        {bottomLeftInfo && <S.BottomLeft size="xs">{bottomLeftInfo}</S.BottomLeft>}
        {bottomRightInfo && <S.BottomRight size="xs">{bottomRightInfo}</S.BottomRight>}
      </S.BottomContainer>
    </S.Container>
  );
}

export default ComplexProgressBar;
