import styled, {keyframes} from "styled-components";
import { Fullscreen, Typography, Button} from 'components/UI';
import BaseIcon from 'icons';

const bubbleSize = 80;


export const Modal = styled(Fullscreen)`
    padding: 40px;
    position:relative;
    overflow:hidden;
    height:min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;
    > div {
        padding: 5px 0px;
    }
`;


export const LinkButton = styled.button`
    margin-top:10px;
    font-size: ${(props) => props.theme.fonts.title.s.size};
    color: ${props => (props.theme.colors.text.error)};
    font-weight: ${(props) => props.theme.fonts.weight.bold};

    color: ${(props) => {
      switch(props.type) {
        case "error":
          return `${props.theme.colors.text.error}`
        case "alert":
          return `${props.theme.colors.text.alert}`
        default:
          return `${props.theme.colors.text.success}`
      }
    }
   };
`;

export const Icon = styled(BaseIcon).attrs((props) => ({
    //size:"small"
    size: "40%",
    name: (props.type === "error") ? "close" : ((props.type === "alert") ? "solid alert" : "check")
}))`
  color: white;
`;



const borderTransform = keyframes`
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
    14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
    28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
    42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
    56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
    70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
    84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
`;


export const Message = styled.div`

font-size: ${(props) => props.theme.fonts.title.s.size};
font-weight: ${(props) => props.theme.fonts.weight.light};

`;


export const Bubble = styled(Typography)`
position: relative;
width: 100%;
width: ${bubbleSize}px;
height: ${bubbleSize}px;
display: flex;
transform: translate3d(0, 0, 35px) perspective(100px);
justify-content: center;
align-items: center;
margin-bottom: 15px;

:before {
    position: absolute;
    content: '';
    z-index: -1;

    ${(props) => {
      switch(props.type) {
        case "error":
          return `background: ${props.theme.colors.button.error.gradient};`
        case "alert":
          return `background: ${props.theme.colors.text.alert};`
        default:
          return `background ${props.theme.colors.button.success.gradient};`
      }
    }
   }

    width: 100%;
    height: 100%;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    box-shadow: 0 6px 30px -5px ${(props) => {
      switch(props.type) {
        case "error":
          return `${props.theme.colors.button.error.left}`
        case "alert":
          return `${props.theme.colors.text.alert}`
        default:
          return `${props.theme.colors.button.success.right}`
      }
    }
   };

   
    animation: ${borderTransform} 6s linear infinite;
}
`;



export const BlurriedBubble = styled.div`
    position: absolute;
    z-index: -1;
    background: ${props => (props.theme.colors.button.error.gradient)};

    background: ${(props) => {
      switch(props.type) {
        case "error":
          return `${props.theme.colors.button.error.gradient}`
        case "alert":
          return `${props.theme.colors.text.alert}`
        default:
          return `${props.theme.colors.button.success.gradient}`
      }
    }
   };

    width: 125%;
    height: 300px;
    display: block;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 20px 0 rgba(16, 39, 112,.3);
    animation: ${borderTransform} 6s linear infinite;
    filter:blur(20px);
`;



