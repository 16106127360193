import * as types from "./types";

const initialState = {
    detecting:false,
    detected:false,
    imageFile:undefined,
    image:undefined,
    detections:[],
    detectionURL:'',
    duration:0,
    confidenceThreshold:0.25,
    iouThreshold:0.45,
    error:''
}

function ObjectDetectionPlaygroundReducer(state=initialState, action){
    switch(action.type){
        case types.OBJECT_DETECTION_PLAYGROUND_INIT:
            return initialState;

        case types.OBJECT_DETECTION_PLAYGROUND_DETECT_REQUEST:
            return {
                ...state,
                detecting:true,
                detected:false,
                detections:[],
                error:''
            };
        
        case types.OBJECT_DETECTION_PLAYGROUND_DETECT_SUCCESS:
            return {
                ...state,
                detecting:false,
                detected:true,
                detections:action.payload.detections,
                detectionURL:action.payload.detectionURL,
                duration:action.payload.duration,
                error:''
            };
        
        case types.OBJECT_DETECTION_PLAYGROUND_DETECT_FAILURE:
            return {
                ...state,
                detecting:false,
                detected:false,
                detections:[],
                error:action.payload.error
            };
        
        case types.OBJECT_DETECTION_PLAYGROUND_IMAGE_SET:
            return {
                ...state,
                imageFile:action.payload.imageFile,
                image:action.payload.image,
                detecting:false,
                detected:false,
                detections:[]
            };
        
        case types.OBJECT_DETECTION_PLAYGROUND_DETECTIONS_SET:
            return {
                ...state,
                detections:action.payload.detections
            };
        
        case types.OBJECT_DETECTION_PLAYGROUND_CONFIDENCE_THRESHOLD_SET:
            return {
                ...state,
                confidenceThreshold:action.payload.confidenceThreshold
            };
        
        case types.OBJECT_DETECTION_PLAYGROUND_IOU_THRESHOLD_SET:
            return {
                ...state,
                iouThreshold:action.payload.iouThreshold
            };

        default: return state
    }
}

export default ObjectDetectionPlaygroundReducer;