import * as Types from "./types";


const initialState = {
    connecting:false,
    connected:false,
    error:'',
}


function WebsocketReducer(state=initialState, action){
    switch(action.type){
        case Types.WEBSOCKET_CONNECT:
        case Types.WEBSOCKET_RECONNECT:
            return {
                connecting:true,
                connected:false,
                error:''
            }
        
        case Types.WEBSOCKET_CONNECT_SUCCESS:
            return {
                connecting:false,
                connected:true,
                error:''
            }

        case Types.WEBSOCKET_ERROR:
            return {
                ...state,
                error:action.payload.error
            }

        case Types.WEBSOCKET_CLOSED:
        case Types.WEBSOCKET_DISCONNECT:
            return initialState

        default: return state
    }
}

export default WebsocketReducer;