  
import React from 'react';
import iconMap from 'assets/svgs/icon-map';

const EmptyIcon = () => <div/>;


const Icon = React.forwardRef(function Icon(props, ref) {
  //  Spread the props to the underlying DOM element.
  const { name, size, color, ...rest } = props;
  
  const Icon = iconMap[name] || EmptyIcon;
  return (
    <Icon style={{ fill:color, width: size, height: size }} {...rest} ref={ref} />
  );
});


export default Icon;