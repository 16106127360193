import styled from "styled-components";
import BaseTooltip from '@mui/material/Tooltip';


export const Tooltip = styled(BaseTooltip).attrs((props) => ({
  componentsProps:{
    tooltip: {style: {backgroundColor: "rgba(255, 255, 255, 0.8)", color: props.theme.colors.text.default, backdropFilter: "blur(4px)", boxShadow: "0px 2px 10px 0px rgba(0,0,0,0.2)" }},
    arrow: {style: {color: "rgba(255, 255, 255, 0.8)"}},
  },
}))`
`;