import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import RootReducer from './Root/reducer';
import { persistStore } from 'redux-persist';
import WebsocketMiddleware from './Websocket/middleware';

const middleware = [thunk, WebsocketMiddleware]

export const store = createStore(
  RootReducer,
  applyMiddleware(...middleware)
);

export const persistor = persistStore(store);