//LIBRARIES
import React, { useState } from 'react';
//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledPrincipalImage as PrincipalImage,
  StyledPrincipalImageBlurried as PrincipalImageBlurried,
  StyledAnimation as Animation,
  StyledVideo as Video,
  StyledLoader as Loader,
} from "./style";


function PrincipalMedia(props) {
   const {selectedMedia, onClick } = props;
   const [insideLoading, setInsideLoading ] = useState(true);

   function onLoad(){
     setInsideLoading(false);
   }

  return (
    <Animation id={selectedMedia.id}>    
        <Container onClick={onClick}>  
            {insideLoading && <Loader/>}
            <PrincipalImageBlurried onLoad={onLoad} src={selectedMedia.original} />
            <PrincipalImage src={selectedMedia.original} onLoad={onLoad} />
            {selectedMedia.video !== undefined && <Video key={selectedMedia.video} onReady={onLoad} url={selectedMedia.video}/>}
        </Container>
    </Animation> 
  );
}

export default PrincipalMedia;
