import * as Types from "./types";


const initialState = {
    deleted:false,
    loading:false,
    error:''
}


function DatasetDeleteReducer(state=initialState, action){
    switch(action.type){
        case Types.DATASET_DELETE:
            return {
                deleted:false,
                loading:true,
                error:''
            }
        
        case Types.DATASET_DELETE_SUCCESS:
            return {
                deleted:true,
                loading:false,
                error:''
            }

        case Types.DATASET_DELETE_FAILURE:
            return {
                deleted:false,
                loading:false,
                error:action.payload.error
            }
        default: return state
    }
}

export default DatasetDeleteReducer;