import styled from "styled-components";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

import {ScrollableDiv} from "components/UI"

import template from "./template";

const spread = 5;
const blurLength = 10;

export const StyledCodeBlock = styled(SyntaxHighlighter).attrs((props) => ({
  wrapLines: true,
  //showLineNumbers: true,
  style: template,
  language: props.language? props.language:"javascript",
}))`
  && {
    padding: 10px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    font-size: 1.6em;
    box-shadow: inset -${spread}px -${spread}px ${blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      inset ${spread}px ${spread}px ${blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};
    user-select: text;
    background-color: rgba(242, 239, 254, 0.01);
  }
`;

export const StyledContainer = styled(ScrollableDiv)`
  border: solid 1px ${(props) => props.theme.colors.border.default};
  position: relative;
  box-shadow: inset -${spread}px -${spread}px ${blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.light},
    inset ${spread}px ${spread}px ${blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast};

  border-radius: 10px;

`;

// export const StyledScreen = styled.div`
//   background-color: rgba(108, 72, 255, 0.1);
//   position: absolute;
//   left: 0;
//   width: 30px;
//   height: 100%;

// `;
