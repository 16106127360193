import React from "react";
import * as S from "./style";

function LazyFallback(props) {
  return (
    <S.Container>
      <S.Loader/>
    </S.Container>
  );
}
export default LazyFallback;