//LIBRARIES
import React, { useEffect, useState } from "react";
import { Divider } from "components/UI";
import { useSmallScreenMediaQuery } from "components/UI/Devices";
//STYLEDCOMPONENTS
import * as S from "./style";
import { clearStorage } from "state/Root/actions";
import { logout } from "components/User/Auth/state/actions";
import { useSelector, useDispatch } from "react-redux";
import { eraseAllCookies } from "utils/browser";
import { useNavigate } from "react-router-dom";
import { disconnect } from 'state/Websocket/actions';


function AccountDropdown(props) {
  const { hideAuthFullscreen } = props;
  const isSmallScreen = useSmallScreenMediaQuery();
  const profile = useSelector((store) => store.user.profile.payload);
  const profileLoaded = useSelector((store) => store.user.profile.loaded);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstLetter, setFirstLetter] = useState("");

  useEffect(() => {
    if (profileLoaded) {
      setFirstLetter(profile.username[0].toUpperCase());
    }
  }, [profileLoaded]);

  function onLogoutClick() {
    dispatch(disconnect());
    dispatch(logout());
    hideAuthFullscreen();
    dispatch(clearStorage());
    dispatch(eraseAllCookies());
    navigate('/', {replace: true});
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <S.Avatar onClick={handleClick}>{firstLetter}</S.Avatar>
      <S.Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        isSmallScreen={isSmallScreen}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <S.Link to={"/account"}>
          <S.MenuItem >
            <div className="icon">
              <S.Icon name="user" />
            </div>
            <div className="text">Account</div>
          </S.MenuItem>
        </S.Link>
        <Divider />
        <S.MenuItem onClick={onLogoutClick}>
          <div className="icon">
            <S.Icon name="sign-out" />
          </div>
          <div className="text">Log out</div>
        </S.MenuItem>
      </S.Menu>
    </>
  );
}

export default AccountDropdown;
