import styled from "styled-components";
import DatePicker from "@mui/lab/DatePicker";
import BaseIcon from "icons";
import hexToRgba from "hex-to-rgba";

export const MuiCalendar = styled(DatePicker).attrs((props) => ({}))
`
`;

export const Paper = styled.div`
  background-color: ${(props) => props.theme.colors.bg.default};
  box-shadow: none;
  border-radius: 16px;


  box-shadow: rgb(88 102 126 / 8%) 0px 4px 24px,
    rgb(88 102 126 / 12%) 0px 1px 2px;


  & .MuiPickersDay-root {
      background-color: ${(props) => props.theme.colors.bg.default};
      :hover {
        background-color: ${(props) => props.theme.colors.button.hover};
      }
  }

  & .MuiPickersDay-today {
    /* color: ${(props) => props.theme.colors.text.purple}; */
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    border: none;
  }


  & .MuiCalendarPicker-root {
    button:not(.MuiIconButton-root) {
      transition: 300ms box-shadow;
      :hover {
        transition: 300ms box-shadow;

        /* box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.1), -2px -2px 2px 0px rgba(255,255,255,1);
        background-color: ${(props) => props.theme.colors.bg.default}; */
      }
      border-radius: 8px;
    }

    & .Mui-selected {
      /* background-color: blue; */

      color: ${(props) => props.theme.colors.text.purple};
      font-weight: ${(props) => props.theme.fonts.weight.bold};
      /* background-color: ${(props) => props.theme.colors.bg.default};
      box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.1), -3px -3px 5px 0px rgba(255,255,255,0.7); */
      background-color: ${(props) =>
        hexToRgba(props.theme.colors.text.purple, 0.1)};

      :hover {
        background-color: ${(props) =>
          hexToRgba(props.theme.colors.text.purple, 0.2)};
      }
    }



  .MuiCalendarPicker-viewTransitionContainer {
    &::-webkit-scrollbar {
    width: 20px; 
    height: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 8px solid rgba(0, 0, 0, 0);
    min-height: 50px;
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    background-color: lightgrey;
    -webkit-box-shadow: inset 0 0 0 1px lightgrey;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  }
 
  }
`;

export const OpenPickerIcon = styled(BaseIcon).attrs((props) => ({
  name: "calendar",
  size: "20px",
}))``;

export const SwitchViewIcon = styled(BaseIcon).attrs((props) => ({
  name: "angle-small-down",
  size: "20px",
}))``;
