import * as Types from './types';

const initialState = {
    successful:false,
    loading:false,
    errors:''
}


function PasswordChangeReducer(state=initialState, action){
    switch(action.type){
        case Types.PASSWORD_CHANGE_INIT:
            return initialState

        case Types.PASSWORD_CHANGE_REQUEST:
            return {
                successful:false,
                loading:true,
                errors:''
            }

        case Types.PASSWORD_CHANGE_SUCCESS:
            return {
                successful:true,
                loading:false,
                errors:''
            }
        
        case Types.PASSWORD_CHANGE_FAILURE:
            return {
                successful:false,
                loading:false,
                errors:action.payload.errors
            }

        default: return state
    }
}

export default PasswordChangeReducer;