import styled from "styled-components";

export const StyledAnimation = styled.div`
    && {

    }

    &.fade-appear {
    opacity: 0;
    filter: blur(16px);
    }

    &.fade-appear-active {
    opacity: 1;
    filter: blur(0px);
    transition: opacity ${props => props.duration}ms linear, filter ${props => props.duration}ms linear;
    }

    &.fade-exit {
    opacity: 1;
    filter: blur(0px);
    }

    &.fade-exit-active {
    opacity: 0;
    filter: blur(16px);
    transition: opacity ${props => props.duration}ms linear, filter ${props => props.duration}ms linear;
    }

    &.fade-exit-done {
    opacity: 0;
    filter: blur(16px);
    }

    &.fade-enter {
    opacity: 0;
    filter: blur(16px);
    }

    &.fade-enter-active {
    opacity: 1;
    filter: blur(0px);
    transition: opacity ${props => props.duration}ms linear, filter ${props => props.duration}ms linear;
    }
`;