import React from "react";

import {
  Loader2,
  ArrowRight,
  Instagram,
  Linkedin,
  type LucideIcon as LucideIconProps,
} from "lucide-react";


export type Icon = LucideIconProps;

// interface XTwitterIconProps {
  interface IconProps {
    className?: string;
    height?: string;
  }
  
  export function TheosIcon({ className }: IconProps) {
    return (
      <svg  className={className} viewBox="0 0 876 180" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M131.796 2.24356H2.83491C1.18575 2.24356 0 3.71627 0 5.4505V23.7424C0 25.4766 1.18575 26.7016 2.83491 26.7016H54.1903V167.545C54.1903 169.28 55.3761 170.505 57.0253 170.505H77.6192C79.2684 170.505 80.6995 169.266 80.6995 167.545V26.7016H131.81C133.459 26.7016 134.644 25.4629 134.644 23.7424V5.4505C134.644 3.71627 133.459 2.24356 131.81 2.24356H131.796Z" />
      <path d="M316.365 2.24356H295.771C294.122 2.24356 292.69 3.73004 292.69 5.4505V73.6495H207.739V5.4505C207.739 3.71627 206.321 2.24356 204.904 2.24356H184.078C182.661 2.24356 181.243 3.73004 181.243 5.4505V167.545C181.243 169.28 182.661 170.505 184.078 170.505H204.904C206.321 170.505 207.739 169.266 207.739 167.545V98.3553H292.69V167.545C292.69 169.28 294.108 170.505 295.771 170.505H316.365C318.014 170.505 319.2 169.266 319.2 167.545V5.4505C319.2 3.71627 318.014 2.24356 316.365 2.24356Z" />
      <path d="M626.351 0.0137939C579.97 0.0137939 541.167 36.5839 541.167 86.7525C541.167 136.921 579.97 172.996 626.351 172.996C672.732 172.996 711.535 136.921 711.535 86.7525C711.535 36.5839 672.5 0.0137939 626.351 0.0137939ZM626.351 147.285C593.927 147.285 569.094 120.597 569.094 86.7387C569.094 52.8801 593.94 25.9446 626.351 25.9446C658.762 25.9446 683.376 52.8801 683.376 86.7387C683.376 120.597 658.762 147.285 626.351 147.285Z" />
      <path d="M856.115 84.5227C842.158 75.1359 825.122 72.4107 809.979 66.7263C796.732 62.0329 787.968 56.3485 787.968 45.4752C787.968 39.2953 790.34 34.3541 794.837 30.6517C799.567 26.4538 807.376 23.9763 818.021 23.9763C832.223 23.9763 846.179 28.9175 857.532 35.8406C859.181 36.8316 860.613 36.3361 861.553 34.6019L869.84 16.5577C870.548 14.8235 870.317 13.3508 868.654 12.3598C858.009 6.17989 841.436 0 819.438 0C800.03 0 785.365 4.69341 775.429 12.8553C765.248 21.0034 760.042 32.8677 760.042 46.7139C760.042 65.7353 769.269 76.3609 782.285 84.0273C797.427 92.9186 813.278 95.6438 826.771 101.328C838.369 106.022 847.119 112.449 847.119 123.818C847.119 131.237 844.516 138.146 838.369 142.839C833.395 146.79 825.83 149.267 815.895 149.267C795.778 149.267 781.59 140.376 771.177 133.7C769.282 132.462 768.096 133.205 767.388 134.691L759.101 152.983C758.161 154.718 758.624 156.19 760.287 157.181C772.117 164.848 790.571 172.996 816.126 172.996C833.163 172.996 848.074 168.55 858.486 159.906C869.376 151.015 876 138.16 876 121.602C876 103.31 867.005 91.7074 856.129 84.5365L856.115 84.5227Z" />
      <path d="M496.818 2.24356H383.926C382.428 2.24356 381.213 3.46984 381.213 4.98253V23.9489C381.213 25.4616 382.428 26.6879 383.926 26.6879H496.818C498.316 26.6879 499.53 25.4616 499.53 23.9489V4.98253C499.53 3.46984 498.316 2.24356 496.818 2.24356Z" />
      <path d="M496.818 74.0349H383.926C382.428 74.0349 381.213 75.2611 381.213 76.7738V95.9879C381.213 97.5006 382.428 98.7269 383.926 98.7269H496.818C498.316 98.7269 499.53 97.5006 499.53 95.9879V76.7738C499.53 75.2611 498.316 74.0349 496.818 74.0349Z" />
      <path d="M496.818 146.074H383.926C382.428 146.074 381.213 147.3 381.213 148.813V168.027C381.213 169.54 382.428 170.766 383.926 170.766H496.818C498.316 170.766 499.53 169.54 499.53 168.027V148.813C499.53 147.3 498.316 146.074 496.818 146.074Z" />
      </svg>
    );
  }
  


export const Icons = {
   Logo: TheosIcon,
   Spinner: Loader2,
   ArrowRight: ArrowRight,
    Instagram: Instagram,
    Linkedin: Linkedin,
};


