import * as Types from './types';
import update from 'immutability-helper';

const initialState = {
    loading:false,
    loaded:false,
    uploading:false,
    uploaded:false,
    exampleBeingUploaded:'',
    currentPage:1,
    uploadProgress:0,
    exampleCount:1,
    examplesUploaded:0,
    pageCount:1,
    images:[],
    error:''
}

function DatasetUploadReducer(state=initialState, action){
    switch(action.type){
        case Types.DATASET_UPLOAD_INIT:
            return initialState;

        case Types.DATASET_UPLOAD_LOADING_IMAGES:
            return {
                ...state,
                loading:true,
                loaded:false
            }
        
        case Types.DATASET_UPLOAD_IMAGES_LOADED:
            return {
                ...state,
                loading:false,
                loaded:true
            }

        case Types.DATASET_UPLOAD_REMOVE_IMAGES:
            return {
                ...state,
                images:[]
            }

        case Types.DATASET_UPLOAD_ADD_IMAGE:
            return {
                ...state,
                images:[...state.images, action.payload.image]
            }

        case Types.DATASET_UPLOAD_IMAGE_SET:
            return update(state, {
                images:{
                    [action.payload.index]: {$set:action.payload.image}
                }
            })

        case Types.DATASET_UPLOAD_PAGE_SET:
            return {
                ...state,
                currentPage:action.payload.currentPage,
                pageCount:action.payload.pageCount,
            }

        case Types.DATASET_UPLOAD_START:
            return {
                ...state,
                uploading:true,
                uploaded:false
            }

        case Types.DATASET_UPLOAD_SUCCESS:
            return {
                ...state,
                uploading:false,
                uploaded:true
            }

        case Types.DATASET_UPLOAD_FAILURE:
            return {
                ...state,
                uploading:false,
                uploaded:false,
                error:action.payload.error
            }

        case Types.DATASET_UPLOAD_IMAGE_EXAMPLE_UPLOAD:
            return {
                ...state,
                exampleBeingUploaded:action.payload.exampleBeingUploaded
            }

        case Types.DATASET_UPLOAD_PROGRESS_SET:
            return {
                ...state,
                uploadProgress:action.payload.uploadProgress
            }
        
        case Types.DATASET_UPLOAD_EXAMPLE_COUNT_SET:
            return {
                ...state,
                exampleCount:action.payload.exampleCount
            }
        
        case Types.DATASET_UPLOAD_EXAMPLES_UPLOADED_ADD:
            return update(state, {
                examplesUploaded:{$apply: function(x) {return x+action.payload.count;}}
            })

        default: return state
    }
}

export default DatasetUploadReducer;