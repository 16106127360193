import styled from "styled-components";

export const StyledAnimation = styled.div`

    &.fade-appear {
    opacity: 0;
    }

    &.fade-appear-active {
    opacity: 1;
    /* transition: opacity ${props => props.duration}ms linear, filter ${props => props.duration}ms linear; */
    transition: opacity ${props => props.duration}ms linear;
    }

`;