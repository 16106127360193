import styled from "styled-components";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";

import hexToRgba from "hex-to-rgba";

export const StyledList = styled(List).attrs((props) => ({}))`
overflow-y: overlay;
scrollbar-width: thin !important;

  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    min-height: 50px;
    background-clip: padding-box;
    -webkit-border-radius: 20px;
    background-color: lightgrey;
    -webkit-box-shadow: inset 0 0 0 1px lightgrey;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const StyledListItem = styled(ListItem).attrs((props) => ({

}))`
  color: ${props => props.theme.colors.text.default};
  margin: 0px;
  border-top: 0.5px solid ${props=>(props.theme.colors.border.default)};
  
  &&.MuiListItem-button.Mui-selected {
    color: ${props => props.color? props.theme.colors.text.default:'#5d42f5'};  
    background-color: ${props => props.color? hexToRgba(props.color, 0.2):'#e6e0ff'};
  }
`;

export const StyledListItemIcon = styled(ListItemIcon).attrs((props) => ({}))`

`;

export const StyledListItemText = styled(ListItemText).attrs((props) => ({}))`
`;
