//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledImage as Image
} from "./style";

  
function Avatar(props) {
  const { children, alt, src, size, ...other } = props;
  return (
    <Container {...other} src={src} size={size}>
      {src !== undefined && <Image src={src}/>}
      {children}
    </Container>
  );
}

export default Avatar;