import React from "react";
import PrimaryActionButton from "./PrimaryActionButton"
import {
  StyledButton,
  StyledLoader as Loader,
  StyledChildren as Children,
  StyledButtonIcon as ButtonIcon,
} from "./style";

const Button = props => {
  const { disabled, children, loading, icon, ...other } = props;

  const button = (
    <StyledButton {...other} disabled={disabled || (loading ? true : false)} disableRipple >
      {icon && <ButtonIcon name={icon}/>}
      {loading === 1 && <Loader/>}
        <Children loading={loading}>
      {children}
      </Children>
    </StyledButton>
  );
  return button;
};

export default Button;

export {PrimaryActionButton};
