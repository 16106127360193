//LIBRARIES
import React, {useState} from "react";

//STYLEDCOMPONENTS
import {
  StyledContainer as Container,
  StyledTitle as Title,
  StyledDescription as Description,
  StyledBackdropFilterImg as BackdropFilterImg,
  StyledContent as Content,
  StyledCard as CardBase,
  StyledBlurriedImage as BlurriedImage,
  StyledTopDiv as TopDiv,
  StyledTopDivText as TopDivText,
  StyledImage as Image,
  StyledSkeleton as Skeleton,
} from "./style";



export default function Card(props) {
  const { img, comingSoon, title, description } = props;
  
  const [insideLoad, setInsideLoad ] = useState(false);

  function onLoad(){
    setInsideLoad(true);
  }
  return (
    <Container comingSoon={comingSoon}>
      {insideLoad && <BlurriedImage img={img}/>}
      <CardBase>
      <Image onLoad={onLoad} src={img}/>
      {!insideLoad &&
        <Skeleton />
      }
        <BackdropFilterImg img={img}/>  
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
        {comingSoon ? <TopDiv><TopDivText>COMING SOON</TopDivText></TopDiv> : <></>}
      </CardBase>
    </Container>

  );
}
