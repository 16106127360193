import styled from "styled-components";
import { Breadcrumbs } from "@mui/material";
import { Typography as MaterialTypography } from "@mui/material";
import { Skeleton } from "components/UI";


const spread = 2;
const blurLength = 5;


export const StyledContainer = styled.div`
    && {
        color: ${props=>(props.theme.colors.text.default)};
        width:100%;
        margin-top:10px;
    }
`;


export const StyledMaterialTypography = styled(MaterialTypography)`
    font-size: 1.6rem;
`;


export const StyledBreadcrumbs = styled(Breadcrumbs)`
    /* font-size: 1.6rem; */
    .MuiButtonBase-root {
      border-radius: 50px;
      box-shadow: -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
    }
`;


export const StyledSkeleton = styled(Skeleton)`
    /* font-size: 1.6rem; */
    height: 30px;
`;


export const StyledHomeTitle = styled.div`
display: flex;
/* text-transform: uppercase;
text-shadow: 2px 2px 4px rgba(0,0,0,0.3), -3px -3px 2px rgba(255,255,255,1); */
align-items: center;
font-size:40px;
color: ${props=>(props.theme.colors.text.default)};
font-weight: ${props => (props.theme.fonts.weight.bold)};
`;