import * as Types from './types';
import update from 'immutability-helper';

const initialState = {
    loading:false,
    loaded:false,
    payload:{},
    error:''
}

function TrainingExperimentMetricsReducer(state=initialState, action){
    switch(action.type){
        case Types.TRAINING_EXPERIMENT_CHARTS_SET:
            return {
                loading:true,
                loaded:false,
                payload:action.payload.metrics,
                error:''
            }

        case Types.TRAINING_EXPERIMENT_CHART_REQUEST:
            return update(state, {
                payload:{
                    [action.payload.metricPathName]: {
                        loading: {$set: true},
                        loaded: {$set: false},
                        data: {$set: []}
                    }
                }
            })
        
        case Types.TRAINING_EXPERIMENT_CHART_SUCCESS:
            return update(state, {
                payload:{
                    [action.payload.metricPathName]: {
                        loading: {$set: false},
                        loaded: {$set: true},
                        data: {$set: action.payload.data},
                        x: {$set: action.payload.x}
                    }
                }
            })

        case Types.TRAINING_EXPERIMENT_CHART_FAILURE:
            return update(state, {
                payload:{
                    [action.payload.metricPathName]: {
                        loading: {$set: false},
                        loaded: {$set: true},
                        data: {$set: []}
                    }
                },
                error: {$set: action.payload.error}
            })

        case Types.TRAINING_EXPERIMENT_CHART_LOADED:
            return update(state, {
                payload:{
                    [action.payload.metricPathName]: {
                        loading: {$set: false},
                        loaded: {$set: true}
                    }
                }
            })

        case Types.TRAINING_EXPERIMENT_CHARTS_LOADED:
            return {
                ...state,
                loading:false,
                loaded:true
            }

        case Types.TRAINING_EXPERIMENT_CHARTS_METRIC_POINT_ADD:
            return update(state, {
                payload:{
                    [action.payload.metricPathName]: {
                        data: {$push: [action.payload.point]},
                        x: {$set: action.payload.x}
                    }
                }
            })
        
        default: return state
    }
}

export default TrainingExperimentMetricsReducer;