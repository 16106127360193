import styled from "styled-components";
import { Link as BaseLink } from "react-router-dom";
import { Menu as BaseMenu, MenuItem as BaseMenuItem, Avatar as BaseAvatar } from "components/UI";
import BaseIcon from "icons";

export const Link = styled(BaseLink)`
  color: inherit;
  text-decoration: none;
`;

export const Avatar = styled(BaseAvatar)`
 cursor: pointer;
`;

export const ItemContainer = styled.div`
  font-size: 13px;
  color: inherit;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled(BaseIcon).attrs((props) => ({
size: "15px",
}))``;

export const Menu = styled(BaseMenu).attrs((props) => ({
  PaperProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px -2px 4px rgba(0,0,0,0.1))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: -1,
        right: props.isSmallScreen ? 97 : 50,
        width: 10,
        height: 10,
        bgcolor: props.theme.colors.button.default,
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
}))`
  
  .MuiMenu-paper {
    /* border: 1px solid ${(props) => props.theme.colors.border.default}; */
    color: ${(props) => props.theme.colors.text.classes};
    border-radius:8px;
    background-color: ${(props) => props.theme.colors.bg.default};
  }
`;

export const MenuItem = styled(BaseMenuItem).attrs((props) => ({}))`
  display: flex;
  padding: 8px 20px 8px 0px;
  .icon {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Item = styled.div`
  margin-left: 15px;
`;