import {
    PaymentElement as BasePaymentElement,
} from "@stripe/react-stripe-js";
import styled from "styled-components";
import {Typography as BaseTypography} from "components/UI";

export const PaymentElement = styled(BasePaymentElement).attrs({
})`
  .p-FieldLabel {
    font-weight: ${(props) => props.theme.fonts.weight.bold};
  }
`;

export const Container = styled.div`
`;

export const ErrorMessage = styled(BaseTypography)`
margin-top:10px;
color: #eb2349;
`