import styled from "styled-components";
import { Fullscreen, Typography, Text, Button as BaseButton , Stepper as BaseStepper, Switch as BaseSwitch} from "components/UI";
// import {Radio as BaseRadio} from '@mui/material';
import Icon from "icons";

//rgb(47,131,189)

export const Title = styled(Typography).attrs((props) => ({
  size: "l"
}))`
`;

export const Description = styled(Typography).attrs((props) => ({
}))`
font-weight: ${(props) => props.theme.fonts.weight.thin};
font-size: 1.2rem;
margin-bottom: 20px;
`;

export const ExtraDescription = styled(Typography).attrs((props) => ({
}))`
font-weight: ${(props) => props.theme.fonts.weight.thin};
font-size: 1rem;
color: ${(props) => props.theme.colors.text.medium};
margin-top: 20px;
`;

export const EnterpriseDescription = styled(Typography).attrs((props) => ({
  size: "s"
}))`
font-weight: ${(props) => props.theme.fonts.weight.thin};
font-size: 1.2rem;
margin-bottom: 20px;
`;

export const SwitchContainer = styled.div`
    display:flex;
    flex-direction:row;
    width: 100%;
    height: 100%;
    align-items: center;
    margin-bottom: 15px;
`;

export const SwitchTitleContainer = styled.div`
    display:flex;
    flex-direction:row
    align-items: center;
`;

export const SwitchTitle = styled(Typography).attrs((props) => ({
  size: "m",
}))`
  white-space: nowrap;
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  margin-left:10px;
`;

export const SwitchTitleDiscount = styled(Typography).attrs((props) => ({
  size: "m",
}))`
  white-space: nowrap;
  font-weight: ${(props) => props.theme.fonts.weight.thin};
  margin-left:5px;
`;

export const Modal = styled(Fullscreen).attrs((props) => ({}))`
  width:100%;
  height:100%;
`;

export const EmptyContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width: 450px;
  height: 450px;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    padding:25px;
    text-align:left;

    > :first-child  {
      margin-bottom: 20px;
    }
`;

export const PlansContainer = styled.div`
    display:flex;
    width:100%;
    height:275px;
    align-items:center;
    justify-content:center;

    > :not(:last-child) {
      margin-right:25px;
    }
`;

export const Switch = styled(BaseSwitch)`
`;