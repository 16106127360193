//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import * as S from "./style";

  
function Bubble(props) {
  const { children, ...others } = props;
  return (
    <S.Bubble {...others}>
      {children}
    </S.Bubble>
  );
}


export default Bubble;