//LIBRARIES
import React from 'react';
import 'rc-slider/assets/index.css';
//STYLEDCOMPONENTS
import * as S from "./style";

function Slider(props) {
  const {  ...others } = props;
  return (
      <S.BaseSlider {...others}/>
  );
}
 


export default Slider;