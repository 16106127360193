import styled from "styled-components";
// import Rocket from "./Rocket";
// import Ufo from "./Ufo";

import {Orb as BaseOrb, Button as BaseButton} from "components/UI"
import { keyframes } from "styled-components";

import BaseIcon from "icons";

const shine = keyframes`
  0% { background-position: 0;}
  60% { background-position: 180px;}
  100% { background-position: 250px;}
`;


const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


export const Logo = styled(BaseIcon).attrs({
  name: "theos",
})`
  && {
    width: 60px;
    filter: drop-shadow(0 0 0px black);
  }
`;

export const GradientWrapper = styled.div`


`;

export const WavesBackground = styled.img`
position: absolute;
 height: 100%; 
 margin-top: 0px;

`;

export const TitleContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.title.l.size};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  


  /* background-color: yellow; */
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* transform: translateX(30px); */

  /* position: absolute;
  transform: translateX(-130%); */

  .plan-name {
    /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) translateZ(0); */

  
  transform: translateZ(0);
  
  // background: linear-gradient(to right, black 0, #ababab 10%, black 20%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // animation: ${shine} 5s infinite linear;
  // animation-fill-mode: forwards;
  background-position: 0;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;
  }
`;
export const InfoContainer = styled.div`


padding: 16px;
  width: 100%;

  height: 100%;
 position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  

  /* background-color: black; */
  margin-bottom: 8px;

  .description {
    max-width:250px;
    font-size: ${props=>props.theme.fonts.title.xsi.size};
  }

  .price {
      display: flex;
      align-items: flex-end;

      .number {
        font-size: 1.2rem;
        font-size: ${props=>props.theme.fonts.title.m.size};
        font-weight: ${props=>props.theme.fonts.weight.medium};
      }

      .label {
        margin-bottom: 4px;
        font-size: ${props=>props.theme.fonts.body.s.size};
        font-weight: ${props=>props.theme.fonts.weight.regular};
      }
  }
  
  .period {
    margin-top: 4px;
    font-size: ${props=>props.theme.fonts.body.m.size};
    font-weight: ${props=>props.theme.fonts.weight.regular};
  }

  background-color: transparent;
`;

export const Container = styled.div`
  
  position: relative;
  /* color: ${(props) => props.theme.colors.text.default}; */
  width: 100%;
  overflow: hidden;
  height: 100%;
  border-radius:20px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
 


`;

// export const StyledRocket = styled(Rocket)`
//   background-color: transparent;
//   position: absolute;
//   height: 50%;
//   width: auto;
//   animation-name: ${upAnimation};
//   animation-duration: 2s;
//   animation-iteration-count: infinite;
// `;

// export const StyledUfo = styled(Ufo)`
//   position: absolute;
//   width: 75px;
//   height: auto;
// `;
export const BlurredOrbs = styled.img`

  filter: saturate(1);
  position: absolute;
  height: 200%;
  top: -130%;
  display: flex;
  left: -50%;
  animation-name: ${rotate};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;


export const Orb = styled(BaseOrb)`
    position: absolute;
    right: -20px;
   bottom: -50px;
   width: 150px;
   height: 150px;
`;

export const ContactUsButton = styled(BaseButton)`
`


