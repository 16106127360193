import styled from "styled-components";
import { ToastContainer } from 'react-toastify';

const borderRadius = 8;

export const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`

        font-size: ${(props) => props.theme.fonts.body.m.size};
        
        color: ${(props) => props.theme.colors.text.default};
        
        width:auto;
        min-width:200px;

        .Toastify__toast-container {
         
        }
        .Toastify__toast {
          border-radius: ${borderRadius}px;
          color: ${(props) => props.theme.colors.text.default};
          padding-left: 20px;
          background-color: ${(props) => props.theme.colors.bg.default};
          font-weight: ${(props) => props.theme.fonts.weight.bold};
          /* border: ${(props) => `1px solid ${props.theme.colors.bg.default}`}; */

          /* background-color: blue; */
        }
        .Toastify__toast--error {
          box-shadow: ${(props) => `inset 0px 0px 5px 0px ${props.theme.colors.text.error},
                                    0px 0px 5px 0px ${props.theme.colors.text.error}`};
          background-color: ${(props) => props.theme.colors.div.secondary.error.bg};
        }
        .Toastify__toast--warning {
          box-shadow: ${(props) => `inset 0px 0px 5px 0px ${props.theme.colors.text.alert},
                                    0px 0px 5px 0px ${props.theme.colors.text.alert}`};
          background-color: ${(props) => props.theme.colors.div.secondary.alert.bg};
        }
        .Toastify__toast--success {
          box-shadow: ${(props) => `inset 0px 0px 5px 0px ${props.theme.colors.text.success},
                                    0px 0px 5px 0px ${props.theme.colors.text.success}`};
          background-color: ${(props) => props.theme.colors.div.secondary.success.bg};

        }
        .Toastify__toast-body {}
        .Toastify__progress-bar {
           background-color: rgba(0,0,0,0.05);
           /* background-color: rgba(255,255,255,0.7); */
        }

        .Toastify__close-button {
              
                color: rgba(0,0,0,0.3);
        }               
`;

