import * as Types from "./types";
import update from "immutability-helper";
import * as m from "components/DatasetTools/modes";

const initialState = {
  addingClassName: false,
  brushSize: 1,
  canvas: { width: 0, height: 0 },
  classNameAdded: false,
  classNames: {},
  color: "#000000",
  contextMenu: { event: null },
  cursor: "pointer",
  datasetId: undefined,
  datasetName: "",
  eraseMode: false,
  error: "",
  exampleCount: 0,
  isDrawing: false,
  loaded: false,
  loading: false,
  lockedMode: false,
  mode: m.TRANSFORM,
  ordered: false,
  previousMode: null,
  projectId: undefined,
  selectedClassNameId: undefined,
  selectedClassNameWithText: false,
  selectedClassificationClassNameId: undefined,
  selectedDeploymentFallbackURL: undefined,
  selectedDeploymentId: undefined,
  selectedDeploymentURL: undefined,
  shortcutsAllowed: true,
  shortcutsPanelOpened: false,
  showNames: false,
  visionLLMinstructions: '',
  superpixelSize: 1,
  tool: "TRANSFORM",
  zoomSensitivity: 5,
};

function ImageDatasetMainReducer(state = initialState, action) {
  switch (action.type) {
    case Types.IMAGE_DATASET_TOOL_INIT:
      return initialState;

    case Types.SET_COLOR:
      return {
        ...state,
        color: action.payload.color,
      };

    case Types.IMAGE_DATASET_TOOL_INFO_REQUEST:
      return {
        ...initialState,
        loaded: false,
        loading: true,
        // classNames: {},
        error: "",
      };

    case Types.IMAGE_DATASET_TOOL_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        datasetName: action.payload.datasetName,
        projectId: action.payload.projectId,
        datasetId: action.payload.datasetId,
        loaded: true,
        loading: false,
        ordered: action.payload.ordered,
        classNames: action.payload.classNames,
        exampleCount: action.payload.exampleCount,
        showNames: action.payload.showNames,
        visionLLMinstructions: action.payload.visionLLMinstructions,
        error: "",
      };

    case Types.SET_VISION_LLM_INSTRUCTIONS:
      return {
        ...state,
        visionLLMinstructions: action.payload.instructions,
      };

    case Types.IMAGE_DATASET_TOOL_INFO_REQUEST_FAILURE:
      return {
        ...state,
        loaded: true,
        loading: false,
        classNames: {},
        error: action.payload.error,
      };

    case Types.SET_SELECTED_CLASSNAME: {
      const className = Object.values(state.classNames).find(
        (x) => x.id === action.payload.selectedClassNameId
      );
      return {
        ...state,
        selectedClassNameId: action.payload.selectedClassNameId,
        selectedClassNameWithText:
          className !== undefined ? className.with_text : false,
      };
    }

    case Types.SET_SELECTED_CLASSIFICATION_CLASSNAME: {
      const className = Object.values(state.classNames).find(
        (x) => x.id === action.payload.selectedClassificationClassNameId
      );
      return {
        ...state,
        selectedClassificationClassNameId:
          action.payload.selectedClassificationClassNameId,
      };
    }

    case Types.SET_CLASSNAME_VISIBLE:
      return update(state, {
        classNames: {
          [action.payload.classNameId]: { $toggle: ["visible"] },
        },
      });

    case Types.SET_MODE:
      return {
        ...state,
        // cursor: action.payload.mode === "DRAW" || "POSE_ESTIMATION" ? "crosshair" : "grab",
        previousMode: state.mode,
        mode: action.payload.mode,
      };

    case Types.SET_SUPERPIXEL_SIZE:
      return {
        ...state,
        superpixelSize: action.payload.superpixelSize,
      };

    case Types.SET_BRUSH_SIZE:
      return {
        ...state,
        brushSize: action.payload.brushSize,
      };

    case Types.SET_ERASE_MODE:
      return {
        ...state,
        eraseMode: action.payload.eraseMode,
      };

    case Types.SET_LOCKED_MODE:
      return {
        ...state,
        lockedMode: action.payload.lockedMode,
      };

    case Types.SET_TOOL:
      return {
        ...state,
        // cursor: action.payload.mode !== "TRANSFORM" ? "crosshair" : "grab",
        // previousMode: state.mode,
        tool: action.payload.tool,
      };

    case Types.SET_CURSOR:
      return {
        ...state,
        cursor: action.payload.cursor,
      };

    case Types.SET_CONTEXT_MENU:
      return {
        ...state,
        contextMenu: action.payload.contextMenu,
      };

    case Types.TOGGLE_IS_DRAWING:
      return {
        ...state,
        isDrawing: !state.isDrawing,
      };

    case Types.SET_CANVAS_SIZE:
      return {
        ...state,
        canvas: {
          ...state.canvas,
          width: action.payload.canvasWidth,
          height: action.payload.canvasHeight,
        },
      };

    case Types.SET_ZOOM_SENSITIVITY:
      return {
        ...state,
        zoomSensitivity: action.payload.zoomSensitivity,
      };

    case Types.SET_SHOW_NAMES:
      return {
        ...state,
        showNames: action.payload.showNames,
      };

    case Types.CLASSNAMES_ADD:
      return {
        ...state,
        addingClassName: true,
        classNameAdded: false,
        error: "",
      };

    case Types.CLASSNAMES_ADD_SUCCESS:
      return update(state, {
        addingClassName: { $set: false },
        classNameAdded: { $set: true },
        classNames: {
          [action.payload.classNameId]: {
            $set: { ...action.payload.className, visible: true },
          },
        },
        error: { $set: "" },
      });

    case Types.CLASSNAMES_ADD_FAILURE:
      return {
        ...state,
        addingClassName: false,
        classNameAdded: false,
        error: action.payload.error,
      };

    case Types.SHORTCUTS_TOGGLE:
      return {
        ...state,
        shortcutsAllowed: !state.shortcutsAllowed,
      };

    case Types.OPEN_SHORTCUTS_PANEL_TOGGLE:
      return {
        ...state,
        shortcutsPanelOpened: !state.shortcutsPanelOpened,
      };

    case Types.SHORTCUTS_SET:
      return {
        ...state,
        shortcutsAllowed: action.payload.shortcutsAllowed,
      };

    case Types.DEPLOYMENT_SET:
      return {
        ...state,
        selectedDeploymentId: action.payload.selectedDeploymentId,
        selectedDeploymentURL: action.payload.selectedDeploymentURL,
        selectedDeploymentFallbackURL:
          action.payload.selectedDeploymentFallbackURL,
      };

    case Types.CLASS_SKELETON_SET:
      return update(state, {
        classNames: {
          [action.payload.classNameId]: {
            skeleton: { 
              keypoints: { $set: action.payload.keypoints },
              connections: { $set: action.payload.connections }
            },
          },
        },
      });

    default:
      return state;
  }
}

export default ImageDatasetMainReducer;
