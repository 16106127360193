import styled from "styled-components";
import { Fullscreen, Typography, Text, Button as BaseButton, Stepper as BaseStepper, Radio as BaseRadio } from "components/UI";
// import {Radio as BaseRadio} from '@mui/material';
import Icon from "icons";

//rgb(47,131,189)

export const Container = styled.div`
/* box-shadow: ${(props) =>
props.selected &&
`0px 1px 1px 5px ${props.theme.colors.colorful.primary}, 0px 0px 10px 6px ${props.theme.colors.colorful.primary}`}; */

padding-top:0px;
display: flex;
flex-direction: column;
text-align: center;
margin-top:10px;
margin-bottom:10px;
margin-left:5px;
`;

export const ButtonContainer = styled.div`
padding: 16px;
padding-top:8px;
display: flex;
justify-content: space-between;
`;


export const Modal = styled(Fullscreen).attrs((props) => ({}))`
overflow: hidden;
border-radius: inherit;
width: 95vw;
max-width: 440px;
`;

export const Radio = styled(BaseRadio).attrs((props) => ({}))`
&& .MuiRadio-root {
  background-color: blue;
}
`;

export const Title = styled(Typography).attrs((props) => ({
  size: "l"
}))`
`;

export const Stepper = styled(BaseStepper).attrs((props) => ({
  small: true,
}))`
width: 100%;
align-self: flex-start;
`;

export const Button = styled(BaseButton).attrs((props) => ({
  variant: "contained"
}))`
`;

export const Description = styled(Text).attrs((props) => ({
}))`
margin-top: 16px;
.link {
  color: ${props => props.theme.colors.text.purple}
}
`;