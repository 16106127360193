import styled from "styled-components";
import Icon from "icons";

export const StyledContainer = styled.div`
height: 60px;
width: 60px;
border-radius: 20px;
box-shadow: 
        0.2em 0.2em 1em rgba(0,0,0,0.08),
        -0.1em -0.1em 0.2em rgba(255,255,255,0.50),
        inset -0.4em -0.4em 0.4em rgba(0,0,0,0.02),
        inset 0.4em 0.4em 0.4em rgba(255,255,255,0.50);

cursor: pointer;

:hover {
 transform: scale(1.1,1.1)
}
`;

export const StyledIcon = styled(Icon).attrs((props) => ({
    size: "50%",
}))`

color: grey;
   

opacity: 1;
${StyledContainer}:hover & {
    color: ${props=>(props.theme.colors.colorful.primary)};
   
    filter: ${props=>(`drop-shadow( 0px 0px 3px  ${props.theme.colors.colorful.primary}) drop-shadow( 0px 0px 1px white)`)};
}

`;


export const StyledLink = styled.a`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
`;
