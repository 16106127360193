
import React, { useEffect } from "react";
import { lazy } from "react";
import { Routes, Route, useNavigate, useLocation, matchPath } from "react-router-dom";
import { useSelector } from "react-redux";
import MainLayout from "./mainLayout";
import FullscreenLayout from "./fullscreenLayout";
import Loading from "components/Other/Loading";


function Router(props) {
  const { routes, signedIn } = props;
  const projectsLoaded = useSelector((store) => store.projects.loaded);
  const projectsLoadError = useSelector((store) => store.projects.loadError);
  const projects = useSelector((store) => store.projects.payload);
  const hasProjects = projects.length > 0;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!signedIn) {
      if(location.pathname === '/'){
        navigate(`/authentication/login?redirect=/library`);
      }else{
        for(let i = 0; i < routes.length; i++) {
          const matched = matchPath({
            path: routes[i].path,
            exact: true,
            strict: true,
          }, location.pathname);
          if (matched && routes[i].projectRequired) {
            navigate(`/authentication/login?redirect=${location.pathname}`);
            break;
          }
        }
      }
    } else if(projectsLoaded){
      if(hasProjects){
        if(location.pathname === '/' || location.pathname === '/welcome'){
          navigate('/library');
        }
      }else{
        const forbiddenPaths = ['/authentication/signup/success', '/verify-email', '/reset-password', '/join', '/maintenance', '/custom-plan'];
        let match = false;
        for(let i = 0; i < forbiddenPaths.length; i++) {
          const matched = matchPath({
            path: forbiddenPaths[i],
            exact: true,
            strict: true,
          }, location.pathname);
          if (matched) {
            match = true;
            break;
          }
        }
        if(!projectsLoadError && !match){
          navigate('/welcome');
        }
      }
    }
  }, [signedIn, projectsLoaded, projectsLoadError]);

  if (signedIn && !projectsLoaded) {
    return <Loading/>;
  } else {
    return (
      <Routes>
        {routes.map((route, key) => {
          if (route.type === "fullscreen") {
            let Component = lazy(() => import("./" + route.importScreenPath));
            return <Route
              exact
              path={route.path}
              element={<FullscreenLayout><Component/></FullscreenLayout>}
              key={key}
            />
          } else if (route.type === "main") {
            let Component = lazy(() => import("./" + route.importScreenPath));
            return <Route
              exact
              path={route.path}
              element={<MainLayout withAppbar={false}><Component/></MainLayout>}
              key={key}
            />
          }
        })}
      </Routes>
    );
  }
}

export default Router;
