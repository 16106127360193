import styled from "styled-components";
import { ParallaxCard as BaseParallaxCard} from "components/UI";




//rgb(47,131,189)

export const Container = styled.div`
 /* box-shadow: ${(props) => props.selected && `0px 1px 1px 5px ${props.theme.colors.colorful.primary}, 0px 0px 10px 6px ${props.theme.colors.colorful.primary}`}; */
 border-radius: 40px;
 cursor: pointer;
 position: relative;
`;



export const ParallaxCard = styled(BaseParallaxCard).attrs((props) => ({
  fixed: true,
  height: props.height,
  width: props.width,
  //boxShadowColor: props.theme.colors.boxShadow.neumo.contrast,
  //boxShadowColor:"#7a83ff",
  boxShadowColor: props.boxShadowColor ? props.boxShadowColor=== "red" ? "#F6C1CF": props.boxShadowColor=== "diamond" ? "#71cef5":"#ADB0F9" : props.theme.colors.colorful.primary,
  scale:2,
  borderRadius: 20,
}))`
`;