import React, { useRef, useState, useEffect } from "react";

//STYLEDCOMPONENTS

import * as S from "./style";
import Plan from "./Plan"

function MachineCard(props) {
  const {
    id,
    plan,
    period,
    onClick
  } = props;
  const [dimensions, setDimensions] = useState({w:335, h:230});
  const ref = useRef(null);

  useEffect(() => {
    if (!dimensions)
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    function handleResize(event) {
      setDimensions({
        h: ref.current.clientHeight,
        w: ref.current.clientWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  function handleOnClick(event) {
    onClick && onClick(event, id);
  }

  return (
    <S.Container ref={ref} onClick={handleOnClick}>
      {dimensions != null && (
        <S.ParallaxCard
          width={dimensions.w}
          height={dimensions.h}
          boxShadowColor={plan.color}
        >
         <Plan plan={plan} period={period}/>
        </S.ParallaxCard>
      )}
    </S.Container>
  );
}

export default MachineCard;
