//LIBRARIES
import React from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

function Popover(props) {
  const { children, ...other } = props;
  return (
    <S.Popover {...other}>
        {children}
    </S.Popover>
  );
}

export default Popover;
