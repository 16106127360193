//LIBRARIES
import React, { useState, useEffect } from "react";
//STYLEDCOMPONENTS
import {
  StyledFullscreen as Fullscreen,
  StyledContainer as Container,
  StyledTab as Tab,
} from "./style";
import { Tabs, TabList, TabPanel, Typography } from "components/UI";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ResendEmailVerification from "./ResendEmailVerification";
import { useDispatch } from "react-redux";
import { initAuth } from "./state/actions";
import { initForgotPassword } from "./ForgotPassword/state/actions";

function Auth(props) {
  const { fullscreenVisible, toggleFullscreen, defaultIndex, onlySignIn, redirect, ...others } = props;
  const [selectedTab, setSelectedTab] = useState(defaultIndex ? defaultIndex : 0);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initAuth());
  }, []);

  function toggleForgotPassword() {
    dispatch(initForgotPassword())
    setForgotPassword(!forgotPassword);
  }

  function toggleEmailVerification(){
    setEmailVerification(!emailVerification);
  }

  function redirectWithMessage() {
    setRedirected(true)
  }

  function onSelected(tabIndex) {
    setSelectedTab(tabIndex);
  }

  return (
    <Fullscreen onClickOutside={toggleFullscreen}>
      {forgotPassword ? (
        <ForgotPassword toggleForgotPassword={toggleForgotPassword}/>
      ) : (
        emailVerification?
          <ResendEmailVerification toggleEmailVerification={toggleEmailVerification}/>
        :
        <Container onlySignIn={onlySignIn}>
          <Typography
            style={{ alignSelf: "flex-start" }}
            weight={"bold"}
            size={"l"}
          >
            {selectedTab === 0 ? "Log In" : "Sign Up"} to Theos
          </Typography>
          {onlySignIn ?
         <SignIn toggleForgotPassword={toggleForgotPassword} toggleEmailVerification={toggleEmailVerification} redirect={redirect}/>
          :
          <Tabs onSelect={onSelected} defaultIndex={defaultIndex}>
            <TabList>
              <Tab>Log In</Tab>
              <Tab>Sign Up</Tab>
            </TabList>
            <TabPanel>
              <SignIn toggleForgotPassword={toggleForgotPassword} toggleEmailVerification={toggleEmailVerification} redirect={redirect}/>
            </TabPanel>
            <TabPanel>
              <SignUp redirectWithMessage={redirectWithMessage} redirect={redirect}/>
            </TabPanel>
          </Tabs>
          }
        </Container>
        
      )}
    </Fullscreen>
  );
}

export default Auth;
