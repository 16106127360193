import styled from "styled-components";

const spread = 4;
const blurLength = 10;
// import { Paper } from "@mui/material";

// export const StyledContainer = styled(Paper).attrs((props) => ({
// }))`
//     && {
//         color: ${props=>(props.theme.colors.text.default)};
//     }
// `;

export const StyledContainer = styled.div`
    width:180px;
    height:40px;
    color: ${props => (props.theme.colors.text.default)};
    background-color: ${props => (props.theme.colors.bg.default)};
    box-shadow: inset -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                inset ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
`;

