//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledButton as Button, 
  StyledIcon as Icon
} from "./style";

function IconButton(props) {
    const {icon, children, size, disabled, color, ...others} = props;
  return (
    <Button {...others} disabled={disabled} color={color}>
        {!children && <Icon size={size} name={icon} color={color}/>}
        {children && children}
    </Button>
  );
}

export default IconButton;