//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledAnimation as Animation, 
} from "./style";

import { CSSTransition  } from "react-transition-group"
  
export default function GrowIn(props) {
  const { children, duration, appear, id, ...other } = props;
  const finalDuration = duration ? duration : 300;
  return (
      <CSSTransition
        in={true}
        appear={true}
        timeout={finalDuration}
        classNames="grow"
        unmountOnExit
      >
        <Animation duration={finalDuration} {...other}>{children}</Animation>
      </CSSTransition>
  );
}