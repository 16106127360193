import styled from "styled-components";
import { Checkbox as BaseCheckbox } from "@mui/material";

import BaseIcon from 'icons'



export const Icon = styled(BaseIcon).attrs({
  size: "70%",
  name: "check"
})`
color: white;

`;

export const IconContainer = styled.div`
  /* box-shadow: inset 0px 0px 2px 4px blue; */
  
display: flex;
justify-content: center;
align-items: center;


border-radius: 4px;
height: 20px;
width: 20px;
transition: 200ms;

background-color: ${(props) => props.theme.colors.colorful.primary}; 
border: 1px solid ${(props) => props.theme.colors.divider};


`;



export const Checkbox = styled(BaseCheckbox)`

  

  &&.Mui-checked {

.base {
  box-shadow: 2px 2px 2px 1px ${props => (props.theme.colors.boxShadow.neumo.contrast)}, -2px -2px 3px 1px white;
  
}
  }

  &&:not(.Mui-checked) {
   .base {
     background-color: transparent;
     box-shadow: inset 1px 1px 3px 1px ${props => (props.theme.colors.boxShadow.neumo.contrast)}, inset -2px -2px 3px 1px white;
     
   }
  }


`;
