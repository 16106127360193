import styled from "styled-components";
import { Select as BaseSelect, MenuItem } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export const Select = styled(BaseSelect).attrs((props) => ({
  // inputProps: {sx: {height: "10px"}},
  // sx: {height: "20px"}

}))`
/* && {
  .MuiSelect-standard {
    height: 16px;
  }
} */

  
  font-size: ${(props) => props.theme.fonts.body.m.size};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text.classes};
  background-color: ${(props) => props.theme.colors.button.default};
 
  text-shadow: -0.1em -0.1em 0.15em ${(props) => props.theme.colors.boxShadow.neumo.light},
    0.1em 0.1em 0.15em ${(props) => props.theme.colors.boxShadow.neumo.contrast};

  box-shadow: -${(props) => props.theme.effects.neumo.spread}px -${(props) =>
        props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.light},
    ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast},
    inset 0px 0px 0px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast},
    inset 0px 0px 0px 0 ${(props) => props.theme.colors.boxShadow.neumo.light};
  transition: box-shadow 200ms ease-out;


  :hover {
    transition: box-shadow 200ms ease-out;
    box-shadow: -1px -1px 1px ${(props) => props.theme.colors.boxShadow.neumo.light},
      1px 1px 1px ${(props) => props.theme.colors.boxShadow.neumo.contrast},
      inset 0px 0px 0px 0 ${(props) => props.theme.colors.boxShadow.neumo.light},
      inset 0px 0px 0px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};
  }

 .MuiSelect-selectMenu {
    border-radius: 10px;
    padding: 10px 30px 10px 20px;
    /* z-index: 5002 !important; */
    &:focus {
      background: ${(props) => props.theme.colors.button.default};
    }
  }

  fieldset {
    border: none;
  }
 
	&.Mui-disabled {
    box-shadow: -1px -1px 1px ${(props) => props.theme.colors.boxShadow.neumo.light},
      1px 1px 1px ${(props) => props.theme.colors.boxShadow.neumo.contrast},
      inset 0px 0px 0px 0 ${(props) => props.theme.colors.boxShadow.neumo.light},
      inset 0px 0px 0px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};
  }
`;

export const SelectItem = styled(MenuItem).attrs((props) => ({}))`
  font-size: ${(props) => props.theme.fonts.body.m.size};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;

export const ArrowIcon = styled(
  KeyboardArrowDownRoundedIcon
).attrs((props) => ({}))`
  pointer-events: none;
`;

export const Paper = styled.div.attrs((props) => ({
  // elevation: 0,
}))`

  /* z-index: 5002 !important; */
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.bg.default};
  box-shadow: inset 2px 2px 4px 0
      ${(props) => props.theme.colors.boxShadow.neumo.light},
    inset -2px -2px 4px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast};
`;
