import * as Types from './types';
import { api } from 'state/api'

function profileInit(){
    return {
        type: Types.PROFILE_INIT
    }
}

function profileRequest(){
    return {
        type: Types.PROFILE_REQUEST
    }
}

function profileRequestSuccess(profile){
    return {
        type: Types.PROFILE_REQUEST_SUCCESS,
        payload: {
            profile
        }
    }
}

function profileRequestFailure(error){
    return {
        type: Types.PROFILE_REQUEST_FAILURE,
        payload: {
            error
        }
    }
}

function  profileLogout(){
    return {
        type: Types.PROFILE_LOGOUT
    }
}

export function initProfile(){
    return (dispatch) => {
        dispatch(profileInit());
    }
}

export function fetchProfile(){
    return (dispatch) => {
        dispatch(profileRequest());
        api()
            .get('/users/profile/')
            .then(response => {
                const profile = response.data;
                dispatch(profileRequestSuccess(profile));
            })
            .catch(error => {
                dispatch(profileRequestFailure(error.message));
            })
    }
}

export function logoutProfile(){
    return (dispatch) => {
        dispatch(profileLogout());
    }
}