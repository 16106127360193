import styled from "styled-components";
// import Rocket from "./Rocket";
// import Ufo from "./Ufo";

import {Orb as BaseOrb} from "components/UI"

import BaseIcon from "icons";

import { keyframes } from "styled-components";



const rotate = keyframes`
  /* 0% { transform: translate(-160%, 0%); }
  50% { transform: translate(-160%, -30%); }
  100% { transform: translate(-160%, 0%); } */
  0% { transform: rotate(0deg); }

  100% { transform: rotate(360deg); }

`;


export const Logo = styled(BaseIcon).attrs({
  name: "theos",
})`
  && {
    width: 80px;
    filter: drop-shadow(0 0 0px black);
  }
`;


export const TitleContainer = styled.div`
  font-size: ${(props) => props.theme.fonts.title.xl.size};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  

  width: min-content;
  height: 100%;
  /* background-color: yellow; */
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(36%);

  /* position: absolute;
  transform: translateX(-130%); */
`;

export const Container = styled.div`
  /* border-radius: 12px 12px 0px 0px; */

  width: 100%;
  overflow: hidden;
  height: ${props => props.height ? props.height : "140px"};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  /* background-color: black; */
  margin-bottom: 8px;

`;

export const BlurredOrbs = styled.img`
position: absolute;
height: 150%;
left: -28%;
animation-name: ${rotate};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  filter: saturate(2) blur(10px);
`;

export const WavesBackground = styled.img`
position: absolute;
 width:100%;
 margin-top: 0%;

`;



export const Orb = styled(BaseOrb)`
    position: absolute;
    left:  110%;

`;


