/* eslint-disable no-use-before-define */
import React from "react";

import {
  StyledDiv as Div,
  StyledBorder as Border,
  StyledSearchIcon as SearchIcon,
  StyledInput as Input,
  StyledSkeleton as Skeleton,
} from "./style";

export default function Autocomplete(props) {
  const { loading, onSearchWrite, onBlur, onFocus, ...others } = props;
  const[focused, setFocused] = React.useState(false);

  function handleOnFocus(event) {
    onFocus && onFocus(event);
    setFocused(true);
  }

  function handleOnBlur(event) {
    onBlur &&  onBlur(event);
    setFocused(false);
  }

  return !loading ? (
    <Border focused={focused} {...others} >
      <Div focused={focused}>
        <SearchIcon />
        <Input
          type="text"
          onBlur={(e) => handleOnBlur(e)}
          onFocus={(e) => handleOnFocus(e)}
          onChange={onSearchWrite}
          placeholder={"Search..."}
        />
      </Div>
    </Border>
  ) : (
    <Skeleton />
  );
}
