import styled from "styled-components";

export const Container = styled.div`
  scrollbar-width: thin !important;
  overflow-y:auto;

  &::-webkit-scrollbar {
    width: 16px; 
    height: 16px;
  }

  &::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    min-height: 50px;
    background-clip: padding-box;
    -webkit-border-radius: 16px;
    background-color: lightgrey;
    -webkit-box-shadow: inset 0 0 0 1px lightgrey;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

