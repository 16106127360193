
import styled from "styled-components";



export const StyledFlexboxVertical = styled.div`
  min-width:50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledPropertieItem = styled.div`
    && {
      align-self: flex-start;
    }
`;

export const StyledPropertieItemTitle = styled.div`
    && {
      color: ${props => (props.theme.colors.text.light)};
      font-weight: ${props => (props.size==="large" ? props.theme.fonts.weight.bold : props.theme.fonts.weight.medium)};
      font-size: ${props => (props.size==="large" ? props.theme.fonts.title.s.size : "calc("+props.theme.fonts.title.s.size+" - 0.2em)")};
    }
`;

export const StyledPropertieItemContent = styled.div`
    && {
      color: ${props => (props.theme.colors.text.default)};
      font-weight: ${props => (props.theme.fonts.weight.light)};
      font-size: ${props => (props.size==="large" ? props.theme.fonts.title.m.size : "calc("+props.theme.fonts.title.m.size+" - 0.2em)")};
    }
`;


