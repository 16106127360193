export function timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        const secondsAgo = Math.round(elapsed/1000);
        if(secondsAgo <= 0){
            return "just now";
        }else{
            return secondsAgo + ' seconds ago';   
        }
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return Math.round(elapsed/msPerYear ) + ' years ago';   
    }
}

export function timeBetweenDates(date2, date1) {
    var diff = date2.getTime() - date1.getTime();
    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;
    if(hh < 0){
        hh = 0;
    }
    if(mm < 0){
        mm = 0;
    }
    if(ss < 0){
        ss = 0;
    }
    
    var response = ss + " seconds";

    if(mm > 0){
        response = mm + " minutes and " + response;
    }

    if(hh > 0){
        response = hh + " hours, " + response;
    }

    return response;
}

export function formatDate(encoded_date){
    const date = new Date(encoded_date);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
}