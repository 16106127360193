import { combineReducers } from 'redux';
import AlgorithmMainReducer from '../Main/state/reducer';
import AlgorithmTrainedReducer from '../Trained/state/reducer';
import AlgorithmUsageReducer from '../Usage/state/reducer';


const AlgorithmReducer = combineReducers({
    main:AlgorithmMainReducer, 
    trained:AlgorithmTrainedReducer,
    usage: AlgorithmUsageReducer
});


export default AlgorithmReducer;