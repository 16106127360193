import styled from "styled-components";
import { TransitionGroupBlur } from "components/UI/Animations";
import { Loader as LoaderBase } from "components/UI";

const WIDTH_PERCENTAGE_DESKTOP = 60;
const WIDTH_PERCENTAGE_MOBILE = 90;

export const Container = styled.div`
  && {

    overflow: hidden;
    
    ${(props) =>
      props.aspectRatio > 1
        ? `width: ${WIDTH_PERCENTAGE_DESKTOP}vw; height: ${
            WIDTH_PERCENTAGE_DESKTOP / props.aspectRatio
          }vw; `
        : `height: ${WIDTH_PERCENTAGE_DESKTOP}vh; width: ${
            WIDTH_PERCENTAGE_DESKTOP * props.aspectRatio
          }vh;`}

    @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
      ${(props) =>
        props.aspectRatio > 1
          ? `width: ${WIDTH_PERCENTAGE_MOBILE}vw; height: ${
              WIDTH_PERCENTAGE_MOBILE / props.aspectRatio
            }vw;`
          : `height: ${WIDTH_PERCENTAGE_MOBILE}vh; width: ${
              WIDTH_PERCENTAGE_MOBILE * props.aspectRatio
            }vh;`}
    }

  }
`;

export const Image = styled.img.attrs((props) => ({
  loading: "lazy",
}))`
    width: 100%;
    height: 100%;
`;

export const Animation = styled(TransitionGroupBlur)`
  overflow: hidden;
  border-radius: 10px;
  position: absolute;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  max-width: 95vw;
  max-height: 95vh;
`;

export const Loader = styled(LoaderBase)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
