//LIBRARIES
import React, {useState} from 'react';

import {InputBase} from 'components/UI';

function validateEmail(email) {
  //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

function Email(props) {
  const {valid, setInput,input, label, ...other } = props;

  const [state, setState] = useState(
    {
      bottomLabel:'',
    });

  function handleOnChange(event){
    let validEmail = validateEmail(event.target.value);
    setInput({value:event.target.value,valid:validEmail});
    // if(validEmail!==valid)
      if(!validEmail)
        setState({...state, bottomLabel:"* Please enter a valid email."})
      else
       setState({...state, bottomLabel:"A verification email will be sent to this address."})
}

  return (
    <InputBase type={"email"} valid={valid} label={label} value={input}  handleOnChange={handleOnChange} showValidIcon={true} bottomLabel={state.bottomLabel} {...other}/>
  );
}

export default Email;

// "You will have to verify that you are the owner of this username account":
// "* Please enter a valid username."