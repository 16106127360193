//LIBRARIES
import React, { useState } from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

function Dialog(props) {
  const {
    title,
    children,
    toggleFullscreen,
    onConfirmClick,
    confirmDisabled,
    cancelLoading,
    confirmLoading,
    ...others
  } = props;
  return (
    <S.Modal onClickOutside={toggleFullscreen} {...others}>
      <S.Title>{title}</S.Title>
      <S.Message>{children}</S.Message>
      <S.ButtonsContainer>
        <S.CancelButton  loading={cancelLoading? 1 : undefined} onClick={toggleFullscreen} disabled={confirmLoading}>Close</S.CancelButton>
        <S.ConfirmButton loading={confirmLoading? 1 : undefined} onClick={onConfirmClick} disabled={confirmDisabled || cancelLoading}>
          Confirm
        </S.ConfirmButton>
      </S.ButtonsContainer>
    </S.Modal>
  );
}

export default Dialog;
