import React, { Suspense } from 'react';
import ThemeProviders from './themeProviders';
import { StyledRoot as Root } from './style';
import { ToastContainer } from 'components/UI';
import { LazyFallbackFull } from 'components/UI/Layouts';
import { history } from 'state/App/reducer';
import Router from './Router';
import DynamicRouter from './DynamicRouter';
import UnifiedRouter from './UnifiedRouter';
import { useSelector } from 'react-redux';
import { FullscreenRoutes, MainRoutes } from "routes";
import { BrowserRouter } from "react-router-dom";


function ScreensRoot(props) {
  const signedIn = useSelector((store) => store.user.auth.signedIn);
  const staticRoutes = [];
  const dynamicRoutes = [];

  MainRoutes.forEach((route) => {
    if (route.projectRequired && route.checkPermission) {
      dynamicRoutes.push(route);
    } else {
      staticRoutes.push(route);
    }
  });

  FullscreenRoutes.forEach((route) => {
    if (route.projectRequired && route.checkPermission) {
      dynamicRoutes.push(route);
    } else {
      staticRoutes.push(route);
    }
  });


  return (
    <ThemeProviders>
        <Suspense fallback={<LazyFallbackFull />}>
          <React.Fragment>
            <Root id='approot'>
              <ToastContainer/>
              <BrowserRouter>
                <Router signedIn={signedIn} routes={staticRoutes} />
                <DynamicRouter signedIn={signedIn} routes={dynamicRoutes} />
                {/* <UnifiedRouter signedIn={signedIn} routes={[...staticRoutes,...dynamicRoutes]}/> */}
              </BrowserRouter>
            </Root>
          </React.Fragment>
        </Suspense>
    </ThemeProviders>
  );
}

export default ScreensRoot;


// import React, { Suspense } from 'react';
// import ThemeProviders from './themeProviders';
// import { StyledRoot as Root } from './style';
// import { ToastContainer } from 'components/UI';
// import { LazyFallbackFull } from 'components/UI/Layouts';
// import { history } from 'state/App/reducer';
// import UnifiedRouter from './UnifiedRouter';  // Importando el nuevo UnifiedRouter
// import { useSelector } from 'react-redux';
// import { FullscreenRoutes, MainRoutes } from "routes";
// import { BrowserRouter } from "react-router-dom";

// function ScreensRoot(props) {
//   const signedIn = useSelector((store) => store.user.auth.signedIn);
//   const allRoutes = [...MainRoutes, ...FullscreenRoutes];  // Combinando todas las rutas

//   return (
//     <ThemeProviders>
//       <Suspense fallback={<LazyFallbackFull />}>
//         <React.Fragment>
//           <Root id='approot'>
//             <ToastContainer />
//             <BrowserRouter>
//               <UnifiedRouter signedIn={signedIn} routes={allRoutes} />
//             </BrowserRouter>
//           </Root>
//         </React.Fragment>
//       </Suspense>
//     </ThemeProviders>
//   );
// }

// export default ScreensRoot;
