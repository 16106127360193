import styled, { css, keyframes } from "styled-components";
import hexToRgba from "hex-to-rgba";
const borderWidth = 2;
const borderRadius = 4;

const animatedGradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const StyledBorder = styled.div`
  /* border: ${(props) =>
    props.valid && `1px solid ${props.theme.colors.border.default}`}; */
  width: 100%;
  /* background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82); */

  padding: ${borderWidth}px;
  border-radius: ${borderRadius + borderWidth}px;

  /* 
  ${(props) =>
    !props.focused &&
    `box-shadow: inset 0px 0px 0px 2px ${props.theme.colors.border.default};`}
   */

  background: ${(props) =>
    props.focused
      ? `linear-gradient(60deg, rgba(220,220,220,1), rgba(177, 181, 254,0.6), rgb(153, 206, 255,0.5),rgba(177, 181, 254,0.4), rgba(220, 220, 220,1))`
      : ""};

  ${(props) =>
    !props.valid &&
    `background: linear-gradient(60deg, ${hexToRgba(
      props.theme.colors.text.error,
      1
    )}, ${hexToRgba(props.theme.colors.text.error, 0.5)}, ${hexToRgba(
      props.theme.colors.text.error,
      0.2
    )},${hexToRgba(props.theme.colors.text.error, 0.5)}, ${hexToRgba(
      props.theme.colors.text.error,
      1
    )})`};


    
  /* background: ${(props) =>
    props.focused
      ? `linear-gradient(60deg, rgba(220,220,200,1), rgba(134, 144, 228,0.6), rgba(255,255,255,1),rgba(134, 144, 228,0.4), rgba(220, 220, 220,0.4))`
      : ""}; */
  animation: ${(props) =>
    props.focused
      ? css`
          ${animatedGradient} 6s infinite alternate
        `
      : ""};

  transition: background-color 0.3s ease-in-out;
  background-size: 300% 300%;
`;

export const StyledTextField = styled.input`
  height: 100%;
  width: 95%;
  -webkit-appearance: none;
  /* border-bottom: ${(props) =>
    props.valid && `1px solid ${props.theme.colors.border.default}`}; */

  border-bottom: 2px solid white;
  /* border-bottom: 1.5px solid ${(props) => props.theme.colors.border.default}; */
  flex-grow: 1;
  font-size: ${(props) => props.theme.fonts.title.s.size};
  padding: 14px 10px;

  border-radius: ${(props) => props.button ? `${borderRadius}px 0px 0px ${borderRadius}px` : `${borderRadius}px`};

  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: ${(props) => props.theme.colors.input.default};
  /* background-color: transparent; */
  &:focus-within {
    ${props=> !props.readOnly && 
    `background-color: ${props.theme.colors.input.focused};
    border-bottom: 2px solid transparent;`}

  }

  &::placeholder {
    color: ${(props) => props.theme.colors.text.light};
  }
`;

export const StyledTextFieldContainer = styled.div`
  height: 36px;
  -webkit-appearance: none;
  width: 100%;
  display: flex;
  background-color: ${(props) => props.theme.colors.bg.default};
  border-radius: ${borderRadius}px;
`;
