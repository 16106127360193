import styled from "styled-components";
import BaseLottie from "react-lottie";
import { keyframes } from "styled-components";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;



export const Container = styled.div`
  overflow: hidden;
  /* border-radius: 100px; */
  border-radius: 50%;
  animation-name: ${rotate};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LottieWrapper = styled.div`
position: relative;

border-radius: 50%;
width: 100%;
height: 100%;

`;


export const Lottie = styled(BaseLottie).attrs({
})``;