//LIBRARIES
import React, { useState } from 'react';

import { InputBase } from 'components/UI';

function validateText(text) {
  return ((text.length >= 1) ? true : false)
}

function Text(props) {
  const { valid, setInput, input, label, ...other } = props;
  const [state, setState] = useState(
    {
      bottomLabel: '',
    });

  function handleOnChange(event) {
    let validText = validateText(event.target.value);
    setInput({ value: event.target.value, valid: validText });

    // if (validText !== valid)
      if (!validText)
        setState({ ...state, bottomLabel: "* This field must not be empty" })
      else
        setState({ ...state, bottomLabel: "" })
  }

  return (
    <InputBase type={"text"} valid={valid} value={input} label={label} bottomLabel={state.bottomLabel} handleOnChange={handleOnChange} {...other} />
  );
}

export default Text;

// "You will have to verify that you are the owner of this text account":
// "* Please enter a valid text."