import * as Types from "./types";
import { api } from 'state/api';


function categoryMediasRequest(){
    return {
        type:Types.CATEGORY_MEDIAS_REQUEST
    }
}

function categoryMediasSuccess(categoryMedias){

    return {
        type:Types.CATEGORY_MEDIAS_SUCCESS,
        payload: {
            categoryMedias
        }
    }
}

function categoryMediasFailure(error){
    return {
        type:Types.CATEGORY_MEDIAS_FAILURE,
        payload: {
            error:error
        }
    }
}

export function fetchCategoryMedias(datasetPathName, categoryId){
    return (dispatch) => {
        dispatch(categoryMediasRequest())
        api()
            .get(`/store/deeplearning/datasets/${datasetPathName}/${categoryId}/medias/`)
            .then(response => {
                const categoryMedias = response.data
                dispatch(categoryMediasSuccess(categoryMedias))
            })
            .catch(error => {
                dispatch(categoryMediasFailure(error.message))
            })
    }
}