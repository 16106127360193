//LIBRARIES
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { signUp } from '../state/actions';
import Form from './Form';
import { useNavigate } from "react-router-dom";
import { UserSignUpForm } from './user-sign-up-form';

function SignUp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(store => store.user.auth.loading);
  const authErrors = useSelector(store => store.user.auth.error);
  
  function onSignUpClick(email, username, firstName, lastName, password1, password2, captchaToken) {
    dispatch(signUp(email, username, firstName, lastName, password1, password2, captchaToken, () => props.isCopilot? window.location.href = 'https://www.theoscopilot.com/es-es/blog/get-started' : navigate('/authentication/signup/success'), props.referrer, props.isCopilot));
  }
 
  return (
    //<Form onSignUpClick={onSignUpClick} loading={loading}/>
    <UserSignUpForm onSignUpClick={onSignUpClick} authErrors={authErrors} isLoading={loading}/>
  );
}
 
export default SignUp;