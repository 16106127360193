
//import { styled } from '@mui/styles';
import styled from "styled-components";
const spread = 4;
const blurLength = 10;

export const StyledSunkDiv = styled.div`
  && {
    color: ${props => (props.theme.colors.text.default)};
    background-color: ${props => (props.theme.colors.bg.default)};
    box-shadow: inset -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                inset ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
                
  }
`;

export const StyledSunkSmoothedDiv = styled.div`
  && {
    color: ${props => (props.theme.colors.text.default)};
    background-color: ${props => (props.theme.colors.bg.default)};
    box-shadow: inset -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                inset ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                 -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}; 
  }
`;


export const StyledDiv = styled.div`
  && {
    color: ${props => (props.theme.colors.text.default)};
    background-color: ${props => (props.theme.colors.bg.default)};
    box-shadow: -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};

  }

`;

export const StyledSmoothedDiv = styled.div`
  && {
    color: ${props => (props.theme.colors.text.default)};
    background-color: ${props => (props.theme.colors.bg.default)};
    box-shadow: -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${spread}px ${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}; 
  }

`;



export const StyledSunkDivClasses = styled.div`
  && {
    color:${props => (props.theme.colors.text.classes)};
    background-color: ${props => (props.theme.colors.div.secondary.classes.bg)} ;
    box-shadow: inset -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.div.secondary.classes.light)},
                inset ${spread}px ${spread}px  ${blurLength}px 0  ${props => (props.theme.colors.div.secondary.classes.contrast)};
  }
`;


export const StyledSunkDivInput = styled.div`
  && {
    color:${props => (props.theme.colors.text.error)};
    background-color: ${props => (props.theme.colors.div.secondary.error.bg)} ;
    box-shadow: inset -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.div.secondary.error.light)},
                inset ${spread}px ${spread}px  ${blurLength}px 0  ${props => (props.theme.colors.div.secondary.error.contrast)};
  }
`;



export const StyledSunkDivOutput = styled.div`
  && {
    color:${props => (props.theme.colors.text.success)};
    background-color: ${props => (props.theme.colors.div.secondary.success.bg)} ;
    box-shadow: inset -${spread}px -${spread}px  ${blurLength}px 0 ${props => (props.theme.colors.div.secondary.success.light)},
                inset ${spread}px ${spread}px  ${blurLength}px 0  ${props => (props.theme.colors.div.secondary.success.contrast)};
  }
`;


