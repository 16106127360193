import * as Types from "./types";
import { api } from 'state/api';
import { displayError, track } from 'utils';

function machinesListRequest(){
    return {
        type:Types.MACHINES_IN_PROJECT_REQUEST
    }
}

function machinesListRequestSuccess(machines){
    return {
        type:Types.MACHINES_IN_PROJECT_REQUEST_SUCCESS,
        payload: {
            machines
        }
    }
}

function machinesListRequestFailure(error){
    return {
        type:Types.MACHINES_IN_PROJECT_REQUEST_FAILURE,
        payload: {
            error:error
        }
    }
}

export function fetchMachinesInProject(projectKey){
    return (dispatch) => {
        dispatch(machinesListRequest());
        api()
            .get(`/projects/${projectKey}/machines/all/`)
            .then(response => {
                const machines = response.data;
                dispatch(machinesListRequestSuccess(machines));
                track('Machines viewed');
            })
            .catch(error => {
                dispatch(machinesListRequestFailure(error.message));
                displayError(error);
            })
    }
}