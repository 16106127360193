import styled from "styled-components";
import { Typography } from "components/UI";
import IconBase from "icons";
import { Button, IconButton } from 'components/UI';


export const Container = styled.div`
        color: ${props=>(props.theme.colors.text.default)};
`;

export const ProjectButton = styled.button`
        color: ${props=>(props.theme.colors.text.default)};
        height: 60px;
        width: 100%;
        background-color: transparent;
        :hover {
                background-color: ${props=>(props.theme.colors.button.hover)};
        }
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 0px 16px;
`;

export const ProjectChooseButton = styled.button`
        height: 50px;
        width: 100%;
        background-color: transparent;
        :hover {
                background-color: ${props=>(props.theme.colors.button.hover)};
        }
        padding: 30px;
`;

export const ProjectName = styled(Typography).attrs((props) => ({
        size: "m"
}))`
overflow:hidden; 
white-space:nowrap; 
text-overflow: ellipsis;

@media (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
    display: none;
}

`;

export const Icon = styled(IconBase).attrs((props) => ({
        name: "angle-small-down",
        size: "24px"
}))`
flex-shrink: 0;
margin-left: 10px;
@media (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
        margin-left: 0px;
}

`;

export const CheckedIcon = styled(IconBase).attrs((props) => ({
        name: "solid circle-check",
        size: "20px",
        color: props.theme.colors.text.success,
}))`
border-radius: 20px;
box-shadow: 0px 0px 5px ${props => props.color};
`;

export const PlusIcon = styled(IconBase).attrs((props) => ({
        name: "plus-small",
        size: "30px",
        color: props.theme.colors.text.default
}))`
margin-right:26px;
`;

export const NewProjectButtonContainer = styled.div`
        display:flex;
        flex-direction: column;
        color: ${props=>(props.theme.colors.text.default)};
        height: 70px;
        align-items: center;
        justify-content: center;
`;

export const NewProjectButton = styled(Button).attrs((props) => ({
        color:"primary",
        variant:"contained",
        size:"small"
}))
`
width:100px;
`;

export const NewProjectPlus = styled(IconButton).attrs((props) => ({
        icon: "plus-small",
        size: "30px",
        color: props.theme.colors.text.default
}))`
`;
