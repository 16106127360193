import styled from "styled-components";

import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

export const Container = styled(Paper).attrs((props) => ({}))`
  color: ${(props) => props.theme.colors.text.default};
`;

export const StyledAccordion = styled(Accordion).attrs((props) => ({}))`
  && {
    .MuiAccordion-root {
      background-color: blue;
    }
  }
  .MuiAccordion-root {
    background-color: blue !important;
  }

  &.MuiPaper-root {
    background-color: ${(props) => props.theme.colors.bg.default};
    box-shadow: ${(props) => `-${props.theme.effects.neumo.spread / 2}px 
              -${props.theme.effects.neumo.spread}px
              ${props.theme.effects.neumo.blurLength}px 0
              ${props.theme.colors.boxShadow.neumo.light},
              ${props.theme.effects.neumo.spread}px
              ${props.theme.effects.neumo.spread}px
              ${props.theme.effects.neumo.blurLength}px 0
            ${props.theme.colors.boxShadow.neumo.contrast}`};
    border: solid 1px ${(props) => props.theme.colors.border.default};
    transition: box-shadow 300ms;

    :hover {
      box-shadow: ${(props) => `-${props.theme.effects.neumo.spread / 2}px 
              -${props.theme.effects.neumo.spread / 2}px
              ${props.theme.effects.neumo.blurLength}px 0
              ${props.theme.colors.boxShadow.neumo.light},
              ${props.theme.effects.neumo.spread / 2}px
              ${props.theme.effects.neumo.spread / 2}px
              ${props.theme.effects.neumo.blurLength}px 0
            ${props.theme.colors.boxShadow.neumo.contrast}`};
      transition: box-shadow 300ms;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary).attrs((props) => ({
}))`


`;

export const StyledAccordionDetails = styled(AccordionDetails).attrs((props) => ({}))`


`;
