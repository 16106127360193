import { combineReducers } from 'redux';
import TrainingSessionMainReducer from 'components/Project/Training/TrainingSession/state/reducer';
import TrainingSessionCreateReducer from 'components/Project/Training/TrainingSession/state/Create/reducer';
import TrainingSessionDeleteReducer from 'components/Project/Training/TrainingSession/state/Delete/reducer';
import TrainingSessionExperimentReducer from './Experiment/reducer';
import TrainingSessionExperimentCreateReducer from 'components/Project/Training/TrainingSession/NewExperiment/state/reducer';
import TrainingSessionExperimentAPIReducer from 'components/Project/Training/TrainingSession/ExperimentAPI/state/reducer';

const TrainingSessionReducer = combineReducers({
    main: TrainingSessionMainReducer,
    create: TrainingSessionCreateReducer,
    delete: TrainingSessionDeleteReducer,
    experiment: TrainingSessionExperimentReducer,
    experimentAPI: TrainingSessionExperimentAPIReducer,
    newExperiment: TrainingSessionExperimentCreateReducer
});

export default TrainingSessionReducer;