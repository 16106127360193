import React, { Suspense } from "react";
import { LazyFallbackFull } from "components/UI/Layouts";
import {
  StyledMain as Main,
} from "./style";

function FullscreenLayout(props) {
  const { children } = props;

  return (
      <div style={{width:'100wh', height:'100vh'}}>
        <Suspense fallback={<LazyFallbackFull />}>
          {children}
        </Suspense>
      </div>
  );
}

export default FullscreenLayout;