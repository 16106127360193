import * as Types from "./types";


function websocketConnect(){
    return {
        type:Types.WEBSOCKET_CONNECT
    }
}

function websocketReconnect(){
    return {
        type:Types.WEBSOCKET_RECONNECT
    }
}

function websocketConnectSuccess(){
    return {
        type:Types.WEBSOCKET_CONNECT_SUCCESS
    }
}

function websocketError(error){
    return {
        type:Types.WEBSOCKET_ERROR,
        payload: {
            error
        }
    }
}

function websocketClosed(){
    return {
        type:Types.WEBSOCKET_CLOSED
    }
}

function websocketDisconnect(){
    return {
        type:Types.WEBSOCKET_DISCONNECT
    }
}


export function connect(){
    return (dispatch) => {
        console.log('WEBSOCKET CONNECTING...');
        dispatch(websocketConnect());
    }
}

export function disconnect(){
    return (dispatch) => {
        console.log('WEBSOCKET DISCONNECTING...');
        dispatch(websocketDisconnect());
    }
}

export function reconnect(){
    return (dispatch) => {
        console.log('WEBSOCKET RECONNECTING...');
        dispatch(websocketReconnect());
    }
}

export function connected(){
    return (dispatch) => {
        console.log('WEBSOCKET CONNECTED');
        dispatch(websocketConnectSuccess());
    }
}

export function error(error){
    return (dispatch) => {
        console.log('WEBSOCKET ERROR:', error);
        dispatch(websocketError(error));
    }
}

export function closed(){
    return (dispatch) => {
        console.log('WEBSOCKET CLOSED');
        dispatch(websocketClosed());
    }
}