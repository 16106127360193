import * as Types from "./types";
import update from "immutability-helper";

const initialState = {
    loaded:false,
    loading:false,
    payload:{},
    error:''
}


function MainProjectReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_INIT:
            return initialState
        
        case Types.PROJECT_REQUEST:
            return {
                loaded:false,
                loading:true,
                payload:{},
                error:''
            }
        
        case Types.PROJECT_REQUEST_SUCCESS:
            return {
                loaded:true,
                loading:false,
                payload:action.payload.project,
                error:''
            }

        case Types.PROJECT_REQUEST_FAILURE:
            return {
                loaded:true,
                loading:false,
                payload:{},
                error:action.payload.error
            }
        
        case Types.PROJECT_PLAN_SET:
            return update(state, {
                payload: {
                    plan_path_name: { $set: action.payload.plan_path_name}
                },
            });
        
        case Types.PROJECT_ROLE_SET:
            return update(state, {
                payload: {
                    role: { $set: action.payload.role}
                },
            });

        default: return state
    }
}

export default MainProjectReducer;