import * as Types from "./types";


const initialState = {
    loaded:false,
    payload:{},
    dataset: null,
    error:''
}


function SubfieldReducer(state=initialState, action){
    switch(action.type){
        case Types.SUBFIELD_INIT:
            return initialState;

        case Types.SUBFIELD_REQUEST:
            return {
                ...state,
                loaded:false
            }

        case Types.SUBFIELD_SUCCESS:
            return {
                loaded:true,
                payload:action.payload.subfield,
                dataset: state.dataset ? state.dataset : action.payload.subfield.datasets[0] ,
                error:''
            }
            
        case Types.SUBFIELD_FAILURE:
            return {
                loaded:false,
                payload:{},
                error:action.payload.error
            }

        default: return state
    }
}

export default SubfieldReducer