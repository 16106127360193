import styled from "styled-components";

import IconBase from "icons";

import {
  Select as BaseSelect,
  SelectItem as BaseSelectItem,
  Div,
  Typography,
  ScrollableDiv,

} from "components/UI";



export const Container = styled(ScrollableDiv)`
  overflow-y: scroll;
  padding: 8px  12px  16px  30px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography).attrs((props) => ({
  size: "s",
}))``;


export const Subtitle = styled(Typography).attrs((props) => ({
  size: "xs"
}))`
  white-space: nowrap;
  color: ${(props) => props.theme.colors.text.medium};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  text-transform: uppercase;
  margin-bottom: 16px;
`;


export const Select = styled(BaseSelect)`
  height: 46px;
  width: 100%;
  padding: 0;
  .option-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .check {
      display: none;
    }

    .payment-source-option {
      height: 40px;
      display: flex;
      align-items: center;

      .icon {
        height: 55%;
        width: auto;
        margin-right: 8px;
      }
    }
  }
`;

export const SelectItem = styled(BaseSelectItem)`
  height: 40px;

  .option-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .payment-source-option {
      display: flex;
      align-items: center;
      height: 100%;

      .icon {
        height: 75%;
        width: auto;
        margin-right: 16px;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
display: flex;
flex: 1 1 auto;

.label {
  padding-left: 8px;
  flex: 1 1 auto;
}
`;

export const Anchor = styled.a`

    color: ${(props) => props.theme.colors.text.darkPurple};
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  
`;

export const Section = styled.div`
 margin-bottom: 16px;
 width: 100%;
`;


export const Invoice = styled(Div)`
    border-radius: 12px;
    padding: 16px;

    .header {
      margin-bottom: 8px;
    }

    .row-base {
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: start;
      align-items: flex-start;
      display: flex;

      font-weight: ${(props) => props.theme.fonts.weight.bold};
      color: ${(props) => props.theme.colors.text.medium};
      font-size: ${(props) => props.theme.fonts.title.s.size};
      margin-bottom: 8px;
      /* .row-label {

      }
      .row-amount {

      } */
    }

    .total-row {
      margin-top: 16px;

    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

      .total-label{
        font-weight: ${(props) => props.theme.fonts.weight.regular};
        span {
          color: ${(props) => props.theme.colors.text.medium};
          font-weight: ${(props) => props.theme.fonts.weight.medium};
        }
      }
      .total-amount {
        font-weight: ${(props) => props.theme.fonts.weight.regular};
      }
    }

    .fine-print {
      font-size: ${(props) => props.theme.fonts.title.xsi.size};
      font-weight: ${(props) => props.theme.fonts.weight.thin};
      margin-top: 16px;
    }
  }
`;

export const Icon = styled(IconBase).attrs((props) => ({
  size: "20px",
}))`
  /* filter: ${(props) =>
    props.valid
      ? `drop-shadow(0px 0px 3px ${props.theme.colors.text.success})`
      : `drop-shadow(0px 0px 3px ${props.theme.colors.text.error})`}; */
  color: ${(props) => props.theme.colors.text.success};
  filter: drop-shadow(-2px -2px 1px white)
    drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.1));

  margin-right: 16px;
`;
