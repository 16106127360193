import * as Types from "./types";


const initialState = {
    created:false,
    loading:false,
    error:''
}


function TrainingSessionExperimentCreateReducer(state=initialState, action){
    switch(action.type){
        case Types.TRAINING_SESSION_EXPERIMENT_CREATE:
            return {
                created:false,
                loading:true,
                error:''
            }
        
        case Types.TRAINING_SESSION_EXPERIMENT_CREATE_SUCCESS:
            return {
                created:true,
                loading:false,
                error:''
            }

        case Types.TRAINING_SESSION_EXPERIMENT_CREATE_FAILURE:
            return {
                created:false,
                loading:false,
                error:action.payload.error
            }
        default: return state
    }
}

export default TrainingSessionExperimentCreateReducer;