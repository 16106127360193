//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import * as S from "./style";

function Checkbox(props) {
  const {field, ...others } = props;

  return (
    <S.Checkbox
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<S.IconContainer className="base">
        <S.Icon/>
      </S.IconContainer>}
      icon={<S.IconContainer className="base"/>}
      {...field}
      {...others }
    />
  );
}

export default Checkbox;