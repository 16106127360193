import React, { useState, useEffect } from "react";
import * as S from "./style"
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Loader } from "components/UI";
import { api } from 'state/api';
import { sleep } from 'utils';

const stripeAccounts = {
  'theos-artificial-intelligence-inc': loadStripe(process.env.REACT_APP_NEW_STRIPE_PUBLISHABLE_KEY),
  'theos-ai': loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
}

function PaymentMethod(props) {
  const {stripeAccountName, projectName, planPathName, planPeriod, setPrimaryButtonDisabled, onSuccess, setConfirmPaymentFunction, onError, ...others } = props;
  const [clientSecret, setClientSecret] = useState("");
  const [stripePromise, setStripePromise] = useState();
  const [ready, setReady] = useState(false);
  const [newPlanId, setNewPlanId] = useState();

  useEffect(() => {
    console.log('stripeAccountName:', stripeAccountName);
    setStripePromise(stripeAccounts[stripeAccountName]);
  }, [stripeAccountName]);

  useEffect(() => {
    api()
    .post(`/projects/create/payment/intent/`, {name:projectName, plan:planPathName, plan_period:planPeriod})
    .then((response) => {
      const data = response.data;
      setNewPlanId(data.plan_id);
      setClientSecret(data.payment_intent_client_secret);
    })
    .catch((error) => {
      console.log('error:', error);
    })
  }, []);

  const options = {
    clientSecret
  };

  async function onPaymentSuccess(){
    let active = false;
    while(!active){
      try{
        const response = await api().get(`/projects/plans/${newPlanId}/status/`);
        if(response.data.status === 'ACTIVE'){
          active = true;
          onSuccess();
        }else{
          await sleep(1000);
        }
      }catch(error){
        console.log(error.message);
        await sleep(1000);
      }
    }
  }

  return (
    <S.Container>
      <S.Section>
      {!ready && <S.Loader/>}
        {clientSecret && stripePromise && (
          <S.Elements options={options} stripe={stripePromise}>
            <CheckoutForm setReady={setReady}  setPrimaryButtonDisabled={setPrimaryButtonDisabled} onSuccess={onPaymentSuccess} setConfirmPaymentFunction={setConfirmPaymentFunction} onError={onError}/>
          </S.Elements>
        )}
      </S.Section>
    </S.Container>
  );
}

export default PaymentMethod;