//LIBRARIES
import React, {useState} from 'react';
import styled from "styled-components";
import {InputBase} from 'components/UI';
import { IconButton as IconButtonBase } from "components/UI";

export const IconButton = styled(IconButtonBase)`
height: 100%;
width: 40px;
color: ${(props) => props.theme.colors.text.light};
border-radius: 0px 4px 4px 0px;
background-color:  ${(props) => !props.focused ?  `rgba(0, 0, 0, 0.025)`:`rgba(255, 255, 255, 0.5)` };
border-bottom:  ${(props) => !props.focused ?  `2px solid white` : `2px solid transparent`};
transition: background-color 0.3s ease-in-out;

:hover {
  background-color: ${props=>(!props.disabled && !props.focused ? "rgba(0,0,0,0.05)": "rgba(0,0,0,0.01)")}; 
}
`;

function validatePassword(passwordToConfirm, password) {

  return ((passwordToConfirm === password));
}

function ConfirmPassword(props) {
  const {valid, setInput,input, password, label, ...other } = props;
  const [state, setState] = useState(
    {
      bottomLabel:'',
    });

  const [visible, setVisible] = useState(false);

  function changeVisibility(){
    setVisible(!visible);
  }

  let buttonComponent = <IconButton onClick={changeVisibility} icon={!visible ? "eye" : "eye-slash"} />

  function handleOnChange(event){
      let validPassword = validatePassword(event.target.value, password);
      setInput({value:event.target.value,valid:validPassword});
      validPassword ? setState({...state, bottomLabel:""}) : setState({...state, bottomLabel:"*Passwords do not match. Please try again"})
  }

  return (
    <InputBase type={visible ? "text" : "password"} valid={valid}  value={input} label={label}  handleOnChange={handleOnChange} bottomLabel={state.bottomLabel} buttonComponent={buttonComponent}{...other}/>
  );
}

export default ConfirmPassword;

// "You will have to verify that you are the owner of this username account":
// "* Please enter a valid username."