import * as Types from "./types";


const initialState = {
    created:false,
    loading:false,
    payload:[],
    error:''
}


function TrainingSessionCreateReducer(state=initialState, action){
    switch(action.type){
        case Types.TRAINING_SESSION_CREATE:
            return {
                created:false,
                loading:true,
                payload:[],
                error:''
            }
        
        case Types.TRAINING_SESSION_CREATE_SUCCESS:
            return {
                created:true,
                loading:false,
                payload:action.payload.trainingSession,
                error:''
            }

        case Types.TRAINING_SESSION_CREATE_FAILURE:
            return {
                created:false,
                loading:false,
                payload:[],
                error:action.payload.error
            }
        default: return state
    }
}

export default TrainingSessionCreateReducer;