export const DATASET_UPLOAD_INIT = 'DATASET_UPLOAD_INIT';
export const DATASET_UPLOAD_LOADING_IMAGES = 'DATASET_UPLOAD_LOADING_IMAGES';
export const DATASET_UPLOAD_ADD_IMAGE = 'DATASET_UPLOAD_ADD_IMAGE';
export const DATASET_UPLOAD_IMAGE_SET = 'DATASET_UPLOAD_IMAGE_SET';
export const DATASET_UPLOAD_REMOVE_IMAGES = 'DATASET_UPLOAD_REMOVE_IMAGES';
export const DATASET_UPLOAD_IMAGES_LOADED = 'DATASET_UPLOAD_IMAGES_LOADED';
export const DATASET_UPLOAD_PAGE_SET = 'DATASET_UPLOAD_PAGE_SET';
export const DATASET_UPLOAD_IMAGE_EXAMPLE_UPLOAD = 'DATASET_UPLOAD_IMAGE_EXAMPLE_UPLOAD';
export const DATASET_UPLOAD_START = 'DATASET_UPLOAD_START';
export const DATASET_UPLOAD_SUCCESS = 'DATASET_UPLOAD_SUCCESS';
export const DATASET_UPLOAD_FAILURE = 'DATASET_UPLOAD_FAILURE';
export const DATASET_UPLOAD_PROGRESS_SET = 'DATASET_UPLOAD_PROGRESS_SET';
export const DATASET_UPLOAD_EXAMPLE_COUNT_SET = 'DATASET_UPLOAD_EXAMPLE_COUNT_SET';
export const DATASET_UPLOAD_EXAMPLES_UPLOADED_ADD = 'DATASET_UPLOAD_EXAMPLES_UPLOADED_ADD';