import styled, {keyframes} from "styled-components";
import { Skeleton } from 'components/UI'

const borderRadius = "20px";
// const tfunction = "cubic-bezier(0.19, 1, 0.22, 1)";
const tfunction = "cubic-bezier(0.19, 1, 0.22, 1)";
const ttime = "700ms";

export const StyledSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
`;


export const StyledTitle = styled.h2`
  font-size: 0.65rem;
  font-weight: ${props => (props.theme.fonts.weight.regular)};
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5em;
`;

export const StyledBlurriedImage = styled.div`
  position: absolute;
  background-image: url(${(props) => props.img});
  /* transform: scale(0.2); */

  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-transform: scale(0.85,0.8) translateY(15%) translate3d(0,0,0);
  transform: scale(0.85,0.8) translateY(15%);

  :not(:root:root){
    transform: scale(0.90,0.8) translateY(20%) translateZ(0);
  }

  filter: blur(15px);
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);

`;


export const StyledBackdropFilterImg = styled.div`


  @supports (-moz-appearance:none) {
   display: block;
  }

  position: absolute;
  background-image: url(${(props) => props.img});
  transform: scale(1.3);
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  clip-path: polygon(100% 100%, 100% 70%, 0% 70%, 0% 100%);

  transition: clip-path ${ttime} ${tfunction};
  overflow: hidden;

  filter: blur(10px);

  /* filter: blur(15px);
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px); */

/* filter: blur(10px); */

`;

const shine = keyframes`
  0% { background-position: 0;}
  60% { background-position: 180px;}
  100% { background-position: 250px;}
`;




export const StyledTopDiv = styled.div`
  width:100%;
  height: 25px;
  top:0;
  position: absolute;
  background-color:rgba(0,0,0,0.7);

  /* display: flex;
  justify-content: center;
  color:white;
  align-items: center;
  font-weight: ${props=> (props.theme.fonts.weight.bold)};
  font-size: ${props=> (props.theme.fonts.title.xs.size)};
  letter-spacing: 0.2em; */

  transition: transform ${ttime} ${tfunction};
`;


export const StyledTopDivText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) translateZ(0);
  background: linear-gradient(to right, #c9c9c9 0, white 10%, #c9c9c9 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${shine} 3s infinite linear;

  @media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    animation: none
  }

  /* animation: ${shine} 3s infinite linear; */
  display:block;
  font-weight: ${props=> (props.theme.fonts.weight.bold)};
  letter-spacing: 0.2em;
  font-size: 12px;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;

`;

export const StyledImage = styled.img`
  position: absolute;

  /* transform: scale(0.2); */
  top: auto; left: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  transition: transform ${ttime} ${tfunction};
`;


export const StyledDescription = styled.p`
  margin-top: 20px;
  font-weight: ${props => (props.theme.fonts.weight.regular)};
  /* font-size: 1.25rem; */
  font-size: 0.785rem;
  font-style: regular;
  color: white;
  opacity: 0;
  transition: opacity ${ttime} ${tfunction};
`;



// box-sizing: border-box;
// border: 1px solid grey;

export const StyledCard = styled.div`

-webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);

  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0,0,0);
  transform: translateZ(0);

  position: relative;

  display: flex;

  border-radius: ${borderRadius};
  height: 100%;
  width: 100%;
  align-items: flex-end;
  overflow: hidden;
  text-align: center;

  /* background-image: url(${(props) => props.img});
  background-size: cover; */
`;


export const StyledContent = styled.div`


-webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0,0,0);
  transform: translateZ(0);
  overflow:hidden;
   position:relative;
   padding: 14px;
   display: flex;
   flex-direction: column;
   width: 100%;
   align-items: center;
   background-color: rgba(0,0,0,0.2);
/* 
   -webkit-backdrop-filter: blur(5px);
   backdrop-filter: blur(5px); */
   border-radius: 0px 0px ${borderRadius} ${borderRadius};

   height: 100%;
   /* height: 25%; */
   -webkit-transform: translateY(75%) translateZ(0) translate3d(0,0,0);
   transform: translateY(75%)  translateZ(0) translate3d(0,0,0);

   /* transition: height ${ttime} ${tfunction}; */
   transition: transform ${ttime} ${tfunction};
`;



export const StyledContainer = styled.div`
position: relative;
height: 180px;
width: 100%;
cursor:${props => props.comingSoon ? "not-allowed":"pointer"};


&:hover {

      ${StyledContent} {
        border-radius: ${borderRadius} ;
        transform: translateY(0px);
        background-color: rgba(0,0,0,0.5);
        transition:
        transform ${ttime} ${tfunction},
      }

      ${StyledTopDiv} {
        transform: translateY(-30px) translateZ(0);
        transition: transform ${ttime} ${tfunction};
      }

      ${StyledTopDivText} {
        display:none;
      }

      ${StyledDescription} {
        opacity: 1;
        /* transition: opacity ${ttime} ${tfunction}; */
      }

      /* ${StyledImage} {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -webkit-transition: -webkit-transform ${ttime} ${tfunction};
        transition: transform ${ttime} ${tfunction};
      } */

      ${StyledBackdropFilterImg} {
        clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%);
        /* clip-path: polygon(100% 100%, 100% 0%, 0% 0%, 0% 100%); */
        
        transition: clip-path ${ttime} ${tfunction};
      }

      /* ${StyledBlurriedImage} {
        transform: scale(0.90,0.85) translateY(20%);
        -webkit-transform: scale(0.90,0.85) translateY(20%);
        -webkit-transition: -webkit-transform ${ttime} ${tfunction};
        transition: transform ${ttime} ${tfunction};

        :not(:root:root){
            transform: scale(0.90,0.85) translateY(20%) translateZ(0);
        }

      } */

  }
`;
