export default function formatNumber(value) {
    var suffixes = ["", "K", "M", "B", "T"];
    var rest = value;
    var trillion = Math.pow(1000, 5);
    var i = 0;
    if (value < 1000) {
      return value + "";
    } else if (value >= trillion) {
      return value / trillion + suffixes[4];
    } else {
      while (rest > 1 || i > 5) {
        rest = rest / 1000;
        i++;
      }
    }
    if (Math.pow(1000, i) === value) return rest + suffixes[i];
    else return Math.floor(rest * 1000) + suffixes[i - 1];
}


export function bytesToSize(bytes) {
  var sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0B';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return round(bytes / Math.pow(1024, i), 1) + sizes[i];
}


export function round(_float, _digits) {
  var rounded = Math.pow(10, _digits);
  return (Math.ceil(_float * rounded) / rounded).toFixed(_digits);
}
