import * as Types from "./types";


const initialState = {
    changed:false,
    loaded:false,
    loading:false,
    error:''
}


function ChangeProjectNameReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_NAME_CHANGE:
            return {
                changed:false,
                loaded:false,
                loading:true,
                error:''
            }

        case Types.PROJECT_NAME_CHANGE_SUCCESS:
            return {
                changed:true,
                loaded:true,
                loading:false,
                error:''
            }

        case Types.PROJECT_NAME_CHANGE_FAILURE:
            return {
                changed:false,
                loaded:false,
                loading:false,
                error:action.payload.error
            }
        default: return state
    }
}

export default ChangeProjectNameReducer;