import React from "react";
import { withStyles } from "@mui/styles";
import { Switch as BaseSwitch } from "@mui/material";
import styled from "styled-components";

const rootWidth = 45*0.90;
const rootHeight = 25*0.85;
const borderWidth = 1;
const thumbMargin = 1;
const borderRadius = rootHeight/2;
const thumbHeight = rootHeight-thumbMargin*1;
const ledLightWidth = thumbHeight/4;
const translateX = rootWidth-thumbHeight-thumbMargin*1.5;


export const LedBorder = styled.div`
  && {
    width: 7px;
    height: 7px;
    background: ${props => props.theme.colors.switch.led.border.linearGradient};
    border-radius: 50%;
    padding: 1px;
    margin-left:5px;
  }
`;

export const Led = styled.div`
  && {
    width: 5px;
    height: 5px;
    background-color: #f2f2f2;
    border-radius: 50%;
    box-shadow: inset  -0.5px -0.5px 3px 0 rgba(0,0,0,0.2);
    ${({active, ...props }) => active && `
      background-color: rgb(53, 222, 185);
      box-shadow: 0.6px 0.6px 2px 0 #70FF00, inset  -0.5px -0.5px 1px 0 rgba(0,0,0,0.1);
    `}
  }
`;

export const Border = styled.div`
  && {
    width: ${rootWidth+borderWidth*2}px;
    background: ${props => props.theme.colors.switch.border.linearGradient};
    padding: ${borderWidth}px;
    border-radius: ${borderRadius+borderWidth}px;
  }
`;


export const Grid = styled.div`
&& {
  display: flex;
  width:min-content;
  justify-items: center;
  align-items: center;
}
`;


export const Switch = styled(BaseSwitch).attrs({
  disableRipple: true,
})`
  && {
    &.MuiSwitch-root {
      width: ${rootWidth}px;
      height: ${rootHeight}px;
      padding: 0px;
    }

    .MuiSwitch-thumb {
      box-shadow: 2px 0px 5px 0 rgba(0, 0, 0, 0.2),
        inset 0px -1px 1px 0 rgba(0, 0, 0, 0.3);
      width: ${thumbHeight}px;
      height: ${thumbHeight}px;
    }

    .MuiSwitch-switchBase {
      padding: ${thumbMargin}px;
      color:${props=> props.theme.colors.bg.default};

      &.Mui-checked {
        transform: translateX(${translateX}px);

        .MuiSwitch-thumb {
          box-shadow: -2px 0px 5px 0 rgba(0, 0, 0, 0.2),
            inset 0px -1px 1px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }

    .MuiSwitch-track {
      border-radius: ${borderRadius}px;
      box-shadow: inset 2px 2px 5px 0 rgba(0, 0, 0, 0.1),
        inset -3px -3px 7px 0 rgba(0, 0, 0, 0.1);
      /* background-color: red; */
      opacity: 1;

      background-color:${props=> props.checked? 'rgb(0, 235, 184)':props.theme.colors.bg.default};
      /* background-color: blue; */
    }
  }
`;


export const IOSSwitch = withStyles(theme => ({
  root: {
    width: rootWidth,
    height: rootHeight,
    padding: 0,

  },
  switchBase: {
    color: theme.palette.primary.main,
    padding: thumbMargin,
    "&$checked": {
      transform: "translateX("+translateX+"px)",
      "& + $track": {
        opacity: 1,
        border: "none",
        backgroundColor: theme.palette.primary.main,
      }
    },
    "&$focusVisible $thumb": {
      border: "6px solid #fff"
    },

    "&$checked $thumb": {
        boxShadow: '-2px 0px 5px 0 rgba(0,0,0,0.2), inset  0px -1px 1px 0 rgba(0,0,0,0.3)',
        backgroundColor: theme.palette.primary.main,
      },
  },
  thumb: {
    //boxShadow: '-2px 0px 2px 0 rgba(0,0,0,0.2), inset  0px -1px 1px 0 #000000',
    boxShadow: '2px 0px 5px 0 rgba(0,0,0,0.2), inset  0px -1px 1px 0 rgba(0,0,0,0.3)',
    width: thumbHeight,
    height: thumbHeight,
  },
  track: {
    borderRadius: borderRadius,
    //border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'inset 2px 2px 5px 0 rgba(0,0,0,0.1), inset -3px -3px 7px 0 rgba(0,0,0,0.1)',
    opacity: 1,
    //transition: theme.transitions.create(["background-color", "border"])
  },
  checked: { },
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <BaseSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
});