
export const POSE_ESTIMATION = 'POSE_ESTIMATION';


export const TRANSFORM = 'TRANSFORM';


export const DRAW = 'DRAW';


export const CLASSIFICATION = 'CLASSIFICATION';


export const SKELETON = 'SKELETON';

export const ADD_KEYPOINT = 'ADD_KEYPOINT';
export const ADD_LINE = 'ADD_LINE'
export const RENAME = 'RENAME';

export const SEGMENTATION_DRAW = 'SEGMENTATION_DRAW';

export const BRUSH = 'BRUSH';
export const SUPERPIXEL = 'SUPERPIXEL';
export const POLYGON = 'POLYGON';

export const VISION_LLM = 'VISION_LLM';