//LIBRARIES
import React, { useState } from "react";
import { sendPasswordRecoveryEmail } from "./state/actions";
import { initPasswordReset } from "./PasswordReset/state/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledContainer as Container,
  StyledInput as Input,
  StyledLoader as Loader,
  StyledText as Text,
  StyledPrimaryButton as PrimaryButton,
} from "./style";
import { Typography } from "components/UI";


export default function ForgotPassword(props) {
  const { toggleForgotPassword } = props;
  const dispatch = useDispatch();
  const recovery = useSelector((store) => store.user.forgotPassword);
  const [mailDispatched, setMailDispatched] = useState(false);
  const [inputs, setInputs] = useState({
    email: { value: "", valid: null },
  });

  function onForgotPasswordClick() {
    dispatch(sendPasswordRecoveryEmail(inputs["email"].value));
    dispatch(initPasswordReset());
    setMailDispatched(true);
  }

  const handleFieldChange = (fieldId, input) => {
    setInputs({ ...inputs, [fieldId]: input });
  };

  return (
    <Container>
      {!recovery.loading ? (
        <>
          <Typography
            style={{ alignSelf: "flex-start" }}
            weight={"bold"}
            size={"l"}
          >
            {mailDispatched ? "Check your email" : "Account Recovery"}
          </Typography>
          {mailDispatched ? (
            <Text>
              Click the password reset link we have sent to your email address. Be sure to check any spam and promotional folders, just in case.
            </Text>
          ) : (
            <Text>Please enter the email you used to create your account</Text>
          )}
          {!mailDispatched && (
            <Input
              type="email"
              label={"Email"}
              id={"email"}
              onChange={handleFieldChange}
              input={inputs["email"]}
              focus={true}
            />
          )}
          {mailDispatched ? (
            <PrimaryButton onClick={toggleForgotPassword}>Done</PrimaryButton>
          ) : (
            <PrimaryButton
              disabled={!inputs["email"].valid}
              onClick={onForgotPasswordClick}
            >
              Recover
            </PrimaryButton>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
}
