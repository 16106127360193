import * as Types from './types';


const initialState = {
    loading:false,
    loaded:false,
    payload:{},
    messages:[],
    error:''
}

function ProfileReducer(state=initialState, action){
    switch(action.type){
        case Types.PROFILE_INIT:
            return initialState

        case Types.PROFILE_REQUEST:
            return {
                ...state,
                edited:false,
                loading:true,
                loaded:false,
                messages:[],
                error:''
            }
        
        case Types.PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                edited:false,
                loading:false,
                loaded:true,
                payload:action.payload.profile,
                messages:[],
                error:''
            }
        
        case Types.PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                edited:false,
                loading:false,
                loaded:false,
                messages:[],
                error:action.payload.error
            }
        
        case Types.PROFILE_LOGOUT:
            return initialState

        default: return state
    }
}

export default ProfileReducer;