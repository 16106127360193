import * as Types from "./types";

const initialState = {
    loading:false,
    loaded:false,
    payload:[],
    error:''
}

function ProjectActionsCategoriesReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_ACTIONS_CATEGORIES_INIT:
            return initialState;

        case Types.PROJECT_ACTIONS_CATEGORIES_REQUEST:
            return {
                loading:true,
                loaded:false,
                payload:[],
                error:''
            }

        case Types.PROJECT_ACTIONS_CATEGORIES_REQUEST_SUCCESS:
            return {
                loading:false,
                loaded:true,
                payload:action.payload.actionsCategories,
                error:''
            }

        case Types.PROJECT_ACTIONS_CATEGORIES_REQUEST_FAILURE:
            return {
                loading:false,
                loaded:false,
                payload:[],
                error:action.payload.error
            }
        default: return state
    }
}

export default ProjectActionsCategoriesReducer;