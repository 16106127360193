import { useMediaQuery } from 'react-responsive'
import { screenSizes } from "styles/theme/devices/screenSizes.js";


export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: screenSizes.mobile.max})
  return isMobile ? children : null
}

// export const SmallScreen = ({ children }) => {
//   const isSmallScreen = useMediaQuery({ maxWidth: screenSizes.desktop.max})
//   return isSmallScreen ? children : null
// }

export const useSmallScreenMediaQuery = () =>
  useMediaQuery({ maxWidth: screenSizes.desktop.max })

  export const useTabletMediaQuery = () =>
  useMediaQuery({ maxWidth: screenSizes.tablet.max })

export const SmallScreen = ({ children }) => {
  const isSmallScreen = useSmallScreenMediaQuery();
  return isSmallScreen ? children : null
}


export const BigScreen = ({ children }) => {
  const isNotSmallScreen = useMediaQuery({ minWidth: screenSizes.desktop.max })
  return isNotSmallScreen ? children : null
}

export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: screenSizes.notMobile.min})
  return isNotMobile ? children : null
}