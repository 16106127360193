import { ContentWrapper, MainWrapper, Header, Container } from "./style";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";

function FixedContainer(props) {
  const { children, title, withBreadcrumbs, ...other } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById("approot").scroll(0, 0)
  }, [pathname]);


  return (
    <Container>
      <MainWrapper>
        <Header>
          {withBreadcrumbs && <Breadcrumbs />}
        </Header>
        <ContentWrapper {...other}>
          {children}
        </ContentWrapper>
      </MainWrapper>
    </Container>
  );
}

export default FixedContainer;