import styled from "styled-components";

export const StyledTitle = styled.div`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  font-size: ${(props) => props.theme.fonts.title.m.size};
  color: ${(props) => props.theme.colors.text.default};
  text-shadow: 0px 3px 6px #2f82bd;
  color: white;
`;

export const StyledContainer = styled.div`

`;

export const StyledTextPoints = styled.text`

`;

export const StyledProgressRadial = styled.circle`
            stroke: #6973ff;
            stroke-dasharray: 10 20;
            stroke-linecap: round;
            transition: stroke-dashoffset 2.5s;
            -webkit-transition: stroke-dashoffset 2.5s;
            transform: rotate(-90deg);
            transform-origin: 50% 50%;
         
`;


export const StyledBackgroundCircle = styled.circle.attrs((props) => ({
// fill:"#F7F7F7",
fill:"transparent",
}))`
 
`;


export const StyledProgressContainer = styled.div`

`;