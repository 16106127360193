import { combineReducers } from 'redux'
import SubfieldReducerMain from './Main/reducer'
import SubfieldLeaderboardReducer from './Leaderboard/reducer'


const SubfieldReducer = combineReducers({
    main:SubfieldReducerMain, 
    leaderboard:SubfieldLeaderboardReducer
})


export default SubfieldReducer
