export default {
  "code[class*=\"language-\"]": {
      "color": "#484E75",
      //"textShadow": "0 1px rgba(0, 0, 0, 0.3)",
      //"fontFamily": "Consolas, Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
      "fontFamily": "Roboto Mono, monospace",
      "direction": "ltr",
      "textAlign": "left",
      "whiteSpace": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "lineHeight": "1.5",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none"
  },
  "pre[class*=\"language-\"]": {
      "color": "#484E75",
      //"textShadow": "0 1px rgba(0, 0, 0, 0.3)",
      //"fontFamily": "Consolas, Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace",
      "fontFamily": "Roboto Mono, monospace",
      "direction": "ltr",
      "textAlign": "left",
      "whiteSpace": "pre",
      "wordSpacing": "normal",
      "wordBreak": "normal",
      "lineHeight": "1.5",
      "MozTabSize": "4",
      "OTabSize": "4",
      "tabSize": "4",
      "WebkitHyphens": "none",
      "MozHyphens": "none",
      "msHyphens": "none",
      "hyphens": "none",
      "padding": "1em",
      //"margin": ".5em 0",
      "overflow": "auto",
      //"borderRadius": "0.3em",
      "background": "#F2EFFE"
  },
  ":not(pre) > code[class*=\"language-\"]": {
      "background": "#1d1f21",
      "padding": "1em",
      "borderRadius": ".3em"
  },
  "comment": {
      "color": "#7C7C7C"
  },
  "prolog": {
      "color": "#7C7C7C"
  },
  "doctype": {
      "color": "#7C7C7C"
  },
  "cdata": {
      "color": "#7C7C7C"
  },
  "punctuation": {
      "color": "#484E75"
  },
  ".namespace": {
      "Opacity": ".7"
  },
  "property": {
      "color": "#96CBFE"
  },
  "keyword": {
      "color": "#6C48FF",
      "fontWeight": "bold"
  },
  "tag": {
      "color": "#96CBFE"
  },
  "class-name": {
      "color": "#FFFFB6",
      "textDecoration": "underline"
  },
  "boolean": {
      "color": "#99CC99"
  },
  "constant": {
      "color": "#99CC99"
  },
  "symbol": {
      "color": "#484E75"
  },
  "deleted": {
      "color": "#f92672"
  },
  "number": {
      //"color": "#ff73ce",
      //"fontWeight": "bold"
  },
  "selector": {
      "color": "#A8FF60"
  },
  "attr-name": {
      "color": "#A8FF60"
  },
  "string": {
      "color": "#6944fc",
      "fontWeight": "regular"
  },
  "char": {
      "color": "#A8FF60"
  },
  "builtin": {
      "color": "#484E75"
  },
  "inserted": {
      "color": "#A8FF60"
  },
  "variable": {
      "color": "#C6C5FE"
  },
  "operator": {
      "color": "#484E75"
  },
  "entity": {
      "color": "#FFFFB6",
      "cursor": "help"
  },
  "url": {
      "color": "#96CBFE"
  },
  ".language-css .token.string": {
      "color": "#87C38A"
  },
  ".style .token.string": {
      "color": "#87C38A"
  },
  "atrule": {
      "color": "#F9EE98"
  },
  "attr-value": {
      "color": "#F9EE98"
  },
  "function": {
      "color": "#6C48FF",
      "fontWeight": "bold"
  },
  "regex": {
      "color": "#E9C062"
  },
  "important": {
      "color": "#fd971f",
      "fontWeight": "bold"
  },
  "bold": {
      "fontWeight": "bold"
  },
  "italic": {
      "fontStyle": "italic"
  }
}