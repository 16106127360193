import SearchIcon from "@mui/icons-material/Search";
import styled from "styled-components";
import { Autocomplete } from "@mui/lab";
import { TextField, Grid } from "@mui/material";
import {Skeleton} from "components/UI";

const iconWidth = 24;
const iconMarginLeft = 12;
const borderWidth = 2.5;
const borderRadius = 25;

export const StyledListbox = styled.ul`
  color: ${props => (props.theme.colors.text.default)};
`;

export const StyledPaper = styled.div.attrs((props) => ({
  // elevation: 0,
}))`
  
    border-radius: 10px;
    background-color: ${props => (props.theme.colors.bg.default)};
    box-shadow: 
                    inset 2px 2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.light)}, 
                    inset -2px -2px 4px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)};
                    /* -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                    ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}; */
`;

export const StyledAutocomplete = styled(Autocomplete)`
 
`;

export const StyledTextField = styled(TextField)`

& .MuiOutlinedInput-root {
  
  color:  ${props => (props.theme.colors.text.default)};
  & fieldset{
      border: none;
  }
}
`;

export const StyledGridContainer = styled(Grid)`
  && {
   
    border-radius: ${borderRadius};
  }
`;

export const StyledGridItem = styled(Grid)`
  && {
    width: calc(100% - ${iconWidth}px - ${iconMarginLeft}px);
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`
  && {
    color: ${(props) => props.theme.colors.text.light};
    margin-left: ${iconMarginLeft}px;
    margin-top: ${iconMarginLeft/2}px;
  }
`;

// margin-top: 0px;
// margin-bottom: 0px;

export const StyledBorder = styled.div`
  && {
    border: solid 1px ${props=>(props.theme.colors.border.default)};
    width: 100%;
    background: ${(props) =>
      props.theme.colors.autocomplete.border.linearGradient};
    padding: ${borderWidth}px;
    border-radius: ${borderRadius + borderWidth}px;
    transition: box-shadow 0.2s ease-in-out;
    
    &:hover {
      box-shadow: -${(props) => props.theme.effects.neumo.spread / 4}px -${(
            props
          ) => props.theme.effects.neumo.spread / 4}px
          ${(props) => props.theme.effects.neumo.blurLength}px 0
          ${(props) => props.theme.colors.boxShadow.neumo.light},
        ${(props) => props.theme.effects.neumo.spread / 4}px
          ${(props) => props.theme.effects.neumo.spread / 4}px
          ${(props) => props.theme.effects.neumo.blurLength}px 0
          ${(props) => props.theme.colors.boxShadow.neumo.contrast};
    }
  }
`;

export const StyledDiv = styled.div`
  && {
    
    box-shadow: inset -${(props) => props.theme.effects.neumo.spread / 2}px -${(
          props
        ) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      inset ${(props) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};

    background-color: ${(props) => props.theme.colors.bg.default};
    border-radius: ${borderRadius}px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.bg.default};
  }
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:focus-within {
    box-shadow: -${(props) => props.theme.effects.neumo.spread / 2}px -${(
          props
        ) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      ${(props) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast},
      inset -${(props) => props.theme.effects.neumo.spread / 2}px -${(props) =>
          props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      inset ${(props) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.spread / 2}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};
    transform: scale(1.0005);
  }
`;

export const StyledSkeleton = styled(Skeleton)`
    height: 40px;
`;