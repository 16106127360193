
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AppReducer from '../App/reducer';
import * as Types from './types';


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

function RootReducer(state, action) {
  switch(action.type){
    case Types.STORAGE_CLEANING:
      storage.removeItem('persist:root')
      state = undefined;
  }
  return AppReducer(state, action);
};

export default persistReducer(persistConfig, RootReducer);