//LIBRARIES
import React, { useState } from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

function MessageDialog(props) {
  const {
    title,
    children,
    toggleFullscreen
  } = props;
  return (
    <S.Modal onClickOutside={toggleFullscreen}>
      <S.Title>{title}</S.Title>
      <S.Message>{children}</S.Message>
    </S.Modal>
  );
}

export default MessageDialog;
