//LIBRARIES
import React, {useState} from "react";

//STYLEDCOMPONENTS
import {
  StyledThumbnailContainer as ThumbnailContainer,
  StyledThumbnailContainerFlexbox as ThumbnailContainerFlexbox,
  StyledThumbnailImage as ThumbnailImage,
  StyledThumbnailImageBlurried as ThumbnailImageBlurried,
  StyledBlackContainer as BlackContainer,
  StyledThumbnailSkeleton as ThumbnailSkeleton,
  StyledOnClickDiv as OnClickDiv,
  StyledIcon as Icon,
} from "./style";

Thumbnail.defaultProps = {
  height: "8em",
  aspectRatio: 16/9,
}

export default function Thumbnail(props) {
  const {loading, img, video, id, index, onClick, height, width, aspectRatio, ...other } = props;

  const [insideLoading, setInsideLoading ] = useState(true);

  function onLoad(){
    setInsideLoading(false);
  }

  return (
    <ThumbnailContainer height={height}  width={width} aspectRatio={aspectRatio}>
      {!loading && 
      <ThumbnailContainerFlexbox {...other} onClick={onClick} id={id}> 
        <ThumbnailImageBlurried  onLoad={onLoad} src={img}/>

        {video!==undefined && 
        <BlackContainer>
           <Icon/>
        </BlackContainer>}
        {video===undefined && 
        <ThumbnailImage
          key={index}
          src={img}
        />} 

        {insideLoading && <ThumbnailSkeleton />}
        <OnClickDiv id={id}/>
      </ThumbnailContainerFlexbox>}

      {(loading) &&
      <ThumbnailContainerFlexbox> 
        <ThumbnailSkeleton />
      </ThumbnailContainerFlexbox>
      }
    </ThumbnailContainer>
  );
}
