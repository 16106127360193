//LIBRARIES
import React from 'react';

import {InputBase} from 'components/UI';

function validateUsername(username) {
  return ((username ? true : false))
}

function Username(props) {
  const {valid, setInput, label, input, ...other } = props;

  function handleOnChange(event){
      let validUsername = validateUsername(event.target.value);
      setInput({value:event.target.value,valid:validUsername});
  }
  

  return (
    <InputBase type={"text"} valid={true} label={label} value={input} handleOnChange={handleOnChange} {...other}/>
  );
}

export default Username;

// "You will have to verify that you are the owner of this username account":
// "* Please enter a valid username."