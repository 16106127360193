import * as Types from './types';
import { api } from 'state/api'
import { fetchProfile, logoutProfile, initProfile } from 'components/User/Account/Main/Profile/state/actions';
import { fetchProjects } from 'components/User/Projects/state/actions';
import { trackSignUp, trackLogin, trackLogout } from 'utils';


function authInit() {
    return {
        type: Types.AUTH_INIT
    }
}


function authRequest() {
    return {
        type: Types.AUTH_REQUEST
    }
}

function authSuccess(token) {
    return {
        type: Types.AUTH_SUCCESS,
        payload: {
            token
        }
    }
}

function authFailure(error) {
    return {
        type: Types.AUTH_FAILURE,
        payload: {
            error
        }
    }
}

function authLogout() {
    return {
        type: Types.LOGOUT
    }
}

export function initAuth() {
    return (dispatch) => {
        dispatch(authInit());
        dispatch(initProfile());
    }
}

export function login(username, password, onSuccess) {
    return (dispatch) => {
        username = username.toLowerCase();
        dispatch(authRequest());
        api()
            .post('/auth/login/', { username, password })
            .then(response => {
                const token = response.data.key;
                dispatch(authSuccess(token));
                dispatch(fetchProfile(token, false));
                dispatch(fetchProjects());
                onSuccess();
                trackLogin(username);
            })
            .catch(error => {
                if (typeof error?.response?.data === 'object') {
                    dispatch(authFailure(error.response.data));
                } else {
                    dispatch(authFailure({ 'errors': ['An error ocurred. Please try again.'] }));
                }
            })
    }
}

export function logout() {
    return (dispatch, getState) => {
        const username = getState().user.profile.payload.username;
        trackLogout(username);
        dispatch(authLogout());
        dispatch(logoutProfile());
    }
}

export function signUp(email, username, first_name, last_name, password1, password2, captcha_token, onSuccess, referrer, isCopilot) {
    return (dispatch) => {
        username = username.toLowerCase();
        email = email.toLowerCase();
        let data = { email, username, first_name, last_name, password1, password2, captcha_token };
        if (referrer) {
            data = { ...data, referred_by: referrer };
        }
        dispatch(authRequest());
        api()
            .post('/auth/register/', data)
            .then(response => {
                if (!isCopilot) {
                    const token = response.data.key;
                    trackSignUp(username, first_name, last_name, email);
                    dispatch(authSuccess(token));
                    dispatch(fetchProfile(token, false));
                    dispatch(fetchProjects());
                    onSuccess();
                    trackLogin(username);
                } else {
                    onSuccess();
                }
            })
            .catch(error => {
                if (typeof error?.response?.data === 'object') {
                    dispatch(authFailure(error.response.data));
                } else {
                    dispatch(authFailure({ 'errors': ['An error ocurred. Please try again.'] }));
                }
            })
    }
}