//LIBRARIES
import React from "react";

//STYLEDCOMPONENTS
import {
  StyledSkeletonPulse as SkeletonBase,
  StyledBorder as Border,
} from "./style";

import {SkeletonTable} from "./table"
import {SkeletonCode} from "./code"


function Skeleton(props) {
  const { children, variant, inverse, columns, rows, height, width, position, ...other} = props;
  switch (variant) {
    case "table":
      return <SkeletonTable inverse={inverse} columns={columns} rows={rows}/>;
    case "code":
        return <SkeletonCode/>;
    default:
      return (
        <Border height={height} width={width} position={position} {...other}>
          <SkeletonBase />
        </Border>
      );
  }
}

export default Skeleton;
