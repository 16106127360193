import * as Types from './types';


function storageCleaning(){
    return {
      type:Types.STORAGE_CLEANING
    }
}

export function clearStorage(){
  return (dispatch) => {
    dispatch(storageCleaning());
  }
}