import * as Types from "./types";


const initialState = {
    loaded:false,
    loading:false,
    payload:{
        public:[],
        private:[]
    },
    trainingMetrics:[],
    mainMetric:{},
    error:''
}


function AlgorithmInProjectWeightsReducer(state=initialState, action){
    switch(action.type){
        case Types.ALGORITHM_IN_PROJECT_WEIGHTS_REQUEST:
            return {
                ...state,
                loaded:false,
                loading:true,
                payload:{
                    public:[],
                    private:[]
                },
                trainingMetrics:[],
                mainMetric:{},
                error:''
            }
        
        case Types.ALGORITHM_IN_PROJECT_WEIGHTS_REQUEST_SUCCESS:
            return {
                ...state,
                loaded:true,
                loading:false,
                payload:action.payload.weights,
                trainingMetrics:action.payload.trainingMetrics,
                mainMetric:action.payload.mainMetric,
                error:''
            }

        case Types.ALGORITHM_IN_PROJECT_WEIGHTS_REQUEST_FAILURE:
            return {
                ...state,
                loaded:true,
                loading:false,
                payload:{
                    public:[],
                    private:[]
                },
                trainingMetrics:[],
                mainMetric:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default AlgorithmInProjectWeightsReducer;