
import styled , { keyframes } from "styled-components";


const rotation = keyframes`


  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


const SIZE = 36;
const STROKE = 4;

export const Loader = styled.div`

height:${props => (props.size)}px;
width:${props => (props.size)}px;

border-radius: -50%;
position: relative;

/* border-top: ${props => (props.width)}px solid rgba(128, 138, 157, 0.5);
border-left: ${props => (props.width)}px solid rgba(128, 138, 157, 0.5);
border-bottom: ${props => (props.width)}px solid transparent; */

border-bottom: 0px solid rgba(128, 138, 157, 0.5);
border-left: ${props => (props.width)}px solid rgba(128, 138, 157, 0.5);
border-top: ${props => (props.width)}px solid transparent; 



/* border-right: 4px solid transparent; */
opacity: 0.9;
border-radius: 50%;
/* box-shadow: -2px -3px 6px -2px rgba(128, 138, 157, 0.3), inset 5px 6px 6px -3px rgba(128, 138, 157, 0.3),   1px 1px 0px 1px rgba(255, 255, 255, 1); */
box-shadow: -2px 3px 6px -2px rgba(128, 138, 157, 0.3), inset 5px -6px 6px -3px rgba(128, 138, 157, 0.3), 1px -1px 0px 1px rgba(255, 255, 255, 1);
animation: ${rotation} .5s ease-in-out infinite;



`;

