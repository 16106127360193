import React from "react";
import Lottie from "react-lottie";
import * as o from "assets/lotties/blurred-orbs";
import * as osvg from "assets/svgs/complex/blurred-orbs";


//STYLEDCOMPONENTS
import * as S from "./style";



function Orb(props) {
  const { color, fixed, ...others } = props;

  const orbs = {
    red: fixed ? osvg.redOrb : o.redOrb,
    green: fixed ? osvg.greenOrb : o.greenOrb,
    gold: fixed ? osvg.goldOrb : o.goldOrb,
    black: fixed ? osvg.blackOrb : o.blackOrb,
    purple: fixed ? osvg.purpleOrb : o.purpleOrb,
    diamond: fixed ? osvg.diamondOrb : o.purpleOrb,
    "light-blue" : o.lightBlueOrb
  }

  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const blurredOrbsOptions = {
    loop: true,
    autoplay: true,
    animationData: orbs[color] ? orbs[color] : o.purpleOrb,
    rendererSettings: {
      preserveAspectRatio: "xMinYMid slice",
    },
    renderer: isSafari ? "canvas" : "svg",
  };


  console.log(orbs[color])

  return (
    <S.Container {...others}>
      <S.LottieWrapper >
         {!fixed && <Lottie
          //style={{transform: "scale(50%,-50%) translate(0, -100%)"}}
           style={{
             transform: "scale(160%,160%)",
             filter: isSafari ? "blur(10px)" : "none",
          }}
          isClickToPauseDisabled={true}
          options={blurredOrbsOptions}
          height={"100%"}
          speed={1}
          width={"auto"}
        />} 
          {fixed &&
          <S.Svg src={orbs[color] ? orbs[color] : o.purpleOrb} />
} 
         {/* <S.Img src={rocket} /> */}
      </S.LottieWrapper>
    </S.Container>

  );
}

export default Orb;
