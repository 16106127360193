import * as Types from "./types";

const initialState = {
    loading:false,
    loaded:false,
    payload:[],
    roles:[],
    error:''
}

function ProjectMembersReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_MEMBERS_INIT:
            return initialState;

        case Types.PROJECT_MEMBERS_REQUEST:
            return {
                loading:true,
                loaded:false,
                payload:[],
                roles:[],
                error:''
            }

        case Types.PROJECT_MEMBERS_REQUEST_SUCCESS:
            return {
                loading:false,
                loaded:true,
                payload:action.payload.members,
                roles:action.payload.roles,
                error:''
            }

        case Types.PROJECT_MEMBERS_REQUEST_FAILURE:
            return {
                loading:false,
                loaded:false,
                payload:[],
                roles:[],
                error:action.payload.error
            }
        default: return state
    }
}

export default ProjectMembersReducer;