export default function truncateMatrixColumns(matrix, columns) {
  if (matrix.length > 0)
    if (Array.isArray(matrix[0])) {
      if (matrix[0].length > columns) {
        let newMatrix = [];
        matrix.forEach((row) => {
          let newRow = [];
          for (var i = 0; i < columns; i++) {
            newRow = [...newRow, row[i]];
          }
          newMatrix = [...newMatrix, newRow];
        });
        return newMatrix;
      }
    } else if (matrix.length > columns) {
      let newRow = [];
      for (var i = 0; i < columns; i++) {
        newRow = [...newRow, matrix[i]];
      }
      return newRow;
    }
  return matrix;
}

// } else{
//   let newRow = [];
//   for (var i = 0; i < columns; i++) {
//     newRow = [...newRow, matrix[i]];
//   }
//   return newRow;