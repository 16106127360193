import * as Types from "./types";
import { api } from 'state/api';
import { displayError, track } from 'utils';


function projectCreate(){
    return {
        type:Types.PROJECT_CREATE
    }
}

function projectCreateSuccess(project){
    return {
        type:Types.PROJECT_CREATE_SUCCESS,
        payload: {
            project
        }
    }
}

function projectCreateFailure(error){
    return {
        type:Types.PROJECT_CREATE_FAILURE,
        payload: {
            error:error
        }
    }
}

export function createProject(name, description){
    return async (dispatch) => {
        dispatch(projectCreate());
        try{
            const response = await api().post(`/projects/create/`, description? {name, description}:{name})
            const project = response.data;
            dispatch(projectCreateSuccess(project));
            track('Project created', {'Name':name});
            return 'success';
        }catch(error){
            dispatch(projectCreateFailure(error.message));
            if(error.response){
                if(error.response.data.error !== 'limit'){
                    displayError(error);
                }else{
                    track('Project creation limit', {'Name':name});
                }
                return error.response.data.error;
            }else{
                return 'unkown'
            }
        }
    }
}