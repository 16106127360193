import styled from "styled-components";
import { Div, Thumbnail, Skeleton, ScrollableDiv } from "components/UI";


const borderRadius = 15;
const aspectRatio = 16 / 9;
const paddingBottomPercentage = Math.pow(aspectRatio, -1) * 100;
const paddingWithNeumo = 10;
const ttime = 200;
const tfunction = "ease-in";

export const StyledNeumoContainer = styled(Div).attrs((props) => ({
  variant: "sunk",
}))`
  && {
    /* border-radius: ${borderRadius + paddingWithNeumo}px; */
    border: solid 1px ${props=>(props.theme.colors.border.default)};
    border-radius: ${borderRadius}px;
    padding: ${paddingWithNeumo}px;
  }
`;
 
//THUMBNAIL
export const StyledThumbnailGallery = styled(ScrollableDiv)`
  && {
    margin-top: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative;
    white-space: nowrap;

  }
`;


export const StyledMediaContainer = styled.div`
  && {
    border-radius: ${borderRadius}px;
    position: relative;
    overflow: hidden;
    width: calc(100%);
    padding-bottom: ${paddingBottomPercentage}%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    box-shadow: 
      -${(props) => props.theme.effects.neumo.spread}px -${(props) =>
      props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.light},
      ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast};
       
  }
`;

export const StyledThumbnail = styled(Thumbnail)`
  &:hover {
 
   box-shadow: 0 0 5px 1px  ${(props) => props.theme.colors.colorful.primary},
    0 0 0 1pt ${(props) => props.theme.colors.colorful.primary};
    transition: box-shadow ${ttime}ms ${tfunction};
  }
`;

export const StyledGallerySkeleton = styled(Skeleton)`
    position: absolute;
    width: 100%;
    height: 100%;
`;

