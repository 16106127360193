import styled from "styled-components";
import { ScrollableDiv } from "components/UI";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

`;

export const SetupContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  /* flex: 3; */

 
flex-grow: 1;

   
    /* flex-basis: 0%; */

  /* min-width:315px; */
`;

export const ContentContainer = styled(ScrollableDiv)`
  overflow-y: scroll;
  height:100%;
`;


