import * as Types from "./types";
import update from 'immutability-helper';

const initialState = {
    loaded:false,
    loading:false,
    loadingFilesystem:false,
    filesystemLoaded:false,
    loadingLabels:false,
    labelsLoaded:false,
    labelsLoadProgress:0,
    loadingGallery:false,
    galleryLoaded:false,
    empty:false,
    galleryCurrentPage:1,
    galleryPageCount:1,
    addingClassName:false,
    classNameAdded:false,
    editingClassName:false,
    deletingClassNames:false,
    mergingClassNames:false,
    classNames:{},
    filesystem:{
        split:{
            imagesTotal:0,
            train:{
                imagesCount:0,
                labelsCount:0,
                percentage:0.7
            },
            valid:{
                imagesCount:0,
                labelsCount:0,
                percentage:0.2
            },
            test:{
                imagesCount:0,
                labelsCount:0,
                percentage:0.1
            }
        },
        image:{},
        txt:{},
        json:{},
        yaml:{},
        csv:{},
        darknetLabel:[],
        classNames:{},
        labelFilesCount:0
    },
    info:{},
    images:[],
    error:''
}


function DatasetMainReducer(state=initialState, action){
    switch(action.type){
        case Types.DATASET_INIT:
            return initialState;

        case Types.DATASET_REQUEST:
            return {
                ...initialState,
                loaded:false,
                loading:true,
                info:{},
                classNames:{},
                images:[],
                error:''
            }
        
        case Types.DATASET_REQUEST_SUCCESS:
            return {
                ...state,
                loaded:true,
                loading:false,
                info:action.payload.info,
                empty:action.payload.empty,
                classNames:action.payload.classNames,
                error:''
            }

        case Types.DATASET_REQUEST_FAILURE:
            return {
                ...state,
                loaded:true,
                loading:false,
                info:{},
                classNames:{},
                images:[],
                error:action.payload.error
            }

        case Types.DATASET_CLASSNAMES_ADD:
            return {
                ...state,
                addingClassName:true,
                classNameAdded:false,
                error:''
            }
        
        case Types.DATASET_CLASSNAMES_ADD_SUCCESS:
            return update(state, {
                addingClassName: {$set: false},
                classNameAdded: {$set: true},
                classNames: {
                    [action.payload.class_id]: {$set: action.payload.className}
                },
                info:{
                    class_count:{$apply: function(x) {return x+1;}}
                },
                error: {$set: ''}
            })

        case Types.DATASET_CLASSNAMES_ADD_FAILURE:
            return {
                ...state,
                addingClassName:false,
                classNameAdded:false,
                error:action.payload.error
            }

        case Types.DATASET_CLASSNAME_EDIT:
            return {
                ...state,
                editingClassName:true,
                error:''
            }
        
        case Types.DATASET_CLASSNAME_EDIT_SUCCESS:
            return update(state, {
                editingClassName: {$set: false},
                classNames: {
                    [action.payload.class_id]: {$set: action.payload.className}
                },
                error: {$set: ''}
            })

        case Types.DATASET_CLASSNAME_EDIT_FAILURE:
            return {
                ...state,
                editingClassName:false,
                error:action.payload.error
            }

        case Types.DATASET_CLASSNAMES_DELETE:
            return {
                ...state,
                deletingClassNames:true,
                error:''
            }
        
        case Types.DATASET_CLASSNAMES_DELETE_SUCCESS:
            return update(state, {
                deletingClassNames: {$set: false},
                info:{
                    status:{$set: action.payload.status},
                    status_message:{$set: action.payload.status_message},
                    task_id:{$set: action.payload.task_id},
                    task_progress:{$set:0}
                },
                error: {$set: ''}
            })

        case Types.DATASET_CLASSNAMES_DELETE_FAILURE:
            return {
                ...state,
                deletingClassNames:false,
                error:action.payload.error
            }

        case Types.DATASET_CLASSNAMES_MERGE:
            return {
                ...state,
                mergingClassNames:true,
                error:''
            }
        
        case Types.DATASET_CLASSNAMES_MERGE_SUCCESS:
            return update(state, {
                mergingClassNames: {$set: false},
                info:{
                    status:{$set: action.payload.status},
                    status_message:{$set: action.payload.status_message},
                    task_id:{$set: action.payload.task_id},
                    task_progress:{$set:0}
                },
                error: {$set: ''}
            })

        case Types.DATASET_CLASSNAMES_MERGE_FAILURE:
            return {
                ...state,
                mergingClassNames:false,
                error:action.payload.error
            }

        case Types.DATASET_FILESYSTEM_LOAD:
            return {
                ...state,
                loadingFilesystem:true,
                filesystemLoaded:false,
            }

        case Types.DATASET_FILESYSTEM_SET:
            return {
                ...state,
                loadingFilesystem:false,
                filesystemLoaded:true,
                filesystem:action.payload.filesystem
            }

        case Types.DATASET_FILESYSTEM_RESET:
            return {
                ...state,
                loadingFilesystem:false,
                filesystemLoaded:false,
                filesystem:initialState.filesystem
            }

        case Types.DATASET_FILESYSTEM_IMAGE_LABEL_ADD:
            return update(state, {
                filesystem: {
                    image:{
                        [action.payload.fullFilename]: {
                            labels: {$push: [action.payload.label]}
                        }
                    }
                }
            })

        case Types.DATASET_FILESYSTEM_IMAGES_SET:
            return update(state, {
                filesystem: {
                    split:{
                        train:{
                            images:{$set: action.payload.images.train}    
                        },
                        valid:{
                            images:{$set: action.payload.images.valid}    
                        },
                        test:{
                            images:{$set: action.payload.images.test}    
                        }
                    }
                }
            })

        case Types.DATASET_FILESYSTEM_IMAGE_LABELS_SET:
            return update(state, {
                filesystem: {
                    image:{
                        [action.payload.fullFilename]: {
                            labels: {$set: action.payload.labels}
                        }
                    }
                }
            })

        case Types.DATASET_FILESYSTEM_IMAGE_GALLERY_STATUS_SET:
            return update(state, {
                filesystem: {
                    image:{
                        [action.payload.fullFilename]: {
                            inGallery: {$set: action.payload.inGallery},
                            galleryIndex: {$set: action.payload.galleryIndex}
                        }
                    }
                }
            })
            
        case Types.DATASET_FILESYSTEM_CLASSNAME_SET:
            return update(state, {
                filesystem: {
                    classNames: {
                        [action.payload.classNameId]: {$set: action.payload.className}
                    }
                }  
            })

        case Types.DATASET_FILESYSTEM_CLASSNAMES_SET:
            return update(state, {
                filesystem: {
                    classNames: {$set: action.payload.classNames}
                }  
            })

        case Types.DATASET_FILESYSTEM_LABELS_LOAD:
            return {
                ...state,
                labelsLoaded:false,
                loadingLabels:true
            }

        case Types.DATASET_FILESYSTEM_LABELS_LOAD_SUCCESS:
            return {
                ...state,
                labelsLoaded:true,
                loadingLabels:false
            }
        
        case Types.DATASET_FILESYSTEM_IMAGE_LABELS_LOAD_PROGRESS_SET:
            return {
                ...state,
                labelsLoadProgress: action.payload.labelsLoadProgress
            }

        case Types.DATASET_GALLERY_IMAGE_ADD:
            return {
                ...state,
                images: [...state.images, action.payload.image]
            }

        case Types.DATASET_GALLERY_LOAD_START:
            return {
                ...state,
                loadingGallery:true,
                galleryLoaded:false
            }

        case Types.DATASET_GALLERY_LOAD_END:
            return {
                ...state,
                loadingGallery:false,
                galleryLoaded:true
            }

        case Types.DATASET_GALLERY_IMAGES_REMOVE:
            return {
                ...state,
                images:[]
            }

        case Types.DATASET_GALLERY_PAGE_SET:
            return {
                ...state,
                galleryCurrentPage:action.payload.galleryCurrentPage,
                galleryPageCount:action.payload.galleryPageCount
            }

        case Types.DATASET_FILESYSTEM_SPLIT:
            return update(state, {
                filesystem: {
                    split: {$set:action.payload.split}
                }  
            })
        
        case Types.DATASET_FILESYSTEM_SPLIT_PERCENTAGES_SET:
            return update(state, {
                filesystem: {
                    split:{
                        train:{
                            percentage:{$set:action.payload.trainPercentage}
                        },
                        valid:{
                            percentage:{$set:action.payload.validPercentage}
                        },
                        test:{
                            percentage:{$set:action.payload.testPercentage}
                        }
                    }
                }  
            })
        
        case Types.DATASET_FILESYSTEM_SPLIT_UPDATE:
            return update(state, {
                filesystem: {
                    split:{$set:action.payload.split}
                }  
            })

        case Types.DATASET_GALLERY_STATISTICS_UPDATE:
            return update(state, {
                info: {
                    gallery:{
                        example_count:{$set:action.payload.example_count},
                        page_count:{$set:action.payload.page_count}
                    }
                }  
            })
        
        case Types.DATASET_TASK_PENDING:
            return update(state, {
                info: {
                    status:{$set:'PENDING'},
                    status_message:{$set:'Pending task...'}
                }
            })

        case Types.DATASET_TASK_PROGRESS_UPDATE:
            return update(state, {
                info: {
                    status:{$set:'PROGRESS'},
                    status_message:{$set:action.payload.status_message},
                    task_progress:{$set:action.payload.task_progress}
                }
            })

        case Types.DATASET_TASK_FINISHED:
            return update(state, {
                info: {
                    status:{$set:'IDLE'},
                    status_message:{$set:''},
                    task_id:{$set:''},
                    task_progress:{$set:undefined}
                }
            })

        default: return state;
    }
}

export default DatasetMainReducer;