//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import Password from './Password'
import Username from "./Username.js";
import Text from "./Text.js";
import Email from "./Email.js";
import ConfirmPassword from "./ConfirmPassword.js";

function Input(props) {
  const { children, name, type, placeholder, label, id, onChange, input, password, ...other } = props;
  // const [input, setInput] = useState({value:'', valid:null});

  const setInput = input => {
    onChange(id, input);
  };

 
  switch (type) {
    case 'username':
      return <Username  input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
    case 'email':
        return <Email  input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
    case 'confirm-password':
          return <ConfirmPassword  password={password} input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
    case 'password':
        return <Password  input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
    default:
      return <Text  input={input.value} setInput={setInput}  valid={input.valid} label={label} {...other}/>;
  }
}

export default Input;