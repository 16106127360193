import { combineReducers } from 'redux';
import DatasetMainReducer from 'components/Project/Datasets/Dataset/state/reducer';
import DatasetUploadReducer from 'components/Project/Datasets/Dataset/state/Upload/reducer';
import DatasetDeleteReducer from 'components/Project/Datasets/Dataset/state/Delete/reducer';

const DatasetReducer = combineReducers({
    main: DatasetMainReducer,
    upload: DatasetUploadReducer,
    delete: DatasetDeleteReducer,
});

export default DatasetReducer;