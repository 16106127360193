import styled from "styled-components";

const borderWidth = 1.5;
const borderRadius = 5;

export const StyledSkeletonPulse = styled.div`
  display: inline-block;
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: ${borderRadius}px;
  background: linear-gradient(-90deg, #ebebeb 0%, #FFFFFF 50%, #ebebeb 100%);
  background-color: blue;
  background-size: 400% 400%;
  animation: pulse 1s ease-in-out infinite;
  
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;


export const StyledSkeletonLine = styled(StyledSkeletonPulse)`
  width: 5.5em;
  border-radius: 100px;

  &::before {
    content: "\00a0";
  }
`;

export const StyledBorder = styled.div`
    height: ${(props) => props.height ? props.height : "100%"};
    width: ${(props) => props.width ? props.width : "100%"};
    position: ${(props) => props.position && props.position};
    background: ${(props) =>
      props.theme.colors.autocomplete.border.linearGradient};
    padding: ${borderWidth}px;
    border-radius: ${borderRadius + borderWidth}px;
`;



///TABLE
const tablePadding = 10;

export const StyledTableContainer = styled.div`
  padding: ${tablePadding}px;
  display: flex;
  flex-direction: column;
`;

export const StyledTableRow = styled.div`
  padding: ${tablePadding}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const StyledTableCell = styled.div`
  position: relative;
  width: ${(props) => props.width ? props.width : 100}%;
  padding: 0px ${tablePadding}px 0px ${tablePadding}px;
  height: ${props=>(props.theme.fonts.title.s.size)};
`;


//CODE
export const StyledCodeLinePart = styled.div`
  position: relative;
  width: ${(props) => props.width ? props.width : 100}%;
  padding: 0px ${tablePadding}px 0px ${tablePadding}px;
  height: ${props=>(props.theme.fonts.title.m.size)};
`;

export const StyledCodeLine = styled.div`
  padding: ${tablePadding}px;
  width: 100%;
  display: flex;
`;