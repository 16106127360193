import styled from "styled-components";
export const Wrapper = styled.div`
  && {
    display: grid;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    transition: grid-template-columns ease all .5s;

    /* @media (max-width: 2305px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media (max-width: 1969px) {
      grid-template-columns: repeat(4, minmax(250px, 1fr));
    }

    @media (max-width: 1313px) {
      grid-template-columns: repeat(3, minmax(250px, 1fr));
    }

    @media (max-width: 977px) {
      grid-template-columns: repeat(2, minmax(250px, 1fr));
    }
    @media (max-width: 681px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
    } */
  }
`;
