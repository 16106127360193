import React from "react";

import { 
        StyledBackgroundCircle as BackgroundCircle,
        StyledProgressRadial as ProgressRadial,
} from "./style";
import { newId } from "utils";

let PROGRESS_RADIAL_ID = 'progressRadial';

export default function RadialProgress(props) {
    const { size, strokeWidth } = props
    let circumference;

    const [id] = React.useState(newId(`${PROGRESS_RADIAL_ID}-`));
    const [points, setPoints] = React.useState();
  
    //didMount
    React.useEffect(() => {
        setTimeout(() => setPoints(props.points));
     }, [props.points]);
  
    React.useEffect(() => {
        const circle = document.getElementById(id);
        const radius = circle.r.baseVal.value;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        circumference = radius * 2 * Math.PI;
        circle.style.strokeDasharray = `${circumference} ${circumference}`;
        circle.style.strokeDashoffset = circumference;
        animateRadial(points / props.threshold * 100);
    }, [points, id]);

    function animateRadial(percent) {
        const circle = document.getElementById(id);
        const offset = circumference - percent / 100 * circumference;
        circle.style.strokeDashoffset = offset;
    }


    
    return (
        <div className="row progress">
            <div className="col-xs-6 progress-container">
                <svg
                    width={size}
                    height={size}>

                {/* <filter id="blurMe">
                <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                </filter> */}
                    <BackgroundCircle
                        
                        r={size*0.45}
                        cx={size*0.5}
                        cy={size*0.5}
                    />
                    <circle
                        stroke="#E3E6E8"
                        strokeWidth={strokeWidth}
                        fill="transparent"
                        r={size*0.3}
                        cx={size*0.5}
                        cy={size*0.5}
                    />
                    <ProgressRadial
                        id={id}
                        className="progress-radial"
                        strokeWidth={strokeWidth}
                        fill="transparent"
                        r={size*0.3}
                        cx={size*0.5}
                        cy={size*0.5}
                        // filter="url(#blurMe)"
                    />
                    {/* <TextPoints
                        x="50%"
                        y="50%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        className="txt-points">
                        {points}/{props.threshold}
                    </TextPoints> */}
                </svg>
                {/* <input 
                  placeholder={`enter points <= ${props.threshold}`}
                  onChange={(e) => setPoints(e.target.value)} type="text" /> */}
            </div>
        </div>
    );
}
