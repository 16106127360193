//LIBRARIES
import React, { useState } from 'react';
import AccountDropdown from "./AccountDropdown";
//STYLEDCOMPONENTS
import {
  StyledContainer as Container
} from "./style";


function AppBar(props) {
  const [auth, setAuth] = useState({ fullscreen: false, defaultIndex: 0 });

  function hideAuthFullscreen() {
    setAuth({ ...auth, fullscreen: false });
  }

  return (
    <Container>
      <AccountDropdown hideAuthFullscreen={hideAuthFullscreen}/>
    </Container>
  );
}

export default AppBar;

