//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';

//STYLEDCOMPONENTS
import * as S from "./style";
  
function Text(props) {
   const { loading, children, skeletonLines, light, weight, small, ...other } = props;
  return (
    (!loading) ?
    <S.Typography weight={weight ? weight : "regular"} small={small} color={light ? "light" : undefined} {...other}>
        {children}
    </S.Typography>
    :
    <S.SkeletonsContainer>
    {[...Array(skeletonLines ? skeletonLines : 2)].map((e, i) => { 
      return (<S.Skeleton key={i} />);
    })}
    </S.SkeletonsContainer>
  );
}
 
Text.propTypes = {
  size: PropTypes.string
};

export default Text;