import styled from "styled-components";
// import { Fullscreen, ScrollableDiv, Text } from "components/UI";
import { Slider as BaseSlider } from "@mui/material";

export const Slider = styled(BaseSlider).attrs((props) => ({}))`
 color: ${props => props.theme.colors.colorful.primary};
 height: 2px;
 /* width: 250px; */

 padding: 15px 0px;
  & .MuiSlider-thumb {
    height: 20px;
    width: 20px;
    background-color: #fff;
    box-shadow: 0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02);
    &:focus, &:hover, &.Mui-active {
      box-shadow: 0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02);
      // Reset on touch devices, it doesn't add specificity
      @media (hover: none) {
        box-shadow: 0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02);
      }
    }
  }
  
  & .MuiSlider-valueLabel{
    font-size: 14px;
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    top: 4px;
    background-color: unset;
    color: ${props => props.theme.colors.text.default};

    &:before {
      display: none;
    }
    & * {
      background: transparent;
      
    }
  }

  & .MuiSlider-track {
    /* border: none; */
  }
  & .MuiSlider-rail {
    opacity: 0.5;
    background-color: #bfbfbf;
  }

  & .MuiSlider-mark {
    background-color: #bfbfbf;
    height: 8px;
    width: 1px;
    &.MuiSlider-markActive{
      opacity: 1px;
      background-color: currentColor;
    }
  }

`;