import styled from "styled-components";
import { Fullscreen, Tab }  from 'components/UI';


export const StyledFullscreen = styled(Fullscreen).attrs({
    borderRadius:15
})`
  width:100vw;
  max-width: 350px;
  min-width: 350px;
`;
export const StyledTab= styled(Tab)`
      height: 40px;
      width: 100px;
      margin-right:5px;
`;

export const StyledContainer = styled.div`
    display: flex;
    padding: 30px;
    height:min-content;
    flex-direction: column;
    & >:first-child {
       ${props => !props.onlySignIn && "margin-bottom: 20px;"} 
    }
    /* display: flex;
    padding: 40px;
    height:min-content;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:100%;
    & >:not(:first-child):not(:last-child) {
        margin: 10px 0px;
    }
    & >:first-child {
        margin-bottom: 10px;
    }
    & >:last-child {
        margin-top: 30px;
    } */
`;

