import * as Types from './types';


const initialState = {
    signedIn:false,
    loading:false,
    token:'',
    error:''
}

function AuthReducer(state=initialState, action){
    switch(action.type){
        case Types.AUTH_INIT:
            return {
                signedIn:false,
                loading:false,
                token:'',
                error:''
            }

        case Types.AUTH_REQUEST:
            return {
                signedIn:false,
                loading:true,
                token:'',
                error:''
            }

        case Types.AUTH_SUCCESS:
            return {
                loading: false,
                token: action.payload.token,
                signedIn: true,
                error: ''
            }
        
        case Types.AUTH_FAILURE:
            return {
                loading: false,
                token: '',
                signedIn: false,
                error:action.payload.error
            }
        
        case Types.LOGOUT:
            return {
                signedIn:false,
                loading:false,
                token:'',
                error:''
            }

        default: return state
    }
}

export default AuthReducer;