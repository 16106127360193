import * as Types from './types';


const initialState = {
    loaded:false,
    payload:{
        info:{
            version:''
        }
    },
    error:'',
}


function AlgorithmMainReducer(state=initialState, action){
    switch(action.type){
        case Types.ALGORITHM_MAIN_INIT:
            return initialState

        case Types.ALGORITHM_MAIN_REQUEST:
            return {
                ...state,
                loaded:false
            }

        case Types.ALGORITHM_MAIN_SUCCESS:
            return {
                loaded:true,
                payload:action.payload.algorithm,
                error:''
            }
            
        case Types.ALGORITHM_MAIN_FAILURE:
            return {
                loaded:false,
                payload:{},
                error:action.payload.error
        }
        default: return state
    }
}

export default AlgorithmMainReducer;