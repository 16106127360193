import styled from "styled-components";


export const StyledFlexboxHorizontal = styled.div`
  display: flex;
  align-items: flex-end;
  & > * {
    margin-right: 8px;
  }

  &  :first-child {
    transform: translateY(0.125em);
  }
`;

export const StyledFlexboxVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-top: ${props => props.paddingTop && props.paddingTop};
  padding-bottom: ${props => props.paddingBottom && props.paddingBottom};
  &  :last-child {
    margin-top:8px;
  }
`;