import styled from "styled-components";
import { AppearFade } from "components/UI/Animations";
import { IconButton as BaseIconButton } from "components/UI";
import BaseModal from "@mui/material/Modal";

const borderRadius = 13;
const widthPercentage = 90;
//const paddingBottomPercentage = Math.pow(aspectRatio, -1) * 100;

export const ChildContainer = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);



  background-color: ${(props) => props.theme.colors.bg.default};
  box-shadow: -${(props) => props.theme.effects.neumo.spread}px -${(props) =>
        props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0
      rgba(255, 255, 255, 0.07),
    ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0 rgba(0, 0, 0, 0.4); */

   
  font-size: 10px;


border-radius: ${(props) =>
  props.borderRadius !== undefined
    ? props.borderRadius + "px"
    : borderRadius + "px"};   

  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  /* div {
    position: relative;
  } */
  position: relative;

  background-color: ${(props) => props.theme.colors.bg.default};
  
  box-shadow: -${(props) => props.theme.effects.neumo.spread}px -${(props) =>
        props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0
      rgba(255, 255, 255, 0.07),
    ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength}px 0 rgba(0, 0, 0, 0.4);
`;

export const IconButton = styled(BaseIconButton)`

  position: absolute;
  color: white;
  width: 35px;
  height: 35px;
  top: -30px;
  right: -30px;

  &:hover {
    color: ${(props) => props.theme.colors.text.classes};
  }
`;

export const BlackScreen = styled.div`
  /* top: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%; */

  /* backdrop-filter: blur(5px); */

  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
`;

export const Animation = styled(AppearFade)``;

export const Modal = styled(BaseModal).attrs((props) => ({
  BackdropComponent:BlackScreen,
  closeAfterTransition: true,
}))`
  /* font-size: 10px;
position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;   */
  display: flex;
  justify-content: center;
  align-items: center;
`;

