import iconMapSolid from 'assets/svgs/solid/icon-map';
import iconMapRegular from 'assets/svgs/regular/icon-map';
import iconMapBold from 'assets/svgs/bold/icon-map';
import iconMapBrands from 'assets/svgs/brands/icon-map';
import iconMapColor from 'assets/svgs/color/icon-map';

export default {
...iconMapSolid,
...iconMapRegular,
...iconMapBold,
...iconMapBrands,
...iconMapColor,
};


