import * as Types from "./types";


const initialState = {
    loaded:false,
    loading:false,
    payload:[],
    error:''
}

function DeploymentsReducer(state=initialState, action){
    switch(action.type){
        case Types.DEPLOYMENTS_INIT:
            return initialState
        
        case Types.DEPLOYMENTS_REQUEST:
            return {
                ...initialState,
                loaded:false,
                loading:true,
                error:''
            }
        
        case Types.DEPLOYMENTS_REQUEST_SUCCESS:
            return {
                ...state,
                loaded:true,
                loading:false,
                payload: action.payload.deployments,
                error:''
            }

        case Types.DEPLOYMENTS_REQUEST_FAILURE:
            return {
                ...state,
                loaded:true,
                loading:false,
                error:action.payload.error
            }

        default: return state
    }
}

export default DeploymentsReducer;