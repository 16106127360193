import styled from "styled-components";
import BaseMenuItem from '@mui/material/MenuItem';
import BaseMenu from '@mui/material/Menu';
import hexToRgba from "hex-to-rgba";



export const MenuItem = styled(BaseMenuItem).attrs({
    disableRipple: true
})`
&& {

  ${(props) => {
    switch (props.color) {
      case "error":
        return `color: ${props.theme.colors.text.error};`;
      case "success":
        return `color: ${props.theme.colors.text.success};`;
      case "regular":
        return `color: ${props.theme.colors.text.mediumPurple};`;
      default:
        return `color: ${props.theme.colors.text.default};`;
    }
  }}

  && svg {
    ${(props) => {
    switch (props.color) {
      case "error":
        return `color: ${props.theme.colors.text.error};`;
      case "success":
        return `color: ${props.theme.colors.text.success};`;
      case "regular":
        return `color: ${props.theme.colors.text.mediumPurple};`;
      default:
        return `color: ${props.theme.colors.text.default};`;
    }
  }}
  }
  
         justify-content: space-between;
         padding: 6px;
         margin: 4px;
         font-size: 14px;
         border-radius: 4px;
         :hover {
            background-color: rgba(10, 20, 28, 0.09);
         }

         svg {
             color: rgb(10, 20, 28);
             margin: 0px 7px;
             font-size: 13px;
         }
 
     }



`;

export const Menu = styled(BaseMenu).attrs({})`
  .MuiMenu-paper {
    box-shadow: none;
    color: rgba(#fff, 0.7);
    font-size: 2.4vw;
    backdrop-filter: blur(6px);
    border-radius: 8px;
    /* background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.45) 0%,
      rgba(255, 255, 255, 0.85) 100%
    ); */
    background: linear-gradient(
      45deg,
      ${props=> hexToRgba(props.theme.colors.bg.default, 0.45)} 0%,
      ${props=> hexToRgba(props.theme.colors.bg.default, 0.85)} 100%
    );

  

   ul {
    padding: 0;
   }
    
    /* background-color: ${props => (props.theme.colors.bg.default)}; */
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgb(0 0 0 / 3%),
      0 4px 4px rgb(0 0 0 / 4%), 0 10px 8px rgb(0 0 0 / 5%),
      0 15px 15px rgb(0 0 0 / 6%), 0 30px 30px rgb(0 0 0 / 7%),
      0 70px 65px rgb(0 0 0 / 9%);


   

  }
`;


// & .MuiMenuItem-root {
//   /* background-color: blue; */
//   padding: 6px;
//   margin: 4px;
//   font-size: 14px;
//   border-radius: 4px;
//   :hover {
//      background-color: rgba(10, 20, 28, 0.09);
//   }

//   svg {
//       color: rgb(10, 20, 28);
//       margin-right: 7px;
//       font-size: 13px;
//   }

// }