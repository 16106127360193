import React from 'react';
import * as S from './style'

import {Popover} from "components/UI"

import { SmallScreen } from 'components/UI/Devices';

function MenuButton ({children, icon, onClick, selected, ...props}){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [delayPass, setDelayPass] = React.useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    delayPass && setDelayPass(false)
    setTimeout(
      function () {
        setDelayPass(true);
      }.bind(this),
      25
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

    return (
      <>
        {delayPass && <SmallScreen><S.Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>{children}</div>
      </S.Popover></SmallScreen>}
      <S.Button onClick={onClick} selected={selected} disableRipple {...props}  onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}>
        <S.Icon name={icon} selected={selected}/>
        <S.TextContainer>{children}</S.TextContainer>
      </S.Button>
      </>
    );
}

export default MenuButton;



