import { combineReducers } from 'redux'
import FieldsReducer from 'components/Library/state/reducer';
import SubfieldReducer from 'components/Library/Subfield/state/reducer';
import AlgorithmReducer from 'components/Library/Algorithm/state/reducer';
import FieldReducer from 'components/Library/Field/state/reducer';
import DatasetReducer from 'components/Library/Dataset/state/reducer';
import CategoryMediasReducer from 'components/Library/Dataset/CategoryMedias/state/reducer';


const LibraryReducer = combineReducers({
    fields: FieldsReducer,
    field: FieldReducer,
    dataset: DatasetReducer,
    subfield: SubfieldReducer,
    algorithm: AlgorithmReducer,
    categoryMedias: CategoryMediasReducer
});

export default LibraryReducer;
