import React, { useState } from "react";
import { useSelector } from 'react-redux';
//STYLEDCOMPONENTS
import * as S from "./style";


function NewProject(props) {
  const {onClickConfirm, onClickOutside} = props;
  const [project, setProject] = useState({name: { value: "", valid: false }});
  const loading = useSelector((store) => store.project.create.loading);

  const handleFieldChange = (fieldId, input) => {
    setProject({ ...project, [fieldId]: input });
  };

  const handleConfirm = (event) => {
    onClickConfirm(project.name.value)
  };

  return (
    <S.Container onClickOutside={onClickOutside}>
      <S.Typography size={"m"}>New project</S.Typography>
      <S.Input
        label={"Name"}
        input={project.name.value}
        onChange={handleFieldChange}
        id={"name"}
        focus={true}
      />
      <S.Button loading={loading ? 1 : undefined} disabled={!areAllInputsValid(project)} onClick={handleConfirm}>Confirm</S.Button>
    </S.Container>
  );
}

export default NewProject;

function areAllInputsValid(inputs) {
  let allInputsValid = true;
  for (const [key, value] of Object.entries(inputs)) {
    if (!value.valid) {
      allInputsValid = false;
    }
  }
  return allInputsValid
}