import * as types from "./types";
import update from 'immutability-helper';


const initialState = {
    namespace:'',
    configuration:{
        subfield:'',
        machine_uuid:'',
        args:{
            algorithm: {
                path_name:'',
                version_path_name:''
            },
            weights:'',
            device:'',
        }
    },

    statusMessage:'',

    starting:false,
    stopping:false,    
    ended:false,
    queued:false,

    startedSetup:false,
    endedSetup:false,
    loadingMachine:false,
    downloadingAlgorithm:false,
    algorithmDownloaded:false,
    installingAlgorithm:false,
    algorithmInstalled:false,
    downloadingWeights:false,
    weightsDownloaded:false,
    pullingDataset:false,
    datasetPulled:false,
    translatingDataset:false,
    datasetTranslated:false,

    downloadProgress:{
        downloaded:0,
        totalSize:1,
        bytesPerSecond:0
    },

    loadingAlgorithm:false,
    algorithmLoaded:false,
    loadingWeights:false,
    weightsLoaded:false,
    sendingAlgorithmToCPU:false,
    algorithmSentToCPU:false,
    sendingAlgorithmToGPU:false,
    algorithmSentToGPU:false,
    ready:false,
    anotherSessionRunning:false,
    machineDisconnected:false,
    machineOutOfMemory:false
}


function TrainingSessionExperimentAPIReducer(state=initialState, action){
    switch(action.type){
        case types.TRAINING_SESSION_EXPERIMENT_API_INIT:
            return initialState;

        case types.TRAINING_SESSION_EXPERIMENT_API_FLAGS_RESET:
            return {
                ...state,
                statusMessage:'',

                starting:false,
                stopping:false,    
                ended:false,
                queued:false,

                startedSetup:false,
                endedSetup:false,
                loadingMachine:false,
                downloadingAlgorithm:false,
                algorithmDownloaded:false,
                installingAlgorithm:false,
                algorithmInstalled:false,
                downloadingWeights:false,
                weightsDownloaded:false,
                pullingDataset:false,
                datasetPulled:false,
                translatingDataset:false,
                datasetTranslated:false,

                downloadProgress:{
                    downloaded:0,
                    totalSize:1,
                    bytesPerSecond:0
                },

                loadingAlgorithm:false,
                algorithmLoaded:false,
                loadingWeights:false,
                weightsLoaded:false,
                sendingAlgorithmToCPU:false,
                algorithmSentToCPU:false,
                sendingAlgorithmToGPU:false,
                algorithmSentToGPU:false,
                ready:false,

                anotherSessionRunning:false,
                machineDisconnected:false,
                machineOutOfMemory:false
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_SUBFIELD_SET:
            return update(state, {
                configuration:{
                    subfield:{$set: action.payload.subfield}
                }
            })

        case types.TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_MACHINE_SET:
            return update(state, {
                configuration:{
                    machine_uuid:{$set: action.payload.machine_uuid}
                }
            })

        case types.TRAINING_SESSION_EXPERIMENT_API_START:
            return {
                ...initialState,
                namespace:action.payload.namespace,
                statusMessage:action.payload.statusMessage,
                configuration:action.payload.configuration,
                starting:true,
                ready:false
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_READY:
            return {
                ...state,
                starting:false,
                ready:true
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_STOP:
            return {
                ...state,
                stopping:true,
                ended:false
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_STOP_ANOTHER_SESSION:
            return {
                ...state,
                stopping:true,
                ended:false
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_ENDED:
            return {
                ...state,
                stopping:false,
                ended:true
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_QUEUED:
            return {
                ...state,
                queued:true
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_STARTED_SETUP:
            return {
                ...state,
                startedSetup:true,
                endedSetup:false,
                downloadingAlgorithm:false,
                algorithmDownloaded:false,
                installingAlgorithm:false,
                algorithmInstalled:false,
                downloadingWeights:false,
                weightsDownloaded:false,
                pullingDataset:false,
                datasetPulled:false,
                translatingDataset:false,
                datasetTranslated:false,
                downloadProgress:{
                    downloaded:0,
                    totalSize:1,
                    bytesPerSecond:0
                },
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_ENDED_SETUP:
            return {
                ...state,
                startedSetup:false,
                endedSetup:true,
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_LOADING_MACHINE:
            return {
                ...state,
                loadingMachine:true
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_MACHINE_LOADED:
            return {
                ...state,
                loadingMachine:false
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_ALGORITHM:
            return {
                ...state,
                downloadingAlgorithm:true,
                algorithmDownloaded:false,
                downloadProgress:{
                    downloaded:0,
                    totalSize:1,
                    bytesPerSecond:0
                }
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_DOWNLOADED:
            return {
                ...state,
                downloadingAlgorithm:false,
                algorithmDownloaded:true
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOAD_PROGRESS:
            return {
                ...state,
                downloadProgress:{
                    downloaded:action.payload.downloadProgress.downloaded,
                    totalSize:action.payload.downloadProgress.total_size,
                    bytesPerSecond:action.payload.downloadProgress.bytes_per_second
                }
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_INSTALLING_ALGORITHM:
            return {
                ...state,
                installingAlgorithm:true,
                algorithmInstalled:false
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_INSTALLED:
            return {
                ...state,
                installingAlgorithm:false,
                algorithmInstalled:true
            }
    
        case types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_WEIGHTS:
            return {
                ...state,
                downloadingWeights:true,
                weightsDownloaded:false,
                downloadProgress:{
                    downloaded:0,
                    totalSize:1,
                    bytesPerSecond:0
                }
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_DOWNLOADED:
            return {
                ...state,
                downloadingWeights:false,
                weightsDownloaded:true
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_PULLING_DATASET:
            return {
                ...state,
                pullingDataset:true,
                datasetPulled:false
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_DATASET_PULLED:
            return {
                ...state,
                pullingDataset:false,
                datasetPulled:true
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_TRANSLATING_DATASET:
            return {
                ...state,
                translatingDataset:true,
                datasetTranslated:false
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_DATASET_TRANSLATED:
            return {
                ...state,
                translatingDataset:false,
                datasetTranslated:true
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_LOADING_ALGORITHM:
            return {
                ...state,
                loadingAlgorithm:true,
                algorithmLoaded:false,
                statusMessage:action.payload.statusMessage
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_LOADED:
            return {
                ...state,
                loadingAlgorithm:false,
                algorithmLoaded:true,
                statusMessage:action.payload.statusMessage
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_LOADING_WEIGHTS:
            return {
                ...state,
                loadingWeights:true,
                weightsLoaded:false,
                statusMessage:action.payload.statusMessage
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_LOADED:
            return {
                ...state,
                loadingWeights:false,
                weightsLoaded:true,
                statusMessage:action.payload.statusMessage
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_CPU:
            return {
                ...state,
                sendingAlgorithmToCPU:true,
                algorithmSentToCPU:false,
                statusMessage:action.payload.statusMessage
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_CPU:
            return {
                ...state,
                sendingAlgorithmToCPU:false,
                algorithmSentToCPU:true,
                statusMessage:action.payload.statusMessage
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_GPU:
            return {
                ...state,
                sendingAlgorithmToGPU:true,
                algorithmSentToGPU:false,
                statusMessage:action.payload.statusMessage
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_GPU:
            return {
                ...state,
                sendingAlgorithmToGPU:false,
                algorithmSentToGPU:true,
                statusMessage:action.payload.statusMessage
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_ANOTHER_SESSION_RUNNING:
            return {
                ...state,
                anotherSessionRunning:true
            }
        
        case types.TRAINING_SESSION_EXPERIMENT_API_MACHINE_DISCONNECTED:
            return {
                ...state,
                machineDisconnected:true,
                statusMessage:action.payload.statusMessage
            }

        case types.TRAINING_SESSION_EXPERIMENT_API_OUT_OF_MEMORY:
            return {
                ...state,
                machineOutOfMemory:true,
                statusMessage:action.payload.message
            }

        default: return state
    }
}

export default TrainingSessionExperimentAPIReducer;