import styled from "styled-components";
import { Skeleton } from "components/UI";

export const Text = styled.div`
  && {
    color: ${(props) =>
      !props.light
        ? props.theme.colors.text.default
        : props.theme.colors.text.light};
  }
`;

export const StyledText = styled.div`
  ${(props) => {
    switch (props.size) {
      case "xl":
        return `font-size: ${props.theme.fonts.title.xl.size};
                  font-weight: ${props.theme.fonts.weight.bold};`;
      case "l":
        return `font-size: ${props.theme.fonts.title.l.size};
          font-weight: ${props.theme.fonts.weight.bold};`;
      case "m":
        return `font-size: ${props.theme.fonts.title.m.size};
          font-weight: ${props.theme.fonts.weight.bold};`;
      case "xs":
        return `font-size: ${props.theme.fonts.title.xs.size};
          font-weight: ${props.theme.fonts.weight.bold};`;
      case "xsi":
        return `font-size: ${props.theme.fonts.title.xsi.size};
          font-weight: ${props.theme.fonts.weight.bold};`;
      default:
        return `font-size: ${props.theme.fonts.title.s.size};
          font-weight: ${props.theme.fonts.weight.bold};`;
    }
  }}

  ${(props) => {
    switch (props.color) {
      case "light":
        return `color: ${props.theme.colors.text.light};`;
      case "medium":
        return `color: ${props.theme.colors.text.medium};`;
      case "blue":
        return `color: ${props.theme.colors.text.light};`;
      default:
        return `color: ${props.theme.colors.text.default};`;
    }
  }}


  ${(props) => {
    switch (props.weight) {
      case "bold":
        return `font-weight: ${props.theme.fonts.weight.bold};`;
      case "medium":
        return `font-weight: ${props.theme.fonts.weight.medium};`;
      case "regular":
        return `font-weight: ${props.theme.fonts.weight.regular};`;
      case "light":
        return `font-weight: ${props.theme.fonts.weight.light};`;
      case "thin":
        return `font-weight: ${props.theme.fonts.weight.thin};`;
      default:
        return ``;
    }
  }}
  font-size: ${(props) => props.fontSize && props.fontSize};
  text-shadow: ${(props) =>
    props.shadow && "-2px -2px 4px #FFFFFF, 2px 2px 4px rgba(0,0,0,0.1)"};
  text-transform: ${(props) => props.uppercase && "uppercase"};

  &:hover {
    text-decoration: ${(props) => props.isLink && "underline"};
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  height: 100%;

  ${(props) => {
    switch (props.size) {
      case "xl":
        return `height: ${props.theme.fonts.title.xl.size};`;
      case "l":
        return `height: ${props.theme.fonts.title.l.size};`;
      case "s":
        return `height: ${props.theme.fonts.title.s.size};`;
      case "xsi":
        return `height: ${props.theme.fonts.title.xsi.size};`;
      case "xs":
        return `height: ${props.theme.fonts.title.xs.size};`;
      default:
        return `height: ${props.theme.fonts.title.m.size};`;
    }
  }}
`;

//${props=>(props.theme.fonts.title.l.size)}
