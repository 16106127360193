import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import {Header} from "components/UI/Common/Billing"
import paymentSuccessful from "assets/lotties/payment_successful.json";
import paymentUnsuccessful from "assets/lotties/payment_unsuccessful.json";
import fireworks from "assets/lotties/fireworks.json";
//STYLEDCOMPONENTS
import * as S from "./style";
//const stepsLabels = ["Select Plan", "Payment", "Review"];

const paymentAnimationOptions = {
  loop: false,
  // autoplay: true,
  animationData: paymentSuccessful,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
    //  scaleMode: 'noScale',
  },
};

const fireworksAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: fireworks,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid meet",
    //  scaleMode: 'noScale',
  },
};

function PaymentSuccessful(props) {
  const { onClickOutside, plan,  ...others } = props;

  return (
    <S.Modal onClickOutside={onClickOutside}>
       <Lottie
          // style={{ position: "absolute", transform: "scaleX(1)", }}
          style={{
  
            position: "absolute",
            top: "-250px",
            right: "50%",
            pointerEvents: "none",
            // transform: "scaleX(1)",
            // marginTop: "-7%",
            // visibility: "visible",
            // transform: "scaleX(1)",
            transform: "scale(1) translate(50%)",
            height: "350px",
  
          }}
          // style={{ marginTop: "-8%", marginRight: 0 }}
          isClickToPauseDisabled={true}
          options={fireworksAnimationOptions}
          // height={"130%"}
          speed={1}
        />
         <Header height="120px" planName={plan.name} />
      <S.Container>
     <S.Title>Plan activated successfully</S.Title>
      <S.LottieContainer>
        <Lottie
          // style={{ position: "absolute", transform: "scaleX(1)", }}
          style={{
            filter: "drop-shadow(0px 0px 3px white)",
            // position: "absolute",
            // right: "0px",
            // transform: "scaleX(1)",
            // marginTop: "-7%",
            // visibility: "visible",
            // transform: "scaleX(1)",
            transform: "scale(1.3)",
            height: "100%",
            width: "auto"
          }}
          // style={{ marginTop: "-8%", marginRight: 0 }}
          isClickToPauseDisabled={true}
          options={paymentAnimationOptions}
          // height={"130%"}
          speed={1}
        />
      </S.LottieContainer>
      <S.Description>You now have {plan.description.toLowerCase()}</S.Description>
      <S.ButtonContainer>
        <S.Button onClick={onClickOutside}>Continue</S.Button>
      </S.ButtonContainer>
      </S.Container>
    </S.Modal>
  );
}

export default PaymentSuccessful;
