import styled from "styled-components";

const spread = 6;
const blurLength = 18;
const borderRadius = 50;
const padding = 20;

export const StyledOpacityScreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: ${(props) => !props.open && "none"};
  z-index: 100;
  /* opacity: ${(props) => (props.open ? 0.3 : 0)}; */

  opacity: ${(props) => (props.open ? 0.3 : 0)};
  transition: opacity 500ms;
  /* background-color:${(props) =>
    props.open ? "rgba(0,0,0,0.3)" : "rgba(0,0,0,0)"}; */
  background-color: #000000;
  /* backdrop-filter: ${(props) => (props.open ? "blur(30px)" : "none")}; */
  /* background-color: ${(props) =>
    props.open ? "rgba(0,0,0,0.5)" : "transparent"}; */
`;

export const StyledContent = styled.div`
  display: flex;
  padding: 0px ${padding}px 0px ${padding}px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-width: ${(props) => props.theme.breakpoints.phone.min}px;
`;

export const StyledMain = styled.div`

  /* padding-left:5px;
  padding-right:5px; */
  min-height: 100vh;
  height:100%;
 
/* 
  width: calc(
    100vw -
      ${(props) =>
        props.open ? props.theme.breakpoints.components.sidemenu + "px" : "0px"}
  ); */
  margin-left: ${(props) =>
    props.open ? props.theme.breakpoints.components.sidemenu + "px" : "0"};



  border-radius: ${borderRadius}px 0px 0px 0px;

  box-shadow: ${(props) =>
    props.open
      ? `-${spread}px -${spread}px ${blurLength}px 0px ${props.theme.colors.boxShadow.neumo.light}, ${spread}px ${spread}px  ${blurLength}px 0 ${props.theme.colors.boxShadow.neumo.contrast}`
      : "none"};
  transition: box-shadow 1500ms;

  /* @media (min-width: ${props => (props.theme.breakpoints.tabletLandscape.min)}px) and (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
    width: calc(
    100vw -
      ${(props) =>
        props.open ? props.theme.breakpoints.components.IconsSidemenu + "px" : "0px"}
  ); */

  @media (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
    width: calc(100vw - ${(props) => props.open ? props.theme.breakpoints.components.IconsSidemenu + "px" : "0px"});


  margin-left: ${(props) =>
    props.open ? props.theme.breakpoints.components.IconsSidemenu + "px" : "0"};
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile.max}px) {
    filter: blur(${(props) => (props.open ? "10px" : "none")});
    width: 100vw;
    margin-left: 0px;
  } */
`;

export const StyledRoot = styled.div`
  height:auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  max-width: 100vw;
  font-size: 10px;
  background-color: ${(props) => props.theme.colors.bg.default};
`;
