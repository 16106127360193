import * as Style from "./types";
import { api } from 'state/api';


function forgotPasswordInit(){
    return {
        type: Style.FORGOT_PASSWORD_INIT
    }
}

function forgotPasswordRequest(){
    return {
        type: Style.FORGOT_PASSWORD_REQUEST
    }
}

function forgotPasswordSuccess(){
    return {
        type: Style.FORGOT_PASSWORD_SUCCESS
    }
}

function forgotPasswordFailure(error){
    return {
        type: Style.FORGOT_PASSWORD_FAILURE,
        payload: {
            error
        }
    }
}

export function initForgotPassword(){
    return (dispatch) => {
        dispatch(forgotPasswordInit())
    }
}

export function sendPasswordRecoveryEmail(email){
    return (dispatch) => {
        dispatch(forgotPasswordRequest())
        api()
        .post('/auth/password/reset/', {email})
        .then(response => {
            dispatch(forgotPasswordSuccess())
        })
        .catch(error => {
            dispatch(forgotPasswordFailure(error.response.data))
        })
    }
}



