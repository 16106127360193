"use client";

import React, { useState, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { cn } from "lib/utils";
import { userLoginSchema } from "lib/validations/auth";
import { buttonVariants } from "components/shadcnui/button";
import { Input } from "components/shadcnui/input";
import { PasswordInput } from "../password-input"
import { Label } from "components/shadcnui/label";
import {Icons} from "components/shadcnui/icons";
import { AlertDestructive } from "../alert-destructive";


interface UserSignInFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSignInClick?: (username: string, password: string) => void;
  isLoading?: boolean;
  toggleForgotPassword?: () => void;
  toggleEmailVerification?: () => void;
  authErrors?: any;
  disabled?: boolean;
}

type FormData = z.infer<typeof userLoginSchema>;

export function UserSignInForm({
  className,
  onSignInClick,
  isLoading,
  toggleForgotPassword,
  toggleEmailVerification,
  authErrors,
  disabled,
  ...props
}: UserSignInFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    resolver: zodResolver(userLoginSchema),
  });

  // const [notVerified, setNotVerified] = useState(false);
  const [loginErrors, setLoginErrors] = useState<string[]>([]);

  useEffect(() => {
    const newLoginErrors = [];
    for (var errorKey in authErrors) {
      for (var errorMessageKey in authErrors[errorKey]) {
        const message = authErrors[errorKey][errorMessageKey];
        newLoginErrors.push(message
          // <AlertDestructive  error={message}/>
        )
        // if(message.includes('not verified')){
        //   setNotVerified(true);
        // }
      }
    }
    setLoginErrors(newLoginErrors);
  }, [authErrors]);



  function onSubmit(data: FormData) {
    if (onSignInClick) {
      onSignInClick(data.username, data.password);
    }
  }

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4 pt-4 text-left">
          {loginErrors.map((error, index) => (
            <AlertDestructive key={index} error={error} />
          ))}

          <div className="grid gap-2">
            <Label htmlFor="text">Username</Label>
            <Input
              id="text"
              placeholder="username"
              type="text"
              autoCapitalize="none"
              autoComplete="username"
              autoCorrect="off"
              disabled={isLoading}
              errors={
                (dirtyFields.username || errors?.username) && errors.username
              }
              {...register("username")}
            />
            <p className={`px-1 text-xs text-destructive ${errors?.username ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
              {errors.username && errors.username.message as React.ReactNode}
            </p>
          </div>
          {/* Password Field */}
          <div className="grid gap-2">
            <Label htmlFor="password">Password</Label>

            <PasswordInput
              id="password"
              placeholder="password"
              errors={
                (dirtyFields.password || errors?.password) && errors.password
              }
              {...register("password")} />
            <p className={`px-1 text-xs text-destructive ${errors?.password ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
              {errors?.password && errors.password.message as React.ReactNode}
            </p>
          </div>

          <div onClick={toggleEmailVerification} className="w-full text-dark-purple cursor-pointer text-left self-start text-sm font-normal text-primary-600 hover:underline">
            Resend verification email
          </div>

          <div onClick={toggleForgotPassword} className="w-full text-dark-purple cursor-pointer text-left self-start text-sm text-primary-600 font-normal hover:underline">
            Forgot Password?
          </div>

          {!disabled && (
            <button className={cn(buttonVariants())} disabled={isLoading}>
              {isLoading && (
                <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
              Sign In
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
