import React from "react";
//STYLEDCOMPONENTS
import * as S from "./style";


function ContentWithRightMenu(props) {
  const { children, rightMenu, header } = props;

  return (
    <S.SetupContainer>
      <S.Container>
        <S.LeftContainer>
          {header}
          <S.ContentContainer>
            {children}
          </S.ContentContainer>
        </S.LeftContainer>
        {rightMenu}
      </S.Container>
    </S.SetupContainer>
  );
}

export default ContentWithRightMenu;
