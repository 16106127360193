"use client"

import React, { useEffect, useState, useRef } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
// import { signIn } from "next-auth/react"
import { useForm } from "react-hook-form"
import * as z from "zod"

import { cn } from "lib/utils"
import { userRegisterSchema } from "lib/validations/auth"
import { buttonVariants } from "components/shadcnui/button"
import { Input } from "components/shadcnui/input"
import { Label } from "components/shadcnui/label"
import { PasswordInput } from "../password-input"
import { AlertDestructive } from "../alert-destructive"
import { Icons } from "components/shadcnui/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";



// import { Icons } from "components/shadcnui/icons"

interface UserSignUpFormProps extends React.HTMLAttributes<HTMLDivElement> {
  //email, firstName, lastName, password1, password2, captchaToken
  authErrors?: any;
  //email, username, firstName, lastName, password1, password2, captchaToken
  onSignUpClick?: (email: string, username: string, firstName: string, lastName: string, password: string, confirmPassword: string, captchaToken: string) => void
  // onSignUpClick?: (username:string, email: string, password: string, firstName: string, lastName: string, captchaToken: string) => void
  isLoading?: boolean
}


type FormData = z.infer<typeof userRegisterSchema>

export function UserSignUpForm({ className, onSignUpClick, authErrors, isLoading, ...props }: UserSignUpFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<FormData>({
    resolver: zodResolver(userRegisterSchema),
    // mode: "onChange",
  })

  const captchaRef = useRef<ReCAPTCHA>(null);
  var signUpErrors = [];

  for (var errorKey in authErrors) {
    for (var errorMessageKey in authErrors[errorKey]) {
      signUpErrors.push(
        authErrors[errorKey][errorMessageKey]
      );
    }
  }

  const [captchaToken, setCaptchaToken] = useState<string | null | undefined>(undefined);

  function onCaptchaChange(value: string | null) {
    console.log("Captcha value:", value);
    setCaptchaToken(value);
  }

  

  function onSubmit(data: FormData) {
    if (onSignUpClick) {
      //email, username, firstName, lastName, password1, password2, captchaToken
      onSignUpClick(data.email, data.username, data.firstName, data.lastName, data.password, data.confirmPassword, captchaToken as string)

      if (captchaRef.current) {
      captchaRef.current.reset();
      }
    }

  }

  console.log("errors", errors)

  useEffect(() => {
    // window.grecaptcha.reset();
  }
  , [])


  return (
    <div {...props}>
      {signUpErrors &&
        <div className="pt-2 space-y-1">
          {signUpErrors.map((error, index) => (
            <AlertDestructive key={index} error={error} />
          ))}
        </div>
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col items-center justify-between w-full min-h-min">
          <div className="grid gap-y-2 w-full pt-4 text-left mb-4">

            <div className="grid gap-2">
              <Label htmlFor="email">Username</Label>
              <Input
                id="username"
                placeholder="username"
                type="username"
                autoCapitalize="none"
                autoComplete="username"
                autoCorrect="off"
                disabled={isLoading}
                errors={
                  (dirtyFields.username || errors?.username) && errors.username
                }
                {...register("username")}
              />
              <p className={`px-1 text-xs text-destructive ${errors?.username ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
                {errors?.username && errors.username.message as React.ReactNode}
              </p>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="email">First Name</Label>
              <Input
                id="firstName"
                placeholder="First Name"
                type="firstName"
                autoCapitalize="none"
                autoComplete="firstName"
                autoCorrect="off"
                disabled={isLoading}
                errors={
                  (dirtyFields.firstName || errors?.firstName) && errors.firstName
                }
                {...register("firstName")}
              />
              <p className={`px-1 text-xs text-destructive ${errors?.firstName ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
                {errors?.firstName && errors.firstName.message as React.ReactNode}
              </p>
            </div>
            <div className="grid gap-2">
              <Label htmlFor="email">Last Name</Label>
              <Input
                id="lastName"
                placeholder="Last Name"
                type="lastName"
                autoCapitalize="none"
                autoComplete="lastName"
                autoCorrect="off"
                disabled={isLoading}
                errors={
                  (dirtyFields.lastName || errors?.lastName) && errors.lastName
                }
                {...register("lastName")}
              />
              <p className={`px-1 text-xs text-destructive ${errors?.lastName ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
                {errors?.lastName && errors.lastName.message as React.ReactNode}
              </p>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                placeholder="email"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                disabled={isLoading}
                errors={
                  (dirtyFields.email || errors?.email) && errors.email
                }
                {...register("email")}
              />
              <p className={`px-1 text-xs text-destructive ${errors?.email ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
                {errors?.email && errors.email.message as React.ReactNode}
              </p>
            </div>
            {/* Password Field */}

            <div className="grid gap-2">
              <Label htmlFor="password">Password</Label>

              <PasswordInput
                id="password"
                placeholder="password"
                errors={
                  (dirtyFields.password || errors?.password) && errors.password
                }
                {...register("password")} />
              <p className={`px-1 text-xs text-destructive ${errors?.password ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
                {errors?.password && errors.password.message as React.ReactNode}
              </p>
            </div>

            <div className="grid gap-2">
              <Label htmlFor="confirm password">Confirm Password</Label>

              <PasswordInput
                id="confirmPassword"
                placeholder="confirm password"
                {...register("confirmPassword")}
                errors={
                  (dirtyFields.confirmPassword || errors?.confirmPassword) && errors.confirmPassword
                }
              />
              <p className={`px-1 text-xs text-destructive ${errors?.confirmPassword ? 'h-5' : 'h-0'} transition-height duration-200 ease-in-out`}>
                {errors?.confirmPassword && errors.confirmPassword.message as React.ReactNode}
              </p>
            </div>

            <div className="w-full text-xs font-normal">
              By signing up, you agree to our{" "}
              <Link to={"/site/terms"} target="_blank" className="text-primary-500 hover:underline">
                Terms
              </Link>{" "}and{" "}<Link to={"/site/privacy"} target="_blank" className="text-primary-500 hover:underline">Privacy & Cookies Policy</Link>
            </div>

          </div>

          {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChange}
            />
          )}
  


          <button className={cn(buttonVariants(), "w-full mt-4")} disabled={isLoading}>
            {isLoading && (
              <Icons.Spinner className="mr-2 h-4 w-4 animate-spin" />
            )}
            Sign Up
          </button>
        </div>
      </form>


    </div>
  )
}