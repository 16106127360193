import React from "react";


import * as S from './style'

import { Switch as BaseSwitch } from '@mui/material';

export default function Switch({ ...props }) {
  const { withLed, checked, name, onChange, field, ...others } = props;


  // const [state, setState] = React.useState({
  //   checked: true
  // });

  // const handleChange = name => event => {
  //   setState({ ...state, [name]: event.target.checked });
  // };

  return (
    <S.Grid {...others}>
      <S.Border>
        <S.Switch
          name={name}
          checked={checked}
          value="checked"
          onChange={onChange}
          {...field}
        />
      </S.Border>
      {/* {withLed &&
        <S.LedBorder>
          <S.Led active={checked}></S.Led>
        </S.LedBorder>} */}
    </S.Grid>
  );
}



