
import React, { useEffect, Suspense } from "react";
import { lazy } from "react";
import { Routes, Route, useLocation, Navigate, matchPath } from "react-router-dom";
import MainLayout from "./mainLayout";
import FullscreenLayout from "./fullscreenLayout";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjects } from "components/User/Projects/state/actions";
import Loading from "components/Other/Loading";

function DynamicRouter(props) {
  const { signedIn, routes } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const projectLoaded = useSelector((store) => store.project.main.loaded);
  const role = useSelector((store) => store.project.main.payload.role);
  let match = false;

  useEffect(() => {
    if (signedIn && !projectLoaded) {
      dispatch(fetchProjects());
    }
  }, []);
  
  for(let i = 0; i < routes.length; i++) {
    const route = routes[i];
    const matched = matchPath({
      path: route.path,
      exact: true,
      strict: true,
    }, location.pathname);
  
    if (matched) {
      match = true;
    }
  }

  if (!match) {
    return null;
  }

  if (!signedIn) {
    return <Navigate to={`/authentication/login?redirect=${location.pathname}`} />
  } else {
    if (!projectLoaded) {
      return <Loading/>;
    } else {
      return <Routes>
        {routes.map((route, key) => {
          if (role.permissions && role.permissions[route.actionName]) {
            if (route.type === "fullscreen") {
              let Component = lazy(() => import("./" + route.importScreenPath));
              return (
                <Route
                  exact
                  path={route.path}
                  element={<FullscreenLayout><Component/></FullscreenLayout>}
                  key={key}
                />
              );
            } else if (route.type === "main") {
              let Component = lazy(() => import("./" + route.importScreenPath));
              return <Route
                exact
                path={route.path}
                element={<MainLayout withAppbar={false}><Component /></MainLayout>}
                key={key}
              >
              </Route>
            }
          }
        })}
      </Routes>
    }
  }
}

export default DynamicRouter;
