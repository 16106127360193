export const TRAINING_SESSION_EXPERIMENT_API_INIT = 'TRAINING_SESSION_EXPERIMENT_API_INIT';
export const TRAINING_SESSION_EXPERIMENT_API_FLAGS_RESET = 'TRAINING_SESSION_EXPERIMENT_API_FLAGS_RESET';
export const TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_MACHINE_SET = 'TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_MACHINE_SET';
export const TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_SUBFIELD_SET = 'TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_SUBFIELD_SET';

// sessions message types
export const TRAINING_SESSION_EXPERIMENT_API_STATUS = 'TRAINING_SESSION_EXPERIMENT_API_STATUS';
export const TRAINING_SESSION_EXPERIMENT_API_RESPONSE = 'TRAINING_SESSION_EXPERIMENT_API_RESPONSE';
export const TRAINING_SESSION_EXPERIMENT_API_DOWNLOAD_PROGRESS = 'TRAINING_SESSION_EXPERIMENT_API_DOWNLOAD_PROGRESS';
export const TRAINING_SESSION_EXPERIMENT_API_CONFLICT = 'TRAINING_SESSION_EXPERIMENT_API_CONFLICT';

// sessions commands
export const TRAINING_SESSION_EXPERIMENT_API_START = 'TRAINING_SESSION_EXPERIMENT_API_START';
export const TRAINING_SESSION_EXPERIMENT_API_STOP = 'TRAINING_SESSION_EXPERIMENT_API_STOP';

// sessions statuses
export const TRAINING_SESSION_EXPERIMENT_API_READY = 'TRAINING_SESSION_EXPERIMENT_API_READY';
export const TRAINING_SESSION_EXPERIMENT_API_ENDED = 'TRAINING_SESSION_EXPERIMENT_API_ENDED';
export const TRAINING_SESSION_EXPERIMENT_API_QUEUED = 'TRAINING_SESSION_EXPERIMENT_API_QUEUED';

// TRAINING session statuses
export const TRAINING_SESSION_EXPERIMENT_API_STARTED_SETUP = 'TRAINING_SESSION_EXPERIMENT_API_STARTED_SETUP';
export const TRAINING_SESSION_EXPERIMENT_API_ENDED_SETUP = 'TRAINING_SESSION_EXPERIMENT_API_ENDED_SETUP';

export const TRAINING_SESSION_EXPERIMENT_API_LOADING_MACHINE = 'TRAINING_SESSION_EXPERIMENT_API_LOADING_MACHINE';
export const TRAINING_SESSION_EXPERIMENT_API_MACHINE_LOADED = 'TRAINING_SESSION_EXPERIMENT_API_MACHINE_LOADED';

export const TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_ALGORITHM = 'TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_ALGORITHM';
export const TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_DOWNLOADED = 'TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_DOWNLOADED';

export const TRAINING_SESSION_EXPERIMENT_API_INSTALLING_ALGORITHM = 'TRAINING_SESSION_EXPERIMENT_API_INSTALLING_ALGORITHM';
export const TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_INSTALLED = 'TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_INSTALLED';

export const TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_WEIGHTS = 'TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_WEIGHTS';
export const TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_DOWNLOADED = 'TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_DOWNLOADED';

export const TRAINING_SESSION_EXPERIMENT_API_LOADING_ALGORITHM = 'TRAINING_SESSION_EXPERIMENT_API_LOADING_ALGORITHM';
export const TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_LOADED = 'TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_LOADED';

export const TRAINING_SESSION_EXPERIMENT_API_LOADING_WEIGHTS = 'TRAINING_SESSION_EXPERIMENT_API_LOADING_WEIGHTS';
export const TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_LOADED = 'TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_LOADED';

export const TRAINING_SESSION_EXPERIMENT_API_PULLING_DATASET = 'TRAINING_SESSION_EXPERIMENT_API_PULLING_DATASET';
export const TRAINING_SESSION_EXPERIMENT_API_DATASET_PULLED = 'TRAINING_SESSION_EXPERIMENT_API_DATASET_PULLED';

export const TRAINING_SESSION_EXPERIMENT_API_TRANSLATING_DATASET = 'TRAINING_SESSION_EXPERIMENT_API_TRANSLATING_DATASET';
export const TRAINING_SESSION_EXPERIMENT_API_DATASET_TRANSLATED = 'TRAINING_SESSION_EXPERIMENT_API_DATASET_TRANSLATED';

export const TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_CPU = 'TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_CPU';
export const TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_CPU = 'TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_CPU';

export const TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_GPU = 'TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_GPU'
export const TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_GPU = 'TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_GPU';

export const TRAINING_SESSION_EXPERIMENT_API_METRICS_UPDATE = 'TRAINING_SESSION_EXPERIMENT_API_METRICS_UPDATE';
export const TRAINING_SESSION_EXPERIMENT_API_OUT_OF_MEMORY = 'TRAINING_SESSION_EXPERIMENT_API_OUT_OF_MEMORY';

export const TRAINING_SESSION_EXPERIMENT_API_STOP_ANOTHER_SESSION = 'TRAINING_SESSION_EXPERIMENT_API_STOP_ANOTHER_SESSION';

// session conflicts
export const TRAINING_SESSION_EXPERIMENT_API_ANOTHER_SESSION_RUNNING = 'TRAINING_SESSION_EXPERIMENT_API_ANOTHER_SESSION_RUNNING';
export const TRAINING_SESSION_EXPERIMENT_API_MACHINE_DISCONNECTED = 'TRAINING_SESSION_EXPERIMENT_API_MACHINE_DISCONNECTED';
