import React from 'react';


import {
    StyledSunkDiv,
    StyledDiv,
    StyledSunkSmoothedDiv,
    StyledSmoothedDiv,
    StyledSunkDivClasses,
    StyledSunkDivInput,
    StyledSunkDivOutput
} from './style';


const handleLinkWrapping = (props) => {
    const { variant, smoothed,color, children} = props;
    const Component = whatComponentIs(variant,smoothed,color)
    const div = (
      <Component {...props}>
        {children}
      </Component>
    );
    return div;
  };
  
  type Props = {
    color?: string,
    variant?: string,
    smoothed?: boolean,
    children: React$Node,
  };



function whatComponentIs(variant, smoothed, color) {
  switch (variant) {
    case "sunk":
      return smoothed ? StyledSunkSmoothedDiv : StyledSunkDiv;
    case "secondary": {
      switch (color) {
        case "red":
          return StyledSunkDivInput;
        case "green":
          return StyledSunkDivOutput;
        default:
          return StyledSunkDivClasses;
      }
    }
    default:
      return smoothed ? StyledSmoothedDiv : StyledDiv;
  }
}


const Div = (props: Props) => handleLinkWrapping(props);

export default Div;