import React from 'react';
import {
  StyledText as Text,
  StyledSkeleton as Skeleton,
} from './style';


const handleLinkWrapping = (props) => {
    const {loading, size, weight, shadow, children, isLink, uppercase, fontSize, light, ...others } = props;
    const Component = Text;
    const div = (
      <Component size={size} weight={weight} shadow={shadow} fontSize={fontSize} isLink={isLink} light={light} uppercase={uppercase} {...others}>
        {children}
      </Component>
    );
    return !loading ? div : <Skeleton {...others} size={size}/>;
  };
  
  type Props = {
    size?: string,
    smoothed?: boolean,
    children: React$Node,
  };


export const Typography = (props: Props) => handleLinkWrapping (props);

export default Typography;