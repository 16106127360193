import * as Types from './types';


const initialState = {
    verified:false,
    loaded:false,
    error:''
}


function EmailVerificationReducer(state=initialState, action){
    switch(action.type){
        case Types.EMAIL_VERIFICATION_REQUEST:
            return initialState

        case Types.EMAIL_VERIFICATION_SUCCESS:
            return {
                verified:true,
                loaded:true,
                error:''
            }
        
        case Types.EMAIL_VERIFICATION_FAILURE:
            return {
                verified:false,
                loaded:true,
                error:action.payload.error
            }

        default: return state
    }
}

export default EmailVerificationReducer;