import styled from "styled-components";
import Icon from "icons";


export const StyledContainer = styled.div.attrs((props) => ({
    // elevation: 0,
}))`
    && {
        border-radius: 50%;
        position: relative;
        display: flex;
        height: 5em;
        width: 5em;
        color: ${props=>(props.theme.colors.colorful.primary)};
        overflow: hidden;
        justify-content: center;
        align-items: center;
        box-shadow: 
        0.2em 0.2em 1em rgba(0,0,0,0.08),
        -0.1em -0.1em 0.2em rgba(255,255,255,0.50),
        inset -0.4em -0.4em 0.4em rgba(0,0,0,0.02),
        inset 0.4em 0.4em 0.4em rgba(255,255,255,0.50)
    }

`;


export const StyledReflex = styled.div`
    && {
        background-color: rgba(255,255,255,0.0);
        background-position: bottom;
        border-radius: 100% 100% 100% 100%;
        position: absolute;
        border-top: 15px solid  rgba(255,255,255,0.5);
        top: 10%;
        left: 15%;
        transform: rotate(-30deg);
        height: 70%;
        width: 70%;  
    }

`;

//box-shadow: inset 0em 8em 0.0em -6em rgba(255,255,255,0.60)
//color: ${props=>(props.theme.colors.colorful.primary)};

export const StyledIcon = styled(Icon).attrs((props) => ({
    size: "50%",
}))`

`;