import * as Types from "./types";


const initialState = {
    loaded:false,
    payload:{},
    error:''
}


function DatasetReducer(state=initialState, action){
    switch(action.type){
        case Types.DATASET_INIT:
            return initialState;
        
        case Types.DATASET_REQUEST:
            return {
                ...state,
                loaded:false
            }
        case Types.DATASET_SUCCESS:
            return {
                loaded:true,
                payload:action.payload.dataset,
                error:''
            }
        case Types.DATASET_FAILURE:
            return {
                loaded:false,
                payload:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default DatasetReducer