import { createTheme } from "@mui/material/styles";
import theme from './';

const materialTheme = createTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: theme.colors.text.mediumPurple,
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: theme.colors.bg.default,
        dark: '#ba000d',
        contrastText: '#000',
      },
      text: {
        main: theme.colors.text.default,
      },
      switch: {
          border: {
            linearGradient: theme.colors.switch.border.linearGradient,
          }
      },
    },

    own: theme,
  });


  export default materialTheme;