import * as Types from "./types";


const initialState = {
    successful:false,
    loading:false,
    error:''
}


function PasswordResetReducer(state=initialState, action){
    switch(action.type){
        case Types.PASSWORD_RESET_INIT:
            return initialState

        case Types.PASSWORD_RESET_REQUEST:
            return {
                successful:false,
                loading:true,
                error:''
            }

        case Types.PASSWORD_RESET_SUCCESS:
            return {
                successful:true,
                loading:false,
                error:''
            }
        
        case Types.PASSWORD_RESET_FAILURE:
            return {
                successful:false,
                loading:false,
                error:action.payload.error
            }

        default: return state
    }
}

export default PasswordResetReducer;