import React, { Suspense } from "react";
import { AppBar, SideMenu, LazyFallback } from "components/UI/Layouts";
import {
  StyledMain as Main,
} from "./style";
import { useSelector } from "react-redux";


function MainLayout(props) {
  const { children, withAppbar } = props;
  const signedIn = useSelector((store) => store.user.auth.signedIn);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      {signedIn && <SideMenu open={open} handleDrawerClose={handleDrawerClose} {...props} />}
      <Main open={signedIn && open}>
        <Suspense fallback={<LazyFallback />}>
          {(withAppbar || !signedIn) ? (
            <>
              <AppBar open={open} handleDrawerOpen={handleDrawerOpen}></AppBar>
              {children}
            </>
          ) : (
            children
          )}
        </Suspense>
      </Main>
    </>
  );
}

export default MainLayout;
