import styled from "styled-components";
import { Radio as BaseRadio } from "@mui/material";

export const Icon = styled.span`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.colors.bg.default};
  transition: 200ms;
`;

export const CheckedIcon = styled(Icon)`
  box-shadow: inset 0px 0px 2px 4px blue;
  transition: 200ms;
`;

export const Radio = styled(BaseRadio)`
  /* .PrivateSwitchBase-input {
    color: blue;
    box-shadow: 2px 2px 2px 2px blue;
  } */



  &&.Mui-checked {
    color: ${(props) => props.theme.colors.colorful.primary};
    filter: drop-shadow(
      0px 0px 2px ${(props) => props.theme.colors.colorful.primary}
    );
  }

  &&.Mui-checked::before {
    border: 1px solid ${(props) => props.theme.colors.divider};

    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    /* background-color: yellow; */
    border-radius: 20px;
    box-shadow: inset 0px 0px 4px 4px white;
    /* position: absolute;
  background-color: yellow;
 width: 20px;
 height: 20px;
 border-radius: 20px;
 transition: 200ms; */
  }

  &&:not(.Mui-checked)::before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    /* background-color: yellow; */
    border-radius: 20px;
    box-shadow: inset -2px -2px 2px 0px white,
      inset 2px 2px 2px 0px
        ${(props) => props.theme.colors.div.secondary.classes.contrast};
    border: 1px solid ${(props) => props.theme.colors.divider};
  }

  &&:not(.Mui-checked) {
    color: transparent;
    /* filter: drop-shadow(1px 1px 1px #4444dd); */
  }
`;
