import styled from "styled-components";

import { Popover as BasePopover, Typography } from "@mui/material";

export const Popover = styled(BasePopover).attrs((props) => ({}))`
  pointer-events: none;
  
  .MuiPopover-root {
    pointer-events: none;
  }

  .MuiPopover-paper {
    max-width: 400px;
    border-radius: 10px;
    padding:10px;
    text-align: left;
    background-color: ${(props) => props.theme.colors.bg.default};
    box-shadow: inset ${(props) => props.theme.effects.neumo.spread * 0.5}px
        ${(props) => props.theme.effects.neumo.spread * 0.5}px
        ${(props) => props.theme.effects.neumo.blurLength * 0.75}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      ${(props) => props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.blurLength * 2}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast},
      inset 0px 0px 0px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};
  }
`;

export const StyledTypography = styled(Typography).attrs((props) => ({}))`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  font-size: ${(props) => props.theme.fonts.title.xs.size};
  color: ${(props) => props.theme.colors.text.default};
`;
