import * as Types from "./types";
import update from 'immutability-helper';

const initialState = {
    loading:false,
    loaded:false,
    payload:[],
    error:''
}

function ProjectRolesReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_ROLES_INIT:
            return initialState;

        case Types.PROJECT_ROLES_REQUEST:
            return {
                loading:true,
                loaded:false,
                payload:[],
                error:''
            }

        case Types.PROJECT_ROLES_REQUEST_SUCCESS:
            return {
                loading:false,
                loaded:true,
                payload:action.payload.roles,
                error:''
            }

        case Types.PROJECT_ROLES_REQUEST_FAILURE:
            return {
                loading:false,
                loaded:false,
                payload:[],
                error:action.payload.error
            }
        
        case Types.PROJECT_ROLES_SET:
            const roles = state.payload.map(role => {
                if(role.id === action.payload.role.id){
                    return action.payload.role;
                }else{
                    return role;
                }
            });
            return update(state, {
                payload: {$set: roles}
            });
        default: return state
    }
}

export default ProjectRolesReducer;