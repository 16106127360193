import styled from "styled-components";
import { ScrollableDiv } from "components/UI";

export const RightMenu = styled.div`
  height: 100%;
  /* min-width:315px; */
  flex: 0 0 315px;
  overflow-y: auto;
  overflow-x: hidden;
  /* flex-shrink:0; */
  /* background: #131315; */
  border-left: solid 1px ${(props) => props.theme.colors.border.default};
  box-shadow: -8px 0px 10px 0px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  top: 0;
`;

export const RightMenuContent = styled(ScrollableDiv)`
  flex-grow: 1;
`;

export const BottomSection = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.border.default};
`;

export const TopSection = styled.div`
  border-bottom: solid 1px ${(props) => props.theme.colors.border.default};
`;