import * as Types from './types';

const initialState = {
    created:false,
    loading:false,
    payload:{},
    error:''
}

function DeploymentCreateReducer(state=initialState, action){
    switch(action.type){
        case Types.DEPLOYMENT_CREATE:
            return {
                created:false,
                loading:true,
                payload:{},
                error:''
            }
        
        case Types.DEPLOYMENT_CREATE_SUCCESS:
            return {
                created:true,
                loading:false,
                payload:action.payload.deployment,
                error:''
            }

        case Types.DEPLOYMENT_CREATE_FAILURE:
            return {
                created:false,
                loading:false,
                payload:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default DeploymentCreateReducer;