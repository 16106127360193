import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import * as S from "./style";


export default function CheckoutForm(props) {
  const { setReady, setPrimaryButtonDisabled, onSuccess, setConfirmPaymentFunction, onError, ...others } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [complete, setComplete] = useState(false);

  setConfirmPaymentFunction((async () => {
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "http://localhost:3000",
      },
      redirect: 'if_required'
    });
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occured. Please, go back and try again.");
      }
      onError();
    } else {
      onSuccess();
    }
  }));

  const handleOnChange = (event) => {
    if (event.complete !== complete) {
      setComplete(event.complete);
      setPrimaryButtonDisabled(!event.complete)
    }
  };

  return (
    <S.Container>
      <form id="payment-form">
        <S.PaymentElement
          id="payment-element"
          onChange={handleOnChange}
          onReady={(e) => setReady(true)}
        />
        {message && <S.ErrorMessage weight="medium">{message}</S.ErrorMessage>}
      </form>
    </S.Container>
  );
}