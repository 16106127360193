import styled from "styled-components";

const size = 32;

export const StyledContainer = styled.div`
border-radius:100%;
overflow: hidden;
background-color: ${props=>(props.theme.colors.text.light)};
color:white;
height: ${props=>props.size ? props.size : size+'px'};
width: ${props=>props.size ? props.size : size+'px'};

flex-shrink: 0;
line-height: ${props=>props.size ? props.size : size+'px'};
font-weight: ${props=>(props.theme.fonts.weight.regular)};
font-size: ${props=>props.size ? `calc(${props.size} * 0.7)` : props.theme.fonts.title.s.size};

box-shadow: -${props => (props.theme.effects.neumo.spread)}px -${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.light)},
                ${props => (props.theme.effects.neumo.spread)}px ${props => (props.theme.effects.neumo.spread)}px  ${props => (props.theme.effects.neumo.blurLength)}px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)},
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.contrast)}, 
                inset 0px 0px 0px 0 ${props => (props.theme.colors.boxShadow.neumo.light)};
text-align: center;
text-transform: uppercase;
`;


export const StyledImage= styled.img`
width:100%;
`;
