//LIBRARIES
import React, { useState, useRef, useEffect } from "react";
//STYLEDCOMPONENTS
import {
  StyledNeumoContainer as NeumoContainer,
  StyledMediaContainer as MediaContainer,
  StyledThumbnailGallery as ThumbnailGallery,
  StyledGallerySkeleton as GallerySkeleton,
  StyledThumbnail as Thumbnail
} from "./style";
import { FullscreenMedia, PrincipalMedia } from "components/UI";


export default function Gallery({ medias, loading, showPrincipalMedia = true }) {

  const [selectedMedia, setSelectedMedia] = useState({ info: [], index: null });
  const [fullscreen, setFullscreen] = useState(false);
  const thumbnailGalleryRef = useRef(null);

  useEffect(() => {
    if (medias) {
      setSelectedMedia({ info: medias[0], index: 0 });
    }
  }, [medias]);

  function mediaSelection(event) {
    let mediaIndex = medias.findIndex((item) => Number(item.id) === Number(event.target.id));
    setSelectedMedia(
      { info: medias[mediaIndex], index: mediaIndex }
    );
    if (!showPrincipalMedia) setFullscreen(true);
  }

  function mediaNext() {
    var nextIndex = selectedMedia.index + 1;
    if (medias[nextIndex] === undefined) {
      setSelectedMedia({ info: medias[0], index: 0 });
    } else {
      setSelectedMedia({ info: medias[nextIndex], index: nextIndex });
    }
  }

  function mediaPrevious() {
    var previousIndex = selectedMedia.index - 1;
    if (medias[previousIndex] === undefined) {
      setSelectedMedia({ info: medias[medias.length - 1], index: medias.length - 1 });
    } else {
      setSelectedMedia({ info: medias[previousIndex], index: previousIndex });
    }
  }

  function clickOutside() {
    setFullscreen(false);
  }

  function onClickMedia(event) {
    setFullscreen(true);
  }

  return (
    <>
      <NeumoContainer>
        {showPrincipalMedia ? (
          <MediaContainer>
            {!loading ? (

              <PrincipalMedia
                selectedMedia={selectedMedia.info}
                onClick={onClickMedia}
              />

            ) : (
              <GallerySkeleton />
            )}
          </MediaContainer>)
          : null}
        <ThumbnailGallery ref={thumbnailGalleryRef}>
          {!loading &&
            medias.map((media, index) => {
              return (
                <Thumbnail
                  id={media.id}
                  key={media.id}  // Modificado para usar media.id como key
                  img={media.thumbnail}
                  labels={media.labels}
                  video={media.video}
                  onClick={mediaSelection}
                  index={index}
                />
              );
            })}
          {loading && [...Array(5)].map((_, i) => <Thumbnail loading key={i} />)}
        </ThumbnailGallery>
      </NeumoContainer>
      {!loading && fullscreen && (
        <FullscreenMedia
          index={selectedMedia.index + 1}
          mediasQuantity={medias.length}
          onClickOutside={clickOutside}
          onClickNext={mediaNext}
          onClickPrevious={mediaPrevious}
          media={selectedMedia.info}
        >{console.log(selectedMedia)}</FullscreenMedia>
      )}
    </>
  );
}
