//LIBRARIES
import React from 'react';
//STYLEDCOMPONENTS
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

import { 
  StyledToastContainer, 
} from "./style";

export { toast } from 'react-toastify';


function ToastContainer(props) {
  return (
    <StyledToastContainer
    autoClose={4500}
    hideProgressBar={false}
    newestOnTop={true}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    />
  );
}
export default ToastContainer;