import * as Types from './types';


const initialState = {
    algorithm:{},
    dataset:{},
    machine:{}
}


function TrainingSessionSetupReducer(state=initialState, action){
    switch(action.type){
        case Types.TRAINING_SESSION_INIT:
            return initialState;

        case Types.TRAINING_SESSION_ALGORITHM_SET:
            return {
                ...state,
                algorithm:action.payload.algorithm
            }

        case Types.TRAINING_SESSION_DATASET_SET:
            return {
                ...state,
                dataset:action.payload.dataset
            }
            
        case Types.TRAINING_SESSION_MACHINE_SET:
            return {
                ...state,
                machine:action.payload.machine
            }

        default: return state;
    }
}

export default TrainingSessionSetupReducer;