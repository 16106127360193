import styled from "styled-components";
import ReactPlayer from "react-player";
import { TransitionGroupBlur } from "components/UI/Animations";
import { Loader } from 'components/UI'

export const StyledLoader= styled(Loader)`

`;

export const StyledContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 101%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
 

//IMAGE
export const StyledPrincipalImage = styled.img.attrs((props) => ({
    loading:"lazy",
  }))`
      position: absolute;
      height: 100%;
  `;
  
  export const StyledPrincipalImageBlurried = styled.img.attrs((props) => ({
    //loading: "lazy",
  }))`
   
      filter:blur(30px);
      position: absolute;
      width: 150%;
      height: 150%;
    
  `;

//VIDEO
export const StyledVideo = styled(ReactPlayer).attrs((props) => ({
    width: "100%",
    height: "100%",
    controls: true,
  }))`
  
    position: absolute;
  
  `;


export const StyledAnimation = styled(TransitionGroupBlur)`
  position: absolute;
  width: 100%;
  height: 100%;
`;
