import React from "react";
import Lottie from "react-lottie";
// import shapesAnimation from "assets/lotties/shapes_animation_green";
// import blurredOrbs from "assets/lotties/blurred-orbs/blurred_orbs_green";

import neumoBackground from "assets/lotties/neumo_background.json";

import * as o from "assets/svgs/complex/blurred-orbs";
import * as s from "assets/lotties/shapes-animation";
import * as bg from "assets/svgs/complex/waves-background";

//STYLEDCOMPONENTS
import * as S from "./style";


const animations = {
  red: {
    orbs: o.redOrb,
    shapes: s.redShapes,
    background: bg.wavesBackgroundRed
  },
  green: {
    orbs: o.greenOrb,
    shapes: s.greenShapes,
  },
  purple: {
    orbs: o.purpleOrb,
    shapes: s.purpleShapes,
    background: bg.wavesBackgroundPurple
  },
  diamond: {
    orbs: o.diamondOrb,
    // shapes: s.blackShapes,
    background: bg.wavesBackgroundDiamond
  },
}

function Header(props) {
  const { color, height, planName, ...others } = props;

  const shapesAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[color] ? animations[color].shapes : animations.purple.shapes,
    rendererSettings: {
      preserveAspectRatio: "xMaxYMid slice"
    },
  };

  return (
    <S.Container height={height}>
      <S.WavesBackground src={animations[color] ? animations[color].background : animations.purple.background} />
      <S.TitleContainer>
        <S.Logo />
        <div>{planName}</div>
        <S.Orb color={color ? color : "purple"} fixed />
      </S.TitleContainer>
      <S.BlurredOrbs src={animations[color] ? animations[color].orbs : animations.purple.orbs} />
      <Lottie
        style={{
          position: "absolute",
          right: "0px",
          transform: "scaleX(1)",
          marginTop: "-7%",
          visibility: "visible",

        }}
        isClickToPauseDisabled={true}
        options={shapesAnimationOptions}
        height={"130%"}
        speed={1.25}
        width={"auto"}
      />
    </S.Container>
  );
}

export default Header;
