import { combineReducers } from 'redux';
import TrainingSessionSetupReducer from './Setup/reducer';
import TrainingSessionsReducer from 'components/Project/Training/TrainingSessions/state/reducer';
import TrainingSessionReducer from './Session/reducer';


const TrainingReducer = combineReducers({
    setup: TrainingSessionSetupReducer,
    sessions: TrainingSessionsReducer,
    session: TrainingSessionReducer
});

export default TrainingReducer;