import { ReactComponent as IconCheck } from './check.svg';
import { ReactComponent as IconClose } from './close.svg';
import { ReactComponent as IconMinus } from './minus.svg';
import { ReactComponent as IconPlay } from './play.svg';

export default {
"check":IconCheck,
"close" : IconClose,
"minus":IconMinus,
"play" : IconPlay
};


