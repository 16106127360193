import * as types from "./types";
import * as namespaces from 'state/Websocket/namespaces';
import { addExperimentMetricPoint } from '../../CenterContent/ChartsSelector/state/actions';
import { setTrainingSessionExperimentStatus, trainingSessionExperimentStopSuccess } from 'components/Project/Training/TrainingSession/state/actions';


function trainingSessionInit(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_INIT
    }
}

function trainingSessionFlagsReset(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_FLAGS_RESET
    }
}

function trainingSessionConfigurationMachineSet(machine_uuid){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_MACHINE_SET,
        payload:{
            machine_uuid
        }
    }
}

function trainingSessionConfigurationSubfieldSet(subfield){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_CONFIGURATION_SUBFIELD_SET,
        payload:{
            subfield
        }
    }
}

function sessionStart(namespace, statusMessage, configuration){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_START,
        payload:{
            namespace,
            statusMessage,
            configuration
        }
    }
}

function sessionReady(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_READY
    }
}

function sessionStopAnotherSession(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_STOP_ANOTHER_SESSION
    }
}

function sessionStop(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_STOP
    }
}

export function sessionEnded(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ENDED
    }
}

function commandQueued(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_QUEUED
    }
}

function downloadingAlgorithm(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_ALGORITHM
    }
}

function algorithmDownloaded(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_DOWNLOADED
    }
}

function downloadProgressUpdate(downloadProgress){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOAD_PROGRESS,
        payload:{
            downloadProgress
        }
    }
}

function loadingMachine(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_LOADING_MACHINE
    }
}

function machineLoaded(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_MACHINE_LOADED
    }
}

function installingAlgorithm(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_INSTALLING_ALGORITHM
    }
}

function algorithmInstalled(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_INSTALLED
    }
}

function downloadingWeights(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_WEIGHTS
    }
}

function weightsDownloaded(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_DOWNLOADED
    }
}

function pullingDataset(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_PULLING_DATASET
    }
}

function datasetPulled(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_DATASET_PULLED
    }
}

function translatingDataset(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_TRANSLATING_DATASET
    }
}

function datasetTranslated(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_DATASET_TRANSLATED
    }
}

export function startedSetup(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_STARTED_SETUP
    }
}

export function endedSetup(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ENDED_SETUP
    }
}

function loadingAlgorithm(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_LOADING_ALGORITHM,
        payload:{
            statusMessage
        }
    }
}

function algorithmLoaded(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_LOADED,
        payload:{
            statusMessage
        }
    }
}

function loadingWeights(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_LOADING_WEIGHTS,
        payload:{
            statusMessage
        }
    }
}

function weightsLoaded(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_LOADED,
        payload:{
            statusMessage
        }
    }
}

function sendingAlgorithmToCPU(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_CPU,
        payload:{
            statusMessage
        }
    }
}

function algorithmSentToCPU(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_CPU,
        payload:{
            statusMessage
        }
    }
}

function sendingAlgorithmToGPU(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_GPU,
        payload:{
            statusMessage
        }
    }
}

function algorithmSentToGPU(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_GPU,
        payload:{
            statusMessage
        }
    }
}

function anotherSessionRunning(){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_ANOTHER_SESSION_RUNNING
    }
}

function machineDisconnected(statusMessage){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_MACHINE_DISCONNECTED,
        payload:{
            statusMessage
        }
    }
}

function machineOutOfMemory(message){
    return {
        type: types.TRAINING_SESSION_EXPERIMENT_API_OUT_OF_MEMORY,
        payload:{
            message
        }
    }
}

export function startTrainingSessionExperiment(configuration){
    return (dispatch) => {
        console.log('START TRAINING SESSION:', configuration);
        dispatch(sessionStart(namespaces.TRAINING_SESSION, 'Starting session', configuration));
        dispatch(setTrainingSessionExperimentStatus('STARTING'));
    }
}

export function setTrainingSessionConfigurationMachine(machine_uuid){
    return (dispatch) => {
        dispatch(trainingSessionConfigurationMachineSet(machine_uuid));
    }
}

export function setTrainingSessionConfigurationSubfield(subfield){
    return (dispatch) => {
        dispatch(trainingSessionConfigurationSubfieldSet(subfield));
    }
}

export function stopSession(){
    return (dispatch) => {
        dispatch(sessionStop());
        dispatch(setTrainingSessionExperimentStatus('STOPPING'));
    }
}

export function stopAnotherSession(){
    return (dispatch) => {
        dispatch(sessionStopAnotherSession());
        dispatch(setTrainingSessionExperimentStatus('STOPPING'));
    }
}

export function initTrainingSessionExperimentAPI(){
    return (dispatch) => {
        dispatch(trainingSessionInit());
    }
}

export function resetTrainingSessionExperimentAPIFlags(){
    return (dispatch) => {
        dispatch(trainingSessionFlagsReset());
    }
}

export function handleSessionStatus(status){
    return (dispatch) => {
        switch(status){
            case types.TRAINING_SESSION_EXPERIMENT_API_READY:
                dispatch(sessionReady());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_ENDED:
                dispatch(sessionEnded());
                dispatch(setTrainingSessionExperimentStatus('FINISHED'));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_QUEUED:
                dispatch(commandQueued());
                break;

            case 'STOPPING':
                dispatch(trainingSessionExperimentStopSuccess());
                break;
            
            // case types.TRAINING_SESSION_EXPERIMENT_API_STARTED_SETUP:
            //     dispatch(startedSetup());
            //     break;

            case types.TRAINING_SESSION_EXPERIMENT_API_ENDED_SETUP:
                dispatch(endedSetup());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_LOADING_MACHINE:
                dispatch(loadingMachine());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_MACHINE_LOADED:
                dispatch(machineLoaded());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_ALGORITHM:
                dispatch(downloadingAlgorithm());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_DOWNLOADED:
                dispatch(algorithmDownloaded());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_INSTALLING_ALGORITHM:
                dispatch(installingAlgorithm());
                break;
            
            case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_INSTALLED:
                dispatch(algorithmInstalled());
                break;
            
            case types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOADING_WEIGHTS:
                dispatch(downloadingWeights());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_DOWNLOADED:
                dispatch(weightsDownloaded());
                break;
            
            case types.TRAINING_SESSION_EXPERIMENT_API_PULLING_DATASET:
                dispatch(pullingDataset());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_DATASET_PULLED:
                dispatch(datasetPulled());
                break;
            
            case types.TRAINING_SESSION_EXPERIMENT_API_TRANSLATING_DATASET:
                dispatch(translatingDataset());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_DATASET_TRANSLATED:
                dispatch(datasetTranslated());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_LOADING_ALGORITHM:
                dispatch(loadingAlgorithm('Loading algorithm'));
                break;
            
            case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_LOADED:
                dispatch(algorithmLoaded('Algorithm loaded'));
                break;
            
            case types.TRAINING_SESSION_EXPERIMENT_API_LOADING_WEIGHTS:
                dispatch(loadingWeights('Loading weights'));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_WEIGHTS_LOADED:
                dispatch(weightsLoaded('Weights loaded'));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_CPU:
                dispatch(sendingAlgorithmToCPU('Sending algorithm to CPU'));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_CPU:
                dispatch(algorithmSentToCPU('Algorithm sent to CPU'));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_SENDING_ALGORITHM_TO_GPU:
                dispatch(sendingAlgorithmToGPU('Sending algorithm to GPU'));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_ALGORITHM_SENT_TO_GPU:
                dispatch(algorithmSentToGPU('Algorithm sent to GPU'));
                break;

            default:
                break;
        }
    }
}

export function updateDownloadProgress(downloadProgress){
    return (dispatch) => {
        dispatch(downloadProgressUpdate(downloadProgress));
    }
}

export function updateTrainingMetrics(metrics){
    return (dispatch, getState) => {
        const experimentStatus = getState().project.training.session.main.experiment.status;
        console.log('EXPERIMENT STATUS:', experimentStatus);
        if(experimentStatus === 'IDLE' || experimentStatus === 'ERROR' || experimentStatus === 'STARTED' || experimentStatus === 'STARTING'){
            dispatch(setTrainingSessionExperimentStatus('RUNNING'));
        }
        let sessionEnd = false;
        
        metrics.forEach((metric) => {
            const x = metric.x;
            Object.keys(metric.y).forEach(metricPathName => {
                const point = {x:x.value, y:metric.y[metricPathName]};
                dispatch(addExperimentMetricPoint(metricPathName, point, x));
            });
            
            if(!sessionEnd && x.value === x.total){
                sessionEnd = true;
            }
        });

        if(sessionEnd){
            dispatch(sessionEnded());
            dispatch(setTrainingSessionExperimentStatus('FINISHED'));
        }
    }
}

export function handleSessionResponse(response){
    return (dispatch) => {
        // switch(response.type){
        //     case types.TRAINING_SESSION_EXPERIMENT_API_IMAGE_DETECTED:
        //         dispatch(imageDetected(response.content));
        //         break;

        //     default:
        //         break;
        // }
    }
}

export function handleSessionConflict(conflict){
    return (dispatch) => {
        switch(conflict){
            case types.TRAINING_SESSION_EXPERIMENT_API_ANOTHER_SESSION_RUNNING:
                dispatch(anotherSessionRunning());
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_MACHINE_DISCONNECTED:
                dispatch(machineDisconnected('Retrying because machine is disconnected'));
                break;

            default:
                break;
        }
    }
}

export function handleTrainingSessionExperimentAPIMessage(data){
    return (dispatch) => {
        switch(data.type){
            case types.TRAINING_SESSION_EXPERIMENT_API_STATUS:
                dispatch(handleSessionStatus(data.content));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_DOWNLOAD_PROGRESS:
                dispatch(updateDownloadProgress(data.content));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_OUT_OF_MEMORY:
                dispatch(setTrainingSessionExperimentStatus('ERROR', data.content));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_METRICS_UPDATE:
                dispatch(updateTrainingMetrics(data.content));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_RESPONSE:
                dispatch(handleSessionResponse(data.response));
                break;

            case types.TRAINING_SESSION_EXPERIMENT_API_CONFLICT:
                dispatch(handleSessionConflict(data.content));
                break;

            default:
                break;
        }
    }
}