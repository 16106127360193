import React from "react";
import {
  StyledLink as Link,
  StyledTable as TableBase,
  StyledTableCell as TableCell,
  StyledTableRow as TableRow,
  StyledTableRowBorder as TableRowBorder,
  StyledTableBody as TableBody,
  StyledTableHead as TableHead,
  StyledTableRowSelected as TableRowSelected,
} from "./style";

import { useLocation } from "react-router-dom";
import { truncateMatrixColumns } from "utils";

function GenericTableRow(props) {
  let location = useLocation();
  const { children, i, id, selected, paths, onClick } = props;
  var isSelected =
    selected !== undefined && selected.includes(id) ? true : false;
  //var hasPath = paths !== undefined && paths[id] !== undefined ? true : false;
  var hasPath = false;
  var path = undefined;

  if (paths !== undefined)
    path = paths.find(function (item) { return item[0] === id });
  hasPath = (path !== undefined)

  function handleClick(event) {
    if (onClick)
      onClick(event, id);
  }

  if (hasPath && location !== undefined) {
    return (
      <Link key={i} to={path[1]}>
        <TableRowBorder id={id} key={i}>
          <TableRow className={"table-row"} id={id} key={i} onClick={(event) => handleClick(event)}>
            {children}
          </TableRow>
        </TableRowBorder>
      </Link>
    );
  } else {
    if (!isSelected)
      return (
        <TableRowBorder id={id} key={i} selected={isSelected}>
          <TableRow className={"table-row"} id={id} key={i} onClick={(event) => handleClick(event)}>
            {children}
          </TableRow>{" "}
        </TableRowBorder>
      );
    else
      return (
        <TableRowBorder id={id} key={i} selected={isSelected}>
          <TableRowSelected id={id} key={i} onClick={(event) => handleClick(event)}>
            {children}
          </TableRowSelected>
        </TableRowBorder>
      );
  }
}

export function Table(props) {
  const {
    children,
    selected,
    truncateColumns,
    tableCellStyle,
    paths,
    small,
    onClick,
    ...others
  } = props;

  let {
    titles,
    body,
  } = props;

  if (truncateColumns !== undefined) {
    titles = truncateMatrixColumns(titles, truncateColumns)
    body = truncateMatrixColumns(body, truncateColumns + 1)
  }

  if (!Array.isArray(titles) && !Array.isArray(body)) {
    return <TableBase {...others}>{children}</TableBase>;
  } else {
    var bodyRows = [];
    var bodyRow = [];
    var titleRow = [];
    var id;


    if (Array.isArray(titles)) {
      titleRow = [];
      titles.forEach(function (title, i) {
        titleRow = [...titleRow, <TableCell key={i}>{title}</TableCell>];
      });
      titleRow = [<TableHead key={1}>{titleRow}</TableHead>];
    }

    if (Array.isArray(body)) {
      body.forEach(function (row, i) {
        id = Number(row[0]);
        row.forEach(function (cell, j) {
          if (j !== 0)
            bodyRow = [
              ...bodyRow,
              typeof (cell) === 'string' ?
                <TableCell style={tableCellStyle} id={id} key={j}>
                  <div style={{ justifyContent: 'left', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '125px', textOverflow:'ellipsis'}}>
                    {cell}
                  </div>
                </TableCell> :
                <TableCell style={tableCellStyle} id={id} key={j}>
                  {cell}
                </TableCell>
              ,
            ];
        });
        bodyRows = [
          ...bodyRows,
          <GenericTableRow
            id={id}
            key={i}
            selected={selected}
            paths={paths}
            onClick={onClick}
          >
            {bodyRow}
          </GenericTableRow>,
        ];
        bodyRow = [];
      });
      bodyRows = [<TableBody key={2}>{bodyRows}</TableBody>];
    }

    return (
      <TableBase className={"table"} small={small} {...others} >
        {titleRow}
        {bodyRows}
      </TableBase>
    );
  }
}

export default Table;
