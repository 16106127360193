import styled from "styled-components";
import { alpha } from '@mui/material/styles';
import { Menu as BaseMenu, MenuItem as BaseMenuItem  } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

export const Menu = styled(BaseMenu).attrs((props) => ({
 
}))`

  font-size: ${(props) => props.theme.fonts.body.m.size};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  border-radius: 10px;
  color: ${(props) => props.theme.colors.text.classes};
  /* background-color: ${(props) => props.theme.colors.button.default}; */
 
  /* text-shadow: -0.1em -0.1em 0.15em ${(props) => props.theme.colors.boxShadow.neumo.light},
    0.1em 0.1em 0.15em ${(props) => props.theme.colors.boxShadow.neumo.contrast}; */



  .MuiMenu-paper {
    background-color: ${(props) => props.theme.colors.button.default};
    box-shadow: 
  0 100px 80px rgb(0 0 0 / 2%), 
  0 41.7776px 33.4221px rgb(0 0 0 / 2%), 
  0 22.3363px 17.869px rgb(0 0 0 / 3%), 
  0 12.5216px 10.0172px rgb(0 0 0 / 3%), 
  0 6.6501px 5.32008px rgb(0 0 0 / 4%), 
  0 2.76726px 2.21381px rgb(0 0 0 / 7%); 

  }



`;

export const MenuItem = styled(BaseMenuItem).attrs((props) => ({
  disableRipple: true

}))`
  font-size: ${(props) => props.theme.fonts.body.m.size};


  background-color:  ${(props) => props.theme.colors.button.default} !important;

  :hover {
    background-color:  ${(props) => alpha(props.theme.colors.text.classes,0.075)} !important;
  }
  :active {
    background-color:  ${(props) => alpha(props.theme.colors.text.classes,0.12)} !important;
  }
`;
