export const MainRoutes = [
  {
    path: '/library',
    component: "ScreensLibrary",
    importScreenPath: 'Library',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/settings',
    component: "ScreensSettings",
    importScreenPath: 'Project/Settings',
    projectRequired: true,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/account',
    component: "ScreensAccount",
    importScreenPath: 'User/Account',
    projectRequired: true,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/contact-us',
    component: "ScreensContactUs",
    importScreenPath: 'Other/ContactUs',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/site/terms',
    component: "ScreensSiteTerms",
    importScreenPath: 'Other/Site/Terms',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/site/privacy',
    component: "ScreensSitePrivacy",
    importScreenPath: 'Other/Site/Privacy',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/subfield/:subfieldPathName',
    component: "ScreensSubfield",
    importScreenPath: 'Library/Subfield',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/algorithm/:algorithmPathName/:algorithmVersionPathName',
    component: "ScreensAlgorithm",
    importScreenPath: 'Library/Algorithm',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/field/:fieldPathName',
    component: "ScreensField",
    importScreenPath: 'Library/Field',
    projectRequired: false,
    checkPermission: false,
    type: 'main'
  },
  {
    path: '/machines',
    component: "ScreensMachines",
    importScreenPath: 'Project/Machines',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_machines',
    type: 'main'
  },
  {
    path: '/machines/:machineUUID',
    component: "ScreensMachine",
    importScreenPath: 'Project/Machines/Machine',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_machines',
    type: 'main'
  },
  {
    path: '/datasets',
    component: "ScreensDatasets",
    importScreenPath: 'Project/Datasets',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_datasets',
    type: 'main'
  },
  {
    path: '/datasets/image/:datasetId',
    component: "ScreensDataset",
    importScreenPath: 'Project/Datasets/Dataset',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_datasets',
    type: 'main'
  },
  {
    path: '/training',
    component: "ScreensTraining",
    importScreenPath: 'Project/Training',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_training_sessions',
    type: 'main'
  },
  {
    path: '/training/setup/:trainingSessionName',
    component: "ScreensTrainingSetup",
    importScreenPath: 'Project/Training/Setup',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_training_sessions',
    type: 'main'
  },
  {
    path: '/deployments',
    component: "ScreensDeployments",
    importScreenPath: 'Project/Deployments',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_deployments',
    type: 'main'
  },
  {
    path: '/deployments/setup/:deploymentName',
    component: "ScreensDeploymentSetup",
    importScreenPath: 'Project/Deployments/Setup',
    projectRequired: true,
    checkPermission: true,
    actionName: 'create_deployments',
    type: 'main'
  },
  {
    path: '/deployments/:projectId/:deploymentId',
    component: "ScreensDeployment",
    importScreenPath: 'Project/Deployments/Deployment',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_deployments',
    type: 'main'
  }
];

export const FullscreenRoutes = [
  {
    path: '/welcome',
    component: "ScreensWelcome",
    importScreenPath: 'Other/Welcome',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/custom-plan',
    component: "ScreensCustomPlan",
    importScreenPath: 'Other/CustomPlan',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/verify-email/:verificationKey',
    component: "ScreensEmailVerification",
    importScreenPath: 'User/EmailVerification',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/reset-password/:uid/:token',
    component: "ScreensPasswordReset",
    importScreenPath: 'User/PasswordReset',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/join/:invitationKey',
    component: "ScreensAceptInvite",
    importScreenPath: 'User/AceptInvite',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/authentication/:mode',
    component: "ScreensAuthentication",
    importScreenPath: 'User/Authentication',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/authentication/signup/success',
    component: "ScreensSignUpSuccess",
    importScreenPath: 'User/SignUpSuccess',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/authentication/signup/copilot/success',
    component: "ScreensCopilotSignUpSuccess",
    importScreenPath: 'User/CopilotSignUpSuccess',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/training/session/:projectId/:projectKey/:trainingSessionKey',
    component: "ScreensTrainingSession",
    importScreenPath: 'Project/Training/Session',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_training_sessions',
    type: 'fullscreen'
  },
  {
    path: '/projects/:projectId/datasets/image/:datasetId/tool/:exampleId',
    component: "ScreensImageDatasetTool",
    importScreenPath: 'DatasetTools/ImageDatasetTool',
    projectRequired: true,
    checkPermission: true,
    actionName: 'view_datasets',
    type: 'fullscreen'
  },
  {
    path: '/maintenance',
    component: "ScreensOnMaintenance",
    importScreenPath: 'Other/OnMaintenance',
    projectRequired: false,
    checkPermission: false,
    type: 'fullscreen'
  },
  {
    path: '/superadmin',
    component: "ScreensSuperAdmin",
    importScreenPath: 'Other/SuperAdmin',
    projectRequired: false,
    checkPermission: true,
    type: 'fullscreen'
  }
];

export const SideMenuRoutes = [
  {
    path: '/library',
    menuItemName: 'Library',
    icon: 'library',
    projectRequired: false,
    checkPermission: false,
    adminRequired: false
  },
  {
    path: '/datasets',
    menuItemName: 'Datasets',
    icon: 'folder',
    projectRequired: true,
    checkPermission: true,
    adminRequired: false,
    actionName: 'view_datasets',
  },
  {
    path: '/machines',
    menuItemName: 'Machines',
    icon: 'monitor',
    projectRequired: true,
    checkPermission: true,
    adminRequired: false,
    actionName: 'view_machines'
  },
  {
    path: '/training',
    menuItemName: 'Train',
    icon: 'gym',
    checkPermission: true,
    projectRequired: true,
    adminRequired: false,
    actionName: 'view_training_sessions'
  },
  {
    path: '/deployments',
    menuItemName: 'Deploy',
    icon: 'rocket',
    checkPermission: true,
    projectRequired: true,
    adminRequired: false,
    actionName: 'view_deployments'
  },
  {
    path: '/settings',
    menuItemName: 'Settings',
    icon: 'settings',
    projectRequired: true,
    checkPermission: false,
    adminRequired: false
  },
  {
    path: '/superadmin',
    menuItemName: 'Admin',
    icon: 'user-lock',
    projectRequired: true,
    checkPermission: false,
    adminRequired: true
  },
];