import React from 'react';
import * as S from './style'


function ProgressBar({ percentage, ...props }) {
  return (

    <S.Border {...props}>
      <S.ProgressDiv percentage={percentage}/>
    </S.Border>

  );
}

export default ProgressBar;
