//LIBRARIES
import React from "react";
//STYLEDCOMPONENTS
import {
  StyledContainer as Container,
  //StyledTextField as TextField,
  StyledLabel as Label,
  StyledLabelContainer as LabelContainer,
  StyledIconContainer as IconContainer,
  StyledIcon as Icon,
  StyledLabelBottom as LabelBottom,
  StyledLabelBottomContainer as LabelBottomContainer,
} from "./style";

import { GrowIn } from "components/UI/Animations";
import { TextField } from "components/UI";

function IconInLabel(props) {
  const { valid } = props;
  if (valid !== null)
    return valid ? (
      <GrowIn>
        <IconContainer>
          <Icon valid={valid ? 1 : 0} name={"solid circle-check"} />
        </IconContainer>
      </GrowIn>
    ) : (
      <GrowIn>
        <IconContainer>
          <Icon valid={valid ? 1 : 0} name={"solid circle-minus"} />
        </IconContainer>
      </GrowIn>
    );
  else return <></>;
}

function InputBasePresentational(props) {
  const {
    valid,
    label,
    handleOnChange,
    topRightLabelComponent,
    onBlur,
    onFocus,
    bottomLabel,
    buttonComponent,
    focused,
    showValidIcon,
    value,
    type,
    innerRef,
    ...other
  } = props;

  //let showBottomLabel = valid !== null && valid && !focused;
  let showBottomLabel =  (!valid || focused) && (bottomLabel !== undefined) && (bottomLabel !== '') && (valid !== null);

 

  return (
    <Container {...other}>
      {label && (
        <LabelContainer>
          <Label valid={valid === null ? true : valid}>{label}</Label>
          {topRightLabelComponent ? (
            topRightLabelComponent
          ) : (
            showValidIcon && <IconInLabel valid={valid} />
          )}
        </LabelContainer>
      )}
          <TextField
            value={value}
            valid={valid === null ? true : valid}
            onChange={handleOnChange}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type}
            button={buttonComponent}
            innerRef={innerRef}
          />
      <LabelBottomContainer showBottomLabel={showBottomLabel}> 
        {showBottomLabel ? (
          <LabelBottom valid={valid}>{bottomLabel}</LabelBottom>
        ) : (
          <></>
        )}
      </LabelBottomContainer>
      {/* <LabelBottomContainer valid={displayValid} focused={focused}>
          <LabelBottom valid={valid}>{bottomLabel}</LabelBottom>
      </LabelBottomContainer> */}
    </Container>
  );
}

export default InputBasePresentational;

// "You will have to verify that you are the owner of this email account":
// "* Please enter a valid email."
