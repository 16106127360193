import React from "react";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as ThemeProviderMaterial, StyledEngineProvider } from "@mui/material/styles";
import materialTheme from "styles/theme/materialTheme";
import theme from "styles/theme";
import GlobalStyles from "styles/Global";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({}));

function ThemeProviders(props) {
  useStyles();
  return (
    <StyledEngineProvider injectFirst>
    <ThemeProviderMaterial theme={materialTheme}>
      <ThemeProvider theme={theme}>
        {props.children}
        <GlobalStyles/>
      </ThemeProvider>
    </ThemeProviderMaterial>
    </StyledEngineProvider>
  );
}

export default ThemeProviders;