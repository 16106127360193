import { combineReducers } from 'redux';
import MainDeploymentReducer from 'components/Project/Deployments/Deployment/state/reducer';
import DeploymentCreateReducer from 'components/Project/Deployments/Deployment/state/Create/reducer';
import DeploymentDeleteReducer from 'components/Project/Deployments/Deployment/state/Delete/reducer';
import DeploymentSetupReducer from 'components/Project/Deployments/Setup/state/reducer';
import DeploymentPlaygroundReducer from './Playground/reducer';

const DeploymentReduce = combineReducers({
    main: MainDeploymentReducer,
    setup: DeploymentSetupReducer,
    create: DeploymentCreateReducer,
    delete: DeploymentDeleteReducer,
    playground: DeploymentPlaygroundReducer
});

export default DeploymentReduce;