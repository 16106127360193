import styled from "styled-components";
import { ScrollableDiv } from "components/UI";


export const Content = styled.div`
&& {

}
`;

// CAMBIAR ESTE SI SE QUIERE NEUMO
export const Container = styled(ScrollableDiv)` 
&& {
  height: 100%;
  overflow-y: auto;

&:hover {
  overflow-y: auto;
}
}
`;
