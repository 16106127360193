import * as Types from "./types";
import { api } from 'state/api';
import { fetchProject } from 'components/Project/state/actions';
import { displayError } from 'utils';


function projectsInit(){
    return {
        type:Types.PROJECTS_INIT
    }
}

function projectsRequest(){
    return {
        type:Types.PROJECTS_REQUEST
    }
}

function projectsSuccess(projects){
    return {
        type:Types.PROJECTS_SUCCESS,
        payload: {
            projects
        }
    }
}

function projectsFailure(error){
    return {
        type:Types.PROJECTS_FAILURE,
        payload: {
            error:error
        }
    }
}


export function initProjects(){
    return (dispatch) => {
        dispatch(projectsInit());
    }
}

export function fetchProjects(){
    return (dispatch) => {
        dispatch(projectsRequest());
        api()
            .get(`/projects/all/`)
            .then(response => {
                const memberships = response.data;
                let projects = []

                memberships.forEach((membership) => {
                    projects = [...projects, membership.project];
                });

                dispatch(projectsSuccess(projects));
                if(projects.length > 0){
                    dispatch(fetchProject(projects[0].id));
                }
            })
            .catch(error => {
                dispatch(projectsFailure(error.message));
                displayError(error);
            })
    }
}