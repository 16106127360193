import styled from "styled-components";
import { Elements as BaseElements } from "@stripe/react-stripe-js";
import { Loader as BaseLoader } from "components/UI";

import {
  Typography,
  //Checkbox as BaseCheckbox
} from "components/UI";



export const Title = styled(Typography).attrs((props) => ({
  size: "s",
}))``;

export const Subtitle = styled(Typography).attrs((props) => ({
  size: "xs",
  color: "light",
}))`
  white-space: nowrap;
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Container = styled.div`
 
`;

export const Section = styled.div`
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;
`;


export const Loader = styled(BaseLoader)`
  margin-bottom: 16px;
`

export const Elements = styled(BaseElements).attrs((props) => ({
  options: {
    ...props.options,
    appearance: {
      theme: "stripe",
      variables: {
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        colorBackground: props.theme.colors.input.default,
        colorPrimary: "blue",
        // colorBackground: '#ffffff',
        colorText: props.theme.colors.text.default,
        colorDanger: props.theme.colors.text.error,
        colorTextPlaceholder: props.theme.colors.text.light,
        // fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: "3px",
        borderRadius: "4px",
        fontWeight: props.theme.fonts.weight.bold,

        //colorPrimary: 'red',
        // colorBackground: '#ffffff',
        // colorText: '#30313d',
        // colorDanger: '#df1b41',
        // spacingUnit: '2px',
        // borderRadius: '4px',
        // See all possible variables below
      },

      rules: {
        ".Input:focus": {
          boxShadow: "0px 0px 0px 2px rgba(177, 181, 254,0.6)",
          backgroundColor: "white",
        },

        ".Label": {
          fontWeight: props.theme.fonts.weight.bold,
          textTransform: "uppercase",
          color:props.theme.colors.text.medium,
          fontSize: props.theme.fonts.title.xs.size,
          marginTop: "8px",
          marginBottom: "8px",
        },

        ".Input": {
          //backgroundColor: theme.colors.bg.default,
          //backgroundColor: 'rgba(0, 0, 0, 0.025)',
          border: "none",
          boxShadow: "0 2px 0px 0 white",
          //color: theme.colors.text.light,
        },
      },
    },
  },
}))`
`;
