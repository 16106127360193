//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';

//STYLEDCOMPONENTS
import { 
  StyledBorderedDiv as BorderedDiv,
  StyledTitle as Title
} from "./style";

import {AccompaniedTitle} from 'components/UI'
  
function NeumoCard(props) {
   const {loading, children, title, titleSize, connector, subtitle, paddingBottom, ...other } = props;
  return (
      <>
    {!(title===undefined && subtitle===undefined) && <Title loading={loading} title={title} size={titleSize} paddingBottom={paddingBottom === undefined?"10px":paddingBottom} connector={connector}>{subtitle}</Title>}
    <BorderedDiv {...other}>
        {children}
    </BorderedDiv>
    </>
  );
}
 
NeumoCard.propTypes = {
  title: PropTypes.string
};

export default NeumoCard;