//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';

//STYLEDCOMPONENTS
import {
  StyledAccordion as Accordion,
  StyledAccordionSummary as AccordionSummary,
  StyledAccordionDetails as AccordionDetails,
  Container
} from "./style";

  
function Genetic(props) {
  const { children, name, ...other } = props;
  return (
    <Container>

    </Container>
  );
}
 
Genetic.propTypes = {
  name: PropTypes.string
};

export default Accordion;
export { AccordionSummary, AccordionDetails };