import styled from "styled-components";

import { Paper } from "@mui/material";

import {Button} from 'components/UI';

export const StyledContainer = styled(Paper).attrs((props) => ({
    // elevation: 0,
}))`
    && {
        color: ${props=>(props.theme.colors.text.default)};
    }
`;


export const StyledButton= styled(Button).attrs((props) => ({
}))`
    && {
        position: absolute;
        bottom: -20px;
        width: 10em;
    }
`;
