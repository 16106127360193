import styled from "styled-components";
import { Range } from 'rc-slider';

export const BaseSlider = styled(Range).attrs((props) => ({}))`
  /* color: ${(props) => props.theme.colors.button.primary.left};  */
  /* color: ${(props) => props.theme.colors.text.classes}; */

&& {

        .rc-slider-rail {
                /* background-color:${(props) => props.theme.colors.button.primary.left};  */
                background-color:${(props) => props.theme.colors.text.classes}; 
               
        }

        .rc-slider-track {
                /* background-color:${(props) => props.theme.colors.button.primary.right};  */
                background-color:${(props) => props.theme.colors.text.classes}; 
                
        }

        .rc-slider-handle{
                border: 2px solid ${(props) => props.theme.colors.text.classes};
        }

        .rc-slider-handle-dragging{
                box-shadow: 0 0 4px 2px ${(props) => props.theme.colors.text.classes}; 
        }
}
  /* && {
    .MuiSlider-root {
      color: #3880ff;
      height: 2px;
      padding: 15px 0;
    }

    .MuiSlider-thumb {
      height: 28px;
      width: 28px;
      background-color: #fff;
      box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
        0 0 0 1px rgba(0, 0, 0, 0.02);
      margin-top: -14px;
      margin-left: -14px;
      &:focus,
      &:hover {
        box-shadow: 0 3px 1px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.3),
          0 0 0 1px rgba(0, 0, 0, 0.02);
      }
    }

    .MuiSlider-valueLabel {
      left: calc(-50% + 12px);
      top: -22px;
      & * {
        background: transparent;
        color: #000;
      }
    }

    .MuiSlider-track {
      height: 2px;
    }
    .MuiSlider-rail {
      height: 2px;
      opacity: 0.5px;
      background-color: #bfbfbf;
    }

    .MuiSlider-mark {
      background-color: #bfbfbf;
      height: 8px;
      width: 1px;
      margin-top: -3px;
    }

    .MuiSlider-markActive {
      opacity: 1;
      background-color: currentColor;
    }
  } */


`;
