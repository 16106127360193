import styled from "styled-components";
import { Popover as BasePopover } from "components/UI";
import BaseIcon from "icons";
import hexToRgba from "hex-to-rgba";

const minSpread = 5;
const maxSpread = 20;
const minBlurLength = 15;
const borderRadius = 10;
const ttime = 200;
const tfunction = "ease-in";

export const Button = styled.div`
  /* DEFAULT BUTTON */
  && {
    text-transform: none;
    font-size: 0.9rem;
    display: flex;
    padding-left: 20px;
    font-weight: ${(props) => props.selected? props.theme.fonts.weight.medium:props.theme.fonts.weight.medium};
    justify-content: flex-start;
    align-items: center;
    color: ${(props) =>
    props.selected
      ? props.theme.colors.text.mediumPurple
      : props.theme.colors.text.light};

    border-right: ${(props) =>
    props.selected && "2px solid " + hexToRgba(props.theme.colors.text.mediumPurple, 0.85)};
    background-color: ${(props) =>
    props.selected
      ? hexToRgba(props.theme.colors.text.mediumPurple, 0.1)
      : "none"};


    /* border-radius: ${borderRadius}px 0px 0px ${borderRadius}px; */
    z-index: 1;

    opacity: ${(props) => (props.open ? 1 : 0)};

    box-shadow: ${(props) =>
    props.selected
      ? "inset -" +
      maxSpread +
      "px 0 " +
      minBlurLength +
      "px -20px " +
      hexToRgba(props.theme.colors.colorful.primary, 0.7)
      : "none"};

    transition: color ${ttime}ms ${tfunction},
      box-shadow ${ttime}ms ${tfunction},
      background-color ${ttime}ms ${tfunction};
  }

  &:hover {
    background-color: ${(props) =>
    props.selected
      ? hexToRgba(props.theme.colors.text.mediumPurple, 0.1)
      : props.theme.colors.button.hover};
    //color: ${(props) =>
    props.selected
      ? props.theme.colors.colorful.primary
      : hexToRgba(props.theme.colors.colorful.primary, 0.7)};
    transition: color ${ttime}ms ${tfunction},
      box-shadow ${ttime}ms ${tfunction},
      background-color ${ttime}ms ${tfunction};

    /* box-shadow: -${minSpread}px -${minSpread}px ${minBlurLength}px -1px ${(
        props
      ) =>
    props.theme.colors.boxShadow.neumo
      .light}, -1px ${minSpread}px ${minBlurLength}px -3px ${(props) =>
        props.theme.colors.boxShadow.neumo.contrast}; */

    //box-shadow: inset -${maxSpread}px 0 ${minBlurLength}px -20px
    ${(props) => hexToRgba(props.theme.colors.colorful.primary, 0.7)};
  }

  &:active {
    /* background-color: ${(props) => props.theme.colors.button.default}; */
    background-color: ${(props) =>
    props.selected
      ? hexToRgba(props.theme.colors.text.mediumPurple, 0.1)
      : props.theme.colors.button.hover};
    /* box-shadow: -${maxSpread}px -${maxSpread}px ${minBlurLength}px -1px ${(
        props
      ) =>
    props.theme.colors.boxShadow.neumo
      .light},-1px ${maxSpread}px ${maxSpread}px -3px ${(props) =>
        props.theme.colors.boxShadow.neumo.contrast}; */
  }

  &:focus {
    background-color: ${(props) =>
    props.selected
      ? hexToRgba(props.theme.colors.text.mediumPurple, 0.1)
      : props.theme.colors.button.default};
    /* box-shadow: -${maxSpread}px -${maxSpread}px ${minBlurLength}px -1px ${(
        props
      ) =>
    props.theme.colors.boxShadow.neumo
      .light}, -1px ${maxSpread}px ${maxSpread}px -3px${(props) =>
        props.theme.colors.boxShadow.neumo.contrast}; */
  }
`;

export const TextContainer = styled.div`
  && {
    margin-left: 13px;
    @media (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
        display:none;
    }
  }
`;

export const Icon = styled(BaseIcon).attrs((props) => ({
  size: "20px",
}))``;

export const Popover = styled(BasePopover).attrs((props) => ({
}))`
 .MuiPopover-paper {
  padding: 8px;
  margin-left:5px;
 }
`;
