import React from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

function Select(props) {
  const { children, paperProps, ...others } = props;

  //   let element = S.Paper
  // element.style = {backgroundColor: "red !important"}

  

  return (
    <S.Select
      variant="outlined"
      {...others}
      IconComponent={S.ArrowIcon}
      MenuProps={{
        PaperProps: {
          sx: {
            '& .MuiList-root': paperProps
          },
          component: S.Paper,
        },
      }}
    >
      {children}
    </S.Select>
  );
}

export default Select;

const SelectItem = React.forwardRef((props, ref) => {
  const { children, ...others } = props;

  return (
    <S.SelectItem
      //innerRef={ref}
      {...others}
    >
      {children}
    </S.SelectItem>
  );
});

export { SelectItem };
