//LIBRARIES
import React, { useState } from "react";
import {
  StyledContainer as Container,
  StyledInput as Input,
  StyledLoader as Loader,
  StyledText as Text,
  StyledPrimaryButton as PrimaryButton,
} from "./style";
import { Typography } from "components/UI";
import { openApi } from "state/api";
import { displayError } from "utils";


export default function ResendEmailVerification(props) {
  const { toggleEmailVerification } = props;
  const [mailDispatched, setMailDispatched] = useState(false);
  const [sending, setSending] = useState(false);
  const [inputs, setInputs] = useState({
    email: { value: "", valid: null },
  });

  function onResendEmailClick() {
    setSending(true);
    openApi()
        .post('/auth/resend-account-email-confirmation/', {email:inputs.email.value})
        .then(response => {
            setMailDispatched(true);
            setSending(false);
        })
        .catch(error => {
          displayError(error);
          setSending(false);
        })
    
  }

  const handleFieldChange = (fieldId, input) => {
    setInputs({ ...inputs, [fieldId]: input });
  };

  return (
    <Container>
      {!sending ? (
        <>
          <Typography
            style={{ alignSelf: "flex-start" }}
            weight={"bold"}
            size={"l"}
          >
            {mailDispatched ? "Check your email" : "Email Verification"}
          </Typography>
          {mailDispatched ? (
            <Text>
              Open the account verification email we have sent to you, it may take a few minutes to arrive. Be sure to check any spam and promotional folders, just in case.
            </Text>
          ) : (
            <Text>Please enter the email you used to create your account</Text>
          )}
          {!mailDispatched && (
            <Input
              type="email"
              label={"Email"}
              id={"email"}
              onChange={handleFieldChange}
              input={inputs["email"]}
              focus={true}
            />
          )}
          {mailDispatched ? (
            <PrimaryButton onClick={toggleEmailVerification}>Done</PrimaryButton>
          ) : (
            <PrimaryButton
              disabled={!inputs["email"].valid}
              onClick={onResendEmailClick}
            >
              Resend
            </PrimaryButton>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
}
