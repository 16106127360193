import styled from "styled-components";
import { Link as BaseLink} from "react-router-dom";

export const Container = styled.div`
    position: fixed;
    transform: translate(-${props => (props.open ? "0" : props.theme.breakpoints.components.sidemenu+"px")},0);
    width: ${props => (props.theme.breakpoints.components.sidemenu)}px;

    /* @media (min-width: ${props => (props.theme.breakpoints.tabletLandscape.min)}px) and (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
        transform: translate(-${props => (props.open ? "0" : props.theme.breakpoints.components.IconsSidemenu+"px")},0);
        width: ${props => (props.theme.breakpoints.components.IconsSidemenu)}px;
    } */


    @media (max-width: ${props => (props.theme.breakpoints.desktop.max)}px) {
        transform: translate(-${props => (props.open ? "0" : props.theme.breakpoints.components.IconsSidemenu+"px")},0);
        width: ${props => (props.theme.breakpoints.components.IconsSidemenu)}px;
    }

  
    pointer-events:${props => (!props.open && "none")};
    white-space: nowrap;
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;

    height: 100vh;

    z-index:100;
    /* opacity: ${props => (props.open ? 1 : 0)}; */

    @media (max-width: ${props => (props.theme.breakpoints.components.overlapSidemenuMin)}px) {
    opacity:1;
    }


    /* @media (max-width: ${props => (props.theme.breakpoints.tablet.max)}px) {
        background-color: ${props => (props.theme.colors.bg.default)};
    } */

    transition: transform 300ms;
`;

export const ToolbarIcon = styled.div`
    && {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px;
    }
`;



export const ListContainer = styled.div`
&& {
  flex-grow: 6;
  display: grid;
  grid-template-rows: repeat(10, 45px);
  grid-row-gap: 0px;
}
`;



export const Copyright = styled.div`
&& {
    display: flex;
    justify-content: center;
    align-items: center;
    color:${props=>(props.theme.colors.text.light)};
    font-size:12px;
    bottom:0;
    width:100%;
    height:50px;
}
`;

export const LegalLinks = styled.div`

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-self: center;
    color:${props=>(props.theme.colors.text.light)};
    font-size:12px;
    bottom:0;
    width:80%;
    height:50px;

`;

export const Link = styled(BaseLink)`
    color:${props=>(props.theme.colors.text.light)};
    font-size:12px;
    width:100%;
    margin:5px;
    :hover {
        text-decoration: underline;
    }
`;


