//LIBRARIES
import React, { useEffect } from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

import useImage from "use-image";

function FullscreenImage(props) {
  const { media } = props;
  const [image] = useImage(media.original);
  const [aspectRatio, setAspectRatio] = React.useState(0);
  const [insideLoading, setInsideLoading] = React.useState(true);

  function onLoad() {
    setInsideLoading(false);
  }

  useEffect(() => {
    if (image) setAspectRatio(image.width / image.height);
  }, [image]);

  return (
    <>
          <S.Container aspectRatio={aspectRatio}>
          <S.Animation aspectRatio={aspectRatio} id={media.id}>
          {insideLoading && <S.Loader />}
            <S.Image
              onLoad={onLoad}
              src={media.original}
              aspectRatio={aspectRatio}
            />
            </S.Animation>
          </S.Container>
     

    </>
  );
}

export default FullscreenImage;
