//LIBRARIES
import React from 'react';
import styled from "styled-components";
import IconBase from 'icons';
import hexToRgba from 'hex-to-rgba';

const borderRadius = 10;

export const Container = styled.div`
    border-radius: ${borderRadius}px;
    display: flex;
    width: 100%;
    padding: 20px 20px;
    align-items: center;
    box-shadow: ${(props) => `inset 0px 0px 5px 0px ${props.theme.colors.text.error},
    0px 0px 5px 0px ${props.theme.colors.text.error}`};
    background-color: ${(props) => props.theme.colors.div.secondary.error.bg};
`;

export const Info = styled.div`
  flex-grow:1;
  font-size: ${(props) => props.theme.fonts.title.s.size};
  font-weight: ${(props) => props.theme.fonts.weight.medium};
  text-align: left;
  padding-left: 20px;
`;

export const Icon = styled(IconBase).attrs((props) => ({
size:"20px",
}))`

min-width:20px;
  color: ${(props) =>  props.theme.colors.text.error};
  filter: drop-shadow(-2px -2px 1px white) drop-shadow(2px 2px 1px rgba(0,0,0,0.1));
  filter: drop-shadow(0px 0px 1px white) drop-shadow(0px 0px 5px ${(props) => hexToRgba(props.theme.colors.text.error,0.3)});
`;



export default function WrongCredentials(props) {
  const {signInError} = props;
  return (
  <Container>
    <Icon name="solid circle-minus"/>
    <Info>{signInError}</Info>
  </Container>
  );
}

