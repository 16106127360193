import * as Types from './types';


const initialState = {
    algorithm:{},
    weights:{}
}


function DeploymentSetupReducer(state=initialState, action){
    switch(action.type){
        case Types.DEPLOYMENT_INIT:
            return initialState;

        case Types.DEPLOYMENT_ALGORITHM_SET:
            return {
                ...state,
                algorithm:action.payload.algorithm
            }

        case Types.DEPLOYMENT_WEIGHTS_SET:
            return {
                ...state,
                weights:action.payload.weights
            }

        default: return state;
    }
}

export default DeploymentSetupReducer;