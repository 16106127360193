import styled from "styled-components";
const padding = 20;

export const Header = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

export const MainWrapper = styled.main`
  /* background-color: blue; */
  padding-top: 20px;
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.components.contentMax}px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet.max}px) {
    font-size: 10px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Container = styled.div`
  display: flex;
  padding: 0px ${padding}px 0px ${padding}px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  min-width: ${(props) => props.theme.breakpoints.phone.min}px;
`;