export const DATASET_INIT = 'DATASET_INIT';
export const DATASET_REQUEST = 'DATASET_REQUEST';
export const DATASET_REQUEST_SUCCESS = 'DATASET_REQUEST_SUCCESS';
export const DATASET_REQUEST_FAILURE = 'DATASET_REQUEST_FAILURE';

export const DATASET_CLASSNAMES_ADD = 'DATASET_CLASSNAMES_ADD';
export const DATASET_CLASSNAMES_ADD_SUCCESS = 'DATASET_CLASSNAMES_ADD_SUCCESS';
export const DATASET_CLASSNAMES_ADD_FAILURE = 'DATASET_CLASSNAMES_ADD_FAILURE';

export const DATASET_CLASSNAME_EDIT = 'DATASET_CLASSNAME_EDIT';
export const DATASET_CLASSNAME_EDIT_SUCCESS = 'DATASET_CLASSNAME_EDIT_SUCCESS';
export const DATASET_CLASSNAME_EDIT_FAILURE = 'DATASET_CLASSNAME_EDIT_FAILURE';

export const DATASET_CLASSNAMES_DELETE = 'DATASET_CLASSNAMES_DELETE';
export const DATASET_CLASSNAMES_DELETE_SUCCESS = 'DATASET_CLASSNAMES_DELETE_SUCCESS';
export const DATASET_CLASSNAMES_DELETE_FAILURE = 'DATASET_CLASSNAMES_DELETE_FAILURE';

export const DATASET_CLASSNAMES_MERGE = 'DATASET_CLASSNAMES_MERGE';
export const DATASET_CLASSNAMES_MERGE_SUCCESS = 'DATASET_CLASSNAMES_MERGE_SUCCESS';
export const DATASET_CLASSNAMES_MERGE_FAILURE = 'DATASET_CLASSNAMES_MERGE_FAILURE';

export const DATASET_FILESYSTEM_LOAD = 'DATASET_FILESYSTEM_LOAD';
export const DATASET_FILESYSTEM_SET = 'DATASET_FILESYSTEM_SET';
export const DATASET_FILESYSTEM_RESET = 'DATASET_FILESYSTEM_RESET';

export const DATASET_FILESYSTEM_IMAGES_SET = 'DATASET_FILESYSTEM_IMAGES_SET';
export const DATASET_FILESYSTEM_IMAGE_LABEL_ADD = 'DATASET_FILESYSTEM_IMAGE_LABEL_ADD';
export const DATASET_FILESYSTEM_IMAGE_LABELS_SET = 'DATASET_FILESYSTEM_IMAGE_LABELS_SET';
export const DATASET_FILESYSTEM_IMAGE_LABELS_LOAD_PROGRESS_SET = 'DATASET_FILESYSTEM_IMAGE_LABELS_LOAD_PROGRESS_SET';
export const DATASET_FILESYSTEM_CLASSNAME_SET = 'DATASET_FILESYSTEM_CLASSNAME_SET';
export const DATASET_FILESYSTEM_CLASSNAMES_SET = 'DATASET_FILESYSTEM_CLASSNAMES_SET';
export const DATASET_FILESYSTEM_IMAGE_GALLERY_STATUS_SET = 'DATASET_FILESYSTEM_IMAGE_GALLERY_STATUS_SET';

export const DATASET_FILESYSTEM_LABELS_LOAD = 'DATASET_FILESYSTEM_LABELS_LOAD';
export const DATASET_FILESYSTEM_LABELS_LOAD_SUCCESS = 'DATASET_FILESYSTEM_LABELS_LOAD_SUCCESS';

export const DATASET_FILESYSTEM_SPLIT = 'DATASET_FILESYSTEM_SPLIT';
export const DATASET_FILESYSTEM_SPLIT_PERCENTAGES_SET = 'DATASET_FILESYSTEM_SPLIT_PERCENTAGES_SET';
export const DATASET_FILESYSTEM_SPLIT_UPDATE = 'DATASET_FILESYSTEM_SPLIT_UPDATE';

export const DATASET_GALLERY_IMAGE_ADD = 'DATASET_GALLERY_IMAGE_ADD';
export const DATASET_GALLERY_LOAD_START = 'DATASET_GALLERY_LOAD_START';
export const DATASET_GALLERY_LOAD_END = 'DATASET_GALLERY_LOAD_END';
export const DATASET_GALLERY_IMAGES_REMOVE = 'DATASET_GALLERY_IMAGES_REMOVE';
export const DATASET_GALLERY_PAGE_SET = 'DATASET_GALLERY_PAGE_SET';

export const DATASET_GALLERY_STATISTICS_UPDATE = 'DATASET_GALLERY_STATISTICS_UPDATE';

export const DATASET_TASK_PENDING = 'DATASET_TASK_PENDING';
export const DATASET_TASK_PROGRESS_UPDATE = 'DATASET_TASK_PROGRESS_UPDATE';
export const DATASET_TASK_FINISHED = 'DATASET_TASK_FINISHED';