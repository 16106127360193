import * as Types from "./types";
import update from "immutability-helper";

const initialState = {
    loaded:false,
    loading:false,
    loadingUsages:false,
    payload:{},
    tfjsCompatible:false,
    classes:{},
    ocrClasses:{},
    ocrModel:'disabled',
    ocrLanguage:'eng',
    error:''
}

function MainDeploymentReducer(state=initialState, action){
    switch(action.type){
        case Types.DEPLOYMENT_INIT:
            return initialState;
        
        case Types.DEPLOYMENT_REQUEST:
            return {
                loaded:false,
                loading:true,
                payload:{},
                tfjsCompatible:false,
                classes:{},
                ocrClasses:{},
                ocrModel:'disabled',
                ocrLanguage:'eng',
                error:''
            }
        
        case Types.DEPLOYMENT_REQUEST_SUCCESS:
            return {
                loaded:true,
                loading:false,
                payload:action.payload.deployment,
                tfjsCompatible:action.payload.tfjsCompatible,
                classes:action.payload.classes,
                ocrClasses:action.payload.ocrClasses,
                ocrModel:'disabled',
                ocrLanguage:'eng',
                error:''
            }

        case Types.DEPLOYMENT_REQUEST_FAILURE:
            return {
                loaded:true,
                loading:false,
                payload:{},
                tfjsCompatible:false,
                classes:{},
                ocrClasses:{},
                ocrModel:'disabled',
                ocrLanguage:'eng',
                error:action.payload.error
            }
        
        case Types.DEPLOYMENT_USAGES_REQUEST:
            return {
                ...state,
                loadingUsages:true
            }
        
        case Types.DEPLOYMENT_USAGES_SUCCESS:
            return update(state, {
                loadingUsages: { $set: false},
                payload: {
                    daily_usages: { $set: action.payload.daily_usages }
                }
            });
        
        case Types.DEPLOYMENT_USAGES_FAILURE:
            return {
                ...state,
                loadingUsages:false,
                error:action.payload.error
            }

        case Types.DEPLOYMENT_OCR_CLASS_SET:
            return update(state, {
                ocrClasses: {
                    [action.payload.className]: { $set: action.payload.active }
                }
            });

        case Types.DEPLOYMENT_OCR_MODEL_SET:
            return {
                ...state,
                ocrModel: action.payload.ocrModel
            }
        
        case Types.DEPLOYMENT_OCR_LANGUAGE_SET:
            return {
                ...state,
                ocrLanguage: action.payload.ocrLanguage
            }
        
        default: return state
    }
}

export default MainDeploymentReducer;