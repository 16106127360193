import styled from "styled-components";
import {
  Fullscreen,
  Typography,
  Text,
  Button,
  PrimaryActionButton,
} from "components/UI";
import IconBase from "icons";

export const Modal = styled(Fullscreen)`
    padding: 25px;
    position:relative;
    overflow:hidden;
    height:min-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: min-content;
    position: relative;
    width: min-content; 
  
  > div {
    padding: 5px 0px;
  }
`;


export const Icon = styled(IconBase).attrs((props) => ({
  //size:"small"
  size: "40%",
  name:
    props.type === "error"
      ? "close"
      : props.type === "alert"
      ? "solid alert"
      : "check",
}))`
  color: white;
`;

export const Title = styled(Typography).attrs((props) => ({
  size: "m",
}))`
  text-align: left;
  width: 300px;
`;

export const Message = styled(Text)`
  width: 100%;
  text-align: left;
`;



export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

export const CancelButton = styled(Button).attrs((props) => ({
  variant: "contained",
  color: "secondary",
}))``;

export const ConfirmButton = styled(PrimaryActionButton).attrs((props) => ({
  // variant: "contained",
  // color: "primary"
}))`
  margin-left: 16px;
`;
