//LIBRARIES
import React from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

function Loader(props) {
  const { children, name, size, width, ...other } = props;
  return (
    <S.Loader size={size ? size : 30} width={width ? width : 3} {...other} />
  );
}

export default Loader;
