import styled from "styled-components";
import React from 'react';
import { Button, Divider, Input} from 'components/UI';
//import Input from './Input';

export const StyledContainer = styled.div`
display: flex;
height: min-content;
flex-direction: column;
align-items: center;
justify-content: space-between;
width:100%;
& >:not(:first-child):not(:last-child) {
    margin: 5px 0px;
}
& >:first-child {
    margin-top: 15px;
    margin-bottom: 15px;
}
& >:last-child {
    margin-top: 15px;
}
`;


export const StyledDivider = styled(Divider)`
    flex-grow:1;
`;

export const StyledInput = styled(Input)`
    width:100%;
`;

export const StyledForgotPasswordButton = styled.div`
    width:130px;
    color: ${props=>(props.theme.colors.text.darkPurple)};
    cursor: pointer;
    text-align: left;
    align-self: flex-start;
    font-size:  ${props => (props.theme.fonts.title.xs.size)}; 
    font-weight: ${props=>(props.theme.fonts.weight.regular)};

    :hover {
        text-decoration: underline;
    }
`;

export const StyledResendEmailLinkButton = styled.div`
    width:100%;
    color: ${props=>(props.theme.colors.text.darkPurple)};
    cursor: pointer;
    text-align: left;
    align-self: flex-start;
    font-size:  ${props => (props.theme.fonts.title.xs.size)}; 
    font-weight: ${props=>(props.theme.fonts.weight.regular)};

    :hover {
        text-decoration: underline;
    }
`;

export const StyledResendEmailButton = styled(Button).attrs({
    variant:'contained',
    color:'secondary'
})`
width:100%;
`;


//OR
export const OrContainer = styled.div`
    display: flex;
    width:100%;
`;

export const OrDiv = styled.div`
    font-size: 14px;
    padding: 0px 20px;
`;

export function Or(props) {
    //const { ...other } = props;
    return (
      <OrContainer>
        <StyledDivider />
        <OrDiv>Or</OrDiv>
        <StyledDivider />
      </OrContainer>
    );
}