import * as Types from "./types";


const initialState = {
    loaded:false,
    info:{
        subfields:[]
    },
    error:''
}


function FieldReducer(state=initialState, action){
    switch(action.type){
        case Types.FIELD_INIT:
            return initialState

        case Types.FIELD_REQUEST:
            return {
                ...state,
                loaded:false
            }
        case Types.FIELD_SUCCESS:
            return {
                loaded:true,
                info:action.payload.field,
                error:''
            }
        case Types.FIELD_FAILURE:
            return {
                loaded:false,
                info:{},
                error:action.payload.error
            }
        default: return state
    }
}

export default FieldReducer