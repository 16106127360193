import styled from "styled-components";
const borderRadius = 14;

export const StyledVideo = styled.div`
  && {
    border-radius: ${borderRadius}px;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    flex-basis: 0;
    margin: 10px;
    box-shadow: -${(props) => props.theme.effects.neumo.spread}px -${(props) =>
          props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      ${(props) => props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.blurLength}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast};
  }
`;

export const StyledVideoContainer = styled.div`
  && {
    align-self: center;
    width: 100%;
    /* @media (min-width: ${(props) =>
        props.theme.breakpoints.notMobile.min}px) and (max-width: ${(props) =>
        props.theme.breakpoints.desktop.max}px) and (orientation: landscape) {
      width: 50%;
    } */
    margin-bottom: 30px;
}`;