import styled from "styled-components";
import { SketchPicker as SketchPickerBase } from "react-color";

export const SketchPicker = styled(SketchPickerBase)`
  background: ${(props) => props.theme.colors.bg.default} !important;
  width: 250px !important;
  padding: 10px 10px 0px !important;
  box-sizing: initial !important;
  //border: solid 1px ${(props) => props.theme.colors.border.default} !important;
  border-radius: 4px !important;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),
    -5px -5px 5px rgba(255, 255, 255, 1) important;

  .flexbox-fix {
    input {
      width: 80%;
      height: 25px !important;
      padding: 4px 10% 3px !important;
      border: solid 1px ${(props) =>
        props.theme.colors.border.default} !important;
      font-weight: ${(props) => props.theme.fonts.weight.bold} !important;
      box-shadow: rgb(204, 204, 204) 0px 0px 0px 1px inset;
      border-radius: 5px !important;
      font-size: 12px !important;
      text-align: center;

      box-shadow: ${(props) =>
        `inset -${props.theme.effects.neumo.spread / 2}px 
    -${props.theme.effects.neumo.spread / 2}px
      ${props.theme.effects.neumo.blurLength}px 0
      ${props.theme.colors.boxShadow.neumo.light},
     inset ${props.theme.effects.neumo.spread / 2}px
      ${props.theme.effects.neumo.spread / 2}px
      ${props.theme.effects.neumo.blurLength}px 0
      ${props.theme.colors.boxShadow.neumo.contrast} !important`};
    
  }
  }
`

export const Color = styled.div`
  width: ${p => p.small ? '16px' : '36px'};
  height: 16px;
  border-radius: 2px;
  background: ${(props) =>
    `rgb(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a || 1})`};
`;

export const Swatch = styled.div`
  padding: 4px;
  border-radius: 5px;
  margin-bottom:8px;
  background-color: ${(props) => props.theme.colors.bg.default};
  border: solid 1px ${(props) => props.theme.colors.border.default};
  box-shadow: ${p => p.minimalist ?'none': `5px 5px 10px rgba(0, 0, 0, 0.1),
    -5px -5px 5px rgba(255, 255, 255, 1)`};
  display: inline-block;
  cursor: pointer;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
