import styled from "styled-components";
import { Loader as BaseLoader } from "components/UI";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: left;
  padding:35px;
`;

export const Loader = styled(BaseLoader).attrs((props) => ({
}))`
`;