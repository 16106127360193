//LIBRARIES
import React from 'react';
import Grow from '@mui/material/Grow';

//STYLEDCOMPONENTS
import * as S from "./style";

import {Radio as BaseRadio} from '@mui/material';


function Radio(props) {
  const {...others } = props;

  

  return (
    <S.Radio
      // sx={{
      //   '&:hover': {
      //     bgcolor: 'transparent',
      //   },
      // }}
      disableRipple
      // color="default"
      // checkedIcon={<S.CheckedIcon/>}
      // icon={<S.Icon/>}
      {...props}
    />
  );
}

export default Radio;