import { store } from 'state/store';
import axios from 'axios';
import { clearStorage } from "state/Root/actions";
import { logout } from "components/User/Auth/state/actions";
import { eraseAllCookies } from "utils/browser";
import { disconnect } from 'state/Websocket/actions';


function addResponseInterceptor(instance) {
    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response) {
            if (typeof error.response.data === 'object' && 'error' in error.response.data) {
                if (error.response.data.error === 'old_version') {
                    window.location.reload();
                } else if (error.response.data.error === 'on_maintenance') {
                    if(window.location.pathname !== '/maintenance'){
                        store.dispatch(disconnect());
                        store.dispatch(logout());
                        store.dispatch(clearStorage());
                        store.dispatch(eraseAllCookies());
                        window.location.href = '/maintenance';
                    }
                }else{
                    return Promise.reject(error);            
                }
            }else{
                return Promise.reject(error);
            }
        }else{
            return Promise.reject(error);
        }
    });
}

export function api() {
    const token = store.getState().user.auth.token;
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}`,
        headers: {
            'Authorization': `Token ${token}`,
            'API-Version': process.env.REACT_APP_API_VERSION,
            'Frontend-Version': process.env.REACT_APP_FRONTEND_VERSION
        }
    });
    addResponseInterceptor(instance);
    return instance;
}

export function openApi() {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'API-Version': process.env.REACT_APP_API_VERSION,
            'Frontend-Version': process.env.REACT_APP_FRONTEND_VERSION
        }
    });
    addResponseInterceptor(instance);
    return instance;
}

export function connectToWsApi(uri, onopen, onmessage, onerror, onclose) {
    const token = store.getState().user.auth.token;
    const url = `${process.env.REACT_APP_WS_API_URL}${uri}?token=${token}`;
    const websocket = new WebSocket(url);
    websocket.onopen = onopen;
    websocket.onmessage = onmessage;
    websocket.onerror = onerror;
    websocket.onclose = onclose;
    return websocket;
}