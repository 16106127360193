//LIBRARIES
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
//STYLEDCOMPONENTS
import * as S from "./style";
import ChangeProject from "./ChangeProject";
import NewProject from "./NewProject";
import { fetchProjects } from "components/User/Projects/state/actions";
import { createProject } from "components/Project/state/Create/actions";
import { BigScreen, SmallScreen } from 'components/UI/Devices';
import { Loader } from "components/UI";
import SelectPlan from "./SelectPlan";

function Projects(props) {
  const [isChangeProjectFullscreen, setIsChangeProjectFullscreen] = React.useState(false);
  const [isNewProjectFullscreen, setIsNewProjectFullscreen] = React.useState(false);
  const dispatch = useDispatch();
  const projects = useSelector((store) => store.projects.payload);
  const projectsLoadError = useSelector((store) => store.projects.error);
  const loaded = useSelector((store) => store.projects.loaded);
  const selectedProject = useSelector((store) => store.project.main.payload);
  const selectedProjectLoaded = useSelector((store) => store.project.main.loaded);
  const hasProjects = projects.length > 0;
  const [reachedLimit, setReachedLimit] = useState(false);
  const [limitTitle, setLimitTitle] = useState('Choose a plan for your new project');
  const [limitDescription, setLimitDescription] = useState('You reached your free plans limit. Choose professional plan for your new project.');
  const [newProjectName, setNewProjectName] = useState();

  function toggleIsChangeProjectFullscreen() {
    setIsChangeProjectFullscreen(!isChangeProjectFullscreen);
  }

  function toggleIsNewProjectFullscreen() {
    setIsNewProjectFullscreen(!isNewProjectFullscreen);
  }

  function handleOnConfirmNewProject(name) {
    console.log(name);
    dispatch(createProject(name)).then((response) => {
      if(response === 'limit'){
        setNewProjectName(name);
        setReachedLimit(true);
      }else{
        setIsNewProjectFullscreen(false);
        setIsChangeProjectFullscreen(false);
        dispatch(fetchProjects());
      }
    });
  }

  function onSubscriptionSuccess(){
    setReachedLimit(false);
    setIsNewProjectFullscreen(false);
    setIsChangeProjectFullscreen(false);
    dispatch(fetchProjects());
  }

  return (
    <>
      {reachedLimit && <SelectPlan stripeAccountName={selectedProject.plan_stripe_account_name} projectName={newProjectName} title={limitTitle} description={limitDescription} onClickOutside={() => setReachedLimit(false)} onSubscriptionSuccess={onSubscriptionSuccess}/>}
      {isChangeProjectFullscreen && (
        <ChangeProject
          setIsFullscreen={setIsChangeProjectFullscreen}
          projects={projects}
          selectedProject={selectedProject}
          onNewProjectClick={setIsNewProjectFullscreen}
        />
      )}
      {isNewProjectFullscreen && (
        <NewProject
          onClickConfirm={handleOnConfirmNewProject}
          onClickOutside={toggleIsNewProjectFullscreen}
        />
      )}
      {hasProjects && selectedProjectLoaded ? (
        <S.Container>
          <S.ProjectButton onClick={toggleIsChangeProjectFullscreen}>
            <S.ProjectName>{selectedProject.name}</S.ProjectName>
            <S.Icon />
          </S.ProjectButton>
        </S.Container>
      ) : (
        <S.NewProjectButtonContainer>
          {(!loaded || (hasProjects && !selectedProjectLoaded))?
            <Loader size={25} width={2}/>
            :
            <>
            <BigScreen>
            {!projectsLoadError &&
              <S.NewProjectButton onClick={toggleIsNewProjectFullscreen}>
                New Project
              </S.NewProjectButton>
            }
          </BigScreen>
          <SmallScreen>
            {!projectsLoadError &&
              <S.NewProjectPlus onClick={toggleIsNewProjectFullscreen}/>
            }
          </SmallScreen>
            </>
          }
        </S.NewProjectButtonContainer>
      )}
    </>
  );
}

export default Projects;
