import * as React from "react"
import { FieldError } from "react-hook-form"
import { cn } from "lib/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
    errors?: false | FieldError | undefined
  }


  const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, errors, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          `  
            flex 
            h-9 
            w-full 
            rounded-md 
            bg-input 
            hover:bg-border
            border-b-2
            border-solid
            border-contrast
            px-3 
            py-1 
            text-sm 
            transition-colors 
            file:border-0 
            file:bg-transparent 
            file:text-sm 
            file:font-medium 
            placeholder:text-muted-foreground 
            focus-visible:outline-none 
            focus-visible:ring-2
            focus-visible:ring-ring 
            focus-visible:bg-contrast
            ${errors ? "ring-destructive ring-2   focus-visible:ring-2 focus-visible:ring-destructive" : "focus-visible:ring-ring"}
            disabled:cursor-not-allowed 
            disabled:opacity-50
        
            `,
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  });
Input.displayName = "Input"

export { Input }
