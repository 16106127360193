import styled, {keyframes}  from "styled-components";
import { Typography } from "components/UI";

// export const Container = styled(Paper).attrs((props) => ({

// }))`
//         color: ${props=>(props.theme.colors.text.default)};
// `;

const bubbleSize = 70;

const borderTransform = keyframes`
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
    14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
    28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
    42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
    56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
    70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
    84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
`;

export const Bubble = styled(Typography)`
position: relative;
width: 100%;
width: ${bubbleSize}px;
height: ${bubbleSize}px;
display: flex;
transform: translate3d(0, 0, 35px) perspective(100px);
justify-content: center;
align-items: center;
margin-bottom: 17px;
color: white;
font-weight: ${(props) => props.theme.fonts.weight.bold};
font-size: 25px;

:before {
    position: absolute;
    content: '';
    z-index: -1;
    background: ${(props) => props.theme.colors.button.primary.gradient};
    width: 100%;
    height: 100%;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 6px 30px -5px ${(props) => props.theme.colors.button.primary.average};
    animation: ${borderTransform} 6s linear infinite;
}
`;