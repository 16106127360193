import * as Types from "./types";
import update from 'immutability-helper';


const initialState = {
    loaded:false,
    loading:false,
    addingDatasets:false,
    datasets:{},
    loadedCount:0,
    error:''
}


function ImageDatasetsReducer(state=initialState, action){
    switch(action.type){
        case Types.DATASETS_INIT:
            return initialState
        
        case Types.DATASETS_REQUEST:
            return {
                ...initialState,
                loaded:false,
                loading:true,
                error:''
            }
        
        case Types.DATASETS_REQUEST_SUCCESS:
            return {
                ...state,
                loaded:true,
                loading:false,
                addingDatasets:action.payload.addingDatasets,
                error:''
            }

        case Types.DATASETS_REQUEST_FAILURE:
            return {
                ...state,
                loaded:true,
                loading:false,
                error:action.payload.error
            }
        
        case Types.DATASET_ADD:
            return update(state, {
                datasets: {
                    [action.payload.dataset.id]: { $set: action.payload.dataset }
                }
            });
        
        case Types.DATASET_LOADED:
            return update(state, {
                datasets: {
                    [action.payload.dataset.id]: { $set: action.payload.dataset }
                },
                loadedCount: { $apply: function(x) {return x+1}}
            });
        
        case Types.DATASET_ADD_FINISHED:
            return {
                ...state,
                addingDatasets:false,
            }

        default: return state
    }
}

export default ImageDatasetsReducer;