import styled, { keyframes } from "styled-components";

const borderRadius = 13;
const tfunction = "cubic-bezier(0.19, 1, 0.22, 1)";
const ttime = "300ms";

const rotateMenu = keyframes`
    0% {
        opacity:0;
        transform: rotateX(-90deg)
    }
    70% {
        transform: rotateX(20deg) 
    }
    100% {
        opacity:1;
        transform: rotateX(0deg) 
    }
`;

export const StyledDropdownItem = styled.li`
  color: ${(props) => props.theme.colors.text.regular};
  height: 30px;
  font-size:13px;
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  &:hover {
    //transform: scale(1.05);
    //background-color: ${(props) => props.theme.colors.bg.default};
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    /* box-shadow: inset ${(props) => props.theme.effects.neumo.spread * 0.25}px
        ${(props) => props.theme.effects.neumo.spread * 0.25}px
        ${(props) => props.theme.effects.neumo.blurLength * 0.75}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.light},
      ${(props) => props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.spread}px
        ${(props) => props.theme.effects.neumo.blurLength * 2}px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast},
      inset 0px 0px 0px 0
        ${(props) => props.theme.colors.boxShadow.neumo.contrast}; */
  }
  
`;

export const StyledDropdownMenu = styled.ul`
  display: ${(props) => !props.focused && "none"};
  position: absolute;
  margin-top: 10px;
  top: 100%;
  ${(props) => props.placement === "left" && "right: 0;"};
  ${(props) => props.placement === "right" && "left: 0;"};
  width: 100%;
  min-width: 120px;
  perspective: 1000px;
  animation: ${rotateMenu} 300ms ease-in-out forwards;
  background-color: ${(props) => props.theme.colors.bg.default};
  transform-origin: top center;
  overflow: hidden;
  border-radius: ${borderRadius}px;
  z-index: 3;
  box-shadow: inset ${(props) => props.theme.effects.neumo.spread * 0.5}px
      ${(props) => props.theme.effects.neumo.spread * 0.5}px
      ${(props) => props.theme.effects.neumo.blurLength * 0.75}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.light},
    ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.spread}px
      ${(props) => props.theme.effects.neumo.blurLength * 2}px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast},
    inset 0px 0px 0px 0
      ${(props) => props.theme.colors.boxShadow.neumo.contrast};
`;

export const StyledDropdown = styled.div`
  border-radius: ${(props) =>
    props.borderRadius !== undefined && props.borderRadius};
  padding: ${(props) => props.button === undefined && "10px 40px"};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;

  font-weight: ${(props) => props.theme.fonts.weight.medium};
  /* font-size: ${(props) => props.theme.fonts.title.s.size}; */
  background-color: ${(props) => props.theme.colors.bg.default};

  color: ${(props) => props.theme.colors.text.classes};

  ${(props) =>
    !props.disableBoxShadowAnimation &&
    `box-shadow: 
      -${props.theme.effects.neumo.spread}px 
      -${props.theme.effects.neumo.spread}px 
      ${props.theme.effects.neumo.blurLength}px 0 
      ${props.theme.colors.boxShadow.neumo.light}, 
      ${props.theme.effects.neumo.spread}px 
      ${props.theme.effects.neumo.spread}px 
      ${props.theme.effects.neumo.blurLength}px 0 
      ${props.theme.colors.boxShadow.neumo.contrast}, 
      inset 0px 0px 0px 0 ${props.theme.colors.boxShadow.neumo.contrast}, 
      inset 0px 0px 0px 0 ${props.theme.colors.boxShadow.neumo.light};`}

  &:hover {
    ${(props) =>
      !props.disableBoxShadowAnimation &&
      `box-shadow: 
      -1px -1px 1px ${props.theme.colors.boxShadow.neumo.light},
      1px 1px 1px ${props.theme.colors.boxShadow.neumo.contrast},
      inset 0px 0px 0px 0 ${props.theme.colors.boxShadow.neumo.contrast}, 
      inset 0px 0px 0px 0 ${props.theme.colors.boxShadow.neumo.light};`}

    transition: box-shadow ${ttime} ${tfunction};
    cursor: pointer;
  }

  transition: box-shadow ${ttime} ${tfunction};
`;

export const StyledNav = styled.nav`
  display: flex;
  /* justify-content: left;
  margin-left:20px; */
`;

export const StyledTrigger = styled.nav`
 position: absolute;
 width: 100%;
 height: 100%;
`;

