//LIBRARIES
import React from 'react';
import PropTypes from 'prop-types';

//STYLEDCOMPONENTS
import {
  StyledList as List,
  StyledListItem as ListItem,
  StyledListItemIcon as ListItemIcon,
  StyledListItemText as ListItemText,
} from "./style";

export default List;

export {ListItem, ListItemIcon, ListItemText};