import styled from "styled-components";
import { ProgressBar as BaseProgressBar, Text } from "components/UI";

export const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    margin-top: 10px;
  }
`;

export const ProgressBar = styled(BaseProgressBar)`
  width: 100%;
`;

export const Title = styled(Text)``;

export const Description = styled(Text).attrs((props) => ({
  weight: "light",
}))`
  align-self: flex-end;
`;


export const BottomContainer = styled.div`
display: flex;
`;

export const BottomLeft = styled(Text).attrs((props) => ({
  weight: "medium",
  light: true,
}))`
flex-grow:1;
text-align: left; 
`;
export const BottomRight = styled(Text).attrs((props) => ({
  weight: "light",
}))`
flex-grow:1;
text-align: right;
`;

export const ProgressBarContainer = styled.div`
display: flex;
align-items: center;
`;

export const Percentage = styled.div`
padding: 0px 5px;
font-size: ${props => props.theme.fonts.title.xs.size};
font-weight:${props => props.theme.fonts.weight.light};
`;
