import { toast } from 'react-toastify';

export function displayError(error){
    if(error.response){
        if(typeof error.response.data === 'object'){
            console.log('DATA:', error.response.data);
            Object.values(error.response.data).forEach((item) => {
                toast.error(item);
            });
        }else{
            toast.error(error.message);
        }
    }
}