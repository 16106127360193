import React from "react";
import Lottie from "react-lottie";
// import shapesAnimation from "assets/lotties/shapes_animation_green";
// import blurredOrbs from "assets/lotties/blurred-orbs/blurred_orbs_green";

import * as o from "assets/svgs/complex/blurred-orbs";
import * as s from "assets/lotties/shapes-animation";
import * as bg from "assets/svgs/complex/waves-background";

//STYLEDCOMPONENTS
import * as S from "./style";

const animations = {
  red: {
    orbs: o.redOrb,
    shapes: s.redShapes,
    background: bg.wavesBackgroundRed,
  },
  green: {
    orbs: o.greenOrb,
    shapes: s.greenShapes,
  },
  purple: {
    orbs: o.purpleOrb,
    shapes: s.purpleShapes,
    background: bg.wavesBackgroundPurple,
  },
  black: {
    orbs: o.blackOrb,
    shapes: s.blackShapes,
  },
  diamond: {
    orbs: o.diamondOrb,
    background: bg.wavesBackgroundDiamond
  }
};

function Plan(props) {
  const { plan, period, ...others } = props;

  let color = plan.color;


  const shapesAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[color]
      ? animations[color].shapes
      : animations.purple.shapes,
    rendererSettings: {
      preserveAspectRatio: "xMaxYMid slice",
      //  scaleMode: 'noScale',
    },
  };

  const blurGradientOptions = {
    loop: true,
    autoplay: true,
    animationData: animations[color]
      ? animations[color].orbs
      : animations.purple.orbs,
    rendererSettings: {
      preserveAspectRatio: "xMaxYMid slice",
    },
    // renderer: "canvas",

  };

  return (
    <S.Container>
      <S.WavesBackground src={animations[color] ? animations[color].background : animations.purple.background} />
      <S.GradientWrapper>
      </S.GradientWrapper>
      <S.BlurredOrbs src={animations[color] ? animations[color].orbs : animations.purple.orbs} />
      <S.InfoContainer>
        <S.TitleContainer>
          <S.Logo />
          <div className={"plan-name"}>{plan.name}</div>
        </S.TitleContainer>
        <div className={"description"}>{plan.description}</div>
        {plan.price ?
          <div className="prices">
            {period === 'yearly' ?
              <>
                <div className="price yearly">
                  <div className="number">${plan.price.month_yearly}</div>
                  <div className="label">&nbsp;per month</div>
                </div>
                <div className="period">Billed yearly</div>
              </>
              :
              <>
                <div className="price monthly">
                  <div className="number">${plan.price.month}</div>
                  <div className="label">&nbsp;per month</div>
                </div>
                <div className="period">Billed monthly</div>
              </>
            }
          </div>
          :
          <S.ContactUsButton>
            Contact us
          </S.ContactUsButton>
        }
      </S.InfoContainer>
      <S.Orb color={color} fixed />
    </S.Container>
  );
}

export default Plan;