import { combineReducers } from 'redux';
import LibraryReducer from './Library/reducer';
import DatasetTools from './DatasetTools/reducer';
import BreadcrumbsReducer from 'components/UI/Layouts/Breadcrumbs/state/reducer';
import UserReducer from './User/reducer';
import ProjectsReducer from 'components/User/Projects/state/reducer';
import ProjectReducer from './Project/reducer';
import WebsocketReducer from '../Websocket/reducer';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const AppReducer = combineReducers({
  websocket: WebsocketReducer,
  library: LibraryReducer,
  datasetTools: DatasetTools,
  breadcrumbs: BreadcrumbsReducer,
  user: UserReducer,
  projects: ProjectsReducer,
  project: ProjectReducer
});

export default AppReducer;