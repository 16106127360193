//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import * as S from "./style";

  
const ScrollableDiv = React.forwardRef((props, ref) => {
  const { children, ...others } = props;
  return (
    <S.Container {...others} ref={ref}>{children}</S.Container>
  );
})
 

export default ScrollableDiv;