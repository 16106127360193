export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function SnakeToTitleCase(string) {
  let sentence = string.toLowerCase().split("_");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

export function listWords(array) {
  if(array){
    let arrayLength = array.length;
    switch (true) {
      case arrayLength == 1:
        return array[0];
      case arrayLength > 1:
        return array.map((item, index)=>arrayLength-2 > index ? item+"," : arrayLength-2 === index ? item:"and "+item).join(' ')
      default:
          return ''
    }
  }
  else
    return undefined;
}