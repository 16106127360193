import { ReactComponent as IconApple } from './apple.svg';
import { ReactComponent as IconDiscord } from './discord.svg';
import { ReactComponent as IconFacebook } from './facebook.svg';
import { ReactComponent as IconGitHub } from './github.svg';
import { ReactComponent as IconJavascript } from './javascript.svg';
import { ReactComponent as IconLinkedin } from './linkedin.svg';
import { ReactComponent as IconLinux } from './linux.svg';
import { ReactComponent as IconPython } from './python.svg';
import { ReactComponent as IconTwitter } from './twitter.svg';
import { ReactComponent as IconWhatsapp } from './whatsapp.svg';
import { ReactComponent as IconWindows } from './windows.svg';
import { ReactComponent as IconYouTube } from './youtube.svg';
import { ReactComponent as IconTheos } from './theos.svg';
import { ReactComponent as IconInstagram } from './instagram.svg';


export default {
"apple" : IconApple,
"discord": IconDiscord,
"facebook": IconFacebook,
"github": IconGitHub,
"javascript" : IconJavascript,
"linkedin": IconLinkedin,
"linux" : IconLinux,
"python" : IconPython,
"twitter": IconTwitter,
"whatsapp": IconWhatsapp,
"windows" : IconWindows,
"youtube": IconYouTube,
"theos":IconTheos,
"instagram":IconInstagram
};


