import styled from "styled-components";
import { Fullscreen, Typography, Text, Button} from 'components/UI';
import IconBase from 'icons';

export const Modal = styled(Fullscreen)`
    padding: 20px;
    position:relative;
    overflow:hidden;
    height:min-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:center;
    width:350px;
    height:100%;
    > div {
        padding: 5px 0px;
    }

`;

export const Icon = styled(IconBase).attrs((props) => ({
    //size:"small"
    size: "40%",
    name: (props.type === "error") ? "close" : ((props.type === "alert") ? "solid alert" : "check")
}))`
  color: white;
`;

export const Title = styled(Typography).attrs((props) => ({
        size:"m"
    }))`
    width: 100%;
    text-align: center;
 `;


export const Message = styled(Text)`
width: 100%;
text-align: center;
`;

export const ButtonsContainer = styled.div`
 display:flex;
 justify-content: flex-end;
 width: 100%;
 margin-top: 20px;
`;


export const CancelButton = styled(Button).attrs((props) => ({
    variant: "contained",
    color: "secondary"
}))`

`;

export const ConfirmButton = styled(Button).attrs((props) => ({
    variant: "contained",
    color: "primary"
}))`
margin-left: 16px;
`;


