import * as Types from "./types";
import { api } from 'state/api';
import { setTrainingExperimentCharts } from '../CenterContent/ChartsSelector/state/actions';
import { initTrainingSessionExperimentAPI } from '../ExperimentAPI/state/actions';
import { displayError, track } from 'utils';

function trainingSessionInit(){
    return {
        type:Types.TRAINING_SESSION_INIT
    }
}

function trainingSessionRequest(){
    return {
        type:Types.TRAINING_SESSION_REQUEST
    }
}

function trainingSessionRequestSuccess(id, key, name, pathName, status, algorithm, dataset, experiment, experiments, subfield){
    return {
        type:Types.TRAINING_SESSION_REQUEST_SUCCESS,
        payload: {
            id,
            key,
            name,
            pathName,
            status,
            algorithm,
            dataset,
            experiment,
            experiments,
            subfield
        }
    }
}

function trainingSessionRequestFailure(error){
    return {
        type:Types.TRAINING_SESSION_REQUEST_FAILURE,
        payload: {
            error
        }
    }
}

function trainingSessionSelectedExperimentSet(experimentId){
    return {
        type:Types.TRAINING_SESSION_SELECTED_EXPERIMENT_SET,
        payload: {
            experimentId
        }
    }
}

function trainingSessionSelectedExperimentMetricSet(metricPathName){
    return {
        type:Types.TRAINING_SESSION_SELECTED_EXPERIMENT_METRIC_SET,
        payload: {
            metricPathName
        }
    }
}

function trainingSessionExperimentAdd(experiment){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_ADD,
        payload: {
            experiment
        }
    }
}

function trainingSessionExperimentLoad(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_LOAD
    }
}

function trainingSessionExperimentLoadSuccess(experiment){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_LOAD_SUCCESS,
        payload: {
            experiment
        }
    }
}

function trainingSessionExperimentLoadFailure(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_LOAD_FAILURE,
        payload: {
            error
        }
    }
}

function trainingSessionExperimentSetupSet(setup){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_SETUP_SET,
        payload: {
            setup
        }
    }
}

function trainingSessionExperimentStatusSet(status, status_message){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_STATUS_SET,
        payload: {
            status,
            status_message
        }
    }
}

function trainingSessionExperimentHyperparametersSet(hyperparameters){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_HYPERPARAMETERS_SET,
        payload: {
            hyperparameters
        }
    }
}

function trainingSessionExperimentMachineChangeInit(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_INIT
    }
}

function trainingSessionExperimentMachineChange(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE
    }
}

function trainingSessionExperimentMachineChangeSuccess(machine){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_SUCCESS,
        payload: {
            machine
        }
    }
}

function trainingSessionExperimentMachineChangeFailure(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_MACHINE_CHANGE_FAILURE,
        payload: {
            error
        }
    }
}

function trainingSessionExperimentMachineStatusSet(connected){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_MACHINE_STATUS_SET,
        payload: {
            connected
        }
    }
}

function trainingSessionExperimentInitialWeightChangeInit(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_INIT
    }
}

function trainingSessionExperimentInitialWeightChange(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE
    }
}

function trainingSessionExperimentInitialWeightChangeSuccess(initial_weight){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_SUCCESS,
        payload: {
            initial_weight
        }
    }
}

function trainingSessionExperimentInitialWeightChangeFailure(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_CHANGE_FAILURE,
        payload: {
            error
        }
    }
}

function trainingSessionExperimentInitialWeightRemoveInit(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_INIT
    }
}

function trainingSessionExperimentInitialWeightRemove(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE
    }
}

function trainingSessionExperimentInitialWeightRemoveSuccess(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_SUCCESS
    }
}

function trainingSessionExperimentInitialWeightRemoveFailure(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_INITIAL_WEIGHT_REMOVE_FAILURE,
        payload: {
            error
        }
    }
}

function trainingSessionExperimentStartRequest(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_START_REQUEST,
    }
}

function trainingSessionExperimentStartSuccess(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_START_SUCCESS,
    }
}

function trainingSessionExperimentStartFailure(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_START_FAILURE,
        payload:{
            error
        }
    }
}

function trainingSessionExperimentStopRequest(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_STOP_REQUEST,
    }
}

export function trainingSessionExperimentStopSuccess(){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_STOP_SUCCESS,
    }
}

function trainingSessionExperimentStopFailure(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_STOP_FAILURE,
        payload:{
            error
        }
    }
}

export function trainingSessionExperimentErrorSet(error){
    return {
        type:Types.TRAINING_SESSION_EXPERIMENT_ERROR_SET,
        payload:{
            error
        }
    }
}

export function initTrainingSession(){
    return (dispatch) => {
        dispatch(trainingSessionInit());
    }
}

export function fetchTrainingSession(projectId, trainingSessionKey){
    return (dispatch) => {
        dispatch(trainingSessionRequest());
        api()
            .get(`/projects/${projectId}/training/sessions/${trainingSessionKey}/`)
            .then(response => {
                const trainingSession = response.data;
                const id = trainingSession.id;
                const key = trainingSession.key;
                const name = trainingSession.name;
                const pathName = trainingSession.path_name;
                const status = trainingSession.status;
                const algorithm = trainingSession.algorithm;
                const dataset = trainingSession.dataset;
                const experiment = trainingSession.experiment;
                const experiments = trainingSession.experiments;
                const subfield = algorithm.subfield_path_name.replace('-', '_').toUpperCase();
                dispatch(trainingSessionRequestSuccess(id, key, name, pathName, status, algorithm, dataset, experiment, experiments, subfield));
                dispatch(setTrainingSessionSelectedExperiment(experiment.id));
                dispatch(setTrainingExperimentCharts(projectId, trainingSessionKey, experiment.id, algorithm.training_metrics));
                track('Training session viewed', {'Key': trainingSessionKey});
            })
            .catch(error => {
                dispatch(trainingSessionRequestFailure(error.message));
                displayError(error);
            })
    }
}

export function setTrainingSessionSelectedExperiment(experimentId){
    return (dispatch) => {
        dispatch(trainingSessionSelectedExperimentSet(experimentId));
    }
}

export function setTrainingSessionSelectedExperimentMetric(metricPathName){
    return (dispatch) => {
        dispatch(trainingSessionSelectedExperimentMetricSet(metricPathName));
    }
}

export function addTrainingSessionExperiment(projectId, trainingSessionKey, experiment){
    return (dispatch, getState) => {
        const algorithm = getState().project.training.session.main.algorithm;
        dispatch(setTrainingSessionSelectedExperiment(experiment.id));
        dispatch(trainingSessionExperimentAdd(experiment));
        dispatch(initTrainingSessionExperimentAPI());
        dispatch(setTrainingExperimentCharts(projectId, trainingSessionKey, experiment.id, algorithm.training_metrics));
    }
}

export function fetchTrainingSessionExperiment(projectId, trainingSessionKey, experimentId){
    return (dispatch, getState) => {
        const algorithm = getState().project.training.session.main.algorithm;
        dispatch(setTrainingSessionSelectedExperiment(experimentId));
        dispatch(trainingSessionExperimentLoad());
        api()
            .get(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experimentId}/`)
            .then(response => {
                const trainingSessionExperiment = response.data;
                console.log(trainingSessionExperiment);
                dispatch(trainingSessionExperimentLoadSuccess(trainingSessionExperiment));
                dispatch(setTrainingExperimentCharts(projectId, trainingSessionKey, experimentId, algorithm.training_metrics));
                track('Training session experiment viewed', {'Training session': trainingSessionKey, 'Experiment': experimentId});
            })
            .catch(error => {
                dispatch(trainingSessionExperimentLoadFailure(error.message));
                displayError(error);
            })
    }
}

export function setTrainingSessionExperimentSetup(setup){
    return (dispatch) => {
        dispatch(trainingSessionExperimentSetupSet(setup));
    }
}

export function setTrainingSessionExperimentStatus(status, statusMessage=''){
    return (dispatch) => {
        dispatch(trainingSessionExperimentStatusSet(status, statusMessage));
    }
}

export function setTrainingSessionExperimentHyperparameters(hyperparameters){
    return (dispatch) => {
        dispatch(trainingSessionExperimentHyperparametersSet(hyperparameters));
    }
}

export function changeTrainingSessionExperimentMachine(projectId, trainingSessionKey, experimentId, machine_uuid){
    return (dispatch) => {
        dispatch(trainingSessionExperimentMachineChange());
        api()
            .post(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experimentId}/machine/set/`, {machine_uuid})
            .then(response => {
                const machine = response.data;
                dispatch(trainingSessionExperimentMachineChangeSuccess(machine));
                track('Training session experiment machine changed', {'Training session': trainingSessionKey, 'Experiment': experimentId, 'Machine': machine_uuid});
            })
            .catch(error => {
                dispatch(trainingSessionExperimentMachineChangeFailure(error.message));
            })
    }
}

export function initTrainingSessionExperimentMachineChange(){
    return (dispatch) => {
        dispatch(trainingSessionExperimentMachineChangeInit());
    }
}

export function setTrainingSessionExperimentMachineStatus(connected){
    return (dispatch) => {
        dispatch(trainingSessionExperimentMachineStatusSet(connected));
    }
}

export function changeTrainingSessionInitialWeight(projectId, trainingSessionKey, experimentId, initial_weight){
    return (dispatch) => {
        dispatch(trainingSessionExperimentInitialWeightChange());
        api()
            .post(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experimentId}/initial-weight/set/`, {initial_weight})
            .then(response => {
                const initial_weight = response.data;
                console.log('CHANGED INITIAL WEIGHT:', initial_weight);
                dispatch(trainingSessionExperimentInitialWeightChangeSuccess(initial_weight));
                track('Training session experiment initial weight changed', {'Training session': trainingSessionKey, 'Experiment': experimentId, 'Initial weight': initial_weight.tag});
            })
            .catch(error => {
                dispatch(trainingSessionExperimentInitialWeightChangeFailure(error.message));
            })
    }
}

export function initTrainingSessionExperimentInitialWeightChange(){
    return (dispatch) => {
        dispatch(trainingSessionExperimentInitialWeightChangeInit());
    }
}

export function removeTrainingSessionInitialWeight(projectId, trainingSessionKey, experimentId){
    return (dispatch) => {
        dispatch(trainingSessionExperimentInitialWeightRemove());
        api()
            .post(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experimentId}/initial-weight/remove/`)
            .then(response => {
                dispatch(trainingSessionExperimentInitialWeightRemoveSuccess());
                track('Training session experiment initial weight removed', {'Training session': trainingSessionKey, 'Experiment': experimentId});
            })
            .catch(error => {
                dispatch(trainingSessionExperimentInitialWeightRemoveFailure(error.message));
            })
    }
}

export function initTrainingSessionExperimentInitialWeightRemove(){
    return (dispatch) => {
        dispatch(trainingSessionExperimentInitialWeightRemoveInit());
    }
}

export function startTrainingExperiment(projectId, trainingSessionKey, experimentId, subfield, configuration){
    return (dispatch) => {
        dispatch(trainingSessionExperimentStartRequest());
        const data = {
            namespace:'TRAINING_SESSION',
            content: {
              subfield,
              command:'START',
              args:configuration
            }
        }
        api()
            .post(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experimentId}/start/`, {data})
            .then(response => {
                const responseData = response.data;
                if(responseData.status === 'STARTING'){
                    dispatch(trainingSessionExperimentStartSuccess());
                    let epochs = 0;
                    let batchSize = 0;
                    let imageSize = 0;
                    configuration.args.setup.forEach(item => {
                        if(item.key === 'epochs'){
                            epochs = item.value;
                        }else if(item.key === 'batch_size'){
                            batchSize = item.value;
                        }else if(item.key === 'img_size'){
                            imageSize = item.value;
                        }
                    })
                    track('Training session experiment started', 
                        {
                            'Training session': trainingSessionKey, 
                            'Experiment': experimentId, 
                            'Dataset':configuration.args.dataset, 
                            'Algorithm':`${configuration.args.algorithm.path_name} ${configuration.args.algorithm.version_path_name}`,
                            'Epochs':epochs,
                            'Batch size':batchSize,
                            'Image size':imageSize
                        }
                    );
                }else if(responseData.status === 'ERROR'){
                    dispatch(trainingSessionExperimentStartFailure(responseData.status_message));
                }
            })
            .catch(error => {
                dispatch(trainingSessionExperimentStartFailure(error.data));
            })
    }
}

export function stopTrainingExperiment(projectId, trainingSessionKey, experiment_id, machine_uuid){
    return (dispatch) => {
        dispatch(trainingSessionExperimentStopRequest());
        const data = {
            namespace:'TRAINING_SESSION',
            content: {
              subfield:'OBJECT_DETECTION',
              command:'STOP',
              args:{
                to_another: false,
                experiment_id,
                machine_uuid
              }
            }
        }
        api()
            .post(`/projects/${projectId}/training/sessions/${trainingSessionKey}/experiments/${experiment_id}/stop/`, {data})
            .then(response => {
                const responseData = response.data;
                if(responseData.status === 'STOPPED'){
                    dispatch(trainingSessionExperimentStopSuccess());
                    track('Training session experiment stopped', {'Training session': trainingSessionKey, 'Experiment': experiment_id});
                }else if(responseData.status === 'ERROR'){
                    dispatch(trainingSessionExperimentStopFailure(responseData.status_message));
                }
            })
            .catch(error => {
                dispatch(trainingSessionExperimentStopFailure(error.data));
            })
    }
}