//LIBRARIES
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategoryMedias } from "components/Library/Dataset/CategoryMedias/state/actions";
import { Search } from "components/UI";
//STYLEDCOMPONENTS
import {
  StyledContainer as Container,
  StyledCategoryTitle as CategoryTitle,
  StyledFlexboxHorizontal as FlexboxHorizontal,
  StyledCategorysList as CategoryList,
  StyledCategoryButton as CategoryButton,
  StyledCategoryContainer as CategoryContainer,
  StyledThumbnail as Thumbnail,
} from "./style";
import { FullscreenMedia } from "components/UI";
import { theme } from "styles/theme";

function CategoryGallery(props) {
  const { loading, categories, datasetPath, dataset } = props;
  let categoryMedias = useSelector((store) => store.library.categoryMedias);
  const dispatch = useDispatch();
  const [selectedMedia, setSelectedMedia] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [searchWriting, setSearchWriting] = useState("");
  const [categorySelected, setCategorySelected] = useState({});
  const [isDesktop, setIsDesktop] = useState(true);
  let matchedCategories = [];
  
  if (!loading)
    matchedCategories = categories.filter((category) =>
      category.name.toLowerCase().includes(searchWriting.toLowerCase())
    );

  function onSearchWrite(event) {
    setSearchWriting(event.target.value);
  }

  function checkIfDesktop() {
    setIsDesktop(window.innerWidth >= theme.breakpoints.desktop.min);
  }

  useEffect(() => {
    checkIfDesktop();
    window.addEventListener("resize", checkIfDesktop);
    setSearchWriting("");
    if (categories) {
      setCategorySelected(categories[0]);
      if (isDesktop){
        dispatch(fetchCategoryMedias(datasetPath, categories[0].id));
      }
    }

    return () => {
      window.removeEventListener("resize", checkIfDesktop);
    }
  }, [categories, datasetPath, isDesktop, dispatch]);


  function mediaSelection(event) {
    let media = categoryMedias.payload.find((item) => Number(item.id) === Number(event.target.id));
    media !== undefined && setSelectedMedia(media);
    setFullscreen(true);
  }

  function onCategoryClick(category) {
    setCategorySelected(category);
    dispatch(fetchCategoryMedias(datasetPath, category.id));
  }

  function onClickOutside() {
    setFullscreen(false);
  }
  return (
    <>
      {!loading && fullscreen && (
        <FullscreenMedia
          onClickOutside={onClickOutside}
          media={selectedMedia}
        ></FullscreenMedia>
      )}
      <Container>
        <Search loading={loading} onSearchWrite={onSearchWrite} />
        <FlexboxHorizontal>
          <CategoryList>
            {!loading &&
              matchedCategories.map((category, i) => {
                if (isDesktop) {
                  return (
                    <CategoryButton
                      key={i}
                      id={category.id}
                      onClick={() => onCategoryClick(category)}
                    >
                      {category.name}
                    </CategoryButton>
                  );
                } else {
                  return (
                    <CategoryButton
                      key={i}
                      id={category.id}
                      disabled
                    >
                      {category.name}
                    </CategoryButton>
                  );
                }
              })}
          </CategoryList>
          <CategoryContainer>
            {isDesktop && !loading && categoryMedias.loaded ? (
              <>
                <CategoryTitle>{categorySelected.name}</CategoryTitle>
                {categoryMedias.payload.map((media, i) => {
                  return (
                    <Thumbnail
                      onClick={mediaSelection}
                      id={media.id}
                      key={i}
                      img={media.thumbnail}
                    ></Thumbnail>
                  );
                })}
              </>
            ) : (
              [...Array(5)].map((e, i) => {
                return <Thumbnail loading key={i}></Thumbnail>;
              })
            )}
          </CategoryContainer>
        </FlexboxHorizontal>
      </Container>
    </>
  );
}

export default CategoryGallery;
