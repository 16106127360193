import styled from "styled-components";

export const StyledAnimation = styled.div`

    &.grow-appear {
    opacity: 0;
    transform: scale(0.5);
    }

    &.grow-appear-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity ${props => props.duration}ms linear, transform ${props => props.duration}ms linear;
    }

    &.grow-exit {
    opacity: 1;
    transform: scale(1);
    }

    &.grow-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity ${props => props.duration}ms linear, transform ${props => props.duration}ms linear;
    }

`;