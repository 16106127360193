import styled from "styled-components";
import {Skeleton as BaseSkeleton, Typography as BaseTypography} from "components/UI";

export const SkeletonsContainer = styled.div`
    width:100%;
    height:  ${props => (`calc(${props.theme.fonts.body.m.size} + ${props.theme.fonts.body.m.size} + ${props.theme.fonts.body.m.size})`)}; 
    display: flex;
   
    flex-flow: column;
    justify-content: space-around;
`;

export const Skeleton = styled(BaseSkeleton)`
    height:  ${props => (props.theme.fonts.body.m.size)}; 
`;

export const Typography = styled(BaseTypography)`
     ${props=>props.small && `font-size: ${props.theme.fonts.body.s.size};`}
`;