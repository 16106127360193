import * as Types from './types';


const initialState = {
    loaded:false,
    loading:false,
    payload:[],
    error:'',
    status:undefined
}


function TrainingSessionsReducer(state=initialState, action){
    switch(action.type){
        case Types.TRAINING_SESSIONS_REQUEST:
            return {
                loaded:false,
                loading:true,
                payload:[],
                error:''
            }
        
        case Types.TRAINING_SESSIONS_REQUEST_SUCCESS:
            return {
                loaded:true,
                loading:false,
                payload:action.payload.trainingSessions,
                status:200,
                error:''
            }

        case Types.TRAINING_SESSIONS_REQUEST_FAILURE:
            return {
                loaded:true,
                loading:false,
                payload:[],
                status:action.payload.status,
                error:action.payload.error
            }
        default: return state
    }
}

export default TrainingSessionsReducer;