import * as z from "zod"

export const userRegisterSchema = z.object({
    username: z.string(),
    email: z.string().email(),
    // username: z.string().min(2, { message: "Username must be at least 2 characters" }),
    firstName: z.string().min(2, { message: "First name must be at least 2 characters" }),
    lastName: z.string().min(2, { message: "Last name must be at least 2 characters" }),
    password: z.string().min(8, { message: "Password must be at least 8 characters long" })
    .regex(/[a-z]/, { message: "Password must include lowercase letters" })
    .regex(/[A-Z]/, { message: "Password must include uppercase letters" })
    .regex(/[0-9]/, { message: "Password must include numbers" }),
    confirmPassword: z.string().min(6),
  }).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });



export const userLoginSchema = z.object({
    username: z.string().min(8, { message: "Username must be at least 8 characters long" }),
    password: z.string().min(6, { message: "Password must be at least 6 characters" }),
  })