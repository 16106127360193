import { combineReducers } from 'redux'
import ImageDatasetToolMainReducer from './Main/reducer';
import ImageDatasetToolExampleReducer from '../ExampleStage/MainStage/state/reducer';


const ImageDatasetToolReducer = combineReducers({
    main: ImageDatasetToolMainReducer,
    example: ImageDatasetToolExampleReducer
});

export default ImageDatasetToolReducer;
