import * as Types from "./types";

const initialState = {
    loading:false,
    loaded:false,
    payload:{},
    available:[],
    discount:0,
    coupon:undefined,
    error:''
}

function ProjectPlanReducer(state=initialState, action){
    switch(action.type){
        case Types.PROJECT_PLAN_INIT:
            return initialState;

        case Types.PROJECT_PLAN_REQUEST:
            return {
                loading:true,
                loaded:false,
                payload:{},
                available:[],
                discount:0,
                coupon:undefined,
                error:''
            }

        case Types.PROJECT_PLAN_REQUEST_SUCCESS:
            return {
                loading:false,
                loaded:true,
                payload:action.payload.plan,
                available:action.payload.available,
                discount:action.payload.discount,
                coupon:action.payload.coupon,
                error:''
            }

        case Types.PROJECT_PLAN_REQUEST_FAILURE:
            return {
                loading:false,
                loaded:false,
                payload:{},
                available:[],
                discount:0,
                coupon:undefined,
                error:action.payload.error
            }
        default: return state
    }
}

export default ProjectPlanReducer;