//LIBRARIES
import React, {useState, useEffect, useRef} from 'react';

//STYLEDCOMPONENTS
import { 
  StyledNav as Nav,
  StyledDropdown  as DropdownBase,
  StyledDropdownMenu as DropdownMenu,
  StyledDropdownItem as DropdownItem,
  StyledTrigger as Trigger,
} from "./style";

  
function Dropdown(props) {
  const { children, placeholder, button, borderRadius, placement, disableBoxShadowAnimation, ...other } = props;
  const [focused, setFocused ] = useState(false);
  const dropdownButton = useRef();
  let newButton;

  if(button){
  newButton = React.cloneElement(button, {style: {pointerEvents: "none"}});
  }
  function handleBlur(){
    setFocused(false); 
  }

  useEffect(() => {
    const handleClickOut = event => {
      if(event.target !== dropdownButton.current) { // Actually more complicated, event.target can be a child of button (icon, span, etc)
          handleBlur();
      } else {
      setFocused(!focused); 
      }
    }

    document.body.addEventListener('click', handleClickOut, false);
    return function() {
      document.body.removeEventListener('click', handleClickOut, false);
    };
  }, [focused]);

  
  return (
    <Nav>
      <DropdownBase disableBoxShadowAnimation={disableBoxShadowAnimation} button={button} borderRadius={borderRadius} ref={dropdownButton}  {...other}>
      {button ? newButton : placeholder}
        <DropdownMenu focused={focused} placement={placement}>
          {children}
        </DropdownMenu>
      </DropdownBase>
    </Nav>
  );
}

export { DropdownItem };
export default Dropdown;