//LIBRARIES
import React from "react";


import {
  StyledTableContainer as TableContainer,
  StyledCodeLine as CodeLine,
  StyledCodeLinePart as CodeLinePart
} from "./style";

//STYLEDCOMPONENTS
import {
    StyledSkeletonPulse as SkeletonBase,
    StyledBorder as Border,
  } from "./style";
  

export function SkeletonCode(props) {

  return (
    <TableContainer>
      <CodeLine>
            <CodeLinePart style={{width: "25%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
            <CodeLinePart style={{width: "50%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
      </CodeLine>
      <CodeLine style={{paddingLeft:"15%"}}>
            <CodeLinePart style={{width: "40%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
            <CodeLinePart style={{width: "60%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
      </CodeLine>
      <CodeLine style={{paddingLeft:"15%"}}>
            <CodeLinePart style={{width: "35%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
            <CodeLinePart style={{width: "20%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
            <CodeLinePart style={{width: "25%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
      </CodeLine>
      <CodeLine>
            <CodeLinePart style={{width: "25%"}}>
              <Border>
                <SkeletonBase />
              </Border>
            </CodeLinePart>
      </CodeLine>
    </TableContainer>
  );
  
}