//LIBRARIES
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
//STYLEDCOMPONENTS
import * as S from "./style";
import { fetchProject, fetchProjectByKey } from "components/Project/state/actions";
import { timeDifference, displayError } from "utils";


function Projects(props) {
  const { selectedProject, projects, setIsFullscreen, onNewProjectClick } = props;
  const dispatch = useDispatch();
  const profile = useSelector((store) => store.user.profile.payload);
  const [project, setProject] = useState({ key: { value: "", valid: false } });
  const [loading, setLoading] = useState(false);

  const handleFieldChange = (fieldId, input) => {
    setProject({ ...project, [fieldId]: input });
  };

  function chooseProjectClick(projectId) {
    dispatch(fetchProject(projectId));
    setIsFullscreen(false);
  }

  function handleLoadClick() {
    setLoading(true);
    dispatch(fetchProjectByKey(project.key.value)).then((ok) => {
      setLoading(false);
      if(ok){
        setProject({ key: { value: "", valid: false } });
        setIsFullscreen(false);
      }
    }).catch((error) => {
      setLoading(false);
    });
  }

  return (
    <S.FullscreenContainer onClickOutside={() => setIsFullscreen(false)}>
      {profile.is_admin &&
      <S.ProjectSearchContainer>
          <S.Input
            label={"Project Key"}
            input={project.key.value}
            onChange={handleFieldChange}
            id={"key"}
            focus={true}
            noMessage={true}
          />
          <S.Button loading={loading ? 1 : undefined} onClick={handleLoadClick} disabled={!project.key.valid}>Load</S.Button>
        </S.ProjectSearchContainer>
      }
      <S.ProjectsContainer>
        {projects.map((project, index) => (
          <S.ProjectChooseButton key={index} onClick={(event) => chooseProjectClick(project.id)}>
            <S.ProjectNameContainer>
              <S.ProjectName>
                {project.name}
              </S.ProjectName>
              {selectedProject.id === project.id && <S.CheckedIcon />}
            </S.ProjectNameContainer>
            {profile.is_admin &&
              <S.ProjectCreatedAt>
                Created {timeDifference(new Date(), new Date(project.created_at))}
              </S.ProjectCreatedAt>
            }
          </S.ProjectChooseButton>
        ))}
      </S.ProjectsContainer>
      <S.ProjectChooseButton onClick={onNewProjectClick}>
        <S.NewProjectContainer>
          <S.PlusIcon /><S.ProjectName>New project</S.ProjectName>
        </S.NewProjectContainer>
      </S.ProjectChooseButton>
    </S.FullscreenContainer>
  );
}

export default Projects;
