import React from "react";
//STYLEDCOMPONENTS
import * as S from "./style";

function RightMenu(props) {
  const { children, topSection, bottomSection } = props;
  
  return (
    <S.RightMenu>
      {topSection && 
      <S.TopSection>
        {topSection}
      </S.TopSection>}
      <S.RightMenuContent>{children}</S.RightMenuContent>
      {bottomSection && 
      <S.BottomSection>
        {bottomSection}
      </S.BottomSection>}
    </S.RightMenu>
  );
}

export default RightMenu;
