//LIBRARIES
import React, { useState } from 'react';

import { InputBase } from 'components/UI';

function validateUsername(username) {
  return ((username.length >= 4 && username.length <= 40) ? true : false)
}

function Username(props) {
  const { valid, setInput,input, label, ...other } = props;

  const [state, setState] = useState(
    {
      bottomLabel: '',
    });


  function handleOnChange(event) {
    let validUsername = validateUsername(event.target.value);
    setInput({ value: event.target.value, valid: validUsername });

    // if (validUsername !== valid)
      if (!validUsername)
        setState({ ...state, bottomLabel: "* Usernames must be between 4 and 40 characters." })
      else
        setState({ ...state, bottomLabel: "You will use this username to log in." })
  }

  return (
    <InputBase type={"text"} valid={valid} value={input} label={label} bottomLabel={state.bottomLabel} handleOnChange={handleOnChange} {...other} />
  );
}

export default Username;

// "You will have to verify that you are the owner of this username account":
// "* Please enter a valid username."