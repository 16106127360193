import styled,{ css, keyframes } from "styled-components";
import hexToRgba from "hex-to-rgba";
import TextField from '@mui/material/TextField';

const borderWidth = 2;
const borderRadius = 4;



const animatedGradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`;

export const Border = styled.div`
  /* border: ${(props) =>
    props.valid && `1px solid ${props.theme.colors.border.default}`}; */
 

  /* background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82); */

  padding: ${borderWidth}px;
  border-radius: ${borderRadius + borderWidth}px;

  /* 
  ${(props) =>
    !props.focused &&
    `box-shadow: inset 0px 0px 0px 2px ${props.theme.colors.border.default};`}
   */

  background: ${(props) =>
    props.focused
      ? `linear-gradient(60deg, rgba(220,220,220,1), rgba(177, 181, 254,0.6), rgb(153, 206, 255,0.5),rgba(177, 181, 254,0.4), rgba(220, 220, 220,1))`
      : ""};



    
  /* background: ${(props) =>
    props.focused
      ? `linear-gradient(60deg, rgba(220,220,200,1), rgba(134, 144, 228,0.6), rgba(255,255,255,1),rgba(134, 144, 228,0.4), rgba(220, 220, 220,0.4))`
      : ""}; */
  animation: ${(props) =>
    props.focused
      ? css`
          ${animatedGradient} 6s infinite alternate
        `
      : ""};

  transition: background-color 0.3s ease-in-out;
  background-size: 300% 300%;
`;


export const MuiTextField = styled(TextField).attrs((props) => ({
  // variant:"standard"

}))`
font-size: 1rem;
width:100%;
border: none;
:focus {
  background-color: blue;
}
border-radius: ${borderRadius}px;
background-color: rgba(0, 0, 0, 0.025);
  /* background-color: transparent; */


& .Mui-focused {
  background-color: rgba(255, 255, 255, 1);
}

& .MuiFilledInput-root {

  border: none;
  background-color: blue;
}

& .MuiOutlinedInput-notchedOutline {
  border: none;
  
}

& .MuiOutlinedInput-input{ 
  
}

& .MuiInputBase-root{
  height:38px;
  border-radius: ${borderRadius}px;
  border-bottom: 2px solid white;
}
`;

