import React, { useState, useEffect } from "react";
import { getRandomArbitrary } from "utils";

//STYLEDCOMPONENTS
import * as S from "./style";

function ColorPicker(props) {
  const { onChange, startColor, minimalist, colorValue, small } = props;
  const [state, setState] = useState({
    displayColorPicker: false,
    color: {
      r: getRandomArbitrary(0, 255),
      g: getRandomArbitrary(0, 255),
      b: getRandomArbitrary(0, 255),
      a: 1
    },
  });



  useEffect(() => {
    if (startColor){
      !colorValue && setState({...state, color: startColor });
    }
  }, [startColor]);

  const handleClick = () => {

    if(!props.disabled){
      setState({ ...state, displayColorPicker: !state.displayColorPicker });
    }
  };

  const handleClose = () => {
    setState({...state, displayColorPicker: false });
  };

  const handleChange = (color) => {
    !colorValue && setState({ ...state, color: color.rgb });
    onChange(color);
  };






  return (
    <div>
   <S.Swatch onClick={handleClick} minimalist={minimalist} small={small}>
        <S.Color color={!colorValue ? state.color : (colorValue.rgb ? colorValue.rgb : colorValue)} small={small}/>
      </S.Swatch>
         
      {state.displayColorPicker ? (
        <S.Popover>
          <S.Cover onClick={handleClose} />
          <S.SketchPicker disableAlpha={true} color={!colorValue ? state.color : colorValue} onChange={handleChange} />
        </S.Popover>
      ) : null}
    </div>
  );
}

export default ColorPicker;
