import styled from "styled-components";
import BaseLottie from "react-lottie";
import { keyframes } from "styled-components";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;



export const Container = styled.div`
  overflow: hidden;
  padding:1px;
  /* border-radius: 100px; */
  border-radius: 50%;
  width: 65px;
  height: 65px;
  animation-name: ${rotate};
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LottieWrapper = styled.div`
position: relative;

border-radius: 50%;
width: 100%;
height: 100%;

overflow: hidden;
filter: blur(2px) saturate(2);
box-shadow:  0px 0px 0px 2px white;
`;

export const Svg = styled.img`
height:100%;
width: auto;
transform: scale(160%,160%);
`;


export const Lottie = styled(BaseLottie).attrs({
})``;