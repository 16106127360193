//LIBRARIES
import React from "react";
//STYLEDCOMPONENTS
import * as S from "./style";

function Fullscreen(props) {
  const { children, onClickOutside, ...others } = props;

  return (
    <S.Modal open={true} onClose={onClickOutside}>
        <S.ChildContainer>
          {onClickOutside && (
            <S.IconButton
              size={"30%"}
              onClick={onClickOutside}
              icon={"close"}
            />
          )}
          <div {...others}>{children}</div>
        </S.ChildContainer>
    </S.Modal>
  );
}

export default Fullscreen;
