//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledContainer as Container, 
  StyledReflex as Reflex, 
  StyledIcon as Icon
} from "./style";


export default function IconCap(props) {
   const {icon} = props;
  return (
    <Container>
        <Reflex></Reflex>
        <Icon name={icon}></Icon>
    </Container>
  );
}
 


