import React, { useEffect, lazy } from "react";
import { Routes, Route, useNavigate, useLocation, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "./mainLayout";
import FullscreenLayout from "./fullscreenLayout";
import Loading from "components/Other/Loading";
import { fetchProjects } from "components/User/Projects/state/actions";

// Constants for paths.
const DEFAULT_REDIRECT = '/library';
const LOGIN_PATH = '/authentication/login';
const WELCOME_PATH = '/welcome';
const FORBIDDEN_PATHS = [
    '/authentication/signup/success',
    '/verify-email',
    '/reset-password',
    '/join',
    '/maintenance'
];

function CombinedRouter(props) {
    const { routes, signedIn } = props;
    const projectsLoaded = useSelector((store) => store.projects.loaded);
    const projectsLoadError = useSelector((store) => store.projects.loadError);
    const projects = useSelector((store) => store.projects.payload);
    const hasProjects = projects.length > 0;
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!signedIn) {
            handleRedirectionForUnsignedUsers();
        } else if (projectsLoaded) {
            handleRedirectionForSignedUsers();
        } else {
            dispatch(fetchProjects());
        }
    }, [signedIn, projectsLoaded, dispatch]);

    function handleRedirectionForUnsignedUsers() {
        if (location.pathname === '/') {
            navigate(`${LOGIN_PATH}?redirect=${DEFAULT_REDIRECT}`);
        } else {
            for (let i = 0; i < routes.length; i++) {
                const matched = matchPath({
                    path: routes[i].path,
                    exact: true,
                    strict: true,
                }, location.pathname);
                if (matched && routes[i].projectRequired) {
                    navigate(`${LOGIN_PATH}?redirect=${location.pathname}`);
                    break;
                }
            }
        }
    }

    function handleRedirectionForSignedUsers() {
        if (hasProjects) {
            if (location.pathname === '/' || location.pathname === WELCOME_PATH) {
                navigate(DEFAULT_REDIRECT);
            }
        } else {
            let match = FORBIDDEN_PATHS.some(forbiddenPath => matchPath({
                path: forbiddenPath,
                exact: true,
                strict: true,
            }, location.pathname));
            if (!projectsLoadError && !match) {
                navigate(WELCOME_PATH);
            }
        }
    }

    if (signedIn && !projectsLoaded) {
        return <Loading />;
    } else {
        return (
            <Routes>
                {routes.map((route, key) => {
                    const Component = lazy(() => import("./" + route.importScreenPath));
                    if (route.type === "fullscreen") {
                        return (
                            <Route
                                exact
                                path={route.path}
                                element={<FullscreenLayout><Component /></FullscreenLayout>}
                                key={key}
                            />
                        );
                    } else if (route.type === "main") {
                        return (
                            <Route
                                exact
                                path={route.path}
                                element={<MainLayout withAppbar={false}><Component /></MainLayout>}
                                key={key}
                            />
                        );
                    }
                })}
            </Routes>
        );
    }
}

export default CombinedRouter;
