import * as Types from "./types";
import { toast } from 'components/UI';

function informationShow(message){
    return {
        type:Types.INFORMATION,
        payload:{
            message
        }
    }
}

function successShow(message){
    return {
        type:Types.SUCCESS,
        payload:{
            message
        }
    }
}

function warningShow(message){
    return {
        type:Types.WARNING,
        payload:{
            message
        }
    }
}

function errorShow(message){
    return {
        type:Types.ERROR,
        payload:{
            message
        }
    }
}


export function notify(type, message){
    return (dispatch) => {
        switch(type){
            case Types.INFORMATION:
                toast.info(message);
                dispatch(informationShow(message));
                break;
            case Types.SUCCESS:
                toast.success(message);
                dispatch(successShow(message));
                break;
            case Types.WARNING:
                toast.warn(message);
                dispatch(warningShow(message));
                break;
            case Types.ERROR:
                toast.error(message);
                dispatch(errorShow(message));
                break;
        }
    }
}
