//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledFlexboxHorizontal as FlexboxHorizontal,
  StyledFlexboxVertical as FlexboxVertical
} from "./style";

import {Typography} from 'components/UI'

function AccompaniedTitle(props) {
  const {loading, children, title, subtitle, size, connector, uppercase, paddingBottom, paddingTop, ...other } = props;


  return !loading ? (
    <FlexboxVertical paddingBottom={paddingBottom} paddingTop={paddingTop}>
      <FlexboxHorizontal>
      <Typography size={size} uppercase={uppercase}>
        {title}
      </Typography>
      {connector !== undefined && (
        <Typography color={"light"} weight={"regular"}>
          {connector}
        </Typography>
      )}
      {children !== undefined && (
        <Typography color={"light"} weight={"regular"} {...other}>
          {children}
        </Typography>
      )}
      </FlexboxHorizontal>
      
      {subtitle && 
        <Typography size={"xsi"} weight={"medium"} color={"light"}>
          {subtitle}
        </Typography>
      }
      
    </FlexboxVertical>
  ) : (
    <Typography loading size={size} />
  );
}
 

export default AccompaniedTitle;
