import * as Types from "./types";


const initialState = {
    loading:false,
    added:false,
    error:''
}


function AlgorithmsAddReducer(state=initialState, action){
    switch(action.type){
        case Types.ALGORITHM_IN_PROJECT_ADD:
            return {
                loading:true,
                added:false,
                error:''
            }
        
        case Types.ALGORITHM_IN_PROJECT_ADD_SUCCESS:
            return {
                loading:false,
                added:true,
                error:''
            }

        case Types.ALGORITHM_IN_PROJECT_ADD_FAILURE:
            return {
                loading:false,
                added:false,
                error:action.payload.error
            }
        default: return state
    }
}

export default AlgorithmsAddReducer;