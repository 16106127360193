import styled from "styled-components";
import {Input as BaseInput, Typography as BaseTypography, PrimaryActionButton as BaseButton, Fullscreen} from "components/UI"


export const Container = styled(Fullscreen).attrs({
  borderRadius:15
})`
  max-width: 350px;
  width: 100vw;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Input = styled(BaseInput)`
  width: 100%;
  margin-bottom:20px;
`;

export const Typography = styled(BaseTypography).attrs((props) => ({
}))`
  padding-bottom: 20px;
`;

export const Button = styled(BaseButton).attrs((props) => ({
  variant: "contained",
  color:"primary"
}))`
  align-self: flex-end;
  width: 100px;
`;