import React from "react";

//STYLEDCOMPONENTS
import * as S from "./style";

function Menu(props) {
  const { children, ...others } = props;

  return (
    <S.Menu  {...others} 
    // IconComponent={S.ArrowIcon}
    
      >
      {children}
    </S.Menu>
  );
}

export default Menu;


const MenuItem = React.forwardRef((props, ref) => {
  const { children, ...others } = props;

  return (
    <S.MenuItem 
    //innerRef={ref} 
    {...others}
    >
      {children}
    </S.MenuItem>
  );
});

export { MenuItem };
