import { combineReducers } from 'redux';
import AuthReducer from 'components/User/Auth/state/reducer';
import EmailVerificationReducer from 'components/User/Auth/EmailVerification/state/reducer';
import ForgotPasswordReducer from 'components/User/Auth/ForgotPassword/state/reducer';
import PasswordResetReducer from 'components/User/Auth/ForgotPassword/PasswordReset/state/reducer';
import PasswordChangeReducer from 'components/User/Account/Main/Security/state/reducer';
import ProfileReducer from 'components/User/Account/Main/Profile/state/reducer';

const UserReducer = combineReducers({
    auth: AuthReducer,
    emailVerification: EmailVerificationReducer,
    forgotPassword: ForgotPasswordReducer,
    passwordReset: PasswordResetReducer,
    passwordChange: PasswordChangeReducer,
    profile: ProfileReducer
});

export default UserReducer;