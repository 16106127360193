import React, { useState } from "react";

import { Divider } from "components/UI";
import { Link } from "react-router-dom";

import {
  FormControl,
  RadioGroup,
} from "@mui/material";

//STYLEDCOMPONENTS
import * as S from "./style";

function SelectInterval(props) {
  const { plan, period, setPeriod, ...others } = props;
  const [value, setValue] = React.useState(period);

  const handleChange = (event) => {
    setValue(event.target.value);
    setPeriod(event.target.value);
  };

  return (
    <S.Container>
      <FormControl>
        <RadioGroup
          value={value}
          onChange={handleChange}
          name="radio-buttons-group"
        >
          <S.FormControlLabel
            value="yearly"
            control={<S.Radio />}
            label={
              <S.LabelContainer selected={"yearly" === value}>
                <div className="plan_option">
                  <div className="interval">Yearly</div>
                  <div className="discount">Save 16%</div>
                </div>
                <div className="price">${plan.price.month_yearly}</div>
              </S.LabelContainer>
            }
          />
          <S.FormControlLabel
            value="monthly"
            control={<S.Radio />}
            label={
              <S.LabelContainer selected={"monthly" === value}>
                <div className="plan_option">
                  <div className="interval">Monthly</div>
                </div>
                <div className="price">${plan.price.month}</div>
              </S.LabelContainer>
            }
          />
        </RadioGroup>
        <Divider/>
        <S.TotalContainer>
          <div className="label">{"monthly" === value? 'Total':'Subtotal'}</div>
          <div className="amount">
            <div className="price">${"monthly" === value? plan.price.month:plan.price.month_yearly}</div>
            <div className="interval">&nbsp;/ {value==="yearly" ? "Month (billed yearly)":"Month"}</div>
          </div>
        </S.TotalContainer>
      </FormControl>
    </S.Container>
  );
}

export default SelectInterval;
