//LIBRARIES
import React from "react";


import {
    StyledTableContainer as TableContainer,
    StyledTableRow as TableRow,
    StyledTableCell as TableCell,
} from "./style";

//STYLEDCOMPONENTS
import {
    StyledSkeletonPulse as SkeletonBase,
    StyledBorder as Border,
  } from "./style";
  
  
function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export function SkeletonTable(props) {
    const {inverse,columns,rows} = props;
    return !inverse ? (
      <TableContainer>
        <TableRow>
          <TableCell>
            <Border>
              <SkeletonBase />
            </Border>
          </TableCell>
        </TableRow>
        {[...Array(rows ? rows:3)].map((e, i) => {
          return (
            <TableRow key={i}>
              {[...Array(columns ? columns:4)].map((e, i) => {
                return (
                  <TableCell width={getRandomArbitrary(60,100)} key={i}>
                    <Border>
                      <SkeletonBase />
                    </Border>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableContainer>
    ) : (
      <TableContainer>
        <TableRow >
          {[...Array(columns ? columns:4)].map((e, i) => {
            return (
              <TableCell key={i}>
                <Border>
                  <SkeletonBase />
                </Border>
              </TableCell>
            );
          })}
        </TableRow>
        {[...Array(rows ? rows:3)].map((e, i) => {
          return (
            <TableRow key={i}>
              <TableCell>
                <Border>
                  <SkeletonBase />
                </Border>
              </TableCell>
            </TableRow>
          );
        })}
      </TableContainer>
    );
  }
  