//LIBRARIES
import React from 'react';

//STYLEDCOMPONENTS
import { 
  StyledAnimation as Animation, 
} from "./style";

import { TransitionGroup, CSSTransition  } from "react-transition-group"
  
export default function Genetic(props) {
   const { children, id, duration, ...other } = props;
const finalDuration = duration ? duration : 300;
  return (
    <TransitionGroup>
      <CSSTransition
        key={id}
        //in={appear}
        //in={true}
        //appear={true}
        timeout={finalDuration}
        classNames="fade"
      >
        <Animation duration={finalDuration} {...other}>{children}</Animation>
      </CSSTransition>
    </TransitionGroup>
  );
}